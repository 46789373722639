/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from 'antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getOffsetHoursFromEntity } from '../services/utils';
import SelectUTCModale from './admin/selectUTCModale';

class SelectUTC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  openModale = () => this.setState({ modalVisible: true });

  closeModale = () => this.setState({ modalVisible: false });

  render() {
    const {
      onSave, onChange, value, entity,
    } = this.props;
    const { modalVisible } = this.state;
    const offsetHours = getOffsetHoursFromEntity(entity);

    return (
      <>
        <div
          className="flex"
          style={{
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Tooltip title="Click to edit">
            <a
              style={{
                fontSize: '12px',
                marginTop: '8px',
                border: '1px dotted #80808073',
                borderRadius: '4px',
                padding: '0px 4px',
                whiteSpace: 'nowrap',
              }}
              className="Link_grey"
              onClick={this.openModale}
            >
              {`UTC${(value !== null ? value : offsetHours) >= 0 ? '+' : ''}${value !== null ? value : offsetHours}`}
            </a>
          </Tooltip>
        </div>
        <SelectUTCModale
          value={value}
          visible={modalVisible}
          onChange={onChange}
          onClose={this.closeModale}
          onSave={onSave}
          entity={entity}
        />
      </>
    );
  }
}

SelectUTC.propTypes = {
  onSave: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  entity: PropTypes.object.isRequired,
};

SelectUTC.defaultProps = {
  onSave: () => {},
  value: null,
};
export default SelectUTC;
