import {
  call, put, all, takeEvery, takeLatest,
} from 'redux-saga/effects';

import { actions as ChallengesActions } from '@palette/state/Challenges/slice';
import { manageError as manageChallengesError } from '@palette/state/Challenges/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { isInPast, isUpcoming } from '@palette/helpers/ChallengeHelper';

import * as ChallengesService from '@palette/services/ChallengesService';

import * as ChallengeModel from '@palette/models/Challenge';
import * as ChallengeStatsModel from '@palette/models/ChallengeStats';

export function* list() {
  const callResult = yield call(ChallengesService.list);

  if (callResult.ok) {
    const challenges = ChallengeModel.transformList(callResult.data);
    const pastChallenges = [];
    const activeChallenges = [];

    challenges.forEach((challenge) => {
      if (isInPast(challenge)) {
        pastChallenges.push(challenge);
      } else {
        activeChallenges.push(challenge);
      }
    });

    const stats = ChallengeStatsModel.transform({
      past: pastChallenges.length,
      active: activeChallenges.length,
      activeAndPast: pastChallenges.length + activeChallenges.length,
      total: challenges.length,
    });

    yield put(ChallengesActions.setList({ activeChallenges, pastChallenges, stats }));
  } else {
    const error = manageChallengesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ChallengesActions.getListCompleted());
}

export function* listIC() {
  const callResult = yield call(ChallengesService.listIC);

  if (callResult.ok) {
    const challenges = ChallengeModel.transformList(callResult.data);
    const pastChallenges = [];
    const activeChallenges = [];

    challenges.forEach((challenge) => {
      if (isInPast(challenge)) {
        pastChallenges.push(challenge);
      } else {
        activeChallenges.push(challenge);
      }
    });

    const stats = ChallengeStatsModel.transform({
      past: pastChallenges.length,
      active: activeChallenges.length,
      activeAndPast: pastChallenges.length + activeChallenges.length,
      total: challenges.length,
    });

    yield put(ChallengesActions.setList({ activeChallenges, pastChallenges, stats }));
  } else {
    const error = manageChallengesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ChallengesActions.getListCompleted());
}

export function* getById({ payload = {} }) {
  const { challengeId } = payload;

  const callResult = yield call(ChallengesService.getById, { challengeId });

  if (callResult.ok) {
    const challenge = ChallengeModel.transform(callResult.data);
    yield put(ChallengesActions.setById({ challenge }));
  } else {
    const error = manageChallengesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ChallengesActions.getByIdCompleted());
}

export function* getICById({ payload = {} }) {
  const { challengeId } = payload;

  const callResult = yield call(ChallengesService.getICById, { challengeId });

  if (callResult.ok) {
    const challenge = ChallengeModel.transform(callResult.data);
    yield put(ChallengesActions.setById({ challenge }));
  } else {
    const error = manageChallengesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ChallengesActions.getByIdCompleted());
}

export function* getChallengeListForUser({ payload = {} }) {
  const { user } = payload;

  const callResult = yield call(ChallengesService.getChallengeListForUser, { userId: user.id });

  if (callResult.ok) {
    const challenges = ChallengeModel.transformList(callResult.data);

    const pastChallenges = [];
    const activeChallenges = [];
    const upcomingChallenges = [];

    challenges.forEach((challenge) => {
      if (isInPast(challenge)) {
        pastChallenges.push(challenge);
      } else if (isUpcoming(challenge)) {
        upcomingChallenges.push(challenge);
      } else {
        activeChallenges.push(challenge);
      }
    });

    const stats = ChallengeStatsModel.transform({
      past: pastChallenges.length,
      active: activeChallenges.length,
      activeAndPast: activeChallenges.length + pastChallenges.length,
      upcoming: upcomingChallenges.length,
      total: challenges.length,
    });

    yield put(ChallengesActions.setChallengeListForUser({ pastChallenges, activeChallenges, upcomingChallenges, stats }));
  } else {
    const error = manageChallengesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ChallengesActions.getChallengeListForUserCompleted());
}

export function* loop() {
  yield all([
    takeLatest(ChallengesActions.getList.type, list),
    takeLatest(ChallengesActions.getICList.type, listIC),
    takeEvery(ChallengesActions.getById.type, getById),
    takeEvery(ChallengesActions.getICById.type, getICById),
    takeEvery(ChallengesActions.getChallengeListForUser.type, getChallengeListForUser),
  ]);
}

export function* clean() {
  yield put(ChallengesActions.resetToInitialState());
}
