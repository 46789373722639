import React, { Component } from 'react';
import { Spin, Empty } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { listChallenges } from '../../../services/ic';
import { filterEntities, parseSearch } from '../../../services/utils';
import IndividualChallengeVignette from '../../../components/challenges/individualChallengeVignette';
import PeriodSelector from '../../../components/periodSelector';

import '../../../styles/challenges/mychallenges.less';

class MyChallenges extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, challenges: null };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.listChallenges();
  }

  listChallenges = () => {
    this.setState({ loading: true }, () => {
      listChallenges().then(({ data: challenges }) => {
        this.setState({
          challenges,
          loading: false,
        });
      }).catch((e) => {
        console.error('Error while loading challenges', e);
        this.setState({ loading: false });
      });
    });
  };

  render() {
    const { loading } = this.state;
    const { type } = parseSearch(this.props.location.search);
    const challenges = filterEntities(this.state.challenges || [], type);

    return (
      <div>
        <div className="div-wrapper My_challenges_wrapper">
          <div className="container">
            <div className="container-inner">
              <PeriodSelector />
              <Spin spinning={loading} size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <div className="My_challenges_elements_wrapper">
                  {(challenges && challenges.length > 0)
                    ? challenges.map((c) => (
                      <IndividualChallengeVignette
                        key={c._id}
                        challenge={c}
                      />
                    ))
                    : (!loading && <div className="center width-100"><Empty description={<span className="grey">No Challenge to display</span>} /></div>)}
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MyChallenges.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(MyChallenges);
