import Axios from 'axios';
import { API_URL } from '../constants';
import { getSessionId } from '../utils';

export const getSlackOAuthUrl = () => Axios.post(`${API_URL}/users/admin/company/get-slack-oauth-url`, {}, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const listSlackChannels = () => Axios.post(`${API_URL}/users/admin/company/list-slack-channels`, {}, {
  headers: {
    sessionId: getSessionId(),
  },
});

export default {};
