/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import _mergeWith from 'lodash/mergeWith';

import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _cloneDeep from 'lodash/cloneDeep';
import { original } from 'immer';

import { LIST_TYPES } from '@palette/constants/challenges';
import { CHALLENGES_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

/*
 * Initial State
 */
const initialState = {
  getListIsPending: false,
  getByIdIsPending: false,
  getChallengeListForUserIsPending: false,

  listsByType: Object.values(LIST_TYPES)
    .reduce((res, type) => ({
      ...res,
      [type]: [],
    }), {}),
  listStats: {},
  byId: {},
  challengeListForUser: {
    [CHALLENGES_PAGE_CONTENT_TABS_IDS.PAST]: [],
    [CHALLENGES_PAGE_CONTENT_TABS_IDS.ACTIVE]: [],
    [CHALLENGES_PAGE_CONTENT_TABS_IDS.UPCOMING]: [],
    stats: {},
  },
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'challenges',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* list */
    getList: (state) => {
      state.getListIsPending = true;
    },
    getICList: (state) => {
      state.getListIsPending = true;
    },
    setList: (state, { payload }) => {
      const { activeChallenges = [], pastChallenges = [], stats } = payload;

      state.listsByType[LIST_TYPES.ACTIVE] = _map(activeChallenges, 'id');
      state.listsByType[LIST_TYPES.PAST] = _map(pastChallenges, 'id');

      state.byId = _keyBy(activeChallenges.concat(pastChallenges), 'id');

      state.listStats = stats;
    },
    getListCompleted: (state) => {
      state.getListIsPending = false;
    },
    /* Get By Id */
    getById: (state) => {
      state.getByIdIsPending = true;
    },
    getICById: (state) => {
      state.getByIdIsPending = true;
    },
    setById: (state, { payload }) => {
      const { challenge } = payload;

      const clonedById = _cloneDeep(original(state.byId));
      clonedById[challenge.id] = challenge;

      state.byId = clonedById;
    },
    getByIdCompleted: (state) => {
      state.getByIdIsPending = false;
    },
    /* Get challenge list for user */
    getChallengeListForUser: (state) => {
      state.getChallengeListForUserIsPending = true;
    },
    setChallengeListForUser: (state, { payload }) => {
      const { pastChallenges = [], activeChallenges = [], upcomingChallenges = [], stats } = payload;

      state.challengeListForUser[CHALLENGES_PAGE_CONTENT_TABS_IDS.PAST] = _map(pastChallenges, 'id');
      state.challengeListForUser[CHALLENGES_PAGE_CONTENT_TABS_IDS.ACTIVE] = _map(activeChallenges, 'id');
      state.challengeListForUser[CHALLENGES_PAGE_CONTENT_TABS_IDS.UPCOMING] = _map(upcomingChallenges, 'id');

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(activeChallenges.concat(pastChallenges, upcomingChallenges), 'id'),
      );

      state.challengeListForUser.stats = stats;
    },
    getChallengeListForUserCompleted: (state) => {
      state.getChallengeListForUserIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getListsByType = (state) => root(state).listsByType;
const getListStats = (state) => root(state).listStats;
const getById = (state) => root(state).byId;
const getICById = (state) => root(state).byId;
const rawChallengeListForUser = (state) => root(state).challengeListForUser;

const getListIsPending = (state) => root(state).getListIsPending;
const getByIdIsPending = (state) => root(state).getByIdIsPending;
const getChallengeListForUserIsPending = (state) => root(state).getChallengeListForUserIsPending;

const getChallengesList = createSelector(
  [getListsByType, getById],
  (listsByType, byId) => {
    const allChallengesIds = Object.keys(listsByType).reduce((allIds, listsByTypeKey) => (allIds.concat(listsByType[listsByTypeKey])), []);
    return allChallengesIds.map((challengeId) => byId[challengeId] || null).filter(Boolean);
  },
);

const getChallengesListByType = createSelector(
  [getListsByType, getById, getProps],
  (listsByType, byId, { listType = LIST_TYPES.ACTIVE }) => listsByType[listType].map((challengeId) => byId[challengeId] || null).filter(Boolean),
);

const getChallengeById = createSelector(
  [getById, getProps],
  (byId, { challengeId }) => byId[challengeId] || null,
);

const getICChallengeById = createSelector(
  [getICById, getProps],
  (byId, { challengeId }) => byId[challengeId] || null,
);

const getChallengeListForUser = createSelector(
  [rawChallengeListForUser, getById],
  (challengeListForUser, byId) => ({
    ...challengeListForUser,
    [CHALLENGES_PAGE_CONTENT_TABS_IDS.PAST]: challengeListForUser[CHALLENGES_PAGE_CONTENT_TABS_IDS.PAST].map((planId) => byId[planId] || null).filter(Boolean),
    [CHALLENGES_PAGE_CONTENT_TABS_IDS.ACTIVE]: challengeListForUser[CHALLENGES_PAGE_CONTENT_TABS_IDS.ACTIVE].map((planId) => byId[planId] || null).filter(Boolean),
    [CHALLENGES_PAGE_CONTENT_TABS_IDS.UPCOMING]: challengeListForUser[CHALLENGES_PAGE_CONTENT_TABS_IDS.UPCOMING].map((planId) => byId[planId] || null).filter(Boolean),
  }),
);

export const selectors = {
  getListIsPending,
  getByIdIsPending,
  getChallengeListForUserIsPending,

  getListStats,
  getChallengesList,
  getChallengesListByType,
  getChallengeById,
  getICChallengeById,
  getChallengeListForUser,
};
