import { slice } from '@palette/state/Challenges/slice';
import { loop, clean } from '@palette/state/Challenges/sagas';

export { actions, selectors } from '@palette/state/Challenges/slice';

export default {
  slice,
  loop,
  clean,
};
