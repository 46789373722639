import PropTypes from 'prop-types';

import { manageArrayAttribute } from '@palette/helpers/ModelHelper';

import * as ChallengeModel from '@palette/models/Challenge';

export const modelName = 'ListChallengesData';

export const propTypes = PropTypes.shape({
  challenges: PropTypes.arrayOf(ChallengeModel.propTypes),
});

export const transform = (listChallengesData) => {
  if (!listChallengesData) {
    return null;
  }

  return (
    {
      challenges: ChallengeModel.transformList(manageArrayAttribute(listChallengesData, 'challenges')),
    }
  );
};
