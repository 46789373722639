/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

import _keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import _assign from 'lodash/assign';

const getFoldersListAndById = (folders) => {
  let byId = {};
  const updatedFolders = folders.map((folder) => {
    const updatedFolder = { ...folder };
    if (folder.children.length > 0) {
      const { list: subFoldersList, byId: subFoldersById } = getFoldersListAndById(folder.children);
      updatedFolder.children = subFoldersList;

      byId = _merge(byId, subFoldersById);
    }

    return updatedFolder;
  });

  const list = _map(updatedFolders, 'id');
  byId = _merge(byId, _keyBy(updatedFolders, 'id'));

  return {
    list,
    byId,
  };
};

// eslint-disable-next-line no-use-before-define
const buildFolders = (foldersIds, byId) => foldersIds.map((folderId) => (buildFolder(folderId, byId)));

const buildFolder = (folderId, byId) => {
  const folder = byId[folderId] || null;

  if (folder === null) return null;

  return {
    ...folder,
    children: buildFolders(folder.children, byId),
  };
};

/*
 * Initial State
 */
const initialState = {
  createFolderIsPending: false,
  getListIsPending: false,
  list: [],
  byId: {},
  renameFolderIsPending: false,
  deleteFolderIsPending: false,
  moveFolderIsPending: false,
  moveEntitiesIsPending: false,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get list */
    getList: (state) => {
      state.getListIsPending = true;
    },
    setList: (state, { payload }) => {
      const { folders = [] } = payload;

      const { list, byId } = getFoldersListAndById(folders);

      state.list = list;
      state.byId = byId;
    },
    getListCompleted: (state) => {
      state.getListIsPending = false;
    },
    /* Create folder */
    createFolder: (state) => {
      state.createFolderIsPending = true;
    },
    addFolder: (state, { payload }) => {
      const { folder, merge = false } = payload;
      state.list.push(folder.id);
      if (merge) {
        state.byId = _merge(state.byId, { [folder.id]: folder });
      } else {
        state.byId = _assign(state.byId, { [folder.id]: folder });
      }
    },
    createFolderCompleted: (state) => {
      state.createFolderIsPending = false;
    },
    /* Rename folder */
    renameFolder: (state) => {
      state.renameFolderIsPending = true;
    },
    renameFolderCompleted: (state) => {
      state.renameFolderIsPending = false;
    },
    /* Delete folder */
    deleteFolder: (state) => {
      state.deleteFolderIsPending = true;
    },
    deleteFolderCompleted: (state) => {
      state.deleteFolderIsPending = false;
    },
    /* Move folder */
    moveFolder: (state) => {
      state.moveFolderIsPending = true;
    },
    moveFolderCompleted: (state) => {
      state.moveFolderIsPending = false;
    },
    /* Move entities */
    moveEntities: (state) => {
      state.moveEntitiesIsPending = true;
    },
    moveEntitiesCompleted: (state) => {
      state.moveEntitiesIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getList = (state) => root(state).list;
const getById = (state) => root(state).byId;

const getListIsPending = (state) => root(state).getListIsPending;
const createFolderIsPending = (state) => root(state).createFolderIsPending;
const renameFolderIsPending = (state) => root(state).renameFolderIsPending;
const deleteFolderIsPending = (state) => root(state).deleteFolderIsPending;
const moveFolderIsPending = (state) => root(state).moveFolderIsPending;
const moveEntitiesIsPending = (state) => root(state).moveEntitiesIsPending;

const getFoldersList = createSelector(
  [getList, getById],
  (listIds, byId) => buildFolders(listIds, byId),
);

const getFolderById = createSelector(
  [getById, getProps],
  (byId, { folderId }) => buildFolder(folderId, byId),
);

export const selectors = {
  getListIsPending,
  createFolderIsPending,
  renameFolderIsPending,
  deleteFolderIsPending,
  moveFolderIsPending,
  moveEntitiesIsPending,

  getFoldersList,
  getFolderById,
};
