import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Drawer as AntDDrawer } from 'antd';

import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';
import MyMasterPlansListSelect from '@palette/components/ic/myMasterPlan/MyMasterPlansListSelect/MyMasterPlansListSelect';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import WidgetCard from '@palette/components/widgets/dashboard/WidgetCard/WidgetCard';
import Button from '@palette/components/designSystem/Button/Button';
import AddLine from '@palette/components/utils/Icons/AddLine';
import MyChallengesListSelect from '@palette/components/ic/myChallenge/MyChallengesListSelect/MyChallengesListSelect';
import MasterPlansListForCandidateUserSelect from '@palette/components/dashboard/MasterPlansListForCandidateUserSelect/MasterPlansListForCandidateUserSelect';
import ChallengesListForCandidateSelect from '@palette/components/dashboard/ChallengesListForCandidateSelect/ChallengesListForCandidateSelect';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useKeyInSearch } from '@palette/hooks/NavigationHooks';
import { useDashboardPresetInParams } from '@palette/hooks/DashboardPresetHooks';

import { buildWidgetParams } from '@palette/helpers/DashboardHelper';
import { hasFeature, hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import {
  COMMON_DASHBOARD_WIDGETS,
  DASHBOARD_DRAWER_ADD_WIDGET_DRAWER_QS_KEY,
  PLAN_DASHBOARD_WIDGETS,
  CHALLENGE_DASHBOARD_WIDGETS,
} from '@palette/constants/dashboard';
import { FEATURES, RIGHTS } from '@palette/constants/profile';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { selectors as ChallengesSelectors } from '@palette/state/Challenges';
import { actions as DashboardActions } from '@palette/state/Dashboard';
import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './AddDashboardWidgetDrawer.less';

const classNames = bindClassNames.bind(styles);

const AddDashboardWidgetDrawer = ({ className, forDashboardPreset, ...otherProps }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const { dashboardPreset } = useDashboardPresetInParams();

  const [selectedWidgetType, setSelectedWidgetType] = useState(null);

  const [commonWidgetsShowMore, setCommonWidgetsShowMore] = useState(false);

  const [currentSelectedPlanId, setCurrentSelectedPlanId] = useState(null);
  const [currentSelectedChallengeId, setCurrentSelectedChallengeId] = useState(null);

  const [addWidgetDrawerVisible] = useKeyInSearch(DASHBOARD_DRAWER_ADD_WIDGET_DRAWER_QS_KEY);

  const dashboardListStats = useSelector(MasterPlansSelectors.getListStats);
  const dashboardChallengesListStats = useSelector(ChallengesSelectors.getListStats);
  const dashboardCurrentSelectedPlan = useSelector((state) => MasterPlansSelectors.getICMasterPlanById(state, { masterPlanId: currentSelectedPlanId }));
  const dashboardCurrentSelectedChallenge = useSelector((state) => ChallengesSelectors.getICChallengeById(state, { challengeId: currentSelectedChallengeId }));

  const dashboardPresetListStats = useSelector(DashboardPresetsSelectors.getPlanListForCandidateStats);
  const dashboardPresetChallengesListStats = useSelector(DashboardPresetsSelectors.getChallengeListForCandidateStats);
  const dashboardPresetCurrentSelectedPlan = useSelector((state) => DashboardPresetsSelectors.getMasterPlanForCandidateById(state, { masterPlanId: currentSelectedPlanId }));
  const dashboardPresetCurrentSelectedChallenge = useSelector((state) => DashboardPresetsSelectors.getChallengeForCandidateById(state, { challengeId: currentSelectedChallengeId }));

  const { listStats, challengesListStats, currentSelectedPlan, currentSelectedChallenge } = useMemo(() => {
    if (forDashboardPreset) {
      return {
        listStats: dashboardPresetListStats,
        challengesListStats: dashboardPresetChallengesListStats,
        currentSelectedPlan: dashboardPresetCurrentSelectedPlan,
        currentSelectedChallenge: dashboardPresetCurrentSelectedChallenge,
      };
    }

    return {
      listStats: dashboardListStats,
      challengesListStats: dashboardChallengesListStats,
      currentSelectedPlan: dashboardCurrentSelectedPlan,
      currentSelectedChallenge: dashboardCurrentSelectedChallenge,
    };
  }, [
    forDashboardPreset,
    dashboardPresetListStats,
    dashboardPresetChallengesListStats,
    dashboardPresetCurrentSelectedPlan,
    dashboardPresetCurrentSelectedChallenge,
    dashboardListStats,
    dashboardChallengesListStats,
    dashboardCurrentSelectedPlan,
    dashboardCurrentSelectedChallenge,
  ]);

  const handleOnClose = useCallback(() => {
    dispatch(NavigationActions.cleanLocationSearch({ keysToDelete: [DASHBOARD_DRAWER_ADD_WIDGET_DRAWER_QS_KEY] }));
  });

  const handleSelectWidget = useCallback((widgetType) => {
    setSelectedWidgetType(widgetType);
  }, []);

  const onAddWidgetSuccess = useCallback(() => {
    setSelectedWidgetType(null);
    setCurrentSelectedPlanId(null);
    setCurrentSelectedChallengeId(null);
    handleOnClose();
  }, [handleOnClose]);

  const handleAddWidget = useCallback(() => {
    if (selectedWidgetType === null) return;

    const allParams = {
      planId: currentSelectedPlanId,
      challengeId: currentSelectedChallengeId,
    };

    if (forDashboardPreset) {
      if (dashboardPreset !== null) {
        dispatch(DashboardPresetsActions.addWidget({
          presetId: dashboardPreset.id,
          widgetType: selectedWidgetType,
          params: buildWidgetParams(selectedWidgetType, allParams),
          onSuccessCB: onAddWidgetSuccess,
        }));
      }
    } else {
      dispatch(DashboardActions.addWidget({ widgetType: selectedWidgetType, params: buildWidgetParams(selectedWidgetType, allParams), onSuccessCB: onAddWidgetSuccess }));
    }
  }, [
    currentSelectedPlanId,
    currentSelectedChallengeId,
    selectedWidgetType,
    onAddWidgetSuccess,
    forDashboardPreset,
    dashboardPreset,
  ]);

  const commonWidgetsNode = useMemo(() => {
    if (
      hasFeature(profile, FEATURES.COMPENSATION)
      && (
        hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])
        || (
          forDashboardPreset
          && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])
        )
      )
    ) {
      const widgetCards = COMMON_DASHBOARD_WIDGETS
        .filter((widgetDescription) => (widgetDescription.isAvailableForProfile(profile)))
        .map((widgetDescription) => (
          <WidgetCard
            className={styles.widgetCard}
            key={widgetDescription.type}
            widgetDescription={widgetDescription}
            selected={selectedWidgetType === widgetDescription.type}
            onClick={handleSelectWidget}
          />
        ));

      return (
        <div className={styles.widgetsCategoryWrapper}>
          <div className={styles.widgetsCategoryTitle}>
            {t('addDashboardWidgetDrawer.category.common.label')}
          </div>
          <div
            className={classNames({
              widgetsWrapper: true,
              displayShowMore: !commonWidgetsShowMore,
            })}
          >
            <div
              className={styles.widgetCards}
            >
              {widgetCards}
            </div>
            <Button
              className={styles.showMoreBtn}
              type="secondary"
              onClick={() => setCommonWidgetsShowMore(true)}
            >
              {t('addDashboardWidgetDrawer.category.common.showMore')}
            </Button>
          </div>
        </div>
      );
    }

    return null;
  }, [
    profile,
    selectedWidgetType,
    handleSelectWidget,
    commonWidgetsShowMore,
    forDashboardPreset,
  ]);

  const planWidgetsListNode = useMemo(() => {
    if (listStats?.activeAndPast === undefined || listStats?.activeAndPast === 0) return null;

    if (currentSelectedPlanId === null) {
      return (
        <DefaultEmptyState
          description={t('addDashboardWidgetDrawer.category.plan.chooseAPlan')}
          image={(
            <img
              className={styles.emptyStateImage}
              src="/img/dashboardComponents/previewPlans.png"
              alt="preview plan widgets"
            />
          )}
          size="compact"
        />
      );
    }

    const widgetCards = PLAN_DASHBOARD_WIDGETS
      .filter((widgetDescription) => (widgetDescription.isAvailableForProfileAndPlan(profile, currentSelectedPlan)))
      .map((widgetDescription) => (
        <WidgetCard
          className={styles.widgetCard}
          key={widgetDescription.type}
          widgetDescription={widgetDescription}
          selected={selectedWidgetType === widgetDescription.type}
          onClick={handleSelectWidget}
        />
      ));

    return (
      <div className={styles.widgetCards}>
        {widgetCards}
      </div>
    );
  }, [
    profile,
    selectedWidgetType,
    listStats,
    currentSelectedPlanId,
    currentSelectedPlan,
    handleSelectWidget,
  ]);

  const planWidgetsNode = useMemo(() => {
    if (
      hasFeature(profile, FEATURES.COMPENSATION)
      && (
        hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])
        || (
          forDashboardPreset
          && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])
        )
      )
    ) {
      let plansListSelectNode = (
        <MyMasterPlansListSelect
          className={styles.planSelect}
          value={currentSelectedPlanId}
          onChange={setCurrentSelectedPlanId}
        />
      );
      if (forDashboardPreset) {
        plansListSelectNode = (
          <MasterPlansListForCandidateUserSelect
            className={styles.planSelect}
            value={currentSelectedPlanId}
            onChange={setCurrentSelectedPlanId}
          />
        );
      }

      return (
        <div className={styles.widgetsCategoryWrapper}>
          <div className={styles.widgetsCategoryTitle}>
            {t('addDashboardWidgetDrawer.category.plan.label')}
          </div>
          <div className={styles.widgetsWrapper}>
            {plansListSelectNode}
            {planWidgetsListNode}
          </div>
        </div>
      );
    }

    return null;
  }, [
    profile,
    currentSelectedPlanId,
    planWidgetsListNode,
    forDashboardPreset,
  ]);

  const challengeWidgetsListNode = useMemo(() => {
    if (challengesListStats?.activeAndPast === undefined || challengesListStats?.activeAndPast === 0) return null;

    if (currentSelectedChallengeId === null) {
      return (
        <DefaultEmptyState
          description={t('addDashboardWidgetDrawer.category.challenge.chooseAChallenge')}
          image={(
            <img
              className={styles.emptyStateImage}
              src="/img/dashboardComponents/previewChallenges.png"
              alt="preview challenge widgets"
            />
          )}
          size="compact"
        />
      );
    }

    const widgetCards = CHALLENGE_DASHBOARD_WIDGETS
      .filter((widgetDescription) => (widgetDescription.isAvailableForProfileAndChallenge(profile, currentSelectedChallenge)))
      .map((widgetDescription) => (
        <WidgetCard
          className={styles.widgetCard}
          key={widgetDescription.type}
          widgetDescription={widgetDescription}
          selected={selectedWidgetType === widgetDescription.type}
          onClick={handleSelectWidget}
        />
      ));

    return (
      <div className={styles.widgetCards}>
        {widgetCards}
      </div>
    );
  }, [
    selectedWidgetType,
    challengesListStats,
    currentSelectedChallengeId,
    currentSelectedChallenge,
    handleSelectWidget,
  ]);

  const challengeWidgetsNode = useMemo(() => {
    if (
      hasFeature(profile, FEATURES.CHALLENGE)
      && (
        hasAtLeastOneRight(profile, [RIGHTS.IC.CHALLENGES])
        || (
          forDashboardPreset
          && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])
        )
      )
    ) {
      let challengesListSelectNode = (
        <MyChallengesListSelect
          className={styles.challengeSelect}
          value={currentSelectedChallengeId}
          onChange={setCurrentSelectedChallengeId}
        />
      );
      if (forDashboardPreset) {
        challengesListSelectNode = (
          <ChallengesListForCandidateSelect
            className={styles.challengeSelect}
            value={currentSelectedChallengeId}
            onChange={setCurrentSelectedChallengeId}
          />
        );
      }

      return (
        <div className={styles.widgetsCategoryWrapper}>
          <div className={styles.widgetsCategoryTitle}>
            {t('addDashboardWidgetDrawer.category.challenge.label')}
          </div>
          <div className={styles.widgetsWrapper}>
            {challengesListSelectNode}
            {challengeWidgetsListNode}
          </div>
        </div>
      );
    }

    return null;
  }, [
    profile,
    currentSelectedChallengeId,
    challengeWidgetsListNode,
    forDashboardPreset,
  ]);

  const addWidgetBtnNode = useMemo(() => (
    <div className={styles.addWidgetBtnWrapper}>
      <Button
        icon={(<AddLine />)}
        disabled={selectedWidgetType === null}
        onClick={handleAddWidget}
      >
        {t('addDashboardWidgetDrawer.addWidget')}
      </Button>
    </div>
  ), [selectedWidgetType]);

  const drawerContentNode = useMemo(() => {
    if (commonWidgetsNode === null && planWidgetsNode === null && challengeWidgetsNode === null) {
      return (
        <div className={styles.content}>
          <DefaultEmptyState description={t('addDashboardWidgetDrawer.noWidgetsAvailable')} />
        </div>
      );
    }

    return (
      <div className={styles.content}>
        {commonWidgetsNode}
        {planWidgetsNode}
        {challengeWidgetsNode}
      </div>
    );
  }, [
    commonWidgetsNode,
    planWidgetsNode,
    challengeWidgetsNode,
  ]);

  const drawerActionNode = useMemo(() => (
    <div className={styles.actionWrapper}>
      {addWidgetBtnNode}
    </div>
  ), [addWidgetBtnNode]);

  return (
    <AntDDrawer
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('addDashboardWidgetDrawer.title')}
      placement="right"
      width={600}
      onClose={handleOnClose}
      visible={addWidgetDrawerVisible !== null}
      closeIcon={<ClosePopupLine width={28} height={28} />}
      {...otherProps}
    >
      {drawerContentNode}
      {drawerActionNode}
    </AntDDrawer>
  );
};

AddDashboardWidgetDrawer.propTypes = {
  className: PropTypes.string,
  forDashboardPreset: PropTypes.bool,
};

AddDashboardWidgetDrawer.defaultProps = {
  className: '',
  forDashboardPreset: false,
};

export default AddDashboardWidgetDrawer;
