/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  DeleteOutlined, PlusOutlined, MoreOutlined, CloseOutlined,
} from '@ant-design/icons';
import {
  Button, Card, Input, Select, Dropdown, Menu,
} from 'antd';
import AutoCompleteInput from '../../autoCompleteInput';
import '../../../styles/admin/payoutRuleEditor.less';

const mapRules = (rules, challengeType) => rules.map(({
  conditionFormula,
  ...rule
}) => ({
  ...rule,
  from: rule.from !== 'Infinity' ? rule.from + 1 : rule.from,
  to: rule.to !== 'Infinity' ? rule.to + 1 : rule.to,
  ...(
    typeof conditionFormula !== 'undefined'
      ? { conditionFormula }
      : (
        challengeType === 'INDIVIDUAL'
          ? { conditionFormula: '' }
          : {}
      )
  ),
  useRank: rule.from === rule.to,
}));

class RewardRuleEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: mapRules(props.initialRules, props.challengeType),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.initialRules)
      !== JSON.stringify(this.props.initialRules)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ rules: mapRules(this.props.initialRules, this.props.challengeType) });
    }
  }

  /* eslint-disable-next-line react/no-unused-class-component-methods */
  getRules() {
    return this.state.rules.map((rule) => ({
      ...(this.props.challengeType === 'INDIVIDUAL' ? {
        from: 0,
        to: 'Infinity',
      } : {
        from: rule.from !== 'Infinity' ? rule.from - 1 : rule.from,
        to: rule.to !== 'Infinity' ? rule.to - 1 : rule.to,
      }),
      ...(typeof rule.conditionFormula !== 'undefined' ? { conditionFormula: rule.conditionFormula } : {}),
      rewards: rule.rewards.map(({
        type,
        amount,
        description,
      }) => ({
        type,
        amount,
        ...(type === 'OTHER' ? { description: description || '' } : {}),
      })),
    }));
  }

  addRule = () => {
    this.state.rules.push({
      from: '',
      to: '',
      ...(
        this.props.challengeType === 'INDIVIDUAL'
          ? { conditionFormula: '' }
          : {}
      ),
      useRank: true,
      rewards: [],
    });
    this.setState({ rules: this.state.rules });
  };

  deleteRule = (ruleIndex) => {
    this.state.rules.splice(ruleIndex, 1);
    this.setState({ rules: this.state.rules });
  };

  addItem = (ruleIndex) => {
    this.state.rules[ruleIndex].rewards.push({
      type: 'MONEY',
      amount: '',
    });
    this.setState({ rules: this.state.rules });
  };

  deleteItem = (ruleIndex, itemIndex) => {
    this.state.rules[ruleIndex].rewards.splice(itemIndex, 1);
    this.setState({ rules: this.state.rules });
  };

  updateRuleField = (field, ruleIndex) => (e) => {
    const { rules } = this.state;
    if (Array.isArray(field)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const f of field) {
        rules[ruleIndex][f] = e.target.value;
      }
    } else {
      rules[ruleIndex][field] = e.target.value;
    }
    this.setState({ rules });
  };

  updateRuleFieldAutoComplete = (field, ruleIndex) => (value) => {
    const { rules } = this.state;
    if (Array.isArray(field)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const f of field) {
        rules[ruleIndex][f] = value;
      }
    } else {
      rules[ruleIndex][field] = value;
    }
    this.setState({ rules });
  };

  updateItemField = (field, ruleIndex, itemIndex) => (e) => {
    const { rules } = this.state;
    rules[ruleIndex].rewards[itemIndex][field] = e.target.value;
    this.setState({ rules });
  };

  switchConditionFormula = (ruleIndex) => () => {
    const item = this.state.rules[ruleIndex];
    if (typeof item.conditionFormula !== 'undefined') {
      delete item.conditionFormula;
    } else {
      item.conditionFormula = '';
    }
    this.setState({ rules: this.state.rules });
  };

  switchUseRank = (ruleIndex) => () => {
    const item = this.state.rules[ruleIndex];
    item.useRank = !item.useRank;
    this.setState({ rules: this.state.rules });
  };

  buildAutoSuggest = () => {
    const { challenge, challengeType } = this.props;
    const { type } = challenge.groupByObject;
    const params = ['globalScore', 'scores'];
    const types = [
      type,
    ];
    if ((challengeType === 'PUBLIC_LEADERBOARD'
    || challengeType === 'ANONYMOUS_LEADERBOARD')) {
      types.push('Team');
    }
    const typesWithParams = types.map((t) => params.map((p) => `${t}.${p}`)).flat();
    const trigger = [
      ' ',
      '',
      `${type}.`,
      ((challengeType === 'PUBLIC_LEADERBOARD'
      || challengeType === 'ANONYMOUS_LEADERBOARD') ? 'Team.' : ''),
    ];
    const options = {
      ' ': typesWithParams,
      '': typesWithParams,
      [`${type}.`]: params,
      ...((challengeType === 'PUBLIC_LEADERBOARD'
      || challengeType === 'ANONYMOUS_LEADERBOARD') ? { 'Team.': params } : {}),
    };
    return { options, trigger };
  };

  render() {
    const { options, trigger } = this.buildAutoSuggest();
    return (
      <div className="PayoutRuleEditor">
        {this.state.rules.map((rule, ruleIndex) => (
          <Card bordered key={rule._id || ruleIndex} className="RewardRuleItem RuleItem">
            <div className="Reward_rule_option">
              <Dropdown
                trigger="click"
                className="More_options"
                overlay={(
                  <Menu>
                    <Menu.Item
                      icon={<PlusOutlined />}
                      onClick={this.switchConditionFormula(ruleIndex)}
                    >
                      Add a condition
                    </Menu.Item>
                    <Menu.Item
                      icon={<DeleteOutlined />}
                      onClick={() => this.deleteRule(ruleIndex)}
                      danger
                    >
                      Delete this rule
                    </Menu.Item>
                  </Menu>
                )}
              >
                <div className="Rule_item_option">
                  <MoreOutlined />
                </div>

              </Dropdown>
            </div>
            <h3 className="bold">
              Rule #
              {ruleIndex + 1}
            </h3>
            {this.props.challengeType !== 'INDIVIDUAL' && (rule.useRank ? (
              <div className="flex">
                <Input
                  addonBefore="Rank n°"
                  value={rule.from}
                  onChange={this.updateRuleField(['from', 'to'], ruleIndex)}
                />
                <Button
                  type="link"
                  onClick={this.switchUseRank(ruleIndex)}
                >
                  Use a range of rank
                </Button>
              </div>
            ) : (
              <div className="flex">
                <Input
                  addonBefore="From"
                  value={rule.from}
                  onChange={this.updateRuleField('from', ruleIndex)}
                />
                <Input
                  addonBefore="To"
                  value={rule.to}
                  onChange={this.updateRuleField('to', ruleIndex)}
                />
                <Button
                  type="link"
                  onClick={this.switchUseRank(ruleIndex)}
                >
                  Use an exact rank
                </Button>
              </div>
            ))}
            <div className="flex" style={{ justifyContent: 'flex-end' }}>
              {typeof rule.conditionFormula !== 'undefined' && (
                <div className="flex width-100" style={{ marginTop: '4px' }}>
                  <div className="Condition flex">
                    <div className="AddonBefore">
                      <span className="bold">IF</span>
                    </div>
                    <div className="width-100">
                      <AutoCompleteInput
                        required
                        placeholder="Condition for the reward"
                        value={rule.conditionFormula}
                        onChange={this.updateRuleFieldAutoComplete('conditionFormula', ruleIndex)}
                        style={{ flexGrow: '1' }}
                        trigger={trigger}
                        options={options}
                      />
                    </div>
                  </div>

                  {this.props.challengeType !== 'INDIVIDUAL' && (
                    <Button
                      ghost
                      className="PayoutRules_delete_button grey"
                      style={{ marginLeft: '4px' }}
                      onClick={this.switchConditionFormula(ruleIndex)}
                    >
                      <CloseOutlined />
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div>
              {rule.rewards.map((reward, itemIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={itemIndex} className="RewardRuleEditor_reward_element_wrapper">
                  <div className="flex" style={{ alignItems: 'center' }}>
                    <b style={{ minWidth: '60px' }}>
                      Reward
                    </b>
                    <Select
                      style={{ width: 120, marginRight: '4px' }}
                      value={reward.type}
                      onChange={(value) => this.updateItemField('type', ruleIndex, itemIndex)({ target: { value } })}
                    >
                      <Select.Option value="MONEY">Money</Select.Option>
                      <Select.Option value="OTHER">Other</Select.Option>
                    </Select>
                    {reward.type === 'OTHER' && (
                      <Input
                        addonBefore="Description"
                        value={reward.description}
                        onChange={this.updateItemField('description', ruleIndex, itemIndex)}
                      />
                    )}
                    <Input
                      addonBefore={reward.type === 'MONEY' ? 'Amount' : 'Value'}
                      style={{ width: reward.type === 'MONEY' ? '100%' : '230px' }}
                      value={reward.amount}
                      onChange={this.updateItemField('amount', ruleIndex, itemIndex)}
                    />
                    <Button
                      ghost
                      className="PayoutRules_delete_button grey"
                      onClick={() => this.deleteItem(ruleIndex, itemIndex)}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>

                </div>
              ))}
              <Button
                type="link"
                size="small"
                onClick={() => this.addItem(ruleIndex)}
                icon={<PlusOutlined />}
                className="Add_reward width-100"
              >
                Add another Reward
              </Button>
            </div>

          </Card>
        ))}
        <Card style={{ marginTop: '8px' }}>
          <Button
            type="primary"
            className="width-100"
            onClick={() => this.addRule()}
            icon={<PlusOutlined />}
            ghost
          >
            Add a Rule
          </Button>
        </Card>
      </div>
    );
  }
}

RewardRuleEditor.propTypes = {
  challenge: PropTypes.object.isRequired,
  challengeType: PropTypes.oneOf(['PUBLIC_LEADERBOARD', 'ANONYMOUS_LEADERBOARD', 'INDIVIDUAL']).isRequired,
  initialRules: PropTypes.array,
};

RewardRuleEditor.defaultProps = {
  initialRules: [],
};

export default RewardRuleEditor;
