import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import getI18n from '@palette/i18n';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { getBeginOfHalfYear, getEndOfHalfYear, getMoment } from '@palette/helpers/MomentHelper';
import { getAddQSToLocationNewLocation, getCleanLocationSearchNewLocation } from '@palette/helpers/NavigationHelper';
import { hasFeature } from '@palette/helpers/ProfileHelper';
import { getUserStatementsPanelContext, statementPeriodIsInFuture } from '@palette/helpers/StatementHelper';

import { FEATURES } from '@palette/constants/profile';
import {
  CURRENCY_QS_KEY,
  PERIODS_DATES_FROM_QS_KEY,
  PERIODS_DATES_TO_QS_KEY,
  PERIODS_DATES_TYPE_QS_KEY,
  STATEMENT_PERIODS_DATES_TYPES,
  STATEMENT_PERIOD_STATUSES,
  STATEMENT_STRATEGY_MONTH_I18N,
  STATEMENT_STRATEGY_TYPES,
} from '@palette/constants/statements';

import { actions as NavigationActions } from '@palette/state/Navigation';
import { actions as StatementsActions, selectors as StatementsSelectors } from '@palette/state/Statements';

export const useStatementPeriodStatus = (statementPeriod, inUserStatement = false) => {
  const profile = useProfile();

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION)) return null;

  if (statementPeriodIsInFuture(statementPeriod, false)) return STATEMENT_PERIOD_STATUSES.IN_FUTURE;

  if (statementPeriod.correctionsCount > 0) return STATEMENT_PERIOD_STATUSES.PENDING_ACTIONS;

  if (inUserStatement) {
    if (statementPeriod.paidCount > 0) return STATEMENT_PERIOD_STATUSES.PAID;
    if (statementPeriod.approvedCount > 0) return STATEMENT_PERIOD_STATUSES.PAYMENT_REQUIRED;
  }

  if (statementPeriod.usersCount === statementPeriod.paidCount) {
    if (statementPeriod.usersCount === 0) {
      return STATEMENT_PERIOD_STATUSES.EMPTY_STATEMENTS;
    }
    return STATEMENT_PERIOD_STATUSES.PAID;
  }

  if (statementPeriod.pendingApprovalsCount === 0 || statementPeriod.usersCount === statementPeriod.approvedCount) {
    return STATEMENT_PERIOD_STATUSES.PAYMENT_REQUIRED;
  }

  return STATEMENT_PERIOD_STATUSES.APPROVAL_REQUIRED;
};

export const useCurrencyInSearch = (defaultCurrency = null) => {
  const dispatch = useDispatch();

  const [currency] = useKeyInSearch(CURRENCY_QS_KEY, defaultCurrency);

  const handleCurrencyChange = useCallback((selectedCurrency) => {
    const QSToAdd = {};
    const keysToDelete = [];

    if (selectedCurrency === null) {
      keysToDelete.push(CURRENCY_QS_KEY);
    } else {
      QSToAdd[CURRENCY_QS_KEY] = selectedCurrency;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  return [currency, handleCurrencyChange];
};

export const useCurrentStrategyDefaultDatesType = (inSalesforce = false) => {
  const profile = useProfile();

  const isWeekStatementStrategy = useMemo(() => {
    if (inSalesforce) return false;

    return profile.userData.company.statementStrategy.type === STATEMENT_STRATEGY_TYPES.WEEK;
  }, [profile, inSalesforce]);

  const defaultDatesType = useMemo(() => (
    isWeekStatementStrategy ? STATEMENT_PERIODS_DATES_TYPES.MONTH : STATEMENT_PERIODS_DATES_TYPES.QUARTER
  ), [isWeekStatementStrategy]);

  return useMemo(() => ({
    isWeekStatementStrategy,
    defaultDatesType,
  }), [isWeekStatementStrategy, defaultDatesType]);
};

export const usePeriodsDatesFilterQS = (inSalesforce = false) => {
  const { defaultDatesType } = useCurrentStrategyDefaultDatesType(inSalesforce);

  const [datesType, datesTypeInSearch] = useKeyInSearch(PERIODS_DATES_TYPE_QS_KEY, defaultDatesType, inSalesforce);
  const [datesFrom, datesFromInSearch] = useKeyInSearch(PERIODS_DATES_FROM_QS_KEY, null, inSalesforce);
  const [datesTo, datesToInSearch] = useKeyInSearch(PERIODS_DATES_TO_QS_KEY, null, inSalesforce);

  return {
    datesType,
    datesTypeInSearch,
    datesFrom,
    datesFromInSearch,
    datesTo,
    datesToInSearch,
  };
};

export const usePeriodsDatesFilterAndCurrencyQSObject = (inSalesforce = false) => {
  const {
    datesType,
    datesTypeInSearch,
    datesFrom,
    datesFromInSearch,
    datesTo,
    datesToInSearch,
  } = usePeriodsDatesFilterQS(inSalesforce);

  const [currency] = useCurrencyInSearch();

  return useMemo(() => {
    const qsObject = {};

    if (datesTypeInSearch) {
      qsObject[PERIODS_DATES_TYPE_QS_KEY] = datesType;
    }

    if (datesFromInSearch) {
      qsObject[PERIODS_DATES_FROM_QS_KEY] = datesFrom;
    }

    if (datesToInSearch) {
      qsObject[PERIODS_DATES_TO_QS_KEY] = datesTo;
    }

    if (currency) {
      qsObject[CURRENCY_QS_KEY] = currency;
    }

    return qsObject;
  }, [
    datesType,
    datesTypeInSearch,
    datesFrom,
    datesFromInSearch,
    datesTo,
    datesToInSearch,
    currency,
    inSalesforce,
  ]);
};

export const useComputedPeriodsDatesFilter = (datesType, datesFrom, datesTo, resetPeriodsDatesFilter, inSalesforce = false) => {
  const moment = getMoment();
  const { isWeekStatementStrategy } = useCurrentStrategyDefaultDatesType(inSalesforce);

  return useMemo(() => {
    const datesFromMoment = datesFrom !== null ? moment.utc(datesFrom) : moment.utc();
    const datesToMoment = datesTo !== null ? moment.utc(datesTo) : moment.utc();

    if (datesType === STATEMENT_PERIODS_DATES_TYPES.MONTH) {
      if (isWeekStatementStrategy) {
        const finalType = STATEMENT_PERIODS_DATES_TYPES.MONTH;
        const finalFrom = datesFromMoment.startOf('month');
        const finalTo = moment.utc(datesFromMoment).endOf('month');

        return [finalType, finalFrom, finalTo];
      }

      resetPeriodsDatesFilter();
      return [datesType, datesFrom, datesTo];
    }

    if (datesType === STATEMENT_PERIODS_DATES_TYPES.QUARTER) {
      const finalType = STATEMENT_PERIODS_DATES_TYPES.QUARTER;
      const finalFrom = datesFromMoment.startOf('quarter');
      const finalTo = moment.utc(datesFromMoment).endOf('quarter');

      return [finalType, finalFrom, finalTo];
    }

    if (datesType === STATEMENT_PERIODS_DATES_TYPES.HALF_YEAR) {
      const finalType = STATEMENT_PERIODS_DATES_TYPES.HALF_YEAR;
      const finalFrom = getBeginOfHalfYear(datesFromMoment);
      const finalTo = getEndOfHalfYear(datesFromMoment);

      return [finalType, finalFrom, finalTo];
    }

    if (datesType === STATEMENT_PERIODS_DATES_TYPES.YEAR) {
      const finalType = STATEMENT_PERIODS_DATES_TYPES.YEAR;
      const finalFrom = datesFromMoment.startOf('year');
      const finalTo = moment.utc(datesFromMoment).endOf('year');

      return [finalType, finalFrom, finalTo];
    }

    const finalType = STATEMENT_PERIODS_DATES_TYPES.CUSTOM;
    const finalFrom = datesFromMoment.startOf('day');
    const finalTo = datesToMoment.endOf('day');

    return [finalType, finalFrom, finalTo];
  }, [datesType, datesFrom, datesTo]);
};

export const usePeriodsDatesFilter = (inSalesforce = false) => {
  const moment = getMoment();
  const dispatch = useDispatch();
  const reactLocation = useLocation();
  const reactHistory = useHistory();

  const { datesType, datesFrom, datesTo } = usePeriodsDatesFilterQS(inSalesforce);

  const handlePeriodsDatesFilterChange = useCallback((selectedDatesType, selectedDatesFrom = null, selectedDatesTo = null) => {
    const QSToAdd = {};
    let keysToDelete = [];

    if (selectedDatesType === null) {
      keysToDelete = [PERIODS_DATES_TYPE_QS_KEY, PERIODS_DATES_FROM_QS_KEY, PERIODS_DATES_TO_QS_KEY];
    } else {
      QSToAdd[PERIODS_DATES_TYPE_QS_KEY] = selectedDatesType;

      if (selectedDatesFrom === null) {
        keysToDelete.push(PERIODS_DATES_FROM_QS_KEY);
        keysToDelete.push(PERIODS_DATES_TO_QS_KEY);
      } else {
        QSToAdd[PERIODS_DATES_FROM_QS_KEY] = moment.utc(selectedDatesFrom).format('YYYY-MM-DD');

        if (selectedDatesTo === null) {
          keysToDelete.push(PERIODS_DATES_TO_QS_KEY);
        } else {
          QSToAdd[PERIODS_DATES_TO_QS_KEY] = moment.utc(selectedDatesTo).format('YYYY-MM-DD');
        }
      }
    }

    if (inSalesforce) {
      let newLocation = getAddQSToLocationNewLocation(reactLocation, QSToAdd);
      newLocation = getCleanLocationSearchNewLocation(newLocation, keysToDelete);

      reactHistory.push(newLocation);
    } else {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    }
  }, [inSalesforce, reactLocation, reactHistory]);

  const resetPeriodsDatesFilter = useCallback(() => {
    const keysToDelete = [PERIODS_DATES_TYPE_QS_KEY, PERIODS_DATES_FROM_QS_KEY, PERIODS_DATES_TO_QS_KEY];

    if (inSalesforce) {
      reactHistory.push(getCleanLocationSearchNewLocation(reactLocation, keysToDelete));
    } else {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
    }
  }, [inSalesforce, reactLocation, reactHistory]);

  const [typeToReturn, fromToReturn, toToReturn] = useComputedPeriodsDatesFilter(datesType, datesFrom, datesTo, resetPeriodsDatesFilter, inSalesforce);

  return useMemo(() => ([typeToReturn, fromToReturn, toToReturn, handlePeriodsDatesFilterChange]), [typeToReturn, fromToReturn, toToReturn, handlePeriodsDatesFilterChange]);
};

export const usePeriodsDatesTypesOptions = (fromDate) => {
  const moment = getMoment();
  const i18n = getI18n();
  const { isWeekStatementStrategy } = useCurrentStrategyDefaultDatesType();

  return useMemo(() => {
    const fromDateMoment = moment.utc(fromDate);

    const options = [
      {
        label: i18n.t('statementPeriodsDates.type.custom'),
        value: STATEMENT_PERIODS_DATES_TYPES.CUSTOM,
      },
    ];

    options.unshift({
      label: i18n.t('statementPeriodsDates.type.year', { year: fromDateMoment.year() }),
      value: STATEMENT_PERIODS_DATES_TYPES.YEAR,
    });

    options.unshift({
      label: i18n.t('statementPeriodsDates.type.halfYear', { halfYear: fromDateMoment.month() < 6 ? 1 : 2, year: fromDateMoment.year() }),
      value: STATEMENT_PERIODS_DATES_TYPES.HALF_YEAR,
    });

    options.unshift({
      label: i18n.t('statementPeriodsDates.type.quarter', { quarter: fromDateMoment.quarter(), year: fromDateMoment.year() }),
      value: STATEMENT_PERIODS_DATES_TYPES.QUARTER,
    });

    if (isWeekStatementStrategy) {
      const monthKey = `M${fromDateMoment.month() + 1}`;
      const monthLabel = i18n.t(STATEMENT_STRATEGY_MONTH_I18N[monthKey]);
      options.unshift({
        label: `${monthLabel} ${fromDateMoment.year()}`,
        value: STATEMENT_PERIODS_DATES_TYPES.MONTH,
      });
    }

    return options;
  }, [fromDate, isWeekStatementStrategy]);
};

export const useStatementPeriodIdInParams = () => {
  const { statementPeriodId } = useParams();

  const statementPeriod = useSelector((state) => StatementsSelectors.getStatementPeriodById(state, { statementPeriodId }));
  const isPending = useSelector((state) => StatementsSelectors.getStatementPeriodByIdIsPending(state));

  return { statementPeriodId, statementPeriod, isPending };
};

export const useStatementPeriods = (currency, userId = null) => {
  const dispatch = useDispatch();

  const [, from, to] = usePeriodsDatesFilter();

  const listStatementPeriodsIsPending = useSelector(StatementsSelectors.listStatementPeriodsIsPending);
  const statementPeriods = useSelector(StatementsSelectors.getStatementPeriods);

  useEffect(() => {
    dispatch(StatementsActions.listStatementPeriods({ currency, from, to, userId }));
  }, [currency, from, to, userId]);

  return useMemo(() => ({
    listStatementPeriodsIsPending,
    statementPeriods,
  }), [listStatementPeriodsIsPending, statementPeriods]);
};

export const useStatementUserIdInParams = () => {
  const { statementPeriodId } = useStatementPeriodIdInParams();
  const { userId } = useParams();

  const userStatement = useSelector((state) => StatementsSelectors.getUserStatement(state, { statementPeriodId, userId }));
  const isPending = useSelector((state) => StatementsSelectors.getUserStatementByIdIsPending(state));

  return { userId, userStatement, isPending };
};

export const useStatementUserCorrectionStatementIdInParams = () => {
  const dispatch = useDispatch();
  const { userStatement } = useStatementUserIdInParams();
  const { correctionStatementId } = useParams();

  const userStatementCorrectionSummaries = useSelector((state) => StatementsSelectors.getUserStatementCorrectionSummaries(state, { correctionStatementId }));
  const userStatementCorrectionSummariesIsPending = useSelector((state) => StatementsSelectors.getUserStatementCorrectionSummariesIsPending(state));

  const correctionToInvestigate = useMemo(() => (
    userStatement?.corrections.filter((correction) => correction.from.statementId === correctionStatementId).shift()
  ), [userStatement, correctionStatementId]);

  useEffect(() => {
    if (correctionToInvestigate) {
      dispatch(StatementsActions.getUserStatementCorrectionSummaries({
        correctionStatementId: correctionToInvestigate.from.statementId,
      }));
    }
  }, []);

  return { correctionToInvestigate, userStatementCorrectionSummaries, userStatementCorrectionSummariesIsPending };
};

export const useAndFetchStatementPeriodInParams = (currency, inUserStatement = false) => {
  const dispatch = useDispatch();
  const { statementPeriodId, statementPeriod } = useStatementPeriodIdInParams();
  const { userId } = useStatementUserIdInParams();

  useEffect(() => {
    if (statementPeriodId) {
      const payeeId = inUserStatement ? userId : undefined;
      dispatch(StatementsActions.getStatementPeriodById({ statementPeriodId, currency, payeeId }));
    }
  }, [statementPeriodId, currency, inUserStatement, userId]);

  return useMemo(() => ({ statementPeriodId, statementPeriod }), [statementPeriodId, statementPeriod]);
};

export const useUserStatementsPanelContext = () => {
  const UserStatementsPanelContext = getUserStatementsPanelContext();

  return useContext(UserStatementsPanelContext);
};

export const useMyStatementPeriodIdInParams = () => {
  const { statementPeriodId } = useParams();

  const statementPeriod = useSelector((state) => StatementsSelectors.getStatementPeriodById(state, { statementPeriodId }));

  return { statementPeriodId: statementPeriodId ?? null, statementPeriod };
};

export const useMyStatementPeriods = (inSalesforce = false) => {
  const dispatch = useDispatch();

  const [, from, to] = usePeriodsDatesFilter(inSalesforce);

  const listMyStatementPeriodsIsPending = useSelector(StatementsSelectors.listMyStatementPeriodsIsPending);
  const statementPeriods = useSelector(StatementsSelectors.getMyStatementPeriods);

  useEffect(() => {
    dispatch(StatementsActions.listMyStatementPeriods({ from, to, inSalesforce }));
  }, [from, to, inSalesforce]);

  return useMemo(() => ({
    listMyStatementPeriodsIsPending,
    statementPeriods,
  }), [listMyStatementPeriodsIsPending, statementPeriods]);
};
