import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import {
  ONBOARDING_USERS_FLOWS,
  ONBOARDING_USERS_FROM_RESOURCES_FLOW_FIRST_STEP,
  ONBOARDING_USERS_MANUAL_INPUT_FLOW_FIRST_STEP,
} from '@palette/constants/user';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

export const useCompanyMetaUsers = (disableReloadUsers = false) => {
  const dispatch = useDispatch();

  const usersList = useSelector(UsersSelectors.getUsersList);

  useEffect(() => {
    if (!disableReloadUsers) {
      dispatch(UsersActions.getList());
    }
  }, []);

  return usersList;
};

export const useCurrencies = () => {
  const dispatch = useDispatch();

  const currenciesList = useSelector(UsersSelectors.getCurrenciesList);
  const getCurrenciesListIsPending = useSelector(UsersSelectors.getCurrenciesListIsPending);

  const getCurrenciesList = () => {
    dispatch(UsersActions.getCurrenciesList());
  };

  return [currenciesList, getCurrenciesList, getCurrenciesListIsPending];
};

export const useUserIdInParams = () => {
  const dispatch = useDispatch();

  const { userId } = useParams();

  const user = useSelector((state) => UsersSelectors.getUserById(state, { userId }));
  const isPending = useSelector((state) => UsersSelectors.getUserByIdIsPending(state));

  useEffect(() => {
    dispatch(UsersActions.getUserById({ userId }));
  }, [userId]);

  return useMemo(() => ({
    userId,
    user,
    isPending,
  }), [
    userId,
    user,
    isPending,
  ]);
};

export const useOnboardUsersStepValue = () => {
  const onboardUsersFlow = useSelector(UsersSelectors.getOnboardUsersFlow);
  const onboardUsersCurrentFlowStep = useSelector(UsersSelectors.getOnboardUsersCurrentFlowStep);

  if (onboardUsersCurrentFlowStep === null) {
    return onboardUsersFlow === ONBOARDING_USERS_FLOWS.MANUAL_INPUT ? ONBOARDING_USERS_MANUAL_INPUT_FLOW_FIRST_STEP.value : ONBOARDING_USERS_FROM_RESOURCES_FLOW_FIRST_STEP.value;
  }

  return onboardUsersCurrentFlowStep.value;
};

export const useMergeUserInSearch = (userQSKey) => {
  const dispatch = useDispatch();

  const [userId] = useKeyInSearch(userQSKey, null);

  const user = useSelector((state) => UsersSelectors.getUserById(state, { userId }));
  useEffect(() => {
    if (userId !== null) {
      dispatch(UsersActions.getUserById({ userId }));
    }
  }, [userId]);

  return user;
};

export const useFastOnboarding = (onOnboardingSuccessCB) => {
  const dispatch = useDispatch();

  const [selectedResources, setSelectedResources] = useState([]);
  const [isMissingRequiredFields, setIsMissingRequiredFields] = useState(false);

  const [selectResourcesNextStepFunc, setSelectResourcesNextStepFunc] = useState(null);

  const onboardUsersIsPending = useSelector(UsersSelectors.onboardUsersIsPending);
  const onboardCurrentUsers = useSelector(UsersSelectors.getOnboardCurrentUsers);

  const handleContinueFastOnboard = useCallback(() => {
    if (onboardCurrentUsers.length === 0) {
      if (selectResourcesNextStepFunc !== null) selectResourcesNextStepFunc();
    } else {
      dispatch(UsersActions.onboardUsers({ users: onboardCurrentUsers, onSuccessCB: onOnboardingSuccessCB }));
    }
  }, [selectResourcesNextStepFunc, onboardCurrentUsers, onOnboardingSuccessCB]);

  const registerNextStepHandler = useCallback((nextStepCB) => {
    setSelectResourcesNextStepFunc(() => nextStepCB);
  }, []);

  return useMemo(() => ({
    selectedResources,
    setSelectedResources,
    isMissingRequiredFields,
    setIsMissingRequiredFields,
    onboardUsersIsPending,
    onboardCurrentUsers,
    handleContinueFastOnboard,
    registerNextStepHandler,
  }), [
    selectedResources,
    setSelectedResources,
    isMissingRequiredFields,
    setIsMissingRequiredFields,
    onboardUsersIsPending,
    onboardCurrentUsers,
    handleContinueFastOnboard,
    registerNextStepHandler,
  ]);
};
