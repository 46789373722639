import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { REWARD_TYPES } from '@palette/constants/challenges';

export const modelName = 'Reward';

export const propTypes = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(REWARD_TYPES)),
  amount: PropTypes.number,
  rewardRule: PropTypes.string,
});

export const manageRewardType = (reward) => REWARD_TYPES[manageStringAttribute(reward, 'type')] || REWARD_TYPES.MONEY;

export const transform = (reward) => {
  if (!reward) {
    return null;
  }

  return (
    {
      type: manageRewardType(reward),
      amount: manageAmountAttribute(reward, 'amount'),
      rewardRule: manageStringAttribute(reward, 'rewardRule'),
    }
  );
};

export const transformList = (rewards) => rewards.map((reward) => transform(reward));
