import Axios from 'axios';
import { API_URL } from '../constants';
import { getSessionId } from '../utils';

export const createChallenge = (params) => Axios.post(`${API_URL}/users/admin/challenges/create`, params, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const listChallenges = (filter = {}) => Axios.post(`${API_URL}/users/admin/challenges/list`, { filter }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getChallengeDetails = (challengeId) => Axios.post(`${API_URL}/users/admin/challenges/get-by-id`, { challengeId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getRankingDetails = (challengeId, objectId) => Axios.post(`${API_URL}/users/admin/challenges/get-ranking-details`, { challengeId, objectId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const updateTrackingObject = (challengeId, params) => Axios.post(`${API_URL}/users/admin/challenges/update-tracking-object`, { challengeId, ...params }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const updateGroupByObject = (challengeId, params) => Axios.post(`${API_URL}/users/admin/challenges/update-group-by-object`, { challengeId, ...params }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const deleteChallenge = (challengeId) => Axios.post(`${API_URL}/users/admin/challenges/delete`, { challengeId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const updateChallenge = (challengeId, params) => Axios.post(`${API_URL}/users/admin/challenges/update`, { challengeId, ...params }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const bulkAddUsersToChallenge = (challengeId, objectIds) => Axios.post(`${API_URL}/users/admin/challenges/group-by-objects/bulk-add`, { challengeId, objectIds }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const removeUserFromChallenge = (challengeId, objectId) => Axios.post(`${API_URL}/users/admin/challenges/group-by-objects/delete`, { challengeId, objectId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export default {};
