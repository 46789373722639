import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './reactAutocompleteInput';

const AutoCompleteInput = ({
  style,
  className,
  Component,
  ...params
}) => (
  <TextInput
    spacer=""
    Component={Component}
    size="small"
    style={{
      height: '30px',
      resize: 'none',
      ...style,
    }}
    className={`ant-input ${className}`}
    offsetY={18}
    matchAny
    maxOptions={Infinity}
    {...params}
  />
);

AutoCompleteInput.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  Component: PropTypes.string,
};

AutoCompleteInput.defaultProps = {
  style: {},
  className: '',
  Component: 'input',
};

export default AutoCompleteInput;
