/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Statistic, Divider, Popover, Dropdown, Menu, Tag,
} from 'antd';
import Moment from 'moment';
import {
  WarningTwoTone, CopyOutlined, MoreOutlined, DeleteOutlined,
} from '@ant-design/icons';
import pluralize from 'pluralize';
import { connect } from 'react-redux';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import '../../styles/challenges/challengeVignette.less';

const getMoreOptionsForChallenge = (profile, challenge, callbackDuplicate, deleteChallenge) => (
  <Dropdown
    trigger="click"
    className="More_options"
    overlay={(
      <Menu>
        <Menu.Item
          key="duplicate"
          icon={<CopyOutlined />}
          onClick={() => callbackDuplicate(challenge)}
        >
          Duplicate challenge
        </Menu.Item>
        {hasAtLeastOneRight(profile, ['admin.challenges.delete']) && (
          <Menu.Item
            key="delete"
            icon={<DeleteOutlined />}
            onClick={() => deleteChallenge(challenge)}
            danger
          >
            Delete challenge
          </Menu.Item>
        )}
      </Menu>
    )}
  >
    <div className="Challenge_vignette_option">
      <MoreOutlined />
    </div>
  </Dropdown>
);

const ChallengeVignette = ({
  challenge, computeChallenge, callbackDuplicate, canCreate, deleteChallenge, profile,
}) => (
  <div className={`ChallengeVignette_wrapper ${challenge.type} ${challenge.live ? '' : 'Challenge_vignette_wrapper_draft'}`}>
    <div className="ChallengeVignette_inner">
      <div className="flex">
        <h2
          className="ChallengeVignette_title bold no-margin"
          style={{ flexGrow: '1' }}
        >
          <a href={`/#${routePaths.challengeDetails.replace(':challengeId', challenge._id)}`}>{challenge.name}</a>
          {challenge.lastBreakingChangeUpdate && (
            !challenge.lastComputedAt
            || challenge.lastBreakingChangeUpdate > challenge.lastComputedAt
          ) && computeChallenge && (
            <Popover
              overlayStyle={{ width: '250px' }}
              content={(
                <>
                  Important changes were made, please update the commissions by
                  {' '}
                  <a
                    onClick={() => computeChallenge()}
                  >
                    launching a recomputation
                  </a>
                </>
              )}
              title={<b className="title">Launch a recomputation</b>}
            >
              {' '}
              <WarningTwoTone twoToneColor="orange" />
            </Popover>
          )}
        </h2>
        {canCreate && (
          <div>
            {getMoreOptionsForChallenge(profile, challenge, callbackDuplicate, deleteChallenge)}
          </div>
        )}
      </div>

      <div className="grey">
        {`${Moment(challenge.beginAt).format('MMM Do, YYYY')} - ${Moment(challenge.endAt).format('MMM Do, YYYY')}`}
        {!challenge.live && <Tag color="black" style={{ marginLeft: 8 }}>DRAFT</Tag>}
      </div>
      <Divider />
      <div className="ChallengeVignette_stats center">
        <Statistic
          title="Tracking"
          value={challenge.trackingObjectType}
        />
        <Statistic
          title={pluralize(challenge.groupByObjectType, challenge.groupByObjects)}
          value={challenge.groupByObjects}
        />
      </div>
    </div>
  </div>
);

ChallengeVignette.propTypes = {
  challenge: PropTypes.object.isRequired,
  computeChallenge: PropTypes.func,
  callbackDuplicate: PropTypes.func.isRequired,
  deleteChallenge: PropTypes.func.isRequired,
  canCreate: PropTypes.bool,
  profile: ProfileModel.propTypes.isRequired,
};

ChallengeVignette.defaultProps = {
  computeChallenge: null,
  canCreate: true,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(ChallengeVignette);
