/* eslint-disable react/jsx-closing-tag-location */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  CopyOutlined, LoadingOutlined, PlusOutlined, TrophyOutlined,
  DownOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Button, Checkbox, Divider, Dropdown, Empty, Menu, message, Modal, Select, Spin,
} from 'antd';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import {
  createChallenge, getChallengeDetails, listChallenges, deleteChallenge,
} from '../../../services/admin/challenges';
import PageTopTitle from '../../../components/pageTopTitle';
import ChallengeVignette from '../../../components/challenges/challengeVignette';
import '../../../styles/challenges/challenges.less';
import {
  computeFn,
  filterEntities, formatPeriods, parseSearch, stringifySearch,
} from '../../../services/utils';
import PeriodSelector from '../../../components/periodSelector';
import { sendEvent } from '../../../services/analytics';

class Challenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      challenges: [],
      duplicateLoading: false,
      duplicateValue: null,
      keepTeamChecked: false,
      deleteLoading: false,
    };
  }

  componentDidMount() {
    this.listChallenges();
  }

  listChallenges = () => {
    this.setState({ loading: true }, () => {
      listChallenges()
        .then(({
          data: challenges,
        }) => this.setState({
          challenges,
          loading: false,
        }))
        .catch((err) => {
          console.error('loading challenges', err);
          this.setState({ loading: false });
        });
    });
  };

  computeCommissions = (id) => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      computeFn(id)
        .then(() => this.setState({ loading: false }, this.listChallenges))
        .catch((error) => {
          this.setState({ loading: false });
          if (error && error.response && error.response.status === 409) {
            message.error('A computation is already running, please try again later.');
          } else {
            message.error('Unexpected error while computing commissions.');
          }
        });
    });
  };

  openDuplicate = () => {
    const currentSearch = parseSearch(this.props.location.search);
    const newSearch = {
      ...currentSearch,
      duplicate: 'true',
    };
    if (JSON.stringify(currentSearch) !== JSON.stringify(newSearch)) {
      this.props.history.push({
        pathname: '/challenges',
        search: stringifySearch(newSearch),
      });
    }
  };

  handleChangeDuplicate = (e) => this.setState({ duplicateValue: e });

  handleOkDuplicate = () => {
    const challengeToDuplicate = this.state.challenges
      .find((c) => c._id === this.state.duplicateValue);
    return this.setState({
      duplicateLoading: true,
    }, () => getChallengeDetails(challengeToDuplicate._id)
      .then(({ data: { lastComputedAt, renewedFrom, ...challenge } }) => {
        const newChallenge = {
          ...challenge,
          name: `Copy of ${challenge.name}`,
          rewardRules: challenge.rewardRules.map(({ _id, ...rule }) => rule),
          ranking: this.state.keepTeamChecked ? (
            challenge.ranking.map((r) => ({
              ...r,
              trackingObjects: [],
              rewards: [],
              score: null,
            }))
          ) : [],
          groupByObject: {
            ...challenge.groupByObject,
            values: this.state.keepTeamChecked ? challenge.groupByObject.values : [],
          },
          lastBreakingChangeUpdate: new Date(),
          live: false,
        };
        delete newChallenge._id;
        createChallenge(newChallenge)
          .then(() => {
            sendEvent('duplicate challenge', { userId: this.props.profile.userData.id });
            this.handleCancelDuplicate();
            this.listChallenges();
          })
          .catch((err) => {
            this.setState({ duplicateLoading: false });
            console.error('create plan', err);
          });
      }).catch((e) => {
        console.error('Error while duplicating plan', e);
        message.error('Error while duplicating plan, please try again.');
        this.setState({ duplicateLoading: false });
      }));
  };

  handleCancelDuplicate = () => {
    this.setState({ duplicateLoading: false }, () => {
      const currentSearch = parseSearch(this.props.location.search);
      if (currentSearch.duplicate) {
        delete currentSearch.duplicate;
        this.props.history.push({
          pathname: '/challenges',
          search: stringifySearch(currentSearch),
        });
      }
    });
  };

  onChangeKeepTeam = (e) => {
    this.setState({
      keepTeamChecked: e.target.checked,
    });
  };

  duplicateChallenge = (challenge) => {
    this.handleChangeDuplicate(challenge._id);
    this.openDuplicate();
  };

  deleteChallenge = (challenge) => {
    Modal.confirm({
      title: <div>
        <span>Confirm you want to delete</span>
        <span className="bold">{` ${challenge.name} `}</span>
        <span>?</span>
      </div>,
      icon: <ExclamationCircleOutlined />,
      content: 'Please confirm that you want to delete the challenge.',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => { this.confirmDeleteChallenge(challenge); },
    });
  };

  confirmDeleteChallenge = (challenge) => this.setState({ deleteLoading: true }, () => {
    deleteChallenge(challenge._id).then(() => {
      this.listChallenges();
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      this.setState({ deleteLoading: false });
    });
  });

  render() {
    const {
      loading,
      duplicateLoading,
      duplicateValue,
      keepTeamChecked,
      deleteLoading,
    } = this.state;
    const {
      duplicate, type,
    } = parseSearch(this.props.location.search);
    const isDuplicate = duplicate === 'true';
    const challenges = filterEntities(this.state.challenges, type);
    const canCreate = hasAtLeastOneRight(this.props.profile, ['admin.challenges.create']);
    return (
      <>
        <MetaTag title="Challenges" />
        <div className="div-wrapper Challenges_wrapper">
          <div className="container">
            <div className="container-inner">
              <PageTopTitle
                title={(
                  <>
                    <TrophyOutlined />
                    &nbsp;
                    &nbsp;
                    Challenges
                  </>
                )}
                actions={canCreate ? (
                  <Dropdown
                    trigger="click"
                    overlay={(
                      <Menu>
                        <Menu.Item
                          key="1"
                          icon={<PlusOutlined />}
                          onClick={() => this.props.history.push('/add-challenge')}
                        >
                          Create new challenge
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          icon={<CopyOutlined />}
                          onClick={this.openDuplicate}
                        >
                          Duplicate challenge
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      // icon={<PlusOutlined />}
                      type="link"
                    >
                      Create
                      {' '}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                ) : null}
              />
              <Divider />
              <PeriodSelector />
              <Spin
                tip="Loading..."
                className="center width-100"
                spinning={loading || deleteLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24, marginTop: '12px' }} spin />}
              >
                {(challenges && challenges.length > 0) ? (
                  <div className="Challenges_list_wrapper">
                    {challenges.map((c) => (
                      <ChallengeVignette
                        challenge={c}
                        key={c._id}
                        computeChallenge={() => { this.computeCommissions(c._id); }}
                        callbackDuplicate={this.duplicateChallenge}
                        deleteChallenge={this.deleteChallenge}
                        canCreate={canCreate}
                      />
                    ))}
                  </div>
                ) : (!loading && (
                  <div className="center">
                    <Empty description={<span className="grey">No Challenge to display</span>}>
                      {(!type || type === 'CURRENT' || type.length === 0) && canCreate && (
                        <Button
                          icon={<PlusOutlined />}
                          type="primary"
                          onClick={() => this.props.history.push('/add-challenge')}
                        >
                          Create a challenge
                        </Button>
                      )}
                    </Empty>
                  </div>
                ))}
              </Spin>
            </div>
          </div>
        </div>
        {isDuplicate && (
          <Modal
            title="Duplicate an existing challenge"
            visible={isDuplicate}
            onOk={this.handleOkDuplicate}
            onCancel={this.handleCancelDuplicate}
            okText="DUPLICATE CHALLENGE"
            confirmLoading={duplicateLoading}
            okButtonProps={{
              icon: <CopyOutlined />,
              disabled: !duplicateValue,
            }}
          >
            <Select
              className="width-100"
              placeholder="Choose a challenge to duplicate"
              onChange={this.handleChangeDuplicate}
              value={duplicateValue}
            >
              {this.state.challenges.map((c) => (
                <Select.Option key={c._id} value={c._id}>
                  {`${c.name} - ${formatPeriods(c)}`}
                </Select.Option>
              ))}
            </Select>
            <div className="center" style={{ marginTop: '12px' }}>
              <Checkbox
                checked={keepTeamChecked}
                onChange={this.onChangeKeepTeam}
              >
                Keep existing users in the new challenge
              </Checkbox>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

Challenges.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  profile: ProfileModel.propTypes.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(withRouter(Challenges));
