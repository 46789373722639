/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Button } from 'antd';
import { CloudServerOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

class NoConnector extends Component {
  render() {
    const {
      title, description, actionLink, actionText,
      actionIcon,
    } = this.props;
    return (
      <div className="center" style={{ marginBottom: '44px' }}>
        <img
          src="/img/empty-data.png"
          alt="no data source"
          style={{
            width: '320px',
            maxWidth: '100%',
          }}
        />
        <h2 className="bold no-margin">{title}</h2>
        <div className="grey">{description}</div>
        {hasAtLeastOneRight(this.props.profile, ['admin.connectors.create']) ? (
          <a href={actionLink}>
            <Button
              style={{ marginTop: '20px' }}
              type="primary"
              icon={actionIcon}
            >
              {actionText}

            </Button>
          </a>
        ) : (
          <div style={{
            marginTop: '12px',
            paddingTop: '12px',
          }}
          >
            Contact an administrator to add a connector
          </div>
        )}

      </div>
    );
  }
}

NoConnector.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  actionLink: PropTypes.string,
  actionText: PropTypes.string,
  actionIcon: PropTypes.node,
  profile: ProfileModel.propTypes.isRequired,
};

NoConnector.defaultProps = {
  title: 'Please link a data source',
  description: <div>No data sources were found. Please add a connector.</div>,
  actionLink: `/#${routePaths.addConnector}`,
  actionText: 'Add a data source',
  actionIcon: <CloudServerOutlined />,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(NoConnector);
