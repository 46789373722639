import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Dropdown as AntDDropdown, Menu as AntDMenu } from 'antd';

import ThreeDotsFilled from '@palette/components/utils/Icons/ThreeDotsFilled';

import styles from './MoreMenu.less';

const classNames = bindClassNames.bind(styles);

const MoreMenu = ({ className, items, children, ...otherProps }) => {
  const childrenNode = useMemo(() => {
    if (children !== null) return children;

    return (
      <div className={styles.contentWrapper}>
        <ThreeDotsFilled className={styles.icon} width={24} height={24} />
      </div>
    );
  }, [children]);

  return (
    <AntDDropdown
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      overlayClassName={styles.overlay}
      trigger="click"
      overlay={(
        <AntDMenu>
          {
            items.map((item) => {
              let iconNode = null;
              if (item.icon) {
                iconNode = (
                  <div
                    className={classNames({
                      menuItemIcon: true,
                      [item.iconClassName]: item.iconClassName && item.iconClassName !== '',
                    })}
                  >
                    {item.icon}
                  </div>
                );
              }
              return (
                <AntDMenu.Item key={item.key} onClick={item.onClick}>
                  <div
                    className={classNames({
                      menuItem: true,
                      [item.className]: item.className && item.className !== '',
                    })}
                  >
                    {iconNode}
                    <div className={styles.menuItemTitle}>
                      {item.title}
                    </div>
                  </div>
                </AntDMenu.Item>
              );
            })
          }
        </AntDMenu>
      )}
      {...otherProps}
    >
      <div>
        {childrenNode}
      </div>
    </AntDDropdown>
  );
};

MoreMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.any.isRequired,
    icon: PropTypes.any,
    iconClassName: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
  children: PropTypes.any,
};

MoreMenu.defaultProps = {
  className: '',
  children: null,
};

export default MoreMenu;
