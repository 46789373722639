import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Empty, Form, Input, message, Modal, Tooltip, Switch,
  Card, Dropdown, Menu, Checkbox, Divider, Alert,
} from 'antd';
import {
  DeleteOutlined, LoadingOutlined, LockOutlined, PlusOutlined,
  MoreOutlined, MailOutlined, SaveOutlined,
} from '@ant-design/icons';
import Cron from 'react-js-cron';

import { connect } from 'react-redux';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import FullScreenLoader from '../../loader';
import SlackConnect from '../slackConnect';
import SlackChannelSelect from '../slackChannelSelect';
import { listSlackChannels } from '../../../services/admin/company';
import { updateChallenge } from '../../../services/admin/challenges';
import '../../../styles/admin/notificationsTab.less';

const DEFAULT_EVENT_PARAMS = {
  RANKING_UPDATE: {
    fromRank: 3,
  },
  REMINDER: {
    cron: '30 10 * * 1',
  },
};

const formatChallenge = (challenge, type = 'IN') => ({
  ...challenge,
  notifiers: challenge.notifiers.map((notifier) => ({
    ...notifier,
    events: notifier.events.map(({
      params,
      ...event
    }) => ({
      ...event,
      params: {
        ...params,
        ...(event.type === 'RANKING_UPDATE' ? {
          fromRank: params.fromRank === 'Infinity' ? params.fromRank : (+params.fromRank + (type === 'IN' ? 1 : -1)),
        } : { cron: params.cron }),
      },
    })),
  })),
});

class NotificationsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showAddModal: false,
      type: '',
      channelId: '',
      idToChannel: {},
      challenge: formatChallenge(props.challenge),
      slackChannelLoading: false,
    };
    this.FormRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      slackChannelLoading: true,
    }, () => {
      listSlackChannels()
        .then(({ data: channels }) => this.setState({
          slackChannelLoading: false,
          idToChannel: channels.reduce((obj, channel) => ({
            ...obj,
            [channel.id]: channel,
          }), {}),
        })).catch((e) => {
          console.error('Listing slack channels', e);
          this.setState({ slackChannelLoading: false });
        });
    });
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.challenge) !== JSON.stringify(this.props.challenge)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ challenge: formatChallenge(this.props.challenge) });
    }
  }

  save = () => {
    const {
      loading,
      challenge,
    } = this.state;
    if (loading) return;
    this.setState({ loading: true }, () => {
      updateChallenge(challenge._id, formatChallenge({ notifiers: challenge.notifiers }, 'OUT'))
        .then(() => this.setState({ loading: false }, this.props.refresh))
        .catch(() => {
          message.error('Error while updating notifications settings');
          this.setState({ loading: false });
        });
    });
  };

  resetForm = () => {
    this.FormRef.current.resetFields();
    this.setState({
      type: '',
      channelId: '',
    });
  };

  addNotifier = () => {
    const {
      type,
      channelId,
      loading,
      challenge,
    } = this.state;
    if (loading) return;
    if (type === 'EMAIL') {
      const emailNotifier = challenge.notifiers.find((notifier) => notifier.type === type);
      if (emailNotifier) {
        message.error('You already have an Email notification');
        this.resetForm();
        this.setState({ showAddModal: false });
        return;
      }
    }
    if (type === 'SLACK') {
      const slackNotifier = challenge.notifiers
        .find((notifier) => notifier.type === type && notifier.channelId === channelId);
      if (slackNotifier) {
        message.error('You already have a Slack notification on this channel');
        this.resetForm();
        this.setState({ showAddModal: false });
        return;
      }
    }
    this.resetForm();
    this.setState({
      showAddModal: false,
      challenge: {
        ...challenge,
        notifiers: challenge.notifiers.concat([{
          type,
          ...(type === 'SLACK' ? { channelId } : {}),
          events: [],
        }]),
      },
    });
  };

  deleteNotifier = (index) => {
    const { challenge } = this.state;
    this.setState({
      challenge: {
        ...challenge,
        notifiers: challenge.notifiers.filter((n, i) => i !== index),
      },
    });
  };

  addNotifierEvent = (index, type) => {
    const { challenge } = this.state;
    challenge.notifiers[index].events.push({
      type,
      params: DEFAULT_EVENT_PARAMS[type] || {},
    });
    this.setState({ challenge });
  };

  deleteNotifierEvent = (index, type, rankingUpdateIndex) => {
    const { challenge } = this.state;
    challenge.notifiers[index].events.splice(rankingUpdateIndex, 1);
    this.setState({ challenge });
  };

  updateRank = (index, rankingUpdateIndex) => (e) => {
    const { challenge } = this.state;
    challenge.notifiers[index].events[rankingUpdateIndex].params.fromRank = e.target.value;
    this.setState({ challenge });
  };

  updateRankCheck = (index, rankingUpdateIndex) => (e) => {
    const { challenge } = this.state;
    challenge.notifiers[index].events[rankingUpdateIndex].params.fromRank = e.target.checked ? 'Infinity' : 3;
    this.setState({ challenge });
  };

  onChangeCron = (index, rankingReminderIndex, e) => {
    const { challenge } = this.state;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    challenge.notifiers[index].events[rankingReminderIndex].params.cron = e;
    challenge.notifiers[index].events[rankingReminderIndex].params.tz = (timezone || `Etc/GMT${(-(new Date().getTimezoneOffset() / 60))}`);
    this.setState({ challenge });
  };

  onErrorCron = (index, rankingReminderIndex, e) => {
    console.error('cron', index, rankingReminderIndex, e);
  };

  render() {
    const {
      challenge,
      loading,
      showAddModal,
      type,
      channelId,
      idToChannel,
      slackChannelLoading,
    } = this.state;
    const alreadyHasEmailNotifier = challenge.notifiers.find((notifier) => notifier.type === 'EMAIL');

    return (
      <div className="Plan_details_settings_wrapper">
        {!challenge.live && (
          <Alert
            message="No notification will be sent when Challenge is not live."
            type="info"
            showIcon
            style={{ marginBottom: 12 }}
          />
        )}
        <div className="flex" style={{ marginBottom: 12 }}>
          <div style={{ flex: 1 }} />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => this.setState({ showAddModal: true, type: 'SLACK' })}
          >
            Add notification
          </Button>
        </div>
        {challenge.notifiers.length === 0 && (
          <Empty description={(
            <div>
              <div className="grey">No notifications yet</div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => this.setState({ showAddModal: true, type: 'SLACK' })}
                >
                  Add notification
                </Button>
              </div>
            </div>
          )}
          />
        )}
        {challenge.notifiers.map((notifier, index) => {
          const rankingUpdateIndex = notifier.events.findIndex((e) => e.type === 'RANKING_UPDATE');
          const rankingUpdate = rankingUpdateIndex >= 0
            ? notifier.events[rankingUpdateIndex]
            : null;

          const rankingReminderIndex = notifier.events.findIndex((e) => e.type === 'REMINDER');
          const rankingReminder = rankingReminderIndex >= 0
            ? notifier.events[rankingReminderIndex]
            : null;
          return (
            <Card
              key={`${notifier.type}_${notifier.channelId}`}
              className={`NotifierItem ${notifier.type}`}
            >
              <div className="flex">
                <div className="Notifier_icon">
                  {notifier.type === 'SLACK'
                    ? <img src="img/slack_icon.png" alt="slack icon" style={{ width: '30px', height: '30px' }} />
                    : <MailOutlined />}
                </div>
                <div style={{ flexGrow: '1' }}>
                  <div className="flex">
                    <div style={{ flexGrow: '1' }}>
                      <h3 className="bold">
                        {notifier.type !== 'SLACK' && (
                          <span style={{ textTransform: 'capitalize' }}>
                            {`${notifier.type.toLowerCase()}`}
                          </span>
                        )}

                        {notifier.type === 'SLACK' && (
                          <div>
                            <span>Channel</span>
                            { idToChannel[notifier.channelId] ? (
                              <>
                                {' '}
                                {idToChannel[notifier.channelId].is_private
                                  ? <LockOutlined style={{ padding: '0 2px' }} />
                                  : <span style={{ padding: '0 2px' }}>#</span>}
                                {`${idToChannel[notifier.channelId].name}`}
                              </>
                            ) : (slackChannelLoading ? <LoadingOutlined /> : ` ${notifier.channelId}`)}
                          </div>
                        )}

                      </h3>
                    </div>
                    <div>
                      <Dropdown
                        trigger="click"
                        className="More_options"
                        overlay={(
                          <Menu>
                            <Menu.Item
                              icon={<DeleteOutlined />}
                              onClick={() => this.deleteNotifier(index)}
                              danger
                            >
                              Delete notification
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <MoreOutlined style={{ cursor: 'pointer' }} />
                      </Dropdown>
                    </div>
                  </div>
                  <div>
                    <div className="EventItem flex">
                      <div
                        className="ToggleLine"
                        onClick={() => (rankingUpdate ? this.deleteNotifierEvent : this.addNotifierEvent)(index, 'RANKING_UPDATE', rankingUpdateIndex)}
                      >
                        <Switch checked={!!rankingUpdate} />
                  &nbsp;
                        <span>Ranking Update</span>
                      </div>
                      {rankingUpdate && (
                        <div className="flex" style={{ alignItems: 'center' }}>
                          <div className="Params">
                            <Input
                              disabled={rankingUpdate.params.fromRank === 'Infinity'}
                              addonBefore="Top"
                              value={rankingUpdate.params.fromRank === 'Infinity' ? 3 : rankingUpdate.params.fromRank}
                              onChange={this.updateRank(index, rankingUpdateIndex)}
                            />

                          </div>
                          <div style={{ marginLeft: '10px' }}>
                            <Checkbox
                              size="small"
                              checked={rankingUpdate.params.fromRank === 'Infinity'}
                              onChange={this.updateRankCheck(
                                index,
                                rankingUpdateIndex,
                              )}
                            >
                              Notify every change
                            </Checkbox>
                          </div>
                        </div>
                      )}

                    </div>
                    {rankingUpdate && (
                      <div className="Explanation">
                        {rankingUpdate.params.fromRank === 'Infinity' && 'A notification will be sent everytime there is any change in the ranking.'}
                        {/* eslint-disable-next-line no-restricted-globals */}
                        {rankingUpdate.params.fromRank !== 'Infinity' && rankingUpdate.params.fromRank && !isNaN(+rankingUpdate.params.fromRank) && `A notification will be sent everytime there is a change in the top ${rankingUpdate.params.fromRank} of the ranking.`}
                      </div>
                    )}
                  </div>
                  <Divider style={{ margin: '6px 0' }} />
                  <div>
                    <div className="EventItem ">
                      <div
                        className="ToggleLine"
                        onClick={() => (rankingReminder ? this.deleteNotifierEvent : this.addNotifierEvent)(index, 'REMINDER', rankingReminderIndex)}
                      >
                        <Switch checked={!!rankingReminder} />
                  &nbsp;
                        <span>Ranking reminder</span>
                      </div>
                      {rankingReminder && (
                        <div style={{ marginTop: '8px' }}>
                          <Cron
                            clearButton={false}
                            clearButtonProps={{ type: 'link' }}
                            value={rankingReminder.params.cron}
                            setValue={(e) => this.onChangeCron(index, rankingReminderIndex, e)}
                            onError={(e) => this.onErrorCron(index, rankingReminderIndex, e)}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              </div>

            </Card>
          );
        })}
        <br />
        {challenge.notifiers && challenge.notifiers.length > 0 && (
          <Button
            icon={<SaveOutlined />}
            block
            size="large"
            onClick={this.save}
            type="primary"
          >
            save notifications
          </Button>
        )}
        {loading && <FullScreenLoader />}
        <Modal
          title="Add a notification"
          visible={showAddModal}
          onCancel={() => this.setState({ showAddModal: false })}
          footer={null}
        >
          <Form
            ref={this.FormRef}
            initialValues={{}}
            onFinish={this.addNotifier}
          >

            <div className="Add_notifier_wrapper">
              <div
                className={`Add_notifier_element ${type === 'SLACK' ? 'Add_notifier_element_selected' : ''}`}
                onClick={() => {
                  this.setState({ type: 'SLACK' });
                }}
              >

                <div className="Add_notifier_element_icon">
                  <img src="img/slack_icon.png" alt="slack icon" style={{ width: '50px', height: '50px' }} />
                </div>
                <div className="Add_notifier_element_name">SLACK</div>
              </div>
              <Tooltip
                title="Coming soon"
              // title={alreadyHasEmailNotifier ? 'An email notification is already setup' : null}
              >
                <div
                  className={`Add_notifier_element ${type === 'EMAIL' ? 'Add_notifier_element_selected' : ''} ${alreadyHasEmailNotifier ? 'disabled' : ''}`}
                  onClick={alreadyHasEmailNotifier ? null : () => {
                    this.setState({ type: 'EMAIL' });
                  }}
                >
                  <div className="Add_notifier_element_icon"><MailOutlined /></div>
                  <div className="Add_notifier_element_name">EMAIL</div>
                </div>
              </Tooltip>

            </div>

            {type === 'SLACK' && (
              this.props.profile.userData.company.slack ? (
                <Form.Item label="Channel" required>
                  <SlackChannelSelect
                    value={channelId}
                    onChange={(cId) => this.setState({ channelId: cId })}
                  />
                </Form.Item>
              ) : (
                <div style={{
                  textAlign: 'center',
                  marginBottom: '12px',
                  padding: ' 12px',
                  background: 'whitesmoke',
                  borderRadius: '4px',
                }}
                >
                  <div style={{ marginBottom: 8 }}>
                    You must first connect PaletteHQ to your Slack
                  </div>
                  <SlackConnect />
                </div>
              )
            )}
            <Form.Item style={{ textAlign: 'right' }}>
              <Button
                htmlType="submit"
                type="primary"
                disabled={!type || (!this.props.profile.userData.company.slack && type === 'SLACK') || (type === 'SLACK' && !channelId)}
              >
                {`Add${(type && type.length > 0 ? ` ${type}` : '')} notification`}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

NotificationsTab.propTypes = {
  challenge: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
  profile: ProfileModel.propTypes.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(NotificationsTab);
