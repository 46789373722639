import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Input as AntDInput } from 'antd';

import styles from './TextArea.less';

const classNames = bindClassNames.bind(styles);

const TextArea = ({
  className,
  value,
  onChange,
  disabled,
  disableResize,
  ...otherProps
}, ref = {}) => {
  const [internalValue, setInternalValue] = useState(value);

  const handleOnChange = (event) => {
    let newValue = event;
    if (event && event.target) {
      newValue = event.target.value;
      if (event.type === 'click') {
        newValue = '';
      }
    }

    setInternalValue(newValue);
    onChange(newValue);
  };

  const inputValue = (value !== null ? value : internalValue);

  let refProp = {};
  if (Object.keys(ref).length !== 0) {
    refProp = {
      ref,
    };
  }

  return (
    <AntDInput.TextArea
      {...refProp}
      className={classNames({
        wrapper: true,
        disableResize,
        [className]: className !== '',
      })}
      disabled={disabled}
      value={inputValue}
      onChange={handleOnChange}
      {...otherProps}
    />
  );
};

/* eslint-disable react/no-unused-prop-types */
const propTypesShape = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disableResize: PropTypes.bool,
};

const defaultPropsShape = {
  className: '',
  value: '',
  onChange: () => {},
  disabled: false,
  disableResize: false,
};

TextArea.propTypes = propTypesShape;
TextArea.defaultProps = defaultPropsShape;

export const TextAreaRefForwarded = forwardRef((props, ref) => TextArea(props, ref));
TextAreaRefForwarded.propTypes = propTypesShape;
TextAreaRefForwarded.defaultProps = defaultPropsShape;

export default TextArea;
