export const MAINTENANCE_MODE = process.env.MAINTENANCE_MODE === 'true';

export const API_URL = process.env.DEV_URL || 'http://localhost:5854';

/* eslint-disable-next-line prefer-destructuring */
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;

export const PERIOD = {
  BEGIN_DATE: 'PERIOD_BEGIN_DATE',
  END_DATE: 'PERIOD_END_DATE',
};

export const SCOPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM',
  MANAGER: 'MANAGER',
};

export const CUSTOM_PERIOD_TYPE = 'CUSTOM';

export const PERIODS_MONTH = ['M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'M9', 'M10', 'M11', 'M12'];
export const PERIODS_QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4'];
export const PERIODS_TERTILES = ['T1', 'T2', 'T3'];
export const PERIODS_SEMESTER = ['S1', 'S2'];
export const PERIODS_DOUBLED = [
  ...PERIODS_MONTH,
  ...PERIODS_MONTH,
  ...PERIODS_QUARTERS,
  ...PERIODS_QUARTERS,
  ...PERIODS_TERTILES,
  ...PERIODS_TERTILES,
  ...PERIODS_SEMESTER,
  ...PERIODS_SEMESTER,
];
export const ALL_PERIODS = [
  ...PERIODS_MONTH,
  ...PERIODS_QUARTERS,
  ...PERIODS_TERTILES,
  ...PERIODS_SEMESTER,
  'YEAR',
  CUSTOM_PERIOD_TYPE,
];
export const ORDER_PLANS = {
  ALPHABETICAL: 'alphabetical',
  PROGRESS: 'progress',
  COMMISSION: 'commission',
  TARGET: 'target',
};

export const CRM = {
  HUBSPOT: 'HUBSPOT',
  SALESFORCE: 'SALESFORCE',
  PIPEDRIVE: 'PIPEDRIVE',
  SELLSY: 'SELLSY',
  CSV: 'CSV',
  GSHEET: 'GSHEET',
  ASTRO_CRM: 'ASTRO_CRM',
  SWILE_REDSHIFT: 'SWILE_REDSHIFT',
  QUICKBOOKS: 'QUICKBOOKS',
  QUICKBOOKS_SANDBOX: 'QUICKBOOKS_SANDBOX',
  OUTREACH: 'OUTREACH',
  CLOSE: 'CLOSE',
};

export const DEFAULT_PAGINATION_LIMIT = 20;

export const CURRENCIES = {
  EUR: 'EUR',
  USD: 'USD',
  XAF: 'XAF',
};
export const CHALLENGE_TYPE = {
  PUBLIC_LEADERBOARD: 'PUBLIC_LEADERBOARD',
  ANONYMOUS_LEADERBOARD: 'ANONYMOUS_LEADERBOARD',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const REWARDS_TYPE = {
  MONEY: 'MONEY',
  OTHER: 'OTHER',
};
export const PERIOD_SELECTOR = [
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11',
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11',
  'Q1', 'Q2', 'Q3', 'Q4',
  'Q1', 'Q2', 'Q3', 'Q4',
  'T1', 'T2', 'T3',
  'T1', 'T2', 'T3',
  'S1', 'S2',
  'S1', 'S2',
  'YEAR', 'YEAR',
  'YEAR_TO_DATE', 'YEAR_TO_DATE',
];

export const PERIOD_AVAILABLE_GRAPH = [
  'Q1', 'Q2', 'Q3', 'Q4',
  'T1', 'T2', 'T3',
  'S1', 'S2',
  'YEAR', 'YEAR',
];

export const FIRST_PERIODS = ['0', 'Q1', 'T1', 'S1', 'M1', 'YEAR', 'YEAR_TO_DATE'];
export const LAST_PERIODS = ['11', 'Q4', 'T3', 'S2', 'YEAR', 'YEAR_TO_DATE'];

export const MATCHING_FIELDS = {
  HUBSPOT_Deal: ['hubspot_owner_id'],
  HUBSPOT_User: ['ownerId'],
  HUBSPOT_Engagement: ['ownerId'],
  HUBSPOT_Contact: ['hubspot_owner_id'],
  HUBSPOT_Company: ['hubspot_owner_id'],
  SALESFORCE_User: ['Id'],
  SALESFORCE_Opportunity: ['OwnerId'],
  SALESFORCE_Task: ['OwnerId', 'CreatedById'],
  SELLSY_User: ['linkedid'],
  SELLSY_Opportunity: [],
  SELLSY_Invoice: ['docspeakerStaffId'],
  SELLSY_CreditNote: ['docspeakerStaffId'],
  SELLSY_Estimate: ['docspeakerStaffId'],
  PIPEDRIVE_User: ['id'],
  PIPEDRIVE_Deal: ['user_id', 'creator_user_id'],
};

export const DATE_FIELDS = {
  HUBSPOT_Deal: ['closedate', 'createdate'],
  HUBSPOT_User: [],
  HUBSPOT_Engagement: ['createdAt'],
  HUBSPOT_Contact: ['createdate'],
  HUBSPOT_Company: ['createdate', 'closedate', 'recent_deal_close_date'],
  SALESFORCE_User: [],
  SALESFORCE_Opportunity: ['CloseDate', 'CreatedDate'],
  SALESFORCE_Task: ['CreatedDate'],
  SELLSY_User: [],
  SELLSY_Opportunity: ['dueDate', 'date', 'created'],
  SELLSY_Invoice: ['created'],
  SELLSY_CreditNote: ['created'],
  SELLSY_Estimate: ['created'],
  PIPEDRIVE_User: [],
  PIPEDRIVE_Deal: ['close_time', 'won_time'],
};

export const ENTITIES = {
  PLAN: 'Plan',
  CHALLENGE: 'Challenge',
};

export const CONNECTOR_TYPES = {
  RAW_DATA: 'Databases and Others',
  CRM: 'CRM',
  BILLING: 'Billing & Revenue management',
  HR: 'HR system',
};

export const CONNECTORS = {
  QUICKBOOKS: 'QUICKBOOKS',
  QUICKBOOKS_SANDBOX: 'QUICKBOOKS_SANDBOX',
};

export const HUBSPOT_FORM_URL = 'https://palettehq.com/en/demo-request?utm_campaign=marketplace&utm_source=hubspot';

export const PLAN_PERIODS = {
  MONTH: {
    id: 'MONTH',
    name: 'Month',
    periods: ['M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'M9', 'M10', 'M11', 'M12'],
  },
  QUARTER: {
    id: 'QUARTER',
    name: 'Quarter',
    periods: ['Q1', 'Q2', 'Q3', 'Q4'],
  },
  SEMESTER: {
    id: 'SEMESTER',
    name: 'Semester',
    periods: ['S1', 'S2'],
  },
  YEAR: { id: 'YEAR', name: 'Year' },
  CUSTOM: { id: 'CUSTOM', name: 'Custom Period' },
};

export const PLAN_FREEZE_TYPES = {
  ALL: {
    value: 'ALL',
    label: 'Freeze all calculations and payout rules',
    hint: 'All calculations would be frozen',
  },
  RULES_ONLY: {
    value: 'RULES_ONLY',
    label: 'Freeze only commission calculations',
    hint: 'Calculations would be frozen but payout rules would still be computed.',
  },
};

export const CURRENCY_SELECTOR_MIXED = 'Mixed';

export const ANALYTICS_DOC_SOURCES = {
  ADD_PLAN_PAGE: {
    id: 'ADD_PLAN_PAGE',
    label: 'Documentation link in Add Plan page',
  },
  ADD_USERS_MODAL: {
    id: 'ADD_USERS_MODAL',
    label: 'Documentation link in "Add Users" modal',
  },
  CONNECTOR_DETAILS_PAGE: {
    id: 'CONNECTOR_DETAILS_PAGE',
    label: 'Documentation link in Connector Details page',
  },
  CONNECTORS_PAGE: {
    id: 'CONNECTORS_PAGE',
    label: 'Documentation link in Connectors page',
  },
  NETSUITE_CONNECTOR_MODAL: {
    id: 'NETSUITE_CONNECTOR_MODAL',
    label: 'Documentation link in "Connect NetSuite" modal',
  },
  PLAN_DETAILS_PAGE_SETTINGS: {
    id: 'PLAN_DETAILS_PAGE_SETTINGS',
    label: 'Documentation link in Plan Details page, in Settings tab',
  },
  ROLES_PAGE: {
    id: 'ROLES_PAGE',
    label: 'Documentation link in Roles page',
  },
  SIDEBAR: {
    id: 'SIDEBAR',
    label: 'Documentation link in sidebar',
  },
  TEAM_DETAILS_PAGE: {
    id: 'TEAM_DETAILS_PAGE',
    label: 'Documentation link in Team Details page',
  },
  TEAMS: {
    id: 'TEAMS',
    label: 'Documentation link in Teams page',
  },
  UPLOAD_CSV_MODAL: {
    id: 'UPLOAD_CSV_MODAL',
    label: 'Documentation link in "Upload CSV Data" modal',
  },
  CREATE_PLAN_PAYOUT_RULES: {
    id: 'CREATE_PLAN_PAYOUT_RULES',
    label: 'Documentation link in payout rule form',
  },
};

export default API_URL;
