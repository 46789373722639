import routePaths from '@palette/config/routePaths';

import {
  PLAN_SETTINGS_TABS_IDS,
  PLAN_SETTINGS_TABS_QS_KEY,
  USER_DETAILS_TABS_IDS,
  USER_DETAILS_TABS_QS_KEY,
} from '@palette/constants/tabs';

export const SEARCHED_TYPE_QS_KEY = 'searchedType';

export const SEARCHED_SINCE_QS_KEY = 'searchedSince';

export const FILTER_BY_TIME_OPTIONS = {
  TODAY: 'today',
  WEEK: 'week',
  MONTH: 'month',
};

export const ENTITY_TYPE = {
  PLAN: 'plan',
  STATEMENT: 'statement',
  COMMISSION: 'commission',
  RESOURCE: 'resource',
  USER: 'user',
  SETTING: 'setting',
};

export const FILTER_BY_TYPE_OPTIONS = ENTITY_TYPE;

export const ACTION_TYPE = {
  // PLAN
  FREEZE_PLAN: 'FreezePlan',
  UNFREEZE_PLAN: 'UnfreezePlan',
  HIDE_PLAN_FOR_REPS: 'HidePlanForReps',
  SHOW_PLAN_FOR_REPS: 'ShowPlanForReps',
  AUTO_FREEZE_PLAN_ENABLED: 'EnableAutoFreeze',
  AUTO_FREEZE_PLAN_DISABLED: 'DisableAutoFreeze',
  UPDATED_PLAN_NAME: 'UpdatePlanName',
  UPDATED_PLAN_CURRENCY: 'UpdatePlanCurrency',
  ADDED_USERS_PLAN: 'AddUsersPlan',
  DELETED_USERS_PLAN: 'DeleteUsersPlan',
  LINKED_QUOTA_PLAN: 'LinkQuotaPlan',
  UNLINKED_QUOTA_PLAN: 'UnlinkQuotaPlan',
  // STATEMENT
  ADJUSTMENT_ADDED: 'CreateStatementAdjustment',
  ADJUSTMENT_UPDATED: 'UpdateStatementAdjustment',
  ADJUSTMENT_DELETED: 'DeleteStatementAdjustment',
  CORRECTION_APPLIED: 'ApplyStatementCorrection',
  CORRECTION_IGNORED: 'IgnoreStatementCorrection',
  STATEMENT_APPROVED: 'ApproveStatement',
  STATEMENT_MARKED_AS_PAID: 'MarkAsPaidStatement',
  // COMMISSION
  OVERWRITE_COMMISSION_AMOUNT: 'OverwriteCommissionAmount',
  OVERWRITE_COMMISSION_PAYOUT: 'OverwriteCommissionPayout',
  RESETED_COMMISSION_AMOUNT: 'ResetCommissionAmount',
  RESETED_COMMISSION_PAYOUT: 'ResetCommissionPayout',
  // RESOURCE
  CONNECTOR_ADDED: 'CreateConnector',
  RAW_DATA_UPDATED: 'UpdateRawData',
  RAW_DATA_DELETED: 'DeleteRawDataEntry',
  // USER
  OFFBOARD_LEAVE_DATE: 'OffboardUser',
  OFFBOARD_RESET_DATE: 'ResetUserOffboarding',
  USER_ROLE_UPDATED: 'UpdateUserRole',
  // SETTING
  NUMBER_SEATS: 'UpdateCompanySeats',
  STATEMENT_FX_RATE_MODE: 'UpdateStatementFxRateMode',
  TOGGLE_STATEMENT_VALIDATION: 'ToggleStatementValidation',
};

export const CONTENT_PARAMS = {
  PLAN_ID: 'planId',
  PLAN_NAME: 'planName',
  PREVIOUS_PLAN_NAME: 'previousPlanName',
  PERIOD_ID: 'periodId',
  PERIOD_DETAILS: 'periodDetails',
  SOURCE_PERIOD_DETAILS: 'sourcePeriodDetails',
  TARGET_PERIOD_DETAILS: 'targetPeriodDetails',
  PERIOD_DATE: 'periodDate',
  COMMISSION_ID: 'commissionId',
  PAYEE: 'payee',
  NEW_AMOUNT: 'newAmount',
  OLD_AMOUNT: 'previousAmount',
  AMOUNT: 'amount',
  USER: 'user',
  LEAVE_DATE: 'leaveDate',
  QUOTA: 'quota',
  QUOTA_TRACKING_TYPE: 'quotaTrackingType',
  ROLE_NAME: 'roleName',
  TYPE: 'type',
  ENTRY: 'entry',
  NAME: 'name',
  NEW_SEATS_QUANTITY: 'newSeatsQuantity',
  NEW_STATEMENT_FX_RATE_MODE: 'newStatementFxRateMode',
  NEW_STATEMENT_VALIDATION: 'newStatementValidation',
  AUTO_FREEZE_ENABLED: 'autoFreezeEnabled',
  FREEZE_AFTER: 'freezeAfter',
  CURRENCY: 'currency',
  USERS_COUNT: 'usersCount',
};

export const ACTION_TYPE_CONTENT = {
  // PLAN
  FREEZE_PLAN: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME],
  UNFREEZE_PLAN: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME],
  HIDE_PLAN_FOR_REPS: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME],
  SHOW_PLAN_FOR_REPS: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME],
  AUTO_FREEZE_PLAN_ENABLED: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME, CONTENT_PARAMS.AUTO_FREEZE_ENABLED, CONTENT_PARAMS.FREEZE_AFTER],
  AUTO_FREEZE_PLAN_DISABLED: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME, CONTENT_PARAMS.AUTO_FREEZE_ENABLED],
  UPDATED_PLAN_NAME: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME, CONTENT_PARAMS.PREVIOUS_PLAN_NAME],
  UPDATED_PLAN_CURRENCY: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME, CONTENT_PARAMS.CURRENCY],
  ADDED_USERS_PLAN: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME, CONTENT_PARAMS.USERS_COUNT],
  DELETED_USERS_PLAN: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME, CONTENT_PARAMS.USERS_COUNT],
  LINKED_QUOTA_PLAN: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME, CONTENT_PARAMS.QUOTA],
  UNLINKED_QUOTA_PLAN: [CONTENT_PARAMS.PLAN_ID, CONTENT_PARAMS.PLAN_NAME, CONTENT_PARAMS.QUOTA],
  // STATEMENT
  ADJUSTMENT_ADDED: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.PERIOD_DETAILS, CONTENT_PARAMS.NEW_AMOUNT],
  ADJUSTMENT_UPDATED: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.PERIOD_DETAILS, CONTENT_PARAMS.NEW_AMOUNT, CONTENT_PARAMS.OLD_AMOUNT],
  ADJUSTMENT_DELETED: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.PERIOD_DETAILS],
  CORRECTION_APPLIED: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.SOURCE_PERIOD_DETAILS, CONTENT_PARAMS.TARGET_PERIOD_DETAILS, CONTENT_PARAMS.AMOUNT],
  CORRECTION_IGNORED: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.SOURCE_PERIOD_DETAILS, CONTENT_PARAMS.AMOUNT],
  STATEMENT_APPROVED: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.PERIOD_DETAILS, CONTENT_PARAMS.AMOUNT],
  STATEMENT_MARKED_AS_PAID: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.PERIOD_DETAILS, CONTENT_PARAMS.AMOUNT],
  // COMMISSION
  OVERWRITE_COMMISSION_AMOUNT: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.NEW_AMOUNT, CONTENT_PARAMS.OLD_AMOUNT],
  OVERWRITE_COMMISSION_PAYOUT: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.QUOTA_TRACKING_TYPE],
  RESETED_COMMISSION_AMOUNT: [CONTENT_PARAMS.PAYEE],
  RESETED_COMMISSION_PAYOUT: [CONTENT_PARAMS.PAYEE, CONTENT_PARAMS.QUOTA_TRACKING_TYPE],
  // RESOURCE
  CONNECTOR_ADDED: [CONTENT_PARAMS.NAME],
  RAW_DATA_UPDATED: [CONTENT_PARAMS.TYPE, CONTENT_PARAMS.ENTRY],
  RAW_DATA_DELETED: [CONTENT_PARAMS.TYPE, CONTENT_PARAMS.ENTRY],
  // USER
  OFFBOARD_LEAVE_DATE: [CONTENT_PARAMS.USER, CONTENT_PARAMS.LEAVE_DATE],
  OFFBOARD_RESET_DATE: [CONTENT_PARAMS.USER],
  USER_ROLE_UPDATED: [CONTENT_PARAMS.USER, CONTENT_PARAMS.ROLE_NAME],
  // SETTING
  NUMBER_SEATS: [CONTENT_PARAMS.NEW_SEATS_QUANTITY],
  STATEMENT_FX_RATE_MODE: [CONTENT_PARAMS.NEW_STATEMENT_FX_RATE_MODE],
  TOGGLE_STATEMENT_VALIDATION: [CONTENT_PARAMS.NEW_STATEMENT_VALIDATION],
};

export const LINK_PARAMS = [
  {
    actions: [
      ACTION_TYPE.FREEZE_PLAN,
      ACTION_TYPE.UNFREEZE_PLAN,
    ],
    urlParams: {
      path: routePaths.v2.planDetails,
      params: [
        { urlField: 'masterPlanId', storeFieldPath: 'planId' },
      ],
    },
  },
  {
    actions: [
      ACTION_TYPE.HIDE_PLAN_FOR_REPS,
      ACTION_TYPE.SHOW_PLAN_FOR_REPS,
      ACTION_TYPE.AUTO_FREEZE_PLAN_ENABLED,
      ACTION_TYPE.AUTO_FREEZE_PLAN_DISABLED,
      ACTION_TYPE.UPDATED_PLAN_NAME,
      ACTION_TYPE.UPDATED_PLAN_CURRENCY,
    ],
    urlParams: {
      path: routePaths.v2.planSettings,
      params: [
        { urlField: 'masterPlanId', storeFieldPath: 'planId' },
      ],
    },
  },
  {
    actions: [
      ACTION_TYPE.ADJUSTMENT_ADDED,
      ACTION_TYPE.ADJUSTMENT_UPDATED,
      ACTION_TYPE.ADJUSTMENT_DELETED,
      ACTION_TYPE.STATEMENT_APPROVED,
      ACTION_TYPE.STATEMENT_MARKED_AS_PAID,
    ],
    urlParams: {
      path: routePaths.v2.statementsUserStatement,
      params: [
        { urlField: 'statementPeriodId', storeFieldPath: 'periodId' },
        { urlField: 'userId', storeFieldPath: 'payee.genericId' },
      ],
      qs: [
        { urlField: 'from', storeFieldPath: 'fromDate', isDate: true },
      ],
    },
  },
  {
    actions: [
      ACTION_TYPE.OFFBOARD_LEAVE_DATE,
      ACTION_TYPE.OFFBOARD_RESET_DATE,
      ACTION_TYPE.USER_ROLE_UPDATED,
    ],
    urlParams: {
      path: routePaths.v2.userDetails,
      params: [
        { urlField: 'userId', storeFieldPath: 'user.id' },
      ],
      qs: [
        { urlField: USER_DETAILS_TABS_QS_KEY, storeFieldPath: USER_DETAILS_TABS_IDS.SETTINGS },
      ],
    },
  },
  {
    actions: [
      ACTION_TYPE.OVERWRITE_COMMISSION_AMOUNT,
      ACTION_TYPE.RESETED_COMMISSION_AMOUNT,
    ],
    urlParams: {
      path: routePaths.v2.commissionList,
      qs: [
        { urlField: 'searchedFrom', storeFieldPath: '' },
        { urlField: 'searchedTo', storeFieldPath: '' },
        { urlField: 'commissionId', storeFieldPath: 'commissionId' },
      ],
    },
  },
  {
    actions: [
      ACTION_TYPE.OVERWRITE_COMMISSION_PAYOUT,
      ACTION_TYPE.RESETED_COMMISSION_PAYOUT,
    ],
    urlParams: {
      path: routePaths.v2.commissionList,
      qs: [
        { urlField: 'searchedFrom', storeFieldPath: '' },
        { urlField: 'searchedTo', storeFieldPath: '' },
        { urlField: 'commissionId', storeFieldPath: 'commissionId' },
        { urlField: 'drawerTab', storeFieldPath: 'payments' },
      ],
    },
  },
  {
    actions: [
      ACTION_TYPE.CONNECTOR_ADDED,
    ],
    urlParams: {
      path: routePaths.connectorDetails,
      params: [
        { urlField: 'connectorId', storeFieldPath: 'connectorId' },
      ],
    },
  },
  {
    actions: [
      ACTION_TYPE.NUMBER_SEATS,
      ACTION_TYPE.STATEMENT_FX_RATE_MODE,
      ACTION_TYPE.TOGGLE_STATEMENT_VALIDATION,
    ],
    urlParams: {
      path: routePaths.v2.settings,
    },
  },
  {
    actions: [
      ACTION_TYPE.RAW_DATA_UPDATED,
      ACTION_TYPE.RAW_DATA_DELETED,
    ],
    urlParams: {
      path: routePaths.v2.resourceDetails,
      params: [
        { urlField: 'connectorId', storeFieldPath: 'connectorId' },
        { urlField: 'type', storeFieldPath: 'type' },
        { urlField: 'resourceId', storeFieldPath: 'objectId' },
      ],
    },
  },
  {
    actions: [
      ACTION_TYPE.ADDED_USERS_PLAN,
      ACTION_TYPE.DELETED_USERS_PLAN,
    ],
    urlParams: {
      path: routePaths.v2.planSettings,
      params: [
        { urlField: 'masterPlanId', storeFieldPath: 'planId' },
      ],
      qs: [
        { urlField: PLAN_SETTINGS_TABS_QS_KEY, storeFieldPath: PLAN_SETTINGS_TABS_IDS.USERS },
      ],
    },
  },
  {
    actions: [
      ACTION_TYPE.LINKED_QUOTA_PLAN,
      ACTION_TYPE.UNLINKED_QUOTA_PLAN,
    ],
    urlParams: {
      path: routePaths.v2.planSettings,
      params: [
        { urlField: 'masterPlanId', storeFieldPath: 'planId' },
      ],
      qs: [
        { urlField: PLAN_SETTINGS_TABS_QS_KEY, storeFieldPath: PLAN_SETTINGS_TABS_IDS.QUOTA },
      ],
    },
  },
];
