import React, { PureComponent } from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getNamesFromRawData } from '../services/utils';

const stc = require('string-to-color');

const AVATAR_PROPER_SIZE = {
  large: 90,
  medium: 60,
  small: 44,
  verysmall: 24,
};
const AVATAR_FONT_SIZE = {
  large: '44px',
  medium: '34px',
  small: '20px',
  verysmall: '12px',
};

class CustomAvatar extends PureComponent {
  hasProfilePicture = (profile) => (profile
  && profile.user
  && profile.user.profilePicUrl
  && profile.user.profilePicUrl.length > 0);

  render() {
    const {
      size, profile, style, ...props
    } = this.props;
    return (
      <Avatar
        draggable={false}
        shape="circle"
        size={AVATAR_PROPER_SIZE[size]}
        style={{
          fontWeight: 'bold',
          minWidth: AVATAR_PROPER_SIZE[size],
          minHeight: AVATAR_PROPER_SIZE[size],
          fontSize: AVATAR_FONT_SIZE[size],
          color: 'white',
          backgroundColor: this.hasProfilePicture(profile)
            ? 'transparent'
            : (profile ? (stc((profile.user && profile.user._id)
              ? profile.user._id : (profile._id ? profile._id : 't'))) : 'grey'),
          ...style,
        }}
        src={
          this.hasProfilePicture(profile)
            ? profile.user.profilePicUrl
            : null
        }
        {...props}
      >
        {this.hasProfilePicture(profile)
          ? '' : ((profile && profile.user && profile.user._id)
            ? (profile.user.firstName || profile.user.email || '').substring(0, 1).toUpperCase()
            : (profile && profile.connector && profile.data) ? (getNamesFromRawData(
              profile.connector,
              profile,
            ) || '').substring(0, 1).toUpperCase() : '')}
      </Avatar>
    );
  }
}

CustomAvatar.propTypes = {
  size: PropTypes.string,
  profile: PropTypes.object,
  style: PropTypes.object,
};

CustomAvatar.defaultProps = {
  size: 'small',
  profile: null,
  style: {},
};

export default React.memo(CustomAvatar, _.isEqual);
