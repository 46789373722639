import React, { Component } from 'react';
import {
  LoadingOutlined, SaveOutlined, PlusOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  Alert, Button, Collapse, Divider, Empty, Form, message, Spin, Statistic, Tabs, Tag,
} from 'antd';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import pluralize from 'pluralize';
import { connect } from 'react-redux';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import AddUsersWithFastOnboardingModal from '@palette/components/user/AddUsersWithFastOnboardingModal/AddUsersWithFastOnboardingModal';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';
import * as MetaUserModel from '@palette/models/MetaUser';

import RewardRuleEditor from '../../../components/admin/challenges/rewardRuleEditor';
import {
  parseSearch, getCurrencyLabel, isCurrencyBeforeNumber,
} from '../../../services/utils';
import {
  getChallengeDetails, getRankingDetails,
  updateChallenge, bulkAddUsersToChallenge, removeUserFromChallenge,
} from '../../../services/admin/challenges';
import { REWARDS_TYPE, CHALLENGE_TYPE } from '../../../services/constants';
import ChallengeRanking from '../../../components/challenges/challengeRanking';
import '../../../styles/challenges/challenge-details.less';
import ConnectorTab from '../../../components/admin/entity-details/connector-tab';
import SettingsTab from '../../../components/admin/entity-details/settings-tab';
import LaunchRecomputation from '../../../components/admin/launchRecomputation';
import RewardRulesDisplay from '../../../components/admin/challenges/rewardRulesDisplay';
import NotificationsTab from '../../../components/admin/entity-details/notifications-tab';

import BackLink from '../../../components/backLink';

class ChallengeDetails extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      loading: false,
      challenge: null,
      cost: '-',
      addLoading: false,
    };
    this.RuleEditorRef = React.createRef();
  }

  componentDidMount() {
    this.getChallengeDetails();
  }

  getChallengeDetails = () => {
    this.setState({ loading: true }, () => {
      getChallengeDetails(this.props.match.params.challengeId).then(({
        data: challenge,
      }) => this.setState({

        challenge: {
          ...challenge,
          ranking: challenge.ranking.reduce((acc, element) => [...acc, {
            ...element,
            totalRewards: element.rewards
              .reduce((accu, reward) => (reward.type === REWARDS_TYPE.MONEY
                ? accu + reward.amount : accu),
              0),
            totalRewardsWithOtherValues: element.rewards
              .reduce((accu, reward) => (accu + reward.amount),
                0),
          }], []),
        },
        loading: false,
      }, () => {
        this.setState((prevState) => ({
          cost: prevState.challenge
            .ranking.reduce((acc, r) => r.totalRewardsWithOtherValues + acc, 0),
        }));
      })).catch((err) => {
        console.error('loading challenge', err);
        this.setState({ loading: false });
      });
    });
  };

  onTabChange = (key) => {
    this.props.history.push(`/challenges/${this.props.match.params.challengeId}?tab=${key}`);
  };

  removeUserFromChallenge = (objectId) => {
    this.setState({ loading: true }, () => {
      removeUserFromChallenge(this.props.match.params.challengeId, objectId).then(() => {
        this.getChallengeDetails();
      }).catch((e) => {
        this.setState({ loading: false });
        message.error('Error while removing user, please try again.');
        console.error(e);
      });
    });
  };

  onRulesUpdate = () => {
    this.setState({ loading: true }, () => {
      updateChallenge(this.props.match.params.challengeId, {
        rewardRules: this.RuleEditorRef.current.getRules(),
      })
        .then(() => {
          this.setState({ loading: false });
          message.success('Successfully updated challenge');
          this.getChallengeDetails();
        })
        .catch((error) => {
          console.error(error);
          message.error('Error while updating challenge');
          this.setState({ loading: false });
        });
    });
  };

  getRankingInfoRegardingType = (type) => {
    switch (type) {
      case CHALLENGE_TYPE.PUBLIC_LEADERBOARD:
        return 'The ranking is public to all participant.';
      case CHALLENGE_TYPE.ANONYMOUS_LEADERBOARD:
        return 'The ranking is anonymous, each user only sees its own rank.';
      case CHALLENGE_TYPE.INDIVIDUAL:
        return 'The ranking is individual, each user only sees its own performance, no ranking is diplayed.';
      default:
        return 'The ranking is public to all participant.';
    }
  };

  handleAddUsersToChallenge = (usersToAdd, cleanAndClose) => {
    const objectIds = usersToAdd.map((user) => (user.id));
    this.setState({ addLoading: true }, () => {
      bulkAddUsersToChallenge(this.state.challenge._id, objectIds)
        .then(() => {
          this.setState({ addLoading: false });
          cleanAndClose();
          this.getChallengeDetails();
        })
        .catch((error) => {
          console.error(error);
          message.error('Error while adding users to challenge');
          this.setState({ addLoading: false });
        });
    });
  };

  render() {
    const { loading, challenge, cost } = this.state;
    const { tab, add } = parseSearch(this.props.location.search);
    const canUpdate = hasAtLeastOneRight(this.props.profile, ['admin.challenges.update']);
    const canManageUsers = hasAtLeastOneRight(this.props.profile, ['admin.challenges.gbo.manage']);

    return (
      <>
        <MetaTag title={challenge?.name || ''} />
        <div className="div-wrapper Challenges_wrapper">
          <div className="container">
            <div className="container-inner">
              <div className="Challenge_details_wrapper">
                <br />
                <div>
                  <BackLink defaultUrl="/challenges" />
                </div>
                <div className="Challenge_details_inner">
                  <div className="Challenge_details_title">
                    <h1>
                      {challenge?.name || ''}
                    </h1>
                    {challenge && (
                      <div className="grey">
                        {`${Moment(challenge.beginAt).format('MMM Do, YYYY')} - ${Moment(challenge.endAt).format('MMM Do, YYYY')}`}
                        {!challenge.live && <Tag color="black" style={{ marginLeft: 8 }}>DRAFT</Tag>}
                      </div>
                    )}
                  </div>

                  <div className="right">
                    {challenge && (
                      <div className="flex">
                        <Statistic
                          title="Challenge Cost"
                          value={cost}
                          prefix={isCurrencyBeforeNumber(challenge.currency)
                            ? getCurrencyLabel(challenge.currency) : null}
                          suffix={isCurrencyBeforeNumber(challenge.currency)
                            ? null : getCurrencyLabel(challenge.currency)}
                          style={{ marginLeft: '24px' }}
                        />
                        <Statistic
                          title={pluralize(challenge.groupByObject.type,
                            challenge.groupByObject.values.length)}
                          value={challenge.groupByObject.values.length}
                          style={{ marginLeft: '24px' }}
                        />
                      </div>

                    )}
                  </div>
                </div>
              </div>

              {(canManageUsers && challenge && challenge.groupByObject
               && challenge.groupByObject.values
               && challenge.groupByObject.values.length === 0)
                ? (
                  <div>
                    <Alert
                      message="Add users to the challenge"
                      description={(
                        <div className="flex">
                          <div style={{ flexGrow: '1' }}>
                            You can now add users to the challenge.
                          </div>
                          <div>
                            <a href={`/#${routePaths.challengeDetails.replace(':challengeId', challenge._id)}?tab=ranking&add=true`}>
                              <Button
                                icon={<PlusOutlined />}
                                type="primary"
                              >
                                Add users
                              </Button>
                            </a>
                          </div>
                        </div>
                      )}
                      type="info"
                      showIcon
                    />
                  </div>
                ) : (
                  challenge && (
                    <LaunchRecomputation
                      entity={challenge}
                      callback={this.getChallengeDetails}
                      pleaseUpdateText="please update the ranking by"
                      loadingText={'Hold tight, we\'re recomputing the challenge.'}
                      forceRecompute
                    />
                  )
                )}

              <Spin
                className="center width-100"
                tip="Loading..."
                spinning={loading}
                indicator={<LoadingOutlined style={{ fontSize: 24, marginTop: '12px' }} spin />}
              >
                {challenge && (
                  <div className="Challenge_details_content_wrapper">
                    <Tabs
                      activeKey={tab || 'ranking'}
                      onChange={this.onTabChange}
                      animated={false}
                    >
                      <Tabs.TabPane key="ranking" tab={(challenge && challenge.type === CHALLENGE_TYPE.INDIVIDUAL) ? 'Performance' : 'Ranking'}>

                        {challenge.ranking.length > 0 ? (
                          <div>
                            {canManageUsers && (
                              <div className="flex width-100" style={{ justifyContent: 'flex-end' }}>
                                <Button
                                  icon={<PlusOutlined />}
                                  type="primary"
                                  onClick={() => this.props.history.push(`/challenges/${this.props.match.params.challengeId}?tab=${tab || ''}&add=true`)}
                                >
                                  {`Add ${pluralize(challenge.groupByObject.type)}`}
                                </Button>
                              </div>
                            )}
                            <div className="center grey">
                              {this.getRankingInfoRegardingType(challenge.type)}
                            </div>
                            <ChallengeRanking
                              challenge={challenge}
                              getChallengeRankingDetails={getRankingDetails}
                              removeUser={this.removeUserFromChallenge}
                            />
                          </div>
                        ) : (
                          <div>
                            <Empty description="No users in the challenge yet">
                              {canManageUsers && (
                                <Button
                                  type="primary"
                                  icon={<PlusOutlined />}
                                  onClick={() => this.props.history.push(`/challenges/${this.props.match.params.challengeId}?tab=${tab || ''}&add=true`)}
                                >
                                  Add users now
                                </Button>
                              )}
                            </Empty>
                          </div>
                        )}
                      </Tabs.TabPane>
                      <Tabs.TabPane key="rewardRules" tab="Rewards">
                        {tab === 'rewardRules' && (
                          <div className="Plan_details_settings_wrapper">
                            <h2 className="bold">Details</h2>
                            <RewardRulesDisplay
                              rules={challenge.rewardRules}
                              challenge={challenge}
                            />
                            {canUpdate && (
                              <>
                                <Divider />
                                <Collapse expandIconPosition="right">
                                  <Collapse.Panel
                                    header={(
                                      <div className="flex">
                                        <div style={{ flexGrow: '1' }}>
                                          <h2 className="bold no-margin" style={{ lineHeight: '20px' }}>Configuration</h2>
                                        </div>
                                        <div style={{ color: '#bfbfbf', fontStyle: 'italic' }}>
                                          Click to expand
                                        </div>
                                      </div>
                                    )}
                                    key="1"
                                  >
                                    <Form layout="vertical">
                                      <Form.Item>
                                        <Alert
                                          message="We can help"
                                          description="If you're not sure on how to implement a rule, please contact us at hello@palettehq.com so that we can help."
                                          type="info"
                                          showIcon
                                        />
                                        <br />
                                        <RewardRuleEditor
                                          challenge={challenge}
                                          challengeType={challenge.type}
                                          initialRules={challenge.rewardRules}
                                          ref={this.RuleEditorRef}
                                        />
                                      </Form.Item>
                                      <Button
                                        type="primary"
                                        block
                                        icon={<SaveOutlined />}
                                        loading={loading}
                                        className="width-100"
                                        onClick={this.onRulesUpdate}
                                      >
                                        Save rules
                                      </Button>
                                    </Form>
                                  </Collapse.Panel>
                                </Collapse>
                              </>
                            )}
                          </div>
                        )}
                      </Tabs.TabPane>
                      {hasAtLeastOneRight(this.props.profile, ['admin.challenges.update']) && (
                        <Tabs.TabPane key="settings" tab="Settings">
                          {tab === 'settings' && (
                            <SettingsTab
                              entity={challenge}
                              type="Challenge"
                              refresh={this.getChallengeDetails}
                              canUpdate={canUpdate}
                            />
                          )}
                        </Tabs.TabPane>
                      )}
                      {hasAtLeastOneRight(this.props.profile, ['admin.challenges.update_connector']) && (
                        <Tabs.TabPane key="connector" tab="Connector">
                          {tab === 'connector' && (
                            <ConnectorTab
                              entity={challenge}
                              type="Challenge"
                              refresh={this.getChallengeDetails}
                            />
                          )}
                        </Tabs.TabPane>
                      )}
                      {hasAtLeastOneRight(this.props.profile, ['admin.challenges.update_notifications']) && (
                        <Tabs.TabPane
                          key="notifications"
                          tab={`Notifications${(challenge && challenge.notifiers && challenge.notifiers.length > 0)
                            ? ` (${challenge.notifiers.length})`
                            : ''}`}
                        >
                          {tab === 'notifications' && (
                            <NotificationsTab
                              challenge={challenge}
                              refresh={this.getChallengeDetails}
                            />
                          )}
                        </Tabs.TabPane>
                      )}
                    </Tabs>
                  </div>
                )}
              </Spin>
            </div>
          </div>
        </div>
        {
          challenge && add === 'true' && (
            <AddUsersWithFastOnboardingModal
              title="Add users to challenge"
              visible
              onClose={() => this.props.history.push(`/challenges/${this.props.match.params.challengeId}?tab=${tab || ''}`)}
              onOk={this.handleAddUsersToChallenge}
              loading={this.state.addLoading}
              preSelectedUsers={MetaUserModel.transformList(challenge?.groupByObject?.values || [])}
              restrictToConnectorIdAndType={challenge?.groupByObject?.connector ? { connectorId: challenge.groupByObject.connector._id, type: challenge.groupByObject.type } : null}
              onlyActiveUsers
            />
          )
        }
      </>
    );
  }
}

ChallengeDetails.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  profile: ProfileModel.propTypes.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(withRouter(ChallengeDetails));
