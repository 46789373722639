import { isUserGroupByUserPlan } from '@palette/helpers/MasterPlanHelper';
import { hasFeature, hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { FEATURES, RIGHTS } from '@palette/constants/profile';

export const DASHBOARD_DRAWER_ADD_WIDGET_DRAWER_QS_KEY = 'addWidgetDrawer';
export const DASHBOARD_PRESET_USER_QS_KEY = 'presetUserId';

export const DASHBOARD_WIDGETS = {
  PLAN_PROGRESS_GAUGE: {
    type: 'PLAN_PROGRESS_GAUGE',
    nameI18NId: 'widgets.plan_progress_gauge.name',
    descriptionI18NId: 'widgets.plan_progress_gauge.description',
    previewPictureSrc: 'img/dashboardComponents/gauge.png',
    getParams: ({ useMostRecent, planId, period }) => ({ useMostRecent, planId, periods: (period != null ? [period] : period) }),
    isAvailableForProfileAndPlan: (profile, plan) => (plan.quotaId !== null),
  },
  PLAN_PAYOUT_SCHEDULE: {
    type: 'PLAN_PAYOUT_SCHEDULE',
    nameI18NId: 'widgets.plan_payout_schedule.name',
    descriptionI18NId: 'widgets.plan_payout_schedule.description',
    previewPictureSrc: 'img/dashboardComponents/payoutschedule.png',
    getParams: ({ useMostRecent, planId, period }) => ({ useMostRecent, planId, periods: (period != null ? [period] : period) }),
    isAvailableForProfileAndPlan: () => true,
  },
  PLAN_PROGRESS_BAR_CHART: {
    type: 'PLAN_PROGRESS_BAR_CHART',
    nameI18NId: 'widgets.plan_progress_bar_chart.name',
    descriptionI18NId: 'widgets.plan_progress_bar_chart.description',
    previewPictureSrc: 'img/dashboardComponents/planbarchart.png',
    getParams: ({ useMostRecent, planId, period }) => ({ useMostRecent, planId, periods: (period != null ? [period] : period) }),
    isAvailableForProfileAndPlan: (profile, plan) => (plan.quotaId !== null && !isUserGroupByUserPlan(plan) && plan.periodType === PERIOD_TYPES.MONTH && plan.frequency > 1),
  },
  PLAN_WHAT_IF: {
    type: 'PLAN_WHAT_IF',
    nameI18NId: 'widgets.plan_what_if.name',
    descriptionI18NId: 'widgets.plan_what_if.description',
    previewPictureSrc: 'img/dashboardComponents/projection.png',
    getParams: ({ useMostRecent, planId, period }) => ({ useMostRecent, planId, periods: (period != null ? [period] : period) }),
    isAvailableForProfileAndPlan: (profile, plan) => (
      plan.quotaId !== null
      && plan.estimationDefinition !== null
      && plan.estimationDefinition.referenceDealId !== null
      && plan.estimationDefinition.display
    ),
  },
  PLAN_TOTAL_COMMISSIONS: {
    type: 'PLAN_TOTAL_COMMISSIONS',
    nameI18NId: 'widgets.plan_total_commissions.name',
    descriptionI18NId: 'widgets.plan_total_commissions.description',
    previewPictureSrc: 'img/dashboardComponents/plantotal.png',
    getParams: ({ useMostRecent, planId, period }) => ({ useMostRecent, planId, periods: (period != null ? [period] : period) }),
    isAvailableForProfileAndPlan: () => true,
  },
  PLAN_TOTAL_VALUE: {
    type: 'PLAN_TOTAL_VALUE',
    nameI18NId: 'widgets.plan_total_value.name',
    descriptionI18NId: 'widgets.plan_total_value.description',
    previewPictureSrc: 'img/dashboardComponents/planvalue.png',
    getParams: ({ useMostRecent, planId, period }) => ({ useMostRecent, planId, periods: (period != null ? [period] : period) }),
    isAvailableForProfileAndPlan: () => true,
  },
  PLAN_COUNT_OBJECTS: {
    type: 'PLAN_COUNT_OBJECTS',
    nameI18NId: 'widgets.plan_count_objects.name',
    descriptionI18NId: 'widgets.plan_count_objects.description',
    previewPictureSrc: 'img/dashboardComponents/planobjects.png',
    getParams: ({ useMostRecent, planId, period }) => ({ useMostRecent, planId, periods: (period != null ? [period] : period) }),
    isAvailableForProfileAndPlan: () => true,
  },
  PLAN_DETAILS: {
    type: 'PLAN_DETAILS',
    nameI18NId: 'widgets.plan_details.name',
    descriptionI18NId: 'widgets.plan_details.description',
    previewPictureSrc: 'img/dashboardComponents/plandetails.png',
    getParams: ({ useMostRecent, planId, period }) => ({ useMostRecent, planId, periods: (period != null ? [period] : period) }),
    isAvailableForProfileAndPlan: () => true,
  },
  STATEMENT_ONE_MONTH: {
    type: 'STATEMENT_ONE_MONTH',
    nameI18NId: 'widgets.statement_one_month.name',
    descriptionI18NId: 'widgets.statement_one_month.description',
    previewPictureSrc: 'img/dashboardComponents/statement.png',
    getParams: () => ({}),
    isAvailableForProfile: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION, RIGHTS.ADMIN.COMPANY.MANAGE]),
  },
  STATEMENT_PER_MONTH: {
    type: 'STATEMENT_PER_MONTH',
    nameI18NId: 'widgets.statement_per_month.name',
    descriptionI18NId: 'widgets.statement_per_month.description',
    previewPictureSrc: 'img/dashboardComponents/statementlist.png',
    getParams: () => ({}),
    isAvailableForProfile: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION, RIGHTS.ADMIN.COMPANY.MANAGE]),
  },
  LIST_PLANS: {
    type: 'LIST_PLANS',
    nameI18NId: 'widgets.list_plans.name',
    descriptionI18NId: 'widgets.list_plans.description',
    previewPictureSrc: 'img/dashboardComponents/listplans.png',
    getParams: () => ({}),
    isAvailableForProfile: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION, RIGHTS.ADMIN.COMPANY.MANAGE]),
  },
  CHALLENGE_SIMPLE_DISPLAY: {
    type: 'CHALLENGE_SIMPLE_DISPLAY',
    nameI18NId: 'widgets.challenge_simple_display.name',
    descriptionI18NId: 'widgets.challenge_simple_display.description',
    previewPictureSrc: 'img/dashboardComponents/challengedetails.png',
    getParams: ({ useMostRecent, challengeId }) => ({ useMostRecent, challengeId }),
    isAvailableForProfileAndChallenge: (profile) => hasFeature(profile, FEATURES.CHALLENGE) && hasAtLeastOneRight(profile, [RIGHTS.IC.CHALLENGES, RIGHTS.ADMIN.COMPANY.MANAGE]),
  },
  LATEST_OBJECTS: {
    type: 'LATEST_OBJECTS',
    nameI18NId: 'widgets.latest_objects.name',
    descriptionI18NId: 'widgets.latest_objects.description',
    previewPictureSrc: 'img/dashboardComponents/listobjects.png',
    getParams: () => ({}),
    isAvailableForProfile: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION, RIGHTS.ADMIN.COMPANY.MANAGE]),
  },
  LIST_CHALLENGES: {
    type: 'LIST_CHALLENGES',
    nameI18NId: 'widgets.list_challenges.name',
    descriptionI18NId: 'widgets.list_challenges.description',
    previewPictureSrc: 'img/dashboardComponents/listchallenges.png',
    getParams: () => ({}),
    isAvailableForProfile: (profile) => hasFeature(profile, FEATURES.CHALLENGE) && hasAtLeastOneRight(profile, [RIGHTS.IC.CHALLENGES, RIGHTS.ADMIN.COMPANY.MANAGE]),
  },
  COMMISSIONS_UNRELEASED_LIST: {
    type: 'COMMISSIONS_UNRELEASED_LIST',
    nameI18NId: 'widgets.commissions_unreleased_list.name',
    descriptionI18NId: 'widgets.commissions_unreleased_list.description',
    previewPictureSrc: 'img/dashboardComponents/commissionsUnreleasedList.png',
    getParams: () => ({}),
    isAvailableForProfile: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION, RIGHTS.ADMIN.COMPANY.MANAGE]),
  },
  COMMISSIONS_UNRELEASED: {
    type: 'COMMISSIONS_UNRELEASED',
    nameI18NId: 'widgets.commissions_unreleased.name',
    descriptionI18NId: 'widgets.commissions_unreleased.description',
    previewPictureSrc: 'img/dashboardComponents/commissionsUnreleased.png',
    getParams: () => ({}),
    isAvailableForProfile: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION, RIGHTS.ADMIN.COMPANY.MANAGE]),
  },
};

export const PLAN_DASHBOARD_WIDGETS = [
  DASHBOARD_WIDGETS.PLAN_PROGRESS_GAUGE,
  DASHBOARD_WIDGETS.PLAN_PROGRESS_BAR_CHART,
  DASHBOARD_WIDGETS.PLAN_WHAT_IF,
  DASHBOARD_WIDGETS.PLAN_DETAILS,
  DASHBOARD_WIDGETS.PLAN_TOTAL_COMMISSIONS,
  DASHBOARD_WIDGETS.PLAN_TOTAL_VALUE,
  DASHBOARD_WIDGETS.PLAN_COUNT_OBJECTS,
  DASHBOARD_WIDGETS.PLAN_PAYOUT_SCHEDULE,
];

export const COMMON_DASHBOARD_WIDGETS = [
  DASHBOARD_WIDGETS.LATEST_OBJECTS,
  DASHBOARD_WIDGETS.LIST_CHALLENGES,
  DASHBOARD_WIDGETS.LIST_PLANS,
  DASHBOARD_WIDGETS.STATEMENT_ONE_MONTH,
  DASHBOARD_WIDGETS.STATEMENT_PER_MONTH,
  DASHBOARD_WIDGETS.COMMISSIONS_UNRELEASED_LIST,
  DASHBOARD_WIDGETS.COMMISSIONS_UNRELEASED,
];

export const CHALLENGE_DASHBOARD_WIDGETS = [
  DASHBOARD_WIDGETS.CHALLENGE_SIMPLE_DISPLAY,
];
