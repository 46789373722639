import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import routePaths from '@palette/config/routePaths';

import * as UserStatementChallengeModel from '@palette/models/UserStatementChallenge';

import styles from './MyUserStatementChallengesItem.less';

const classNames = bindClassNames.bind(styles);

const MyUserStatementChallengesItem = ({ className, challenge }) => {
  const moment = getMoment();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.details}>
        <Link
          className={styles.link}
          path={routePaths.myChallengeDetails}
          params={{ challengeId: challenge.id }}
        >
          {challenge.name}
        </Link>
        {` - ${moment(challenge.beginAt).format('ll')}`}
        {` - ${moment(challenge.endAt).format('ll')}`}
      </div>
      <div className={styles.commission}>
        {formatPrice(challenge.amount, challenge.currency)}
      </div>
    </div>
  );
};

MyUserStatementChallengesItem.propTypes = {
  className: PropTypes.string,
  challenge: UserStatementChallengeModel.propTypes.isRequired,
};

MyUserStatementChallengesItem.defaultProps = {
  className: '',
};

export default MyUserStatementChallengesItem;
