import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

const manage404 = (problem) => {
  const managedErrors = {
    'challenge.not.found': {
      code: GLOBAL_NOTIF_REASONS.CHALLENGE_404_NOT_FOUND.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {
    404: manage404,
  };

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    const { code } = error;

    return {
      ...problem,
      message: { code },
    };
  }

  return manageGlobalError({ status, problem });
};
