import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as UserStatementAdjustmentModel from '@palette/models/UserStatementAdjustment';
import * as UserStatementPlanModel from '@palette/models/UserStatementPlan';
import * as UserStatementChallengeModel from '@palette/models/UserStatementChallenge';
import * as UserStatementApprovalModel from '@palette/models/UserStatementApproval';
import * as UserStatementCorrectionModel from '@palette/models/UserStatementCorrection';

export const modelName = 'UserStatement';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  user: MetaUserModel.propTypes,
  beginAt: PropTypes.string,
  endAt: PropTypes.string,
  statementAmount: PropTypes.number,
  currency: PropTypes.string,
  adjustmentsTotalAmount: PropTypes.number,
  adjustmentsCurrency: PropTypes.string,
  adjustments: PropTypes.arrayOf(UserStatementAdjustmentModel.propTypes),
  plans: PropTypes.arrayOf(UserStatementPlanModel.propTypes),
  challengesTotalAmount: PropTypes.number,
  challengesCurrency: PropTypes.string,
  challenges: PropTypes.arrayOf(UserStatementChallengeModel.propTypes),
  approvals: PropTypes.arrayOf(UserStatementApprovalModel.propTypes),
  isDirty: PropTypes.bool,
  isPaid: PropTypes.bool,
  paidBy: PropTypes.shape({
    user: MetaUserModel.propTypes,
    date: PropTypes.string,
    amount: PropTypes.number,
  }),
  correctionsCount: PropTypes.number,
  corrections: PropTypes.arrayOf(UserStatementCorrectionModel.propTypes),
});

export const manageStatementAmount = (userStatement) => manageAmountAttribute(userStatement, 'amount');

export const manageApprovals = (userStatement) => {
  const { approvals } = userStatement;

  if (!approvals) return [];

  const statementAmount = manageStatementAmount(userStatement);

  return approvals.map((approval) => ({
    ...UserStatementApprovalModel.transform(manageFreeShapeObjectAttribute({ approval }, 'approval')),
    ...(parseFloat(approval.amount).toFixed(2) !== parseFloat(statementAmount).toFixed(2) ? { isInConflict: true } : undefined),
  }));
};

export const managePaidByAttribute = (userStatement) => {
  const { paid } = userStatement;

  if (!paid) return null;

  return {
    user: MetaUserModel.transform(manageFreeShapeObjectAttribute(paid, 'who')),
    date: manageDateAttribute(paid, 'when'),
    amount: manageAmountAttribute(paid, 'amount'),
  };
};

export const transform = (userStatement) => {
  if (!userStatement) {
    return null;
  }

  const correctionsArray = manageArrayAttribute(userStatement, 'corrections', null);
  const correctionsInRaw = correctionsArray !== null;

  let correctionsCount = manageNumberAttribute(userStatement, 'correctionsCount', null);
  const correctionsCountInRaw = correctionsCount !== null;
  if (correctionsCountInRaw === null) {
    correctionsCount = 0;
  }

  const adjustmentsArray = manageArrayAttribute(userStatement, 'adjustments', null);
  const adjustmentsInRaw = adjustmentsArray !== null;

  return ({
    id: manageStringAttribute(userStatement, 'statementId'),
    user: MetaUserModel.transform(manageFreeShapeObjectAttribute(userStatement, 'payee')),
    beginAt: manageDateAttribute(userStatement, 'beginAt'),
    endAt: manageDateAttribute(userStatement, 'endAt'),
    statementAmount: manageStatementAmount(userStatement),
    currency: manageStringAttribute(userStatement, 'currency'),
    adjustmentsTotalAmount: manageAmountAttribute(userStatement, 'totalAdjustments'),
    adjustments: UserStatementAdjustmentModel.transformList(manageArrayAttribute(userStatement, 'adjustments')),
    adjustmentsInRaw,
    plans: UserStatementPlanModel.transformList(manageArrayAttribute(userStatement, 'plans')),
    challengesTotalAmount: manageAmountAttribute(userStatement, 'totalChallenges'),
    challenges: UserStatementChallengeModel.transformList(manageArrayAttribute(userStatement, 'challenges')),
    approvals: manageApprovals(userStatement),
    isDirty: manageBooleanAttribute(userStatement, 'isDirty'),
    isPaid: manageBooleanAttribute(userStatement, 'isPaid'),
    paidBy: managePaidByAttribute(userStatement),
    corrections: UserStatementCorrectionModel.transformList(correctionsArray ?? []),
    correctionsInRaw,
    correctionsCount,
    correctionsCountInRaw,
  });
};

export const transformList = (userStatements) => userStatements.map((userStatement) => transform(userStatement));

export const merge = (userStatement1, userStatement2) => {
  if (!userStatement2) return userStatement1;
  if (!userStatement1) return userStatement2;

  let mergedAdjustmentsTotalAmount = userStatement1.adjustmentsTotalAmount;
  if (userStatement2.adjustmentsInRaw) {
    mergedAdjustmentsTotalAmount = userStatement2.adjustmentsTotalAmount;
  }

  let mergedAdjustments = userStatement1.adjustments;
  if (userStatement2.adjustmentsInRaw) {
    mergedAdjustments = userStatement2.adjustments;
  }

  let mergedPlans = userStatement1.plans;
  if (userStatement2.plans.length > 0) {
    mergedPlans = userStatement2.plans;
  }

  let mergedChallengesTotalAmount = userStatement1.challengesTotalAmount;
  if (userStatement2.challengesTotalAmount > 0) {
    mergedChallengesTotalAmount = userStatement2.challengesTotalAmount;
  }

  let mergedChallenges = userStatement1.challenges;
  if (userStatement2.challenges.length > 0) {
    mergedChallenges = userStatement2.challenges;
  }

  let mergedCorrectionsCount = userStatement1.correctionsCount;
  if (userStatement2.correctionsCountInRaw) {
    mergedCorrectionsCount = userStatement2.correctionsCount;
  }

  let mergedCorrections = userStatement1.corrections;
  if (userStatement2.correctionsInRaw) {
    mergedCorrections = userStatement2.corrections;
  }

  return {
    ...userStatement2,
    adjustmentsTotalAmount: mergedAdjustmentsTotalAmount,
    adjustments: mergedAdjustments,
    plans: mergedPlans,
    challengesTotalAmount: mergedChallengesTotalAmount,
    challenges: mergedChallenges,
    correctionsCount: mergedCorrectionsCount,
    corrections: mergedCorrections,
  };
};
