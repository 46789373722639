import React from 'react';
import { Alert } from 'antd';
import PropTypes from 'prop-types';
import FreezedIcon from './freezedIcon';

import '../../styles/freezedAlert.less';

const FreezedAlert = ({ txt }) => (
  <div className="FreezedAlert_wrapper">
    <Alert
      type="info"
      showIcon
      icon={<FreezedIcon />}
      message={txt}
    />
  </div>
);

FreezedAlert.propTypes = {
  txt: PropTypes.string,
};
FreezedAlert.defaultProps = {
  txt: 'This plan is frozen. You have limited update options.',
};
export default FreezedAlert;
