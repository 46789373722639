/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-syntax */
import Moment from 'moment-timezone';
import _ from 'lodash';
import { unflatten } from 'flat';
import {
  CURRENCIES, CUSTOM_PERIOD_TYPE,
  REWARDS_TYPE, CHALLENGE_TYPE, SCOPE, CONNECTORS,
} from './constants';
import { getJob } from './job';
import { compute } from './connector';
import { myEval, capitalize } from './my-eval';
import getCommonData from './get-common-data';

export { myEval, getCommonData, capitalize };

export const getSessionId = () => localStorage.getItem('sessionId');

export function parseSearch(search) {
  return (
    search
      .substr(1, Infinity) // Remove ?
      .split('&') // Split every fragment
      // Reduce
      .reduce((prev, fragment) => {
        const [key, value] = fragment.split('=');
        // eslint-disable-next-line no-param-reassign
        if (key) prev[key] = decodeURIComponent(value);
        return prev;
      }, {})
  );
}

export function stringifySearch(obj) {
  return `?${Object.keys(obj).map((key) => `${key}=${encodeURIComponent(obj[key])}`).join('&')}`;
}

export const getNamesFromRawData = (connector, object) => {
  const { firstName, lastName, email } = getCommonData(connector, object);
  return (firstName || lastName) ? `${firstName} ${lastName}` : email;
};

export const getCurrencyLabel = (currencyCode) => {
  switch (currencyCode) {
    case CURRENCIES.EUR:
      return '€';
    case CURRENCIES.USD:
      return '$';
    case CURRENCIES.XAF:
      return 'FCFA';
    default:
      return currencyCode;
  }
};

export const isCurrencyBeforeNumber = (currencyCode) => {
  switch (currencyCode) {
    case CURRENCIES.EUR:
      return false;
    case CURRENCIES.XAF:
      return false;
    case CURRENCIES.USD:
    default:
      return true;
  }
};

const getCorrectMonth = (monthNumber) => ((monthNumber < 10) ? `0${monthNumber}` : ((monthNumber > 12) ? '01' : monthNumber));
const getCorrectYearFromMonth = (year, month) => (month > 12 ? (+year + 1) : year);

export const periodToDate = (periodType, periodYear, offsetHours = 0) => ({
  ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce((obj, month) => ({
    ...obj,
    [`M${month}`]: {
      beginAt: Moment.utc(`${periodYear}/${getCorrectMonth(month)}/01`).set({ hour: 0 }).add(offsetHours, 'hours')
        .toISOString(),
      endAt: Moment.utc(`${getCorrectYearFromMonth(periodYear, month + 1)}/${getCorrectMonth(month + 1)}/01`).set({ hour: 0 })
        .add(offsetHours, 'hours').subtract(1, 'ms')
        .toISOString(),
    },
  }), {}),
  ...[1, 2, 3, 4].reduce((obj, n) => ({
    ...obj,
    [`Q${n}`]: {
      beginAt: Moment.utc(`${periodYear}/${(((n - 1) * 3) + 1) < 10 ? `0${(((n - 1) * 3) + 1)}` : (((n - 1) * 3) + 1)}/01`).set({ hour: 0 }).add(offsetHours, 'hours')
        .toISOString(),
      endAt: Moment.utc(`${getCorrectYearFromMonth(periodYear, (n * 3) + 1)}/${getCorrectMonth((n * 3) + 1)}/01`).set({ hour: 0 })
        .add(offsetHours, 'hours').subtract(1, 'ms')
        .toISOString(),
    },
  }), {}),
  ...[1, 2, 3].reduce((obj, n) => ({
    ...obj,
    [`T${n}`]: {
      beginAt: Moment.utc(`${periodYear}/${(((n - 1) * 4) + 1) < 10 ? `0${(((n - 1) * 4) + 1)}` : (((n - 1) * 4) + 1)}/01`).set({ hour: 0 }).add(offsetHours, 'hours')
        .toISOString(),
      endAt: Moment.utc(`${getCorrectYearFromMonth(periodYear, (n * 4) + 1)}/${getCorrectMonth((n * 4) + 1)}/01`).set({ hour: 0 })
        .add(offsetHours, 'hours').subtract(1, 'ms')
        .toISOString(),
    },
  }), {}),
  ...[1, 2].reduce((obj, n) => ({
    ...obj,
    [`S${n}`]: {
      beginAt: Moment.utc(`${periodYear}/${(((n - 1) * 6) + 1) < 10 ? `0${(((n - 1) * 6) + 1)}` : (((n - 1) * 6) + 1)}/01`).set({ hour: 0 }).add(offsetHours, 'hours')
        .toISOString(),
      endAt: Moment.utc(`${getCorrectYearFromMonth(periodYear, (n * 6) + 1)}/${getCorrectMonth((n * 6) + 1)}/01`).set({ hour: 0 })
        .add(offsetHours, 'hours').subtract(1, 'ms')
        .toISOString(),
    },
  }), {}),
  YEAR: {
    beginAt: Moment.utc(`${periodYear}/01/01`).set({ hour: 0 }).add(offsetHours, 'hours')
      .toISOString(),
    endAt: Moment.utc(`${parseInt(periodYear, 10) + 1}/01/01`).set({ hour: 0 })
      .add(offsetHours, 'hours').subtract(1, 'ms')
      .toISOString(),
  },
  YEAR_TO_DATE: {
    beginAt: Moment.utc(`${periodYear}/01/01`).set({ hour: 0 }).add(offsetHours, 'hours')
      .toISOString(),
    endAt: Moment.utc().set({ hour: 0 }).add(offsetHours, 'hours')
      .toISOString(),
  },
}[periodType]);

export const customPeriodToDate = (customPeriod, offset = 0) => ({
  beginAt: Moment.utc(customPeriod[0].toISOString()).set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  }).add(-offset, 'hours').toISOString(),
  endAt: Moment.utc(customPeriod[1].toISOString()).set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  }).add(1, 'day')
    .add(-offset, 'hours')
    .subtract(1, 'ms')
    .toISOString(),
});

export const periodTypeToName = (periodType) => ({
  ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce((obj, month) => ({
    ...obj,
    [`M${month}`]: Moment(new Date(2020, month - 1, 15, 0, 0, 0, 0)).format('MMMM'),
  }), {}),
}[periodType] || periodType);

export const getOffsetHoursFromEntity = (entity) => {
  const offsetHours = entity?.beginAt
    ? Moment(entity.beginAt).utc().diff(Moment(entity.beginAt).utc().set({ hour: 0 }), 'hours')
    : -(Moment(new Date()).utcOffset() / 60);
  const offsetSign = offsetHours > 12 ? -(24 - offsetHours) : offsetHours;
  return -offsetSign;
};

export const formatPeriods = (entity) => {
  const offsetHours = getOffsetHoursFromEntity(entity);
  return (
    typeof entity.periodType === 'undefined'
  || entity.periodType === CUSTOM_PERIOD_TYPE
      ? `${Moment.utc(entity.beginAt).add(offsetHours, 'hours').format('MMM Do YYYY')} - ${Moment.utc(entity.endAt).add(offsetHours, 'hours').format('MMM Do YYYY')}`
      : `${periodTypeToName(entity.periodType)} - ${entity.periodYear}`
  );
};

export const getRewardDisplay = (reward, currency) => {
  switch (reward.type) {
    case REWARDS_TYPE.OTHER:
      return `${reward.description}`;
    case REWARDS_TYPE.MONEY:
    default:
      return `${isCurrencyBeforeNumber(currency) ? `${getCurrencyLabel(currency)}${reward.amount}` : `${reward.amount}${getCurrencyLabel(currency)}`}`;
  }
};

export const getChallengeTypeText = (type) => {
  switch (type) {
    case CHALLENGE_TYPE.INDIVIDUAL:
      return 'Individual challenge';
    case CHALLENGE_TYPE.ANONYMOUS_LEADERBOARD:
      return 'Anonymous Challenge';
    case CHALLENGE_TYPE.PUBLIC_LEADERBOARD:
    default:
      return 'Public Challenge';
  }
};

export const dateFromMongoId = (objectId) => new Date(
  parseInt(objectId.substring(0, 8), 16) * 1000,
);

export const isSuperAdmin = () => {
  const ss = getSessionId();
  if (!ss || ss.length === 0) {
    return false;
  }
  return ss.substring(0, 5) === 'admin';
};

export const isDemoAccount = (profile) => {
  if (!profile) return false;
  return [
    '5faadaa4ecebaf7acea5d5af', // Palette (Dev Account) company ID
    '5fc79c5dd05fb74fe8c1b4a7', // Palette (Demo Account)
    '6012cc5b2a062219f948cfcd', // Palette official
  ].includes(profile.userData.company.id);
};

export const isLocalhost = () => window
      && window.location
      && window.location.hostname === 'localhost';

export const getTagColorFromScope = (scope) => {
  switch (scope) {
    case SCOPE.MANAGER:
      return 'red';
    case SCOPE.TEAM:
    default:
      return 'blue';
  }
};

export const slugify = (str) => {
  let stri = str;
  const m = {
    '-': ' ',
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };
  Object.keys(m).forEach((pattern) => {
    stri = stri.replace(new RegExp(m[pattern], 'g'), pattern);
  });

  return stri;
};

export const isPlanFreezed = (entity) => entity && !!entity.freezeDate;

export const filterEntities = (entities, type) => {
  const now = new Date().toISOString();
  return entities.filter((entity) => {
    if (type === 'PAST') {
      return entity.endAt < now;
    }
    if (type === 'UPCOMING') {
      return entity.beginAt > now;
    }
    return entity.beginAt <= now && entity.endAt > now;
  });
};

export const isReport = (type) => (type || '').indexOf('ReportItem_') === 0;

export const getDisplayType = ({ type, uiType }) => {
  if (uiType) return uiType;
  if (isReport(type)) {
    const [, , ...typeFragments] = type.split('_');
    return (typeFragments || []).join('_') || type;
  }
  return type;
};

export const hasRight = (profile, params) => {
  if (!profile || !profile.role || !profile.role.rights) return false;
  if (typeof params === 'string') {
    return profile.role.rights[params] || (profile.role.rights['admin.*'] && params.indexOf('admin.') === 0) || false;
  }
  if (Array.isArray(params)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const param of params) {
      if (profile.role.rights[param] || (profile.role.rights['admin.*'] && param.indexOf('admin.') === 0)) {
        return true;
      }
    }
    return false;
  }
  return false;
};

export class JobError extends Error {
  constructor(message) {
    super(message);
    this.name = 'JobError';
  }
}

export const waitJob = async (jobId) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { data: job } = await getJob(jobId);
    if (new Date(job.lastActivityAt).getTime() < Date.now() - 30000) throw new Error();
    if (job.status === 'DONE') return Promise.resolve(job);
    if (job.status === 'ERRORED') throw new JobError(job.error);
    // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
};

export const computeFn = async (entityId, force = false) => {
  const { data: job } = await compute(entityId, force);
  await waitJob(job._id);
};

export const xToMoment = (x) => {
  if (typeof x === 'number') {
    const strNumber = x.toFixed();
    if (strNumber.length === 10) {
      return Moment(x * 1000);
    }
    return Moment(x);
  }
  if (typeof x === 'string') {
    const isInt = `${parseInt(x, 10)}` === x;
    if (isInt) {
      if (x.length === 13) {
        return Moment(+x);
      }
      if (x.length === 10) {
        return Moment(x * 1000);
      }
    }
    return Moment(x);
  }
  return Moment(NaN);
};

const DATE_FORMAT = {
  Date: 'YYYY-MM-DD',
  DateTime: 'YYYY-MM-DD @ HH:mm',
};

const DEFAULT_VALUE = '';

// gcv = getColumnValue
export const gcv = (data, column, defaultValue = DEFAULT_VALUE) => {
  if (!column) return '';

  const fieldsValues = column.fields.map((field) => _.get(data, field));
  const value = fieldsValues
    .map((fieldValue) => (typeof fieldValue === 'undefined' ? '' : fieldValue))
    .join(' ')
    .trim() || defaultValue;
  if (DATE_FORMAT[column.formatter] && value !== '') {
    return xToMoment(value).format(DATE_FORMAT[column.formatter]);
  }
  if (column.formatter === 'Custom') {
    return myEval(column.evalFn, {
      value,
      fields: fieldsValues,
      ...unflatten(column.fields.reduce((obj, field) => ({
        ...obj,
        [field]: _.get(data, field),
      }), {})),
    }, defaultValue);
  }
  return value;
};

export const escapeRegExp = (text) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const buildMongoSearch = (text = '', columns, basePath = 'data.', $options) => {
  const allFields = [...new Set(columns.map(({ fields }) => fields).flat())];
  if (allFields.length === 0) return {};
  return {
    $and: text.trim().split(' ').map((fragment) => ({
      $or: allFields.map((field) => ({
        [`${basePath}${field}`]: {
          $regex: escapeRegExp(fragment),
          $options,
        },
      })),
    })),
  };
};

export const onRouteChanged = (currenPathname) => {
  const history = (sessionStorage.getItem('history') || '').split(',');
  sessionStorage.setItem('history', [...history, currenPathname]);
};

export const getBackRouteURL = (currenPathname, fallbackUrl) => {
  const history = (sessionStorage.getItem('history') || '').split(',').reverse();
  const found = history.findIndex((route) => route.length > 0 && route.split('?')[0] !== currenPathname);
  if (found === -1) {
    return fallbackUrl;
  }
  return history[found];
};

export const getBackRoute = (currenPathname, fallbackUrl) => {
  const route = getBackRouteURL(currenPathname, fallbackUrl);
  return `/#${route}`;
};

export const getBackRouteName = (currenPathname, fallbackUrl) => {
  const route = getBackRouteURL(currenPathname, fallbackUrl);
  const name = (route.split('/')[1] || '').split('?')[0];
  switch (name) {
    case 'objects':
      return 'Resource';
    case 'my-dashboard':
      return 'Dashboard';
    case 'my-statements':
      return 'Statement';
    case 'my-plans':
      return 'Plans';
    case 'my-challenges':
      return 'Challenges';
    case 'add-plan-from-template':
      return 'Add plan';
    case 'create-plan':
    case 'create-new-plan':
      return 'Create plan';
    case 'add-challenge':
      return 'Add Challenge';
    case 'add-plan':
      return 'Add plan';
    case 'o':
      return 'Resource';
    default:
      return name;
  }
};

export const popLatestBackRoute = () => {
  const history = (sessionStorage.getItem('history') || '').split(',');
  if (history.length > 2) {
    history.pop();
    history.pop();
    sessionStorage.setItem('history', history);
  }
};

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

export function setCookie(name, value) {
  const d = new Date();
  d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};domain=.palettehq.com`;
}

export function deleteCookie(name) {
  const d = new Date(0);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=;${expires};domain=.palettehq.com`;
}

export function setSessionId(sessionId) {
  localStorage.setItem('sessionId', sessionId);
  setCookie('sessionId', sessionId);
}

export const showTrial = (profile) => profile
  && profile.userData.company
  && profile.userData.company.trialEndDate;

export const getConnectorName = (name) => {
  switch (name) {
    case CONNECTORS.QUICKBOOKS:
      return 'QuickBooks';
    case CONNECTORS.QUICKBOOKS_SANDBOX:
      return 'QuickBooks Sandbox';
    default:
      return (name || '').split('_').join(' ');
  }
};

export const flatFolders = (folders, parentId = 0) => (
  _.flatMapDeep(folders, (folder) => ([
    {
      ...folder,
      id: folder._id,
      parent: parentId,
      text: folder.name,
      droppable: true,
      hasChildren: folder.children && folder.children.length > 0,
    },
    (folder.children && folder.children.length > 0 ? flatFolders(folder.children, folder._id) : []),
  ]))
);

export const toVariableName = (str = '') => {
  let formatted = str
    .replace(/ /g, '_')
    .replace(/[^a-zA-Z0-9_]/g, '');
  if (formatted.match(/^[0-9]/)) {
    formatted = `_${formatted}`;
  }
  return formatted;
};
