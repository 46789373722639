/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Col, DatePicker, Divider, Dropdown, Form, Input, Menu,
  message, Modal, Row, Select, Switch, Tooltip,
} from 'antd';
import {
  DeleteOutlined, ExclamationCircleOutlined, LoadingOutlined,
  MoreOutlined, SaveOutlined, QuestionCircleFilled, CalculatorOutlined, SyncOutlined,
} from '@ant-design/icons';
import Moment from 'moment';
import pluralize from 'pluralize';

import { connect } from 'react-redux';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import {
  ALL_PERIODS,
  CUSTOM_PERIOD_TYPE,
  ENTITIES,
  PLAN_FREEZE_TYPES, PLAN_PERIODS,
} from '../../../services/constants';
import {
  periodToDate, periodTypeToName, isPlanFreezed, computeFn,
  isLocalhost, isSuperAdmin, JobError, getOffsetHoursFromEntity, customPeriodToDate,
} from '../../../services/utils';
import * as PlanService from '../../../services/admin/plans';
import * as ChallengeService from '../../../services/admin/challenges';
import FreezedAlert from '../../freeze/freezedAlert';
import FreezedIcon from '../../freeze/freezedIcon';
import FullScreenLoader from '../../loader';

import AutoCompleteInput from '../../autoCompleteInput';
import SelectUTC from '../../selectUTC';

const Entity = {
  Plan: {
    delete: PlanService.deletePlan,
    update: PlanService.updatePlan,
    redirect: routePaths.v2.plans,
  },
  Challenge: {
    delete: ChallengeService.deleteChallenge,
    update: ChallengeService.updateChallenge,
    redirect: routePaths.challenges,
  },
};

class SettingsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteLoading: false,
      updateLoading: false,
      renewLoading: false,
      currentPeriodType: props.entity.periodType || null,
      targetFormula: props.entity.targetFormula || null,
      forceRecomputeLoading: false,
      targetDefinitionInput: null,
      editedUTC: null,
      freezeType: props.entity.freezeType ? props.entity.freezeType : PLAN_FREEZE_TYPES.ALL.value,
    };
  }

  delete = () => {
    if (this.props.type === ENTITIES.PLAN && isPlanFreezed(this.props.entity)) {
      Modal.info({
        title: 'Plan is frozen',
        okText: 'Close',
        content: (
          <div>
            You can't delete this plan because it is frozen.
            Please unfreeze the plan if you want to delete it.
          </div>
        ),
      });
    } else {
      Modal.confirm({
        title: `Do you want to delete this ${this.props.type} ?`,
        icon: <ExclamationCircleOutlined />,
        content: `Please confirm that you want to delete the ${this.props.type}.`,
        okText: `Delete ${this.props.type}`,
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.confirmDelete,
      });
    }
  };

  confirmDelete = () => (
    new Promise((resolveConfirmation, rejectConfirmation) => {
      this.setState({ deleteLoading: true }, () => {
        Entity[this.props.type].delete(this.props.entity._id).then(() => {
          setTimeout(() => {
            resolveConfirmation();
            this.props.history.push(Entity[this.props.type].redirect);
          }, 1000);
        }).catch((e) => {
          console.error(e);
          this.setState({ deleteLoading: false });
          rejectConfirmation(e);
        });
      });
    })
  );

  freeze = (freezeDate) => PlanService.freezePlan(this.props.entity._id, freezeDate);

  renew = () => this.setState({ renewLoading: true }, () => {
    PlanService.renew(this.props.entity._id).then(({ data: plan }) => {
      this.setState({ renewLoading: false });
      message.success((
        <>
          {'Successfully renewed the plan. '}
          <a href={`/#${routePaths.v2.planDetails.replace(':masterPlanId', plan._id)}`}>See it here</a>
          .
        </>
      ));
    }).catch((e) => {
      console.error(e);
      if (e && e.response && e.response.status === 409) {
        message.error('The plan has already been renewed.');
      } else if (e && e.response && e.response.status === 402) {
        message.error(
          <span>
            {'You must add seats to your account in order to add users in this plan. '}
            <a href={`/#${routePaths.settings}`}>Upgrade now</a>
          </span>,
        );
      } else {
        message.error('Unexpected error while renewing the plan.');
      }
      this.setState({ renewLoading: false });
    });
  });

  onUpdate = ({ customPeriod, ...val }) => {
    this.setState({ updateLoading: true }, () => {
      const values = this.props.entity.freezeDate
        ? { name: val.name, autoRenew: val.autoRenew }
        : {
          ...val,
          target: val.target || -1,
          targetFormula: val.targetFormula || '',
          quota: this.props.entity.quota
            ? (
              (val.scope !== 'INDIVIDUAL' || (val.scope === 'INDIVIDUAL' && val.periodType === PLAN_PERIODS.CUSTOM.id)) ? null : (this.props.entity.quota._id || this.props.entity.quota)
            )
            : null,
        };
      const offsetHours = getOffsetHoursFromEntity(this.props.entity);
      const offset = this.state.editedUTC !== null ? this.state.editedUTC : offsetHours;

      Entity[this.props.type].update(this.props.entity._id, {
        ...values,
        ...(
          this.props.entity.freezeDate ? {} : (this.props.type === ENTITIES.CHALLENGE
            || this.state.currentPeriodType === CUSTOM_PERIOD_TYPE
            ? customPeriodToDate(customPeriod, offset)
            : periodToDate(values.periodType, values.periodYear, -offset))
        ),
      }).then(() => {
        if (this.props.type === ENTITIES.PLAN && ((val.freezeDate && !this.props.entity.freezeDate)
          || (!val.freezeDate && this.props.entity.freezeDate))) {
          this.freeze(val.freezeDate ? this.props.entity.endAt : null).then(() => {
            this.setState({ updateLoading: false });
            message.success(`Successfully updated ${this.props.type}`);
            this.props.refresh();
          });
        } else {
          this.setState({ updateLoading: false });
          message.success(`Successfully updated ${this.props.type}`);
          this.props.refresh();
        }
      }).catch((err) => {
        console.error(err);
        if (err && err.response && err.response.status === 402) {
          message.error(
            <span>
              You must add seats to your account in order to perform this update.
              &nbsp;
              <a href={`/#${routePaths.settings}`}>Upgrade now</a>
            </span>,
          );
        } else {
          message.error(`Error while updating ${this.props.type}`);
        }
        this.setState({ updateLoading: false });
      });
    });
  };

  onFieldsChange = (changedFields) => {
    const changed = changedFields.find((f) => f.name.find((n) => n === 'periodType'));
    const changedTargetDefinition = changedFields.find((f) => f.name.find((n) => n === 'targetDefinition'));
    if (changed) {
      this.setState({ currentPeriodType: changed.value });
    }
    if (changedTargetDefinition) {
      this.setState({ targetDefinitionInput: changedTargetDefinition.value });
    }
    const freezeType = changedFields.find((f) => f.name.find((n) => n === 'freezeType'));
    if (freezeType) {
      this.setState({ freezeType: freezeType.value });
    }
  };

  forceRecomputeEntity = async () => {
    this.setState({ forceRecomputeLoading: true });
    try {
      await computeFn(this.props.entity._id, true);
      message.success('Refresh successful');
      this.props.refresh();
      this.setState({ forceRecomputeLoading: false });
    } catch (error) {
      this.setState({ forceRecomputeLoading: false });
      if (error && error.response && error.response.status === 409) {
        message.error('A computation is already running, please try again later.');
      } else if (error instanceof JobError && (isSuperAdmin() || isLocalhost())) {
        const e = JSON.parse(error.message);
        message.error(`JobError: ${e.message}`);
      } else {
        message.error('Unexpected error while computing.');
      }
    }
  };

  buildAutoSuggest = (types) => {
    const trigger = [' ', '', '('];
    const options = {
      ' ': types,
      '': types,
      '(': types,
    };
    return { options, trigger };
  };

  renderPlanFormItems = () => {
    const {
      currentPeriodType, targetDefinitionInput, targetFormula,
      editedUTC,
    } = this.state;
    const { entity, type } = this.props;
    return (
      <>
        <Form.Item
          label="Name"
          name="name"
          rules={[{
            required: true,
            message: 'Please input a name',
          }]}
        >
          <Input autoFocus />
        </Form.Item>
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item
              label="Period Type"
              name="periodType"
              rules={[{
                required: true,
                message: 'Please select a period type',
              }]}
            >
              <Select
                disabled={isPlanFreezed(entity)}
                dropdownMatchSelectWidth={false}
              >
                {ALL_PERIODS.map((value) => (
                  <Select.Option value={value} key={value}>
                    {periodTypeToName(value)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2} style={{ paddingLeft: '2px' }}>
            <SelectUTC
              value={editedUTC}
              onChange={this.onUTCChange}
              entity={entity}
            />
          </Col>
          <Col span={12}>
            {currentPeriodType === CUSTOM_PERIOD_TYPE ? (
              <Form.Item
                label="Period"
                name="customPeriod"
                rules={[{
                  required: true,
                  message: 'Please input the period.',
                }]}
              >
                <DatePicker.RangePicker
                  disabled={isPlanFreezed(entity)}
                  style={{ width: '100%' }}
                  format="MMM Do YYYY"
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Period Year"
                name="periodYear"
                rules={[{
                  required: true,
                  message: 'Please input period year!',
                }]}
              >
                <Input type="number" disabled={isPlanFreezed(entity)} />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              label="Scope"
              name="scope"
              rules={[{
                required: true,
                message: 'Please select scope!',
              }]}
            >
              <Select
                disabled={isPlanFreezed(entity)}
                dropdownMatchSelectWidth={false}
              >
                {['INDIVIDUAL', 'TEAM', 'MANAGER'].map((value) => (
                  <Select.Option value={value} key={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {
            (
              type !== ENTITIES.PLAN
              || entity.scope !== 'INDIVIDUAL'
              || (entity.scope === 'INDIVIDUAL' && (entity.periodType === PLAN_PERIODS.CUSTOM.id || (entity.target !== -1 && !entity.quota)))
            ) && (
              <>
                <Col span={12}>
                  <Form.Item
                    label="Name what is tracked in the plan ?"
                    name="targetDefinition"
                    extra={<span className="grey">Can be Revenue, MRR, ARR, Meeting, Demo...</span>}
                  >
                    <Input
                      placeholder="ex: MRR, ARR, Meeting or other element tracked in the plan"
                      disabled={isPlanFreezed(entity)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className="flex flex-center">
                    <Form.Item
                      label={`${entity.scope === 'INDIVIDUAL' ? 'Default target' : 'Target'}${targetDefinitionInput
                        ? ` (${targetDefinitionInput})`
                        : (entity.targetDefinition ? ` (${entity.targetDefinition})` : '')}`}
                      name="target"
                      style={{ flex: 1 }}
                    >
                      <Input type="number" disabled={isPlanFreezed(entity)} />
                    </Form.Item>
                    <Tooltip title="Use formula">
                      <Button
                        type="link"
                        icon={<CalculatorOutlined />}
                        className="Grey_button"
                        style={{ marginTop: 5 }}
                        onClick={() => this.setState({ targetFormula: targetFormula === null ? '' : null })}
                      />
                    </Tooltip>
                  </div>
                </Col>
                {targetFormula !== null && (
                  <Col span={12}>
                    <Form.Item
                      label="Target Formula"
                      name="targetFormula"
                    >
                      <Input disabled={isPlanFreezed(entity)} />
                    </Form.Item>
                  </Col>
                )}
              </>
            )
          }
          {this.props.profile.userData.company.currencies.length > 1
            && (
              <Col span={12}>
                <Form.Item
                  label="Currency"
                  name="currency"
                >
                  <Select
                    dropdownMatchSelectWidth={false}
                    placeholder="Select a currency"
                    disabled={isPlanFreezed(entity)}
                  >
                    {this.props.profile.userData.company.currencies.map((c) => (
                      <Select.Option value={c.code} key={c.code}>
                        {c.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
        </Row>
        <Form.Item
          label="Auto Renew"
          tooltip={Moment(this.props.entity.endAt).isBefore(Moment(Date.now())) ? { title: 'Past plans cannot be auto-renewed.' } : null}
          name="autoRenew"
          valuePropName="checked"
          extra={(
            <>
              {'When this feature is enabled, your plan will be renewed automatically at the end of period. '}
              <br />
              {'You can also '}
              <a onClick={this.renew}>click here</a>
              {' to renew it manually.'}
            </>
          )}
          className="SwitchFormItem"
        >
          <Switch disabled={Moment(this.props.entity.endAt).isBefore(Moment(Date.now()))} />
        </Form.Item>
        <Form.Item
          label="Live Mode"
          name="live"
          valuePropName="checked"
          extra="When this feature is enabled, commissions calculated by this plan will be shown on the statement of your users."
          className="SwitchFormItem"
        >
          <Switch disabled={isPlanFreezed(entity)} />
        </Form.Item>
        <Divider />
        <div>
          <h2 className="bold">Freeze settings</h2>
          <div className="grey Freeze_settings_description">
            {/* eslint-disable-next-line max-len */}
            {`Freezing a plan stops the calculations. The list of ${pluralize(entity.trackingObjectDisplayType)} will not change.  That way, you ‘ll be able to audit past commissions without the risk of data being updated in the background.`}
          </div>
          <h3 className="bold">Select a freeze type</h3>
          <div className="grey Freeze_type_description">
            {/* eslint-disable-next-line max-len */}
            You can either completely freeze the plan or freeze only the commission calculation but not the payout rules calculations. It is useful if your payout rules are set up to unlock payments over time (If you wait for invoices to be paid for instance).
          </div>
          <Form.Item
            className="Freeze_type"
            name="freezeType"
            required
            rules={[{
              required: true,
            }]}
            extra={
              PLAN_FREEZE_TYPES[this.state.freezeType]?.hint
              || PLAN_FREEZE_TYPES.ALL.hint
            }
          >
            <Select
              style={{
                width: '440px',
              }}
            >
              {Object.values(PLAN_FREEZE_TYPES).map((freezeType) => (
                <Select.Option key={freezeType.value} value={freezeType.value}>
                  {freezeType.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <h3 className="bold">Do you want to freeze the plan?</h3>
          <Form.Item
            label={`Freeze plan${Moment(entity.endAt).isAfter(Moment(Date.now())) ? ' (You cannot freeze an ongoing plan)' : ''}`}
            name="freezeDate"
            valuePropName="checked"
            className="SwitchFormItem"
          >
            <Switch
              className="Freeze_switch"
              checkedChildren={<div style={{ lineHeight: '27px' }}><FreezedIcon width={12} height={22} /></div>}
              unCheckedChildren={<div style={{ lineHeight: '27px' }}><FreezedIcon width={12} height={22} /></div>}
              disabled={Moment(entity.endAt).isAfter(Moment(Date.now()))}
            />
          </Form.Item>
        </div>
        {this.state.renewLoading && <FullScreenLoader />}
      </>
    );
  };

  renderChallengeFormItems = () => {
    const { options, trigger } = this.buildAutoSuggest(['scores', 'AVG(', 'SUM(', 'MIN(', 'MAX(', 'COUNT(']);
    return (
      <>
        {this.state.forceRecomputeLoading && (
          <FullScreenLoader tip={'Hold tight, we\'re recomputing the challenge.'} />
        )}
        {(this.props.entity && Moment(this.props.entity.endAt).isBefore(Moment(Date.now())))
          && (
            <div style={{
              display: 'flex', marginTop: '6px', justifyContent: 'center',
            }}
            >
              <FreezedAlert txt={(
                <div>
                  <div>
                    This challenge has ended therefore the calculations are frozen.
                    {' '}
                    <a
                      className="bold"
                      style={{ color: '#1890ff', marginLeft: '4px' }}
                      onClick={this.forceRecomputeEntity}
                    >
                      Force recompute
                    </a>
                  </div>

                </div>
              )}
              />
            </div>
          )}
        <Form.Item
          label="Name"
          name="name"
          rules={[{
            required: true,
            message: 'Please input name!',
          }]}
        >
          <Input />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={(
                <div>
                  <div>Global Score Formula</div>
                  <div className="grey" style={{ marginBottom: '4px', fontSize: '13px' }}>
                    You can use AVG(scores), MIN, MAX, SUM, COUNT
                  </div>
                </div>
              )}
              name="globalScoreFormula"
              rules={[{
                required: true,
                message: 'Please input global score formula!',
              }]}
            >
              <AutoCompleteInput
                required
                placeholder="ex: AVG(scores)"
                trigger={trigger}
                options={options}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={(
                <div>
                  <div>Global Score Order</div>
                  <div className="grey" style={{ marginBottom: '4px', fontSize: '13px' }}>Define the ranking order</div>
                </div>
              )}
              name="globalScoreOrder"
              rules={[{
                required: true,
                message: 'Please select global score order!',
              }]}
            >
              <Select
                dropdownMatchSelectWidth={false}
                placeholder="Select an order"
              >
                {['ASC', 'DESC'].map((value) => (
                  <Select.Option value={value} key={value}>
                    <span style={{ textTransform: 'capitalize' }}>
                      {value.toLowerCase().split('_').join(' ')}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Period"
              name="customPeriod"
              rules={[{
                required: true,
                message: 'Please input the period.',
              }]}
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                format="MMM Do YYYY"
              />
            </Form.Item>
          </Col>
          <Col span={2} style={{ paddingLeft: '2px' }}>
            <SelectUTC
              value={this.state.editedUTC}
              onChange={this.onUTCChange}
              entity={this.props.entity}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              label="Type"
              name="type"
              rules={[{
                required: true,
                message: 'Please select type!',
              }]}
            >
              <Select
                dropdownMatchSelectWidth={false}
                placeholder="Select a type"
              >
                {['PUBLIC_LEADERBOARD', 'ANONYMOUS_LEADERBOARD', 'INDIVIDUAL'].map((value) => (
                  <Select.Option value={value} key={value}>
                    <span style={{ textTransform: 'capitalize' }}>
                      {value.toLowerCase().split('_').join(' ')}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {hasFeature(this.props.profile, 'compensation') && (
            <Col span={12}>
              <Form.Item
                label={(
                  <>
                    Payout Date (Optional)
                    &nbsp;
                    <Tooltip
                      placement="right"
                      title={'Fill this field if you want "money" type rewards to be displayed on statements'}
                    >
                      <QuestionCircleFilled />
                    </Tooltip>
                  </>
                )}
                name="payoutDate"
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          )}
          {this.props.profile.userData.company.currencies.length > 1
            && (
              <Col span={12}>
                <Form.Item
                  label="Currency"
                  name="currency"
                >
                  <Select
                    dropdownMatchSelectWidth={false}
                    placeholder="Select a currency"
                  >
                    {this.props.profile.userData.company.currencies.map((c) => (
                      <Select.Option value={c.code} key={c.code}>
                        {c.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
        </Row>
        <Form.Item
          label="Auto Renew"
          tooltip={Moment(this.props.entity.endAt).isBefore(Moment(Date.now())) ? { title: 'Past challenges cannot be auto-renewed.' } : null}
          name="autoRenew"
          valuePropName="checked"
          extra="When this feature is enabled, your challenge will be renewed automatically at the end of period."
          className="SwitchFormItem"
        >
          <Switch disabled={Moment(this.props.entity.endAt).isBefore(Moment(Date.now()))} />
        </Form.Item>
        <Form.Item
          label="Live Mode"
          name="live"
          valuePropName="checked"
          extra="When this feature is enabled, all users within the challenge will be able to see it."
          className="SwitchFormItem"
        >
          <Switch />
        </Form.Item>
      </>
    );
  };

  onUTCChange = (value) => this.setState({ editedUTC: value });

  render() {
    const {
      deleteLoading, updateLoading, forceRecomputeLoading,
    } = this.state;
    const { entity, type } = this.props;
    const offsetHours = getOffsetHoursFromEntity(entity);

    return (
      <div className="Plan_details_settings_wrapper">

        <div className="Plan_settings_more flex">
          <Tooltip
            title={`Recompute the ${type}`}
          >
            <Button
              type="link"
              onClick={this.forceRecomputeEntity}
              className="More_options"
              style={{ color: 'grey' }}
            >
              <div>
                {forceRecomputeLoading ? (
                  <LoadingOutlined />
                ) : <SyncOutlined />}
              </div>
            </Button>
          </Tooltip>
          &nbsp;

          {hasAtLeastOneRight(this.props.profile, [`admin.${type.toLowerCase()}s.delete`])
            && (
              <Dropdown
                trigger="click"
                className="More_options"
                overlay={(
                  <Menu>
                    <Menu.Item key="delete" danger>
                      <div
                        onClick={this.delete}
                      >
                        {deleteLoading ? <LoadingOutlined /> : <DeleteOutlined />}
                        {` Delete ${type}`}
                      </div>
                    </Menu.Item>
                  </Menu>
                )}
              >
                <div className="Vignette_option_content">
                  <MoreOutlined />
                </div>
              </Dropdown>
            )}
        </div>
        {isPlanFreezed() && (
          <FreezedAlert />
        )}
        <Form
          layout="vertical"
          initialValues={{
            ...entity,
            customPeriod: [(Moment.utc(entity.beginAt.toString()).add(offsetHours, 'hours')),
              (Moment.utc(entity.endAt.toString()).add(offsetHours, 'hours'))],
            target: (entity.target && entity.target !== -1) ? entity.target : null,
            ...(type === ENTITIES.CHALLENGE && entity.payoutDate ? {
              payoutDate: Moment(entity.payoutDate),
            } : {}),
            freezeType: entity.freezeType ? entity.freezeType : PLAN_FREEZE_TYPES.ALL.value,
          }}
          onFinish={this.onUpdate}
          onFieldsChange={this.onFieldsChange}
        >
          {type === ENTITIES.PLAN && this.renderPlanFormItems()}
          {type === ENTITIES.CHALLENGE && this.renderChallengeFormItems()}
          <Button
            htmlType="submit"
            type="primary"
            block
            icon={<SaveOutlined />}
            loading={updateLoading}
            disabled={updateLoading}
          >
            {`Update ${type}`}
          </Button>
        </Form>

      </div>
    );
  }
}

SettingsTab.propTypes = {
  history: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  type: PropTypes.oneOf([ENTITIES.PLAN, ENTITIES.CHALLENGE]).isRequired,
  refresh: PropTypes.func.isRequired,
  profile: ProfileModel.propTypes.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(withRouter(SettingsTab));
