import PropTypes from 'prop-types';

import { manageAmountAttribute, manageDateAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'UserStatementChallenge';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  beginAt: PropTypes.string,
  endAt: PropTypes.string,
});

export const transform = (userStatementChallenge) => {
  if (!userStatementChallenge) {
    return null;
  }

  return ({
    id: manageStringAttribute(userStatementChallenge, '_id'),
    name: manageStringAttribute(userStatementChallenge, 'name'),
    amount: manageAmountAttribute(userStatementChallenge, 'totalAmount'),
    currency: manageStringAttribute(userStatementChallenge, 'currency'),
    beginAt: manageDateAttribute(userStatementChallenge, 'beginAt'),
    endAt: manageDateAttribute(userStatementChallenge, 'endAt'),
  });
};

export const transformList = (userStatementChallenges) => userStatementChallenges.map((userStatementChallenge) => transform(userStatementChallenge));
