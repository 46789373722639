import React from 'react';

import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';
import BadgeFilled from '@palette/components/utils/Icons/BadgeFilled';
import SpeedometerFilled from '@palette/components/utils/Icons/SpeedometerFilled';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';
import SearchLine from '@palette/components/utils/Icons/SearchLine';
import MenuNotificationsIcon from '@palette/components/navigation/MenuNotificationsIcon/MenuNotificationsIcon';
import MenuNotifications from '@palette/components/navigation/MenuNotifications/MenuNotifications';
import MenuWithComputeButton from '@palette/components/navigation/MenuWithComputeButton/MenuWithComputeButton';

import routePaths from '@palette/config/routePaths';
import { hasAtLeastOneAdminRight, hasFeature, hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { FEATURES, RIGHTS } from '@palette/constants/profile';

const icMenu = {
  key: 'IcMenu',
  title: 'icMenu.title',
  show: (profile) => hasAtLeastOneRight(profile, [
    RIGHTS.IC.COMPENSATION,
    RIGHTS.IC.CHALLENGES,
  ]) || !hasAtLeastOneAdminRight(profile),
  items: [
    {
      key: 'IcMenuMyDashboard',
      path: routePaths.v2.myDashboard,
      icon: (<SpeedometerFilled />),
      name: 'icMenu.myDashboard',
      show: (profile) => (hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]))
        || (hasFeature(profile, FEATURES.CHALLENGE) && hasAtLeastOneRight(profile, [RIGHTS.IC.CHALLENGES])),
    },
    {
      key: 'IcMenuMyCommissions',
      path: routePaths.myStatements,
      icon: (<StatementsFilled />),
      name: 'icMenu.myCommissions',
      show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]),
      submenuItems: [
        {
          key: 'IcMenuMyStatementsV2',
          path: routePaths.v2.myStatements,
          icon: (<StatementsFilled />),
          name: 'icMenu.myStatements',
          show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]),
        },
        {
          key: 'IcMenuMyCommissionList',
          path: routePaths.v2.myCommissionList,
          icon: (<SearchLine />),
          name: 'icMenu.myCommissionList',
          show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]),
        },
      ],
    },
    {
      key: 'IcMenuMyPlans',
      path: routePaths.v2.myPlans,
      icon: (<MedicalChartFilled />),
      name: 'icMenu.myPlans',
      show: (profile) => (hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])),
      disableOnClick: true,
      contentComponent: ({ name, path, pathQS }) => (<MenuWithComputeButton name={name} path={path} pathQS={pathQS} />),
    },
    {
      key: 'IcMenuMyChallenges',
      path: routePaths.myChallenges,
      icon: (<BadgeFilled />),
      name: 'icMenu.myChallenges',
      show: (profile) => (hasFeature(profile, FEATURES.CHALLENGE) && hasAtLeastOneRight(profile, [RIGHTS.IC.CHALLENGES])),
      disableOnClick: true,
      contentComponent: ({ name, path, pathQS }) => (<MenuWithComputeButton name={name} path={path} pathQS={pathQS} />),
    },
    {
      key: 'IcMenuNotifications',
      icon: (<MenuNotificationsIcon />),
      name: 'icMenu.notifications',
      show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && !hasAtLeastOneAdminRight(profile),
      disableOnClick: true,
      contentComponent: ({ name }) => (<MenuNotifications name={name} />),
    },
  ],
};

export default icMenu;
