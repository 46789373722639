import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { ContainerOutlined as AntDContainerOutlined } from '@ant-design/icons';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';
import CompareFilled from '@palette/components/utils/Icons/CompareFilled';
import ArchiveMasterPlanModal from '@palette/components/masterPlan/ArchiveMasterPlanModal/ArchiveMasterPlanModal';
import DeleteMasterPlanModal from '@palette/components/masterPlan/DeleteMasterPlanModal/DeleteMasterPlanModal';
import SettingsFilled from '@palette/components/utils/Icons/SettingsFilled';
import DuplicateMasterPlanModal from '@palette/components/masterPlan/DuplicateMasterPlanModal/DuplicateMasterPlanModal';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useKeyPress } from '@palette/hooks/CommonHooks';

import { isInPast, isArchived } from '@palette/helpers/MasterPlanHelper';
import { getHistoryObject, redirectTo } from '@palette/helpers/NavigationHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import { RIGHTS } from '@palette/constants/profile';
import { COMPARE_PLAN_FIRST_QS_KEY } from '@palette/constants/masterPlans';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MasterPlanListItemMoreOptionsMenu.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanListItemMoreOptionsMenu = ({ className, plan }) => {
  const profile = useProfile();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [archiveMasterPlanModalIsVisible, showArchiveMasterPlanModal] = useState(false);
  const [deleteMasterPlanModalIsVisible, showDeleteMasterPlanModal] = useState(false);
  const [duplicateMasterPlanModalIsVisible, showDuplicateMasterPlanModal] = useState(false);

  const metaPressed = useKeyPress('Meta');
  const controlPressed = useKeyPress('Control');

  const moreMenuItems = useMemo(() => {
    const menuItems = [];

    if (hasAtLeastOneRight(profile, [
      RIGHTS.ADMIN.PLANS.UPDATE,
      RIGHTS.ADMIN.PLANS.GBO.MANAGE,
      RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR,
      RIGHTS.ADMIN.QUOTAS.MANAGE,
      RIGHTS.ADMIN.QUOTAS.USERS.MANAGE,
    ])) {
      const settingsMenuItem = {
        key: 'settings',
        title: t('masterPlan.topActions.settings'),
        icon: (
          <SettingsFilled width={16} height={16} />
        ),
        onClick: () => {
          dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PLAN_LIST.OPTIONS_CLICK_SETTING }));

          const path = routePaths.v2.planSettings;
          const params = { masterPlanId: plan.id };

          if (metaPressed || controlPressed) {
            const historyObject = getHistoryObject({ path, params });
            const planSettingsUrl = `./#${historyObject.pathname}${historyObject.search}`;
            window.open(planSettingsUrl, '_blank');
          } else {
            redirectTo({ path, params });
          }
        },
      };

      menuItems.push(settingsMenuItem);
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE])) {
      const duplicateMenuItem = {
        key: 'duplicatePlan',
        title: t('masterPlan.moreMenu.duplicatePlan'),
        icon: (
          <DuplicateFilled width={16} height={16} />
        ),
        onClick: () => {
          dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PLAN_LIST.OPTIONS_CLICK_DUPLICATE }));
          showDuplicateMasterPlanModal(true);
        },
      };

      menuItems.push(duplicateMenuItem);
    }

    const compareMenuItem = {
      key: 'comparePlan',
      title: t('masterPlan.moreMenu.comparePlan'),
      icon: (
        <CompareFilled width={16} height={16} />
      ),
      onClick: () => {
        dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PLAN_LIST.OPTIONS_CLICK_COMPARE }));
        redirectTo({ path: routePaths.v2.comparator, qsObject: { [COMPARE_PLAN_FIRST_QS_KEY]: plan.id } });
      },
    };

    menuItems.push(compareMenuItem);

    if (isInPast(plan) && !isArchived(plan)) {
      const archiveMenuItem = {
        key: 'archivePlan',
        title: t('masterPlan.moreMenu.archivePlan'),
        icon: (
          <AntDContainerOutlined width={16} height={16} />
        ),
        onClick: () => {
          dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PLAN_LIST.OPTIONS_CLICK_ARCHIVE }));
          showArchiveMasterPlanModal(true);
        },
      };

      menuItems.push(archiveMenuItem);
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DELETE])) {
      const deleteMenuItem = {
        key: 'deletePlan',
        title: t('masterPlan.moreMenu.deletePlan'),
        icon: (
          <TrashFilled width={16} height={16} />
        ),
        onClick: () => {
          dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PLAN_LIST.OPTIONS_CLICK_DELETE }));
          showDeleteMasterPlanModal(true);
        },
      };

      menuItems.push(deleteMenuItem);
    }

    return menuItems;
  }, [
    profile,
    plan,
    showDuplicateMasterPlanModal,
    metaPressed,
    controlPressed,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu
        items={moreMenuItems}
      />
      {archiveMasterPlanModalIsVisible && (
        <ArchiveMasterPlanModal visible={archiveMasterPlanModalIsVisible} onClose={() => showArchiveMasterPlanModal(false)} plan={plan} />
      )}
      {deleteMasterPlanModalIsVisible && (
        <DeleteMasterPlanModal visible={deleteMasterPlanModalIsVisible} onClose={() => showDeleteMasterPlanModal(false)} plan={plan} />
      )}
      {duplicateMasterPlanModalIsVisible && (
        <DuplicateMasterPlanModal visible={duplicateMasterPlanModalIsVisible} onClose={() => showDuplicateMasterPlanModal(false)} planId={plan.id} />
      )}
    </div>
  );
};

MasterPlanListItemMoreOptionsMenu.propTypes = {
  plan: MasterPlanModel.propTypes.isRequired,
  className: PropTypes.string,
};

MasterPlanListItemMoreOptionsMenu.defaultProps = {
  className: '',
};

export default MasterPlanListItemMoreOptionsMenu;
