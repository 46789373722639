import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS } from '@palette/constants/user';

export const modelName = 'MergeUserConflict';

export const propTypes = PropTypes.shape({
  userDetailsDiff: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.oneOf(Object.values(MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS)),
    source: PropTypes.string,
    destination: PropTypes.string,
  })),
  plansInCommon: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  challengesInCommon: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  quotasInCommon: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
});

export const manageUserDetailsDiff = (differences) => differences.map((diff) => ({
  field: manageStringAttribute(diff, 'field'),
  source: manageStringAttribute(diff, 'source'),
  destination: manageStringAttribute(diff, 'destination'),
}));

export const managePlansInCommon = (plans) => plans.map((plan) => ({
  id: manageStringAttribute(plan, '_id'),
  name: manageStringAttribute(plan, 'name'),
}));

export const manageChallengesInCommon = (challenges) => challenges.map((challenge) => ({
  id: manageStringAttribute(challenge, '_id'),
  name: manageStringAttribute(challenge, 'name'),
}));

export const manageQuotasInCommon = (quotas) => quotas.map((quota) => ({
  id: manageStringAttribute(quota, '_id'),
  name: manageStringAttribute(quota, 'name'),
}));

export const transform = (mergeUserConflict) => {
  if (!mergeUserConflict) {
    return null;
  }

  const userDetailsDiff = manageUserDetailsDiff(manageArrayAttribute(mergeUserConflict, 'user'));
  const plansInCommon = managePlansInCommon(manageArrayAttribute(mergeUserConflict, 'plans'));
  const challengesInCommon = manageChallengesInCommon(manageArrayAttribute(mergeUserConflict, 'challenges'));
  const quotasInCommon = manageQuotasInCommon(manageArrayAttribute(mergeUserConflict, 'quotas'));

  return (
    {
      userDetailsDiff,
      plansInCommon,
      challengesInCommon,
      quotasInCommon,
    }
  );
};

export const transformList = (mergeUserConflicts) => mergeUserConflicts.map((mergeUserConflict) => transform(mergeUserConflict));
