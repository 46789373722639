import { ACTION_TYPES } from '@palette/constants/statementsHistory';

import * as PlanGeneralSettingsUpdateStrategyModel from '@palette/models/statementHistoryStrategies/PlanGeneralSettingsUpdateStrategy';
import * as PlanCommissionRulesUpdateStrategyModel from '@palette/models/statementHistoryStrategies/PlanCommissionRulesUpdateStrategy';
import * as PlanPayoutRulesUpdateStrategyModel from '@palette/models/statementHistoryStrategies/PlanPayoutRulesUpdateStrategy';
import * as PlanDeleteStrategyModel from '@palette/models/statementHistoryStrategies/PlanDeleteStrategy';
import * as PlanDependenciesUpdateStrategyModel from '@palette/models/statementHistoryStrategies/PlanDependenciesUpdateStrategy';
import * as PlanSourceTrackUpdateStrategyModel from '@palette/models/statementHistoryStrategies/PlanSourceTrackUpdateStrategy';
import * as PlanUserTrackUpdateStrategyModel from '@palette/models/statementHistoryStrategies/PlanUserTrackUpdateStrategy';
import * as CommissionValueOverwriteStrategyModel from '@palette/models/statementHistoryStrategies/CommissionValueOverwriteStrategy';
import * as CommissionDealValueOverwriteStrategyModel from '@palette/models/statementHistoryStrategies/CommissionDealValueOverwriteStrategy';
import * as CommissionPayoutOverwriteStrategyModel from '@palette/models/statementHistoryStrategies/CommissionPayoutOverwriteStrategy';
import * as ChallengeUpdateStrategyModel from '@palette/models/statementHistoryStrategies/ChallengeUpdateStrategy';
import * as ResourceUpdateStrategyModel from '@palette/models/statementHistoryStrategies/ResourceUpdateStrategy';
import * as UserAddInPlanStrategyModel from '@palette/models/statementHistoryStrategies/UserAddInPlanStrategy';
import * as UserDeleteFromPlanStrategyModel from '@palette/models/statementHistoryStrategies/UserDeleteFromPlanStrategy';
import * as StatementCorrectionApplyStrategyModel from '@palette/models/statementHistoryStrategies/StatementCorrectionApplyStrategy';
import * as StatementCorrectionRejectStrategyModel from '@palette/models/statementHistoryStrategies/StatementCorrectionRejectStrategy';
import * as StatementCorrectionAppliedUpdateStrategyModel from '@palette/models/statementHistoryStrategies/StatementCorrectionAppliedUpdateStrategy';
import * as StatementCorrectionAppliedDeleteStrategyModel from '@palette/models/statementHistoryStrategies/StatementCorrectionAppliedDeleteStrategy';
import * as StatementCorrectionApplyManuallyStrategyModel from '@palette/models/statementHistoryStrategies/StatementCorrectionApplyManuallyStrategy';
import * as StatementCorrectedStrategyModel from '@palette/models/statementHistoryStrategies/StatementCorrectedStrategy';

export const entities = {
  [ACTION_TYPES.PLAN_GENERAL_SETTINGS_UPDATE.action]: PlanGeneralSettingsUpdateStrategyModel,
  [ACTION_TYPES.PLAN_COMMISSION_RULES_UPDATE.action]: PlanCommissionRulesUpdateStrategyModel,
  [ACTION_TYPES.PLAN_PAYOUT_RULES_UPDATE.action]: PlanPayoutRulesUpdateStrategyModel,
  [ACTION_TYPES.PLAN_DELETE.action]: PlanDeleteStrategyModel,
  [ACTION_TYPES.PLAN_DEPENDENCIES_UPDATE.action]: PlanDependenciesUpdateStrategyModel,
  [ACTION_TYPES.PLAN_SOURCE_TRACK_UPDATE.action]: PlanSourceTrackUpdateStrategyModel,
  [ACTION_TYPES.PLAN_USER_TRACK_UPDATE.action]: PlanUserTrackUpdateStrategyModel,
  [ACTION_TYPES.COMMISSION_VALUE_OVERWRITE.action]: CommissionValueOverwriteStrategyModel,
  [ACTION_TYPES.COMMISSION_DEAL_VALUE_OVERWRITE.action]: CommissionDealValueOverwriteStrategyModel,
  [ACTION_TYPES.COMMISSION_PAYOUT_OVERWRITE.action]: CommissionPayoutOverwriteStrategyModel,
  [ACTION_TYPES.CHALLENGE_UPDATE.action]: ChallengeUpdateStrategyModel,
  [ACTION_TYPES.RESOURCE_UPDATE.action]: ResourceUpdateStrategyModel,
  [ACTION_TYPES.USER_ADD_IN_PLAN.action]: UserAddInPlanStrategyModel,
  [ACTION_TYPES.USER_DELETE_FROM_PLAN.action]: UserDeleteFromPlanStrategyModel,
  [ACTION_TYPES.STATEMENT_CORRECTION_APPLY.action]: StatementCorrectionApplyStrategyModel,
  [ACTION_TYPES.STATEMENT_CORRECTION_REJECT.action]: StatementCorrectionRejectStrategyModel,
  [ACTION_TYPES.STATEMENT_CORRECTION_APPLIED_UPDATE.action]: StatementCorrectionAppliedUpdateStrategyModel,
  [ACTION_TYPES.STATEMENT_CORRECTION_APPLIED_DELETE.action]: StatementCorrectionAppliedDeleteStrategyModel,
  [ACTION_TYPES.STATEMENT_CORRECTION_APPLY_MANUALLY.action]: StatementCorrectionApplyManuallyStrategyModel,
  [ACTION_TYPES.STATEMENT_CORRECTED.action]: StatementCorrectedStrategyModel,
};
