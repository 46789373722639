import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import MasterPlanPreview from '@palette/components/masterPlan/MasterPlanPreview/MasterPlanPreview';

import { FOLDER_TYPES } from '@palette/constants/folders';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './ResourcePreview.less';

const classNames = bindClassNames.bind(styles);

const ResourcePreview = ({ className, resourceId, resourceType }) => {
  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: resourceId }));
  // const challenge = ...

  const previewNode = useMemo(() => {
    if (resourceType === FOLDER_TYPES.PLAN && plan !== null) {
      return (
        <MasterPlanPreview plan={plan} />
      );
    }

    // TODO: manage the other types

    return null;
  }, [resourceId, resourceType, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {previewNode}
    </div>
  );
};

ResourcePreview.propTypes = {
  className: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf(Object.values(FOLDER_TYPES)).isRequired,
};

ResourcePreview.defaultProps = {
  className: '',
};

export default ResourcePreview;
