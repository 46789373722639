import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getRewardsTotal } from '@palette/helpers/ChallengeHelper';
import { formatBeginEndDate } from '@palette/helpers/MomentHelper';

import * as ChallengeModel from '@palette/models/Challenge';

import routePaths from '@palette/config/routePaths';

import styles from './ListChallengeItem.less';

const classNames = bindClassNames.bind(styles);

const ListChallengeItem = ({ className, challenge, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const rewardsTotal = useMemo(() => getRewardsTotal(challenge), [challenge]);
  const challengeDates = useMemo(() => formatBeginEndDate(challenge.beginAt, challenge.endAt), [challenge]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Link
        className={styles.name}
        path={routePaths.myChallengeDetails}
        params={{ challengeId: challenge.id }}
        disabled={readOnly && !enableLinks}
      >
        {challenge.name}
      </Link>
      <div className={styles.dates}>{challengeDates}</div>
      <div className={styles.type}>
        {t(`challenges.types.${challenge.type.toLowerCase()}`)}
      </div>
      <div className={styles.scores}>
        <div className={styles.valueArea}>
          <div className={styles.title}>
            {t('challengeSimpleDisplayWidget.rewards')}
          </div>
          <div className={styles.value}>
            {rewardsTotal > 0 ? formatPrice(rewardsTotal, challenge.currency) : '-'}
          </div>
        </div>
        <div className={styles.valueArea}>
          <div className={styles.title}>
            {t('challengeSimpleDisplayWidget.rank')}
          </div>
          <div className={styles.value}>
            {challenge.rankingIndex + 1}
          </div>
        </div>
      </div>
    </div>
  );
};

ListChallengeItem.propTypes = {
  className: PropTypes.string,
  challenge: ChallengeModel.propTypes.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

ListChallengeItem.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default ListChallengeItem;
