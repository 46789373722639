import GLOBAL_NOTIF_GLOBAL_REASONS from '@palette/constants/globalNotifReason/globalNotifGlobalReasons';
import GLOBAL_NOTIF_CHALLENGE_REASONS from '@palette/constants/globalNotifReason/globalNotifChallengeReasons';
import GLOBAL_NOTIF_COMMISSION_REASONS from '@palette/constants/globalNotifReason/globalNotifCommissionReasons';
import GLOBAL_NOTIF_DASHBOARD_REASONS from '@palette/constants/globalNotifReason/globalNotifDashboardReasons';
import GLOBAL_NOTIF_DASHBOARD_PRESET_REASONS from '@palette/constants/globalNotifReason/globalNotifDashboardPresetReasons';
import GLOBAL_NOTIF_FOLDER_REASONS from '@palette/constants/globalNotifReason/globalNotifFolderReasons';
import GLOBAL_NOTIF_NOTEBOOK_REASONS from '@palette/constants/globalNotifReason/globalNotifNotebookReasons';
import GLOBAL_NOTIF_PLAN_REASONS from '@palette/constants/globalNotifReason/globalNotifPlanReasons';
import GLOBAL_NOTIF_PROFILE_REASONS from '@palette/constants/globalNotifReason/globalNotifProfileReasons';
import GLOBAL_NOTIF_QUOTA_REASONS from '@palette/constants/globalNotifReason/globalNotifQuotaReasons';
import GLOBAL_NOTIF_QUOTA_PERFORMANCE_REASONS from '@palette/constants/globalNotifReason/globalNotifQuotaPerformanceReasons';
import GLOBAL_NOTIF_RESOURCE_REASONS from '@palette/constants/globalNotifReason/globalNotifResourceReasons';
import GLOBAL_NOTIF_STATEMENT_REASONS from '@palette/constants/globalNotifReason/globalNotifStatementReasons';
import GLOBAL_NOTIF_TEAM_REASONS from '@palette/constants/globalNotifReason/globalNotifTeamReasons';
import GLOBAL_NOTIF_USER_REASONS from '@palette/constants/globalNotifReason/globalNotifUserReasons';
import GLOBAL_NOTIF_USER_VARIABLE_REASONS from '@palette/constants/globalNotifReason/globalNotifUserVariableReasons';

export const GLOBAL_NOTIF_REASONS = {
  ...GLOBAL_NOTIF_GLOBAL_REASONS,
  ...GLOBAL_NOTIF_CHALLENGE_REASONS,
  ...GLOBAL_NOTIF_COMMISSION_REASONS,
  ...GLOBAL_NOTIF_DASHBOARD_REASONS,
  ...GLOBAL_NOTIF_DASHBOARD_PRESET_REASONS,
  ...GLOBAL_NOTIF_FOLDER_REASONS,
  ...GLOBAL_NOTIF_NOTEBOOK_REASONS,
  ...GLOBAL_NOTIF_PLAN_REASONS,
  ...GLOBAL_NOTIF_PROFILE_REASONS,
  ...GLOBAL_NOTIF_QUOTA_REASONS,
  ...GLOBAL_NOTIF_QUOTA_PERFORMANCE_REASONS,
  ...GLOBAL_NOTIF_RESOURCE_REASONS,
  ...GLOBAL_NOTIF_STATEMENT_REASONS,
  ...GLOBAL_NOTIF_TEAM_REASONS,
  ...GLOBAL_NOTIF_USER_REASONS,
  ...GLOBAL_NOTIF_USER_VARIABLE_REASONS,
};
