export const PALETTE_DOCUMENTATION_URL = 'https://doc.palettehq.com/';
export const PALETTE_SALESFORCE_URL = 'salesforce.palettehq.com';

export const PALETTE_TERMS_AND_CONDITIONS_URL = 'https://www.palettehq.com/terms-conditions';

export const NUMBER_REGEX = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/;

// Catch every number after letters separated by any char, eg: "Employee 10", "INV-1761", "Employee 10/10"
export const LETTER_NUMBER_REGEX = /^\D+((\d+)?\D*)+$/m;

export const SEND_FEEDBACK_MAX_ATTACHMENTS_NB = 10;

export const VERTICAL_AVATAR_SIZE = 48;
export const PALETTE_USER_AVATAR_SIZE = 24;

export const SHORT_CONTENT_LENGTH = 5;
export const MEDIUM_CONTENT_LENGTH = 10;
export const SHORT_CONTENT_LENGTH_COLUMN_MULTIPLICATOR = 16;
export const MEDIUM_CONTENT_LENGTH_COLUMN_MULTIPLICATOR = 12;
export const LONG_CONTENT_LENGTH_COLUMN_MULTIPLICATOR = 10;
