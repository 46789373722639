import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Progress, { PROGRESS_TYPES } from '@palette/components/designSystem/Progress/Progress';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES } from '@palette/constants/profile';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './StatementPeriodApprovalProgress.less';

const classNames = bindClassNames.bind(styles);

const StatementPeriodApprovalProgress = ({ className, classNameProgressBar, statementPeriod }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const progressNode = useMemo(() => {
    const { approvedCount, inConflictCount, usersCount } = statementPeriod;
    const approvedNotInConflictCount = approvedCount - inConflictCount;

    const getTotalPercent = (100 / usersCount) * approvedCount;
    const getTotalSuccess = (100 / usersCount) * approvedNotInConflictCount;

    return (
      <Progress
        className={classNames({
          progressBar: true,
          [classNameProgressBar]: classNameProgressBar !== '',
        })}
        type={PROGRESS_TYPES.WARNING}
        percent={getTotalPercent}
        success={{ percent: getTotalSuccess }}
      />
    );
  }, [statementPeriod]);

  const totalNode = useMemo(() => {
    const { approvedCount, inConflictCount, usersCount } = statementPeriod;
    const approvedNotInConflictCount = approvedCount - inConflictCount;

    const calculResult = [];

    if (approvedCount > 0) {
      calculResult.push(<span key="blue" className={styles.totalBlue}>{approvedNotInConflictCount}</span>);

      if (inConflictCount > 0) {
        calculResult.push(' + ');
        calculResult.push(<span key="orange" className={styles.totalOrange}>{inConflictCount}</span>);
      }
    } else {
      calculResult.push(<span key="grey" className={styles.totalGrey}>0</span>);
    }

    calculResult.push(` / ${usersCount}`);

    const calculResultNode = (
      <div className={styles.total}>
        {calculResult}
      </div>
    );

    if (approvedCount > 0) {
      let tooltipTitle = t('statementPeriodApprovalProgress.totalApprovedStatements', { count: approvedCount });
      if (inConflictCount > 0) {
        tooltipTitle = `${tooltipTitle} ${t('statementPeriodApprovalProgress.totalInConflictApprovals', { count: inConflictCount })}`;
      }

      return (
        <Tooltip title={tooltipTitle}>
          {calculResultNode}
        </Tooltip>
      );
    }

    return calculResultNode;
  }, [statementPeriod]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION)) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.label}>
        {t('statementPeriodApprovalProgress.approved')}
      </div>
      {progressNode}
      {totalNode}
    </div>
  );
};

StatementPeriodApprovalProgress.propTypes = {
  className: PropTypes.string,
  classNameProgressBar: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
};

StatementPeriodApprovalProgress.defaultProps = {
  className: '',
  classNameProgressBar: '',
};

export default StatementPeriodApprovalProgress;
