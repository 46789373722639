/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Timeline, Typography } from 'antd';
import { getRewardDisplay } from '../../../services/utils';
import { CHALLENGE_TYPE } from '../../../services/constants';

const RewardRules = ({ rules, challenge }) => (
  <div
    className="RewardRulesDisplay"
    style={{
      width: '300px',
      margin: 'auto',
      maxWidth: '100%',
    }}
  >
    <Timeline>
      {rules.map((rule) => (
        <Timeline.Item key={`${rule.from}-${rule.to}`}>
          {challenge.type !== CHALLENGE_TYPE.INDIVIDUAL && (
            <div className="title bold" style={{ fontSize: '18px' }}>
              {rule.to === 'Infinity'
                ? `Over rank #${rule.from + 1} (included)`
                : `Rank #${rule.from + 1}${(rule.from === rule.to) ? '' : ` to #${rule.to + 1}`}`}
            </div>
          )}
          <div>
            Reward:
            {' '}
            {rule.rewards.map((r, i) => (
              <b key={`${r.type}_${rule.from}-${rule.to}`}>
                {getRewardDisplay(r, challenge.currency)}
                {(i < rule.rewards.length - 1) && ', '}
              </b>
            ))}
          </div>
          {rule.conditionFormula && rule.conditionFormula !== 'true' && (
            <div>
              Condition:
              {' '}
              <Typography.Text code>{rule.conditionFormula}</Typography.Text>
            </div>
          )}
        </Timeline.Item>
      ))}
    </Timeline>
  </div>
);
RewardRules.propTypes = {
  rules: PropTypes.array.isRequired,
  challenge: PropTypes.object.isRequired,
};
export default RewardRules;
