// Global
import GlobalErrorConstraintViolationContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/global/GlobalErrorConstraintViolationContentStrategy';

// Notebook
import NotebookDataframeNameCopiedContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/notebook/NotebookDataframeNameCopiedContentStrategy';
import NotebookCodeCellRequiredInformationContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/notebook/NotebookCodeCellRequiredInformationContentStrategy';
import NotebookCodeCellConflictingOutputNameContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/notebook/NotebookCodeCellConflictingOutputNameContentStrategy';
import NotebookCodeInvalidOutputUIDContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/notebook/NotebookCodeInvalidOutputUIDContentStrategy';
import NotebookCodeCellOutputUIDLockedContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/notebook/NotebookCodeCellOutputUIDLockedContentStrategy';

// Plan
import PlanInvalidCommissionAmountContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/plan/PlanInvalidCommissionAmountContentStrategy';
import PlanCodeRuleEvaluationErrorContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/plan/PlanCodeRuleEvaluationErrorContentStrategy';
import PlanInjectionErrorContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/plan/PlanInjectionErrorContentStrategy';
import PlanPercentageRuleTargetErrorContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/plan/PlanPercentageRuleTargetErrorContentStrategy';
import PlanInvalidCodeFormulaErrorContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/plan/PlanInvalidCodeFormulaErrorContentStrategy';
import PlanPayoutEvaluationErrorContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/plan/PlanPayoutEvaluationErrorContentStrategy';
import PlanTrackedResourceEvaluationErrorContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/plan/PlanTrackedResourceEvaluationErrorContentStrategy';
import PlanInvalidPaymentPercentageErrorContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/plan/PlanInvalidPaymentPercentageErrorContentStrategy';

// Profile
import ProfileAccountInvalidCredentialsContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/profile/ProfileAccountInvalidCredentialsContentStrategy';

// Resource
import ResourceDeletionContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/resource/ResourceDeletionContentStrategy';

// Statement
import ApproveStatementContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/statement/ApproveStatementContentStrategy';
import MarkAsPaidStatementContentStrategy from '@palette/components/globalNotif/GlobalNotifContentStrategies/statement/MarkAsPaidStatementContentStrategy';

export const entities = {
  // Global
  GlobalErrorConstraintViolationContentStrategy,

  // Notebook
  NotebookDataframeNameCopiedContentStrategy,
  NotebookCodeCellRequiredInformationContentStrategy,
  NotebookCodeCellConflictingOutputNameContentStrategy,
  NotebookCodeInvalidOutputUIDContentStrategy,
  NotebookCodeCellOutputUIDLockedContentStrategy,

  // Plan
  PlanInvalidCommissionAmountContentStrategy,
  PlanCodeRuleEvaluationErrorContentStrategy,
  PlanInjectionErrorContentStrategy,
  PlanPercentageRuleTargetErrorContentStrategy,
  PlanInvalidCodeFormulaErrorContentStrategy,
  PlanPayoutEvaluationErrorContentStrategy,
  PlanTrackedResourceEvaluationErrorContentStrategy,
  PlanInvalidPaymentPercentageErrorContentStrategy,

  // Profile
  ProfileAccountInvalidCredentialsContentStrategy,

  // Resource
  ResourceDeletionContentStrategy,

  // Statement
  ApproveStatementContentStrategy,
  MarkAsPaidStatementContentStrategy,
};
