import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import ChallengeListItemForUser from '@palette/components/userDetails/ChallengeListItemForUser/ChallengeListItemForUser';

import * as ChallengeModel from '@palette/models/Challenge';

import styles from './ChallengeListForUser.less';

const classNames = bindClassNames.bind(styles);

const ChallengeListForUser = ({ className, challengeList }) => {
  const { t } = useTranslation();

  const listContentNode = useMemo(() => {
    if (challengeList.length === 0) {
      return (
        <DefaultEmptyState className={styles.emptyState} description={t('challenges.empty.description')} />
      );
    }

    return challengeList.map((challenge) => (
      <ChallengeListItemForUser
        key={challenge.id}
        className={styles.challengeListItem}
        challenge={challenge}
      />
    ));
  }, [challengeList]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {listContentNode}
    </div>
  );
};

ChallengeListForUser.propTypes = {
  className: PropTypes.string,
  challengeList: PropTypes.arrayOf(ChallengeModel.propTypes).isRequired,
};

ChallengeListForUser.defaultProps = {
  className: '',
};

export default ChallengeListForUser;
