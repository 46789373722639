import React from 'react';
import PropTypes from 'prop-types';
// import { getNamesFromRawData } from '../../services/utils';

const ChallengeRankName = ({ rank, challenge }) => {
  let userName = 'This is Anonymous';

  if (rank.user) {
    userName = `${rank.user.firstName} ${rank.user.lastName}`;
  } else if (rank.groupByObject) {
    console.error('challenge', challenge);
    // userName = getNamesFromRawData(challenge.groupByObject.connector, rank.groupByObject);
  }

  return (
    <div className="ChallengeRanking_rank_name title bold">
      {userName}
    </div>
  );
};

ChallengeRankName.propTypes = {
  rank: PropTypes.object.isRequired,
  challenge: PropTypes.object.isRequired,
};

export default ChallengeRankName;
