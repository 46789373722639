import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Select } from 'antd';
import { getOffsetHoursFromEntity } from '../../services/utils';

const SelectUTCModale = ({
  visible, onClose, onSave, onChange, value, entity,
}) => {
  const offsetHours = getOffsetHoursFromEntity(entity);
  return (
    <Modal
      title="Edit the Timezone"
      visible={visible}
      onCancel={onClose}
      okText="Save timezone"
      onOk={() => {
        onClose();
        onSave();
      }}
    >
      <label>Select a Timezone</label>
&nbsp;
&nbsp;
      <Select
        value={value !== null ? value : offsetHours}
        dropdownMatchSelectWidth={false}
        onChange={onChange}
        style={{ minWidth: '120px' }}
      >
        <Select.Option value={-11}>UTC-11</Select.Option>
        <Select.Option value={-10}>UTC-10</Select.Option>
        <Select.Option value={-9}>UTC-9</Select.Option>
        <Select.Option value={-8}>UTC-8</Select.Option>
        <Select.Option value={-7}>UTC-7</Select.Option>
        <Select.Option value={-6}>UTC-6</Select.Option>
        <Select.Option value={-5}>UTC-5</Select.Option>
        <Select.Option value={-4}>UTC-4</Select.Option>
        <Select.Option value={-3}>UTC-3</Select.Option>
        <Select.Option value={-2}>UTC-2</Select.Option>
        <Select.Option value={-1}>UTC-1</Select.Option>
        <Select.Option value={0}>UTC+0</Select.Option>
        <Select.Option value={1}>UTC+1</Select.Option>
        <Select.Option value={2}>UTC+2</Select.Option>
        <Select.Option value={3}>UTC+3</Select.Option>
        <Select.Option value={4}>UTC+4</Select.Option>
        <Select.Option value={5}>UTC+5</Select.Option>
        <Select.Option value={6}>UTC+6</Select.Option>
        <Select.Option value={7}>UTC+7</Select.Option>
        <Select.Option value={8}>UTC+8</Select.Option>
        <Select.Option value={9}>UTC+9</Select.Option>
        <Select.Option value={10}>UTC+10</Select.Option>
        <Select.Option value={11}>UTC+11</Select.Option>
        <Select.Option value={12}>UTC+12</Select.Option>
      </Select>
    </Modal>
  );
};

SelectUTCModale.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  entity: PropTypes.object.isRequired,
};

SelectUTCModale.defaultProps = {
  value: null,
};

export default SelectUTCModale;
