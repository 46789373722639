export const FOLDER_TYPES = {
  PLAN: 'Plan',
  CHALLENGE: 'Challenge',
};

export const FOLDER_ITEM_TYPES = {
  FOLDER: 'FOLDER',
  RESOURCE: 'RESOURCE',
};

export const DEFAULT_FOLDER_ID = 'root';
