/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { WarningTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Alert, message, Spin } from 'antd';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import {
  computeFn, isSuperAdmin, JobError, isLocalhost,
} from '../../services/utils';
import { sendEvent } from '../../services/analytics';

class LaunchRecomputation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { computeloading: false };
  }

  compute = () => {
    if (this.state.computeloading) return;
    const { entity, callback, forceRecompute } = this.props;
    this.setState({ computeloading: true }, () => {
      computeFn(entity._id, forceRecompute)
        .then(() => this.setState({ computeloading: false }, callback))
        .catch((error) => {
          this.setState({ computeloading: false });
          if (error && error.response && error.response.status === 409) {
            message.error('A computation is already running, please try again later.');
          } else if (error instanceof JobError) {
            const e = JSON.parse(error.message);
            message.error(`JobError: ${e.message}`);
            if (!(isSuperAdmin() || isLocalhost())) {
              sendEvent('ComputeError', {
                userId: this.props.profile.userData.id, errorMessage: e.message, entityId: entity._id,
              });
            }
          } else {
            message.error('Unexpected error while computing.');
          }
        });
    });
  };

  render() {
    const { entity, pleaseUpdateText, loadingText } = this.props;
    const { computeloading } = this.state;
    return (
      (entity.lastBreakingChangeUpdate && (
        !entity.lastComputedAt
        || entity.lastBreakingChangeUpdate > entity.lastComputedAt
      ))
        ? (
          <div className="Needs_refresh_wrapper">
            <Spin spinning={computeloading} tip={loadingText} size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
              <Alert
                icon={<WarningTwoTone twoToneColor="orange" />}
                message="Launch a recomputation"
                description={(
                  <>
                    {`Important changes were made, ${pleaseUpdateText} `}
                    <a onClick={this.compute}>launching a recomputation</a>
                    .
                  </>
                )}
                type="warning"
                showIcon
              />
            </Spin>

          </div>
        ) : null
    );
  }
}
LaunchRecomputation.propTypes = {
  entity: PropTypes.object.isRequired,
  callback: PropTypes.func,
  pleaseUpdateText: PropTypes.string,
  loadingText: PropTypes.string,
  forceRecompute: PropTypes.bool,
  profile: ProfileModel.propTypes.isRequired,
};

LaunchRecomputation.defaultProps = {
  pleaseUpdateText: 'please update the commissions by',
  loadingText: 'Hold tight, we\'re recomputing the commissions.',
  callback: () => {},
  forceRecompute: false,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(LaunchRecomputation);
