import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './MasterPlanPreview.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPreview = ({ className, plan }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <MedicalChartFilled className={styles.icon} />
    <div className={styles.name}>
      {plan.name}
    </div>
  </div>
);

MasterPlanPreview.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanPreview.defaultProps = {
  className: '',
};

export default MasterPlanPreview;
