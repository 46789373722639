import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import WidgetBase from '@palette/components/widgets/dashboard/WidgetBase/WidgetBase';
import Link from '@palette/components/designSystem/Link/Link';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Button from '@palette/components/designSystem/Button/Button';
import MyChallengesListSelect from '@palette/components/ic/myChallenge/MyChallengesListSelect/MyChallengesListSelect';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { buildWidgetParams } from '@palette/helpers/DashboardHelper';
import { formatBeginEndDate } from '@palette/helpers/MomentHelper';

import routePaths from '@palette/config/routePaths';

import { DASHBOARD_WIDGETS } from '@palette/constants/dashboard';

import * as ChallengeModel from '@palette/models/Challenge';

import { actions as DashboardActions } from '@palette/state/Dashboard';

import styles from './ChallengeWidgetBase.less';

const classNames = bindClassNames.bind(styles);

const ChallengeWidgetBase = ({
  className,
  widgetIndex,
  autoRenew,
  challenge,
  title,
  children,
  readOnly,
  enableLinks,
}) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const [challengeEditionMode, enableChallengeEditionMode] = useState(false);

  const onUpdateChallengeIdSuccess = useCallback(() => {
    enableChallengeEditionMode(false);
  }, []);

  const handleChangeChallengeId = useCallback((newChallengeId) => {
    const widgetDesc = profile.dashboardComponents[widgetIndex];
    const allParams = {
      ...widgetDesc.params,
      challengeId: newChallengeId,
    };

    dispatch(DashboardActions.updateWidget({
      widgetIndex,
      widgetType: widgetDesc.type,
      params: buildWidgetParams(widgetDesc.type, allParams),
      onSuccessCB: onUpdateChallengeIdSuccess,
    }));
  }, [profile, widgetIndex, onUpdateChallengeIdSuccess]);

  const isAvailableForWidget = useCallback((challengeCandidate) => {
    const widgetDesc = profile.dashboardComponents[widgetIndex];
    return DASHBOARD_WIDGETS[widgetDesc.type].isAvailableForProfileAndChallenge(profile, challengeCandidate);
  }, [profile]);

  const challengeNameNode = useMemo(() => {
    if (!readOnly && challengeEditionMode) {
      return (
        <div className={styles.challengeSelectWrapper}>
          <MyChallengesListSelect
            value={challenge.id}
            onChange={handleChangeChallengeId}
            bordered={false}
            filterFunc={isAvailableForWidget}
          />
          <Button
            className={styles.nameEditCancelButton}
            type="linkDestroy"
            icon={<ClosePopupFilled width={14} height={14} />}
            onClick={() => enableChallengeEditionMode(false)}
          />
        </div>
      );
    }

    return (
      <div className={styles.nameWrapper}>
        <Link
          className={styles.nameLink}
          path={routePaths.myChallengeDetails}
          params={{ challengeId: challenge.id }}
          disabled={readOnly && !enableLinks}
        >
          {challenge.name}
        </Link>
        {
          !readOnly && (
            <Button
              className={styles.nameEditButton}
              type="link"
              icon={<PenFilled width={14} height={14} />}
              onClick={() => enableChallengeEditionMode(true)}
            />
          )
        }
      </div>
    );
  }, [challenge, challengeEditionMode, handleChangeChallengeId, isAvailableForWidget, readOnly, enableLinks]);

  const challengeDates = useMemo(() => (
    formatBeginEndDate(challenge.beginAt, challenge.endAt)
  ), [challenge]);

  const handleToggleAutoRenew = useCallback(() => {
    const widgetDesc = profile.dashboardComponents[widgetIndex];
    const allParams = {
      ...widgetDesc.params,
      useMostRecent: !autoRenew,
    };

    dispatch(DashboardActions.updateWidget({
      widgetIndex,
      widgetType: widgetDesc.type,
      params: buildWidgetParams(widgetDesc.type, allParams),
    }));
  }, [widgetIndex, autoRenew]);

  const addedActions = useMemo(() => {
    const autoRenewActionNode = (
      <Tooltip key="autoRenew" title={autoRenew ? t('challengeWidgetBase.actions.autoRenew.tooltip.enable') : t('challengeWidgetBase.actions.autoRenew.tooltip.disable')}>
        <Button
          className={styles.actionButton}
          type="linkSecondaryBlack"
          icon={<RefreshLine width={15} height={15} />}
          pressed={autoRenew}
          onClick={handleToggleAutoRenew}
        />
      </Tooltip>
    );

    return [
      autoRenewActionNode,
    ];
  }, [autoRenew, handleToggleAutoRenew]);

  return (
    <WidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      addedActions={addedActions}
      readOnly={readOnly}
    >
      <div className={styles.header}>
        {challengeNameNode}
        <div className={styles.detailsWrapper}>
          <div className={styles.dates}>
            {challengeDates}
          </div>
          <div className={styles.type}>
            {t(`challenges.types.${challenge.type.toLowerCase()}`)}
          </div>
        </div>
      </div>
      {
        title !== null && (
          <div className={styles.title}>
            {title}
          </div>
        )
      }
      <div className={styles.content}>
        {children}
      </div>
    </WidgetBase>
  );
};

ChallengeWidgetBase.propTypes = {
  className: PropTypes.string,
  widgetIndex: PropTypes.number.isRequired,
  autoRenew: PropTypes.bool.isRequired,
  challenge: ChallengeModel.propTypes.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

ChallengeWidgetBase.defaultProps = {
  className: '',
  children: null,
  title: null,
  readOnly: false,
  enableLinks: true,
};

export default ChallengeWidgetBase;
