import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Link from '@palette/components/designSystem/Link/Link';
import StatementPeriodApprovalProgress from '@palette/components/statement/statementPeriod/StatementPeriodApprovalProgress/StatementPeriodApprovalProgress';
import StatementPeriodPaidProgress from '@palette/components/statement/statementPeriod/StatementPeriodPaidProgress/StatementPeriodPaidProgress';
import StatementCounterCorrections from '@palette/components/statement/statementCommon/StatementCounterCorrections/StatementCounterCorrections';
import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';

import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { FEATURES } from '@palette/constants/profile';

import { formatPeriodNameDates, getPeriodName, statementPeriodIsInFuture } from '@palette/helpers/StatementHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { hasFeature } from '@palette/helpers/ProfileHelper';

import { useProfile } from '@palette/hooks/ProfileHooks';

import routePaths from '@palette/config/routePaths';

import { actions as StatementsActions } from '@palette/state/Statements';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './StatementPeriodsListItem.less';

const classNames = bindClassNames.bind(styles);

const StatementPeriodsListItem = ({ className, statementPeriod, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const handleExport = useCallback(() => {
    dispatch(StatementsActions.downloadPeriodUsersStatements({ statementPeriodId: statementPeriod.id, currency }));
  }, [statementPeriod, currency]);

  const headerNode = useMemo(() => (
    <div className={styles.spacedBlocks}>
      <div className={styles.nameDatesWrapper}>
        <Link
          className={styles.name}
          displayIcon
          path={routePaths.v2.statementsPeriod}
          params={{ statementPeriodId: statementPeriod.id }}
          qsObject={periodsDatesFilterQSObject}
        >
          {getPeriodName(statementPeriod.statementStrategy, statementPeriod.period)}
        </Link>
        <div className={styles.dates}>
          {formatPeriodNameDates(statementPeriod.beginDate, statementPeriod.endDate)}
        </div>
      </div>
      <div className={styles.statusesWrapper}>
        <div className={styles.totalWrapper}>
          <div className={styles.totalLabel}>
            {t('statementPeriodsListItem.total')}
          </div>
          <div className={styles.totalAmount}>
            {formatPrice(statementPeriod.totalAmount, statementPeriod.currency)}
          </div>
        </div>
        <div className={styles.usersWrapper}>
          <div className={styles.usersCount}>
            {statementPeriod.usersCount}
          </div>
          <TeamFilled className={styles.usersCountIcon} />
        </div>
      </div>
    </div>
  ), [statementPeriod, periodsDatesFilterQSObject]);

  const footerNode = useMemo(() => {
    if (statementPeriodIsInFuture(statementPeriod)) return null;

    return (
      <>
        <div className={styles.spacedBlocks}>
          <StatementPeriodApprovalProgress
            className={styles.progressBarWrapper}
            classNameProgressBar={styles.progressBarProgress}
            statementPeriod={statementPeriod}
          />
          {!statementPeriodIsInFuture(statementPeriod, false) && (
            <StatementPeriodPaidProgress
              className={styles.progressBarWrapper}
              classNameProgressBar={styles.progressBarProgress}
              statementPeriod={statementPeriod}
            />
          )}
        </div>
        <div
          className={classNames({
            spacedBlocks: true,
            spacedBlocksInFooter: true,
            spacedBlocksInversed: statementPeriod.usersCount > 0,
          })}
        >
          {statementPeriod.usersCount > 0 && (
            <Button
              className={styles.exportButton}
              type="link"
              icon={<DownloadFilled width={20} height={20} />}
              onClick={handleExport}
            >
              {t('statementPeriodsListItem.exportAll')}
            </Button>
          )}
          {hasFeature(profile, FEATURES.STATEMENT_VALIDATION) && (
            <StatementCounterCorrections
              className={styles.correctionsWrapper}
              counter={statementPeriod.correctionsCount}
            />
          )}
        </div>
      </>
    );
  }, [statementPeriod, profile, handleExport]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {headerNode}
      {footerNode}
    </div>
  );
};

StatementPeriodsListItem.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  currency: PropTypes.string,
};

StatementPeriodsListItem.defaultProps = {
  className: '',
  currency: null,
};

export default StatementPeriodsListItem;
