import PropTypes from 'prop-types';

import * as FolderModel from '@palette/models/Folder';
import * as MasterPlanModel from '@palette/models/MasterPlan';

export const modelName = 'FolderWithResources';

const propTypesShape = {
  ...FolderModel.propTypesWithChildrenShape,
  resources: PropTypes.oneOfType([
    PropTypes.arrayOf(MasterPlanModel.propTypes),
    // Could be propTypes of Challenges as well (not done in v2 yet)
  ]),
};

export const propTypes = PropTypes.shape(propTypesShape);
