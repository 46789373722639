/* eslint-disable jsx-a11y/anchor-is-valid,react/no-direct-mutation-state */
import React from 'react';
import PropTypes from 'prop-types';
import flatten from 'flat';
import {
  Alert,
  Button, Form, Input, Modal, Select, Tooltip, Typography,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import pluralize from 'pluralize';
import { connect } from 'react-redux';

import routePaths from '@palette/config/routePaths';

import { PERIOD_TABS_IDS, PERIOD_TABS_QS_KEY } from '@palette/constants/tabs';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import * as ConnectorModel from '@palette/models/Connector';

import AutoCompleteInput from '../autoCompleteInput';
import FormulaHelper from './formulaHelper';
import QueryBuilder from './queryBuilder';
import { DATE_FIELDS, MATCHING_FIELDS, ENTITIES } from '../../services/constants';
import { getDisplayType, isReport, toVariableName } from '../../services/utils';
import ObjectFieldAutoComplete from '../objectFieldAutocomplete';

import Variables from './variables';
import '../../styles/admin/ruleEditor.less';

class TrackingObjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tempConnectorObject: props.initialValues.connector && props.initialValues.type ? `${props.initialValues.connector._id || props.initialValues.connector}_${props.initialValues.type}` : '',
      uiType: props.initialValues.uiType || '',
      fields: [],
      forceFilterFormula: false,
      editUiType: false,
      initialValues: {
        ...(props.initialValues || {}),
        valueVariables: (props.initialValues) ? (props.initialValues.valueVariables || []) : [],
      },
      activeKeysVariables: [],
      fieldsData: null,
    };
    this.QueryBuilderRef = React.createRef();
  }

  componentDidMount() {
    const { refreshConnectorsListIsNeeded, refreshConnectorsList } = this.props;
    if (refreshConnectorsListIsNeeded) {
      refreshConnectorsList();
    }
  }

  onFinish = ({ connectorType, ...values }) => {
    const [connector, ...typeFragments] = connectorType.split('_');
    const type = typeFragments.join('_');
    this.props.onFinish({
      ...values,
      connector,
      type,
      uiType: this.state.uiType || null,
      filterObject: JSON.stringify(this.QueryBuilderRef.current.getQuery()),
    });
  };

  onDragEnd = (fromIndex, toIndex) => {
    const { initialValues } = this.state;
    const [elem] = initialValues.valueVariables.splice(fromIndex, 1);
    initialValues.valueVariables.splice(toIndex, 0, elem);
    this.setState({ initialValues });
  };

  buildOptionsForAutoSuggest = () => {
    const { fieldsData: helper } = this.state;
    const vars = this.state.initialValues.valueVariables.reduce((acc, v) => [
      ...acc,
      v.name,
    ], []);
    const types = Object.keys(helper);
    const typesWithValues = Object.values(helper)
      .reduce((acc, element, index) => ({
        ...acc,
        [`${types[index]}.`]: [...(Object.keys(element) || []), ...(vars || [])],
      }),
      {});
    const grouped = Object.values(typesWithValues).map((v, i) => v.map((e) => `${types[i]}.${e}`)).flat();
    const options = {
      ' ': [...grouped],
      '': [...grouped],
      ...typesWithValues,
    };
    const triggers = ['', ' ', ...Object.keys(typesWithValues)];
    return { options, triggers };
  };

  addActiveKey = (key) => this.setState((prevState) => ({
    activeKeysVariables: [...prevState.activeKeysVariables, key],
  }));

  onChangeActiveKey = (param) => this.setState(() => ({
    activeKeysVariables: param,
  }));

  addVariableCondition = (variableIndex) => {
    this.state.initialValues.valueVariables[variableIndex].steps.push({
      condition: '',
      formula: '',
    });
    this.setState({ rules: this.state.rules });
  };

  deleteVariableStep = (variableIndex, stepIndex) => {
    const variable = this.state.initialValues.valueVariables[variableIndex];
    variable.steps.splice(stepIndex, 1);
    if (variable.steps.length === 0) {
      variable.type = 'SIMPLE';
      delete variable.steps;
    }
    this.setState({ rules: this.state.rules });
  };

  onVariableStepFieldChangeOption = (field, variableIndex, stepIndex, value) => {
    this.state.initialValues.valueVariables[variableIndex].steps[stepIndex][field] = value;
    this.setState({ rules: this.state.rules });
  };

  addVariable = () => {
    this.state.initialValues.valueVariables.push({
      name: '',
      type: 'SIMPLE',
      formula: '',
    });
    this.setState({ rules: this.state.rules });
  };

  onVariableFieldChange = (field, variableIndex, val) => {
    let value = val;
    if (field === 'name') value = toVariableName(value);
    this.state.initialValues.valueVariables[variableIndex][field] = value;
    this.setState({ rules: this.state.rules });
  };

  deleteVariable = (variableIndex) => {
    this.state.initialValues.valueVariables.splice(variableIndex, 1);
    this.setState({ rules: this.state.rules });
  };

  setVariableCond = (variableIndex) => {
    this.state.initialValues.valueVariables[variableIndex].type = 'CONDITIONAL';
    this.state.initialValues.valueVariables[variableIndex].steps = [{
      condition: '',
      formula: '',
    }];
    this.setState({ rules: this.state.rules }, () => { this.addActiveKey(variableIndex); });
  };

  render() {
    const {
      footer,
      type,
      disabled,
      operation,
      entity,
    } = this.props;
    const {
      tempConnectorObject,
      forceFilterFormula,
      uiType,
      editUiType,
      initialValues,
      activeKeysVariables,
      fieldsData,
    } = this.state;
    const [connectorId, ...typeFragments] = tempConnectorObject.split('_');
    const toType = typeFragments.join('_');
    const displayType = getDisplayType({ type: toType, uiType });

    const fields = [
      ...(this.state.fields || []),
      ...(initialValues.valueVariables || []).map((e) => e.name),
    ];
    const typeGrouped = fields.map((v) => `${toType}.${v}`);

    const { options, triggers } = fieldsData
      ? this.buildOptionsForAutoSuggest()
      : { options: {}, triggers: [] };

    return (
      <>
        <Form
          layout="vertical"
          initialValues={{
            ...initialValues,
            connectorType: tempConnectorObject,
          }}
          onFinish={(values) => this.onFinish({
            ...values,
            valueVariables: initialValues.valueVariables || [],
          })}
          style={{ position: 'relative' }}
        >
          <Form.Item
            label="Resource Tracked"
            name="connectorType"
            rules={[{
              required: true,
              message: 'Please enter an Object Type',
            }]}
            style={tempConnectorObject ? { paddingRight: 92 } : {}}
          >
            <Select
              disabled={disabled}
              onChange={(t) => this.setState({ tempConnectorObject: t })}
            >
              {this.props.connectors.map((c) => (
                c.resources.map((o) => {
                  const isSelected = tempConnectorObject === `${c.id}_${o.originalType}`;
                  return (
                    <Select.Option
                      value={`${c.id}_${o.originalType}`}
                      key={`${c.id}_${o.originalType}`}
                    >
                      {isSelected ? uiType : ''}
                      <span
                        style={(uiType && isSelected) ? {
                          marginLeft: 4,
                          textDecoration: 'line-through',
                        } : {}}
                      >
                        {isReport(o.originalType) ? `${getDisplayType(o)} (from "${o.name}" report)` : o.originalType}
                      </span>
                      <span className="grey" style={{ marginLeft: '4px' }}>
                        {` (${c.type})`}
                      </span>
                    </Select.Option>
                  );
                })
              ))}
            </Select>
          </Form.Item>
          {tempConnectorObject && (
            <Button
              style={{ position: 'absolute', right: 0, marginTop: -56 }}
              type="link"
              onClick={() => this.setState({ editUiType: true })}
            >
              Rename
            </Button>
          )}
          {connectorId && toType && (
            <FormulaHelper
              isTrackingObject
              plan={null}
              connectorId={connectorId}
              type={toType}
              uiType={uiType}
              onData={(data) => this.setState({
                fields: Object.keys(flatten(data)),
                fieldsData: { [toType]: data },
              })}
              style={{ marginBottom: 24 }}
            />
          )}
          <Form.Item
            label="Field to match with the Resource owner"
            extra={<span className="grey">{`ex: ${displayType || '[Resource]'}.ownerId`}</span>}
            name="matchingField"
            rules={[{
              required: true,
              message: 'Please enter a Field to match with the Resource owner',
            }]}
          >
            {ObjectFieldAutoComplete({
              topFields: MATCHING_FIELDS,
              fields,
              objectType: displayType,
              connectorType: connectorId ? this.props.connectors.find((connector) => connector.id === connectorId)?.type || '' : '',
              disabled,
            })}
          </Form.Item>
          <Form.Item label="Resource Filter">
            <QueryBuilder
              query={JSON.parse(initialValues.filterObject)}
              prefix="data."
              fields={fields}
              disabled={disabled}
              ref={this.QueryBuilderRef}
              simplify={operation === 'update'}
            />
            {!initialValues.filterFormula && !forceFilterFormula && (
              <div style={{ textAlign: 'center', marginTop: 8 }}>
                <Tooltip title="Only use this when you can't express yourself white the Filter above.">
                  <Typography.Link
                    onClick={() => this.setState({ forceFilterFormula: true })}
                  >
                    use custom code
                  </Typography.Link>
                </Tooltip>
              </div>
            )}
          </Form.Item>
          <Form.Item
            label="Filter Code (Optional)"
            name="filterFormula"
            extra={(
              <>
                {'Only use this when you can\'t express yourself with the Filter above. '}
                {!initialValues.filterFormula && (
                  <Typography.Link onClick={() => this.setState({ forceFilterFormula: false })}>
                    hide
                  </Typography.Link>
                )}
              </>
            )}
            style={initialValues.filterFormula || forceFilterFormula ? {} : { display: 'none' }}
          >
            <Input disabled={disabled} />
          </Form.Item>
          {type === ENTITIES.PLAN && toType && (
            <>
              <div
                className="Plan_rules_variables_section Connector_trackingObject_variables"
                style={{ marginBottom: '16px' }}
                id="variables"
              >
                <h3 className="bold" style={{ marginBottom: '2px' }}>
                  {`Enrich ${pluralize(displayType)} with new fields`}
                </h3>
                <div className="grey" style={{ marginBottom: '6px' }}>
                  In this section you can define new fields that will be added to each
                  {' '}
                  {displayType}
                  {' '}
                  during compute.
                  {operation === 'update'
                  && (
                    <div>
                      <Alert
                        style={{ marginTop: '12px', border: 'none' }}
                        type="info"
                        showIcon
                        message={(
                          <div>
                            Tip - You can display the new fields in the columns of the
                            {' '}
                            {pluralize(displayType)}
                            {' '}
                            tab
                            {' '}
                            <a
                              className="bold"
                              href={`/#${routePaths.v2.planDetails.replace(':masterPlanId', entity._id)}?${PERIOD_TABS_QS_KEY}=${PERIOD_TABS_IDS.DEALS}&editColumns=true`}
                            >
                              here

                            </a>
                            .
                          </div>
                        )}
                      />
                    </div>
                  )}
                </div>
                <Variables
                  addonBefore={`${displayType}.`}
                  variables={initialValues.valueVariables || []}
                  onDragEnd={this.onDragEnd}
                  triggers={triggers}
                  options={options}
                  activeKeysVariables={activeKeysVariables}
                  onChangeActiveKey={this.onChangeActiveKey}
                  displayType={displayType}
                  onVariableStepFieldChangeOption={this.onVariableStepFieldChangeOption}
                  onVariableFieldChange={this.onVariableFieldChange}
                  deleteVariableStep={this.deleteVariableStep}
                  setVariableCond={this.setVariableCond}
                  deleteVariable={this.deleteVariable}
                  addVariableCondition={this.addVariableCondition}
                />
                <div
                  className="center"
                  style={{ marginTop: '8px' }}
                >
                  <Button
                    type="link"
                    onClick={() => this.addVariable()}
                    className="Add_bracket_btn "
                    icon={<PlusOutlined />}
                    size="small"
                  >
                    Add a Field
                  </Button>
                </div>
              </div>
              <Form.Item
                label={`Value ${toType ? `of a ${toType}` : ''} for quota achievement (can be a formula)`}
                name="valueFormula"
                rules={[{
                  required: true,
                  message: 'Please enter a Value Formula',
                }]}
                extra={<span className="grey">{`ex: ${displayType || '[Resource]'}.amount`}</span>}
              >
                <AutoCompleteInput
                  required
                  placeholder="Value for an element"
                  trigger={[`${toType}.`, ' ', '']}
                  options={{
                    [`${toType}.`]: fields,
                    ' ': typeGrouped,
                    '': typeGrouped,
                  }}
                  disabled={disabled}
                  Component="textarea"
                  style={{ width: '100%', resize: 'auto' }}
                />
              </Form.Item>
            </>
          )}
          {type === ENTITIES.CHALLENGE && (
            <Form.Item
              label="Score value (can be a formula)"
              name="scoreFormula"
              rules={[{
                required: true,
                message: 'Please enter a Score Formula',
              }]}
            >
              <AutoCompleteInput
                required
                placeholder="Score value"
                trigger={[`${toType}.`, ' ', '']}
                options={{
                  [`${toType}.`]: fields,
                  ' ': typeGrouped,
                  '': typeGrouped,
                }}
                disabled={disabled}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Date (Reference date field to use)"
            name="dateField"
            extra={<span className="grey">{`ex: ${displayType || '[Resource]'}.closedate`}</span>}
            rules={[{
              required: true,
              message: 'Please enter a Date Field',
            }]}
          >
            {ObjectFieldAutoComplete({
              topFields: DATE_FIELDS,
              fields,
              objectType: displayType,
              connectorType: connectorId ? this.props.connectors.find((connector) => connector.id === connectorId)?.type || '' : '',
              disabled,
            })}
          </Form.Item>
          {footer || (
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                block
                disabled={disabled}
              >
                Update Resource to Track Parameters
              </Button>
            </Form.Item>
          )}
        </Form>
        {editUiType && (
          <Modal
            title="Edit the name of the resource"
            onCancel={() => this.setState({ editUiType: false })}
            footer={null}
            visible
          >
            <Form
              layout="vertical"
              initialValues={{ uiType }}
              onFinish={({ uiType: txt }) => this.setState({ uiType: txt, editUiType: false })}
            >
              <Form.Item
                name="uiType"
                label={`Change ${getDisplayType({ type: toType })} to the following name (used for display only)`}
                extra="ex: Deal, Meeting, Opportunity"
              >
                <Input placeholder="Enter display type" />
              </Form.Item>
              <div className="width-100 flex">
                <Button
                  type="link"
                  onClick={() => this.setState({ uiType: null, editUiType: false })}
                >
                  Reset to original name
                </Button>
                <div style={{ flex: 1 }} />
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  Save name
                </Button>
              </div>
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

TrackingObjectForm.propTypes = {
  initialValues: PropTypes.object,
  onFinish: PropTypes.func,
  footer: PropTypes.element,
  type: PropTypes.oneOf([ENTITIES.PLAN, ENTITIES.CHALLENGE]),
  disabled: PropTypes.bool,
  operation: PropTypes.oneOf(['create', 'update']),
  entity: PropTypes.object,
  connectors: PropTypes.arrayOf(ConnectorModel.propTypes).isRequired,
  refreshConnectorsListIsNeeded: PropTypes.bool.isRequired,
  refreshConnectorsList: PropTypes.func.isRequired,
};

TrackingObjectForm.defaultProps = {
  initialValues: { filterObject: JSON.stringify({ $and: [] }) },
  onFinish: () => null,
  footer: null,
  type: ENTITIES.PLAN,
  disabled: false,
  operation: 'create',
  entity: null,
};

const mapStateToProps = (state) => ({
  connectors: ConnectorsSelectors.getConnectorsList(state),
  refreshConnectorsListIsNeeded: ConnectorsSelectors.refreshListIsNeeded(state),
});

const mapDispatchToProps = (dispatch) => ({
  refreshConnectorsList: () => dispatch(ConnectorsActions.refreshList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingObjectForm);
