import React from 'react';
import PropTypes from 'prop-types';
import {
  message, Select, Button, Tooltip,
} from 'antd';
import { LockOutlined, SyncOutlined } from '@ant-design/icons';
import FullScreenLoader from '../loader';
import { listSlackChannels } from '../../services/admin/company';

class SlackChannelSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      channels: [],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      listSlackChannels()
        .then(({ data: channels }) => this.setState({
          loading: false,
          channels,
        }))
        .catch(() => {
          message.error('Error while loading slack channels');
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const {
      loading,
      channels,
    } = this.state;
    const { value, onChange } = this.props;
    return (
      <>
        <div className="flex">
          <Select
            value={value || undefined}
            onChange={onChange}
            placeholder="Select a channel"
            style={{ flex: 1 }}
          >
            {channels.map((c) => (
              <Select.Option key={c.id}>
                <div style={{ textTransform: 'none' }}>
                  {c.is_private ? <LockOutlined /> : <span style={{ padding: '0 3px' }}>#</span>}
                  {` ${c.name}`}
                </div>
              </Select.Option>
            ))}
          </Select>
          &nbsp;
          <Button shape="circle" onClick={this.fetch}>
            <SyncOutlined />
          </Button>
        </div>
        <Tooltip title="You must first add @PaletteHQ in the channel. To do it, just mention @PaletteHQ in the desired channel.">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            style={{ display: 'inline-block', marginTop: 4 }}
          >
            Don't see your channel?
          </a>
        </Tooltip>
        {loading && <FullScreenLoader />}
      </>
    );
  }
}

SlackChannelSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SlackChannelSelect;
