import React from 'react';
import PropTypes from 'prop-types';
import flatten from 'flat';
import {
  Button, Form, Input, Modal, Select,
} from 'antd';
import { connect } from 'react-redux';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import * as ConnectorModel from '@palette/models/Connector';

import FormulaHelper from './formulaHelper';
import ObjectFieldAutoComplete from '../objectFieldAutocomplete';
import { MATCHING_FIELDS } from '../../services/constants';
import { getDisplayType, isReport } from '../../services/utils';

class GroupByObjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tempConnectorObject: props.initialValues.connector && props.initialValues.type ? `${props.initialValues.connector._id}_${props.initialValues.type}` : '',
      fields: [],
      uiType: props.initialValues.uiType || '',
      editUiType: false,
    };
  }

  componentDidMount() {
    const { refreshConnectorsListIsNeeded, refreshConnectorsList } = this.props;
    if (refreshConnectorsListIsNeeded) {
      refreshConnectorsList();
    }
  }

  onFinish = ({ connectorType, ...values }) => {
    const [connector, ...typeFragments] = connectorType.split('_');
    const type = typeFragments.join('_');
    this.props.onFinish({
      ...values,
      connector,
      type,
      uiType: this.state.uiType || null,
    });
  };

  render() {
    const {
      initialValues,
      footer,
      disabled,
    } = this.props;
    const {
      tempConnectorObject,
      fields,
      uiType,
      editUiType,
    } = this.state;
    const [connectorId, ...typeFragments] = tempConnectorObject.split('_');
    const gboType = typeFragments.join('_');
    const displayType = getDisplayType({ type: gboType, uiType });

    return (
      <>
        <Form
          layout="vertical"
          initialValues={{
            ...initialValues,
            connectorType: tempConnectorObject,
          }}
          onFinish={this.onFinish}
          style={{ position: 'relative' }}
        >
          <Form.Item
            label="Resource Type"
            name="connectorType"
            rules={[{
              required: true,
              message: 'Please enter an Object Type',
            }]}
            style={tempConnectorObject ? { paddingRight: 92 } : {}}
          >
            <Select
              disabled={disabled}
              onChange={(t) => this.setState({ tempConnectorObject: t })}
            >
              {this.props.connectors.map((c) => (
                c.resources.map((o) => (
                  <Select.Option
                    value={`${c.id}_${o.originalType}`}
                    key={`${c.id}_${o.originalType}`}
                  >
                    {uiType}
                    <span
                      style={uiType ? {
                        marginLeft: 4,
                        textDecoration: 'line-through',
                      } : {}}
                    >
                      {isReport(o.originalType) ? `${getDisplayType(o)} (from "${o.name}" report)` : o.originalType}
                    </span>
                    <span className="grey">
                      {` (${c.type})`}
                    </span>
                  </Select.Option>
                ))
              ))}
            </Select>
          </Form.Item>
          {tempConnectorObject && (
            <Button
              style={{ position: 'absolute', right: 0, marginTop: -56 }}
              type="link"
              onClick={() => this.setState({ editUiType: true })}
            >
              Rename
            </Button>
          )}
          {connectorId && gboType && (
            <FormulaHelper
              isTrackingObject={false}
              plan={null}
              connectorId={connectorId}
              type={gboType}
              uiType={uiType}
              onData={(data) => this.setState({ fields: Object.keys(flatten(data)) })}
              style={{ marginBottom: 12 }}
            />
          )}
          <Form.Item
            label="Field to match with the Resource to track"
            extra={<span className="grey">{`ex: ${displayType || '[Resource]'}.ownerId`}</span>}
            name="matchingField"
            rules={[{
              required: true,
              message: 'Please enter a Matching Field',
            }]}
          >
            {ObjectFieldAutoComplete({
              topFields: MATCHING_FIELDS,
              fields,
              objectType: displayType,
              connectorType: connectorId ? this.props.connectors.find((connector) => connector.id === connectorId)?.type || '' : '',
              disabled,
            })}
          </Form.Item>
          {footer || (
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                block
                disabled={disabled}
              >
                Update Resource Owner Parameters
              </Button>
            </Form.Item>
          )}
        </Form>
        {editUiType && (
          <Modal
            title="Edit the name of the resource"
            onCancel={() => this.setState({ editUiType: false })}
            footer={null}
            visible
          >
            <Form
              layout="vertical"
              initialValues={{ uiType }}
              onFinish={({ uiType: txt }) => this.setState({ uiType: txt, editUiType: false })}
            >
              <Form.Item
                name="uiType"
                label={`Change ${getDisplayType({ type: gboType })} to the following name (used for display only)`}
                extra="ex: Deal, Meeting, Opportunity"
              >
                <Input placeholder="Enter display type" />
              </Form.Item>
              <div className="width-100 flex">
                <Button
                  type="link"
                  onClick={() => this.setState({ uiType: null, editUiType: false })}
                >
                  Reset to original name
                </Button>
                <div style={{ flex: 1 }} />
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  Save name
                </Button>
              </div>
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

GroupByObjectForm.propTypes = {
  initialValues: PropTypes.object,
  onFinish: PropTypes.func,
  footer: PropTypes.element,
  disabled: PropTypes.bool,
  connectors: PropTypes.arrayOf(ConnectorModel.propTypes).isRequired,
  refreshConnectorsListIsNeeded: PropTypes.bool.isRequired,
  refreshConnectorsList: PropTypes.func.isRequired,
};

GroupByObjectForm.defaultProps = {
  initialValues: {},
  onFinish: () => null,
  footer: null,
  disabled: false,
};

const mapStateToProps = (state) => ({
  connectors: ConnectorsSelectors.getConnectorsList(state),
  refreshConnectorsListIsNeeded: ConnectorsSelectors.refreshListIsNeeded(state),
});

const mapDispatchToProps = (dispatch) => ({
  refreshConnectorsList: () => dispatch(ConnectorsActions.refreshList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupByObjectForm);
