import React from 'react';
import { message } from 'antd';
import FullScreenLoader from '../loader';
import { getSlackOAuthUrl } from '../../services/admin/company';

class SlackConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  addSlack = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      getSlackOAuthUrl()
        .then(({ data: url }) => {
          window.location.href = url;
        })
        .catch(() => {
          message.error('Error while connecting to slack.');
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <img
          alt="Add to Slack"
          height="40"
          width="139"
          style={{ cursor: 'pointer' }}
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          onClick={this.addSlack}
        />
        {loading && <FullScreenLoader />}
      </>
    );
  }
}

export default SlackConnect;
