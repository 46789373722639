import Axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { API_URL } from './constants';
import { getSessionId } from './utils';

const plans$$ = new BehaviorSubject([]);

// STATEMENT

export const getStatement = (params, download = false) => Axios.post(`${API_URL}/users/ic/get-statement`, {
  ...params,
  download,
}, {
  headers: {
    sessionId: getSessionId(),
  },
});

// CHALLENGES

export const listChallenges = () => Axios.post(`${API_URL}/users/ic/list-challenges`, null, {
  headers: {
    sessionId: getSessionId(),
  },
}).then((obj) => {
  plans$$.next(obj.data);
  return obj;
});

export const getChallengeDetails = (params) => Axios.post(`${API_URL}/users/ic/get-challenge-details`, params, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getChallengeRankingDetails = (challengeId, objectId) => Axios.post(`${API_URL}/users/ic/get-challenge-ranking-details`, { challengeId, objectId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getStatementPeriods = (params) => Axios.post(`${API_URL}/users/ic/get-statement-periods`, params, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const plans$ = plans$$;

export default null;
