import PropTypes from 'prop-types';

import {
  manageNumberAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'ChallengeStats';

export const propTypes = PropTypes.shape({
  active: PropTypes.number,
  past: PropTypes.number,
  activeAndPast: PropTypes.number,
  upcoming: PropTypes.number,
  total: PropTypes.number,
});

export const transform = (challengeStats) => {
  if (!challengeStats) {
    return null;
  }

  return (
    {
      active: manageNumberAttribute(challengeStats, 'active'),
      past: manageNumberAttribute(challengeStats, 'past'),
      activeAndPast: manageNumberAttribute(challengeStats, 'activeAndPast'),
      upcoming: manageNumberAttribute(challengeStats, 'upcoming'),
      total: manageNumberAttribute(challengeStats, 'total'),
    }
  );
};

export const transformList = (challengeStatsList) => challengeStatsList.map((challengeStats) => transform(challengeStats));
