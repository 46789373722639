import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from '@palette/components/designSystem/Select/Select';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { LIST_TYPES } from '@palette/constants/challenges';
import { ALERT_TYPES } from '@palette/constants/alert';
import { RIGHTS } from '@palette/constants/profile';

import { actions as ChallengesActions, selectors as ChallengesSelectors } from '@palette/state/Challenges';

import styles from './MyChallengesListSelect.less';

const classNames = bindClassNames.bind(styles);

const MyChallengesListSelect = ({
  className,
  value,
  onChange,
  disabled,
  filterFunc,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => setInternalValue(value), [value]);

  const handleChange = useCallback((newValue) => {
    if (disabled) return;

    setInternalValue(newValue);

    if (onChange !== null) {
      onChange(newValue);
    }
  }, [onChange]);

  const getListIsPending = useSelector(ChallengesSelectors.getListIsPending);
  const listStats = useSelector(ChallengesSelectors.getListStats);
  const activeChallengesList = useSelector((state) => ChallengesSelectors.getChallengesListByType(state, { listType: LIST_TYPES.ACTIVE }));
  const pastChallengesList = useSelector((state) => ChallengesSelectors.getChallengesListByType(state, { listType: LIST_TYPES.PAST }));

  useEffect(() => {
    if (listStats?.activeAndPast === undefined && hasAtLeastOneRight(profile, [RIGHTS.IC.CHALLENGES])) {
      dispatch(ChallengesActions.getICList());
    }
  }, [listStats]);

  const filteredActiveChallengesList = useMemo(() => {
    if (filterFunc === null) return activeChallengesList;

    return activeChallengesList.filter((challenge) => filterFunc(challenge));
  }, [activeChallengesList, filterFunc]);

  const filteredPastChallengesList = useMemo(() => {
    if (filterFunc === null) return pastChallengesList;

    return pastChallengesList.filter((challenge) => filterFunc(challenge));
  }, [pastChallengesList, filterFunc]);

  const challengesListOptions = useMemo(() => {
    if (filteredActiveChallengesList.length === 0) {
      return filteredPastChallengesList.map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
      }));
    }

    if (filteredPastChallengesList.length === 0) {
      return filteredActiveChallengesList.map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
      }));
    }

    return {
      [t('myChallengesListSelect.active')]: filteredActiveChallengesList.map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
      })),
      [t('myChallengesListSelect.past')]: filteredPastChallengesList.map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
      })),
    };
  }, [filteredActiveChallengesList, filteredPastChallengesList]);

  if (!getListIsPending && filteredActiveChallengesList.length === 0 && filteredPastChallengesList.length === 0) {
    return (
      <Alert
        className={classNames({
          noChallengeAlert: true,
          [className]: className !== '',
        })}
        type={ALERT_TYPES.WARNING}
        message={t('myChallengesListSelect.empty.description')}
      />
    );
  }

  return (
    <Select
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      options={challengesListOptions}
      value={internalValue}
      onSelect={handleChange}
      disabled={disabled}
      placeholder={t('myChallengesListSelect.placeholder')}
      pendingOptions={challengesListOptions.length === 0 && getListIsPending}
      {...otherProps}
    />
  );
};

MyChallengesListSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  filterFunc: PropTypes.func,
};

MyChallengesListSelect.defaultProps = {
  className: '',
  value: null,
  onChange: null,
  disabled: false,
  filterFunc: null,
};

export default MyChallengesListSelect;
