import React, { PureComponent } from 'react';

import {
  Radio,
} from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  parseSearch, stringifySearch,
} from '../services/utils';

class PeriodSelector extends PureComponent {
  onTypeChange = (e) => {
    const currentSearch = parseSearch(this.props.location.search);
    const newSearch = {
      ...currentSearch,
      type: e.target.value,
    };
    if (JSON.stringify(currentSearch) !== JSON.stringify(newSearch)) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: stringifySearch(newSearch),
      });
    }
  };

  render() {
    const { type } = parseSearch(this.props.location.search);
    return (
      <div
        style={{
          textAlign: 'center',
          margin: '16px 0',
        }}
      >
        <Radio.Group
          options={[

            {
              label: 'Past',
              value: 'PAST',
            },
            {
              label: 'Current',
              value: 'CURRENT',
            },
            {
              label: 'Upcoming',
              value: 'UPCOMING',
            },
          ]}
          onChange={this.onTypeChange}
          value={type || 'CURRENT'}
          optionType="button"
        />
      </div>
    );
  }
}

PeriodSelector.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(PeriodSelector);
