import React from 'react';

import DollarFilled from '@palette/components/utils/Icons/DollarFilled';
import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';
import BadgeFilled from '@palette/components/utils/Icons/BadgeFilled';
import ProfileFilled from '@palette/components/utils/Icons/ProfileFilled';
import BoxFilled from '@palette/components/utils/Icons/BoxFilled';
import ElectricalPlugFilled from '@palette/components/utils/Icons/ElectricalPlugFilled';
import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import RolesFilled from '@palette/components/utils/Icons/RolesFilled';
import WidgetsFilled from '@palette/components/utils/Icons/WidgetsFilled';
import FlagFilled from '@palette/components/utils/Icons/FlagFilled';
import SearchLine from '@palette/components/utils/Icons/SearchLine';
import TasksFilled from '@palette/components/utils/Icons/TasksFilled';
import SpeedometerFilled from '@palette/components/utils/Icons/SpeedometerFilled';
import FunctionLine from '@palette/components/utils/Icons/FunctionLine';

import MenuNotificationsIcon from '@palette/components/navigation/MenuNotificationsIcon/MenuNotificationsIcon';
import MenuNotifications from '@palette/components/navigation/MenuNotifications/MenuNotifications';
import MenuWithComputeButton from '@palette/components/navigation/MenuWithComputeButton/MenuWithComputeButton';

import { hasAtLeastOneAdminRight, hasFeature, hasAtLeastOneRight, hasAllRights } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

const adminMenu = {
  key: 'AdminMenu',
  title: 'adminMenu.title',
  show: (profile) => hasAtLeastOneRight(profile, [
    RIGHTS.ADMIN.CONNECTORS.DISPLAY,
    RIGHTS.ADMIN.USERS.DISPLAY,
    RIGHTS.ADMIN.CHALLENGES.DISPLAY,
    RIGHTS.ADMIN.PLANS.DISPLAY,
    RIGHTS.ADMIN.OBJECTS.DISPLAY,
    RIGHTS.ADMIN.ROLES.DISPLAY,
    RIGHTS.ADMIN.QUOTAS.DISPLAY,
  ]),
  items: [
    {
      key: 'AdminMenuCommissions',
      path: routePaths.commissions,
      icon: (<DollarFilled />),
      name: 'adminMenu.commissions',
      show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]),
      submenuItems: [
        {
          key: 'AdminMenuStatements',
          path: routePaths.v2.statements,
          icon: (<DollarFilled />),
          name: 'adminMenu.commissions.statements',
          show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]),
        },
        {
          key: 'AdminMenuCommissionList',
          path: routePaths.v2.commissionList,
          icon: (<SearchLine />),
          name: 'adminMenu.commissions.list',
          show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]),
        },
      ],
    },
    {
      key: 'AdminMenuPlans',
      path: routePaths.v2.plans,
      icon: (<MedicalChartFilled />),
      name: 'adminMenu.plans',
      show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]),
      disableOnClick: true,
      contentComponent: ({ name, path, pathQS }) => (<MenuWithComputeButton name={name} path={path} pathQS={pathQS} />),
    },
    {
      key: 'AdminMenuChallenges',
      path: routePaths.challenges,
      icon: (<BadgeFilled />),
      name: 'adminMenu.challenges',
      show: (profile) => hasFeature(profile, FEATURES.CHALLENGE) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.CHALLENGES.DISPLAY]),
      disableOnClick: true,
      contentComponent: ({ name, path, pathQS }) => (<MenuWithComputeButton name={name} path={path} pathQS={pathQS} />),
    },
    {
      key: 'AdminMenuQuotas',
      path: routePaths.v2.quotas,
      icon: (<FlagFilled />),
      name: 'adminMenu.quotas',
      show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.DISPLAY]),
      submenuItems: [
        {
          key: 'AdminMenuQuotasConfiguration',
          path: routePaths.v2.quotas,
          icon: (<FlagFilled />),
          name: 'adminMenu.quotas.configuration',
          show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.DISPLAY]),
        },
        {
          key: 'AdminMenuQuotasPerformance',
          path: routePaths.v2.quotasPerformance,
          icon: (<SpeedometerFilled />),
          name: 'adminMenu.quotas.performance',
          show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.DISPLAY]),
        },
      ],
    },
    {
      key: 'AdminMenuUsers',
      path: routePaths.v2.users,
      icon: (<ProfileFilled />),
      name: 'adminMenu.users',
      show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.DISPLAY]),
      submenuItems: [
        {
          key: 'AdminMenuUsersList',
          path: routePaths.v2.users,
          icon: (<ProfileFilled />),
          name: 'adminMenu.users.list',
          show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.DISPLAY]),
        },
        {
          key: 'AdminMenuUsersTeams',
          path: routePaths.v2.teams,
          icon: (<TeamFilled />),
          name: 'adminMenu.users.teams',
          show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.DISPLAY]),
        },
        {
          key: 'AdminMenuUsersRoles',
          path: routePaths.v2.roles,
          icon: (<RolesFilled />),
          name: 'adminMenu.users.roles',
          show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.ROLES.DISPLAY]),
        },
        {
          key: 'AdminMenuUsersVariables',
          path: routePaths.v2.variables,
          icon: (<FunctionLine />),
          name: 'adminMenu.users.variables',
          show: (profile) => hasAllRights(profile, [RIGHTS.ADMIN.COMPANY.MANAGE, RIGHTS.ADMIN.USERS.VIEW]),
        },
        {
          key: 'AdminMenuUsersPresets',
          path: routePaths.v2.dashboardPresets,
          icon: (<WidgetsFilled />),
          name: 'adminMenu.users.presets',
          show: (profile) => hasAllRights(profile, [RIGHTS.ADMIN.COMPANY.MANAGE, RIGHTS.ADMIN.USERS.VIEW]),
        },
      ],
    },
    {
      key: 'AdminMenuResources',
      path: routePaths.v2.resources,
      icon: (<BoxFilled />),
      name: 'adminMenu.resources',
      badge: 0,
      show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.OBJECTS.DISPLAY]),
    },
    {
      key: 'AdminMenuNotebooks',
      path: routePaths.v2.notebooks,
      icon: (<BoxFilled />),
      name: 'adminMenu.notebooks',
      show: (profile) => hasFeature(profile, FEATURES.NOTEBOOKS) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.DISPLAY]),
    },
    {
      key: 'AdminMenuConnectors',
      path: routePaths.connectors,
      icon: (<ElectricalPlugFilled />),
      name: 'adminMenu.connectors',
      show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.CONNECTORS.DISPLAY]),
    },
    {
      key: 'AdminMenuAuditTrail',
      path: routePaths.v2.auditTrail,
      icon: (<TasksFilled />),
      name: 'adminMenu.auditTrail',
      show: (profile) => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE]),
    },
    {
      key: 'MenuNotifications',
      icon: (<MenuNotificationsIcon />),
      name: 'adminMenu.notifications',
      show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneAdminRight(profile),
      disableOnClick: true,
      contentComponent: ({ name }) => (<MenuNotifications name={name} />),
    },
  ],
};

export default adminMenu;
