import { all, call, put, take, takeLatest } from 'redux-saga/effects';

import { actions as FoldersActions } from '@palette/state/Folders/slice';
import { actions as MasterPlansActions } from '@palette/state/MasterPlans';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { manageError as manageFoldersError } from '@palette/state/Folders/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import { FOLDER_TYPES } from '@palette/constants/folders';

import * as FoldersService from '@palette/services/FoldersService';

import * as FolderModel from '@palette/models/Folder';

export function* getList({ payload = {} }) {
  const { type = FOLDER_TYPES.PLAN } = payload;

  const data = { type };

  const callResult = yield call(FoldersService.getList, data);

  if (callResult.ok) {
    const folders = FolderModel.transformList(callResult.data);
    yield put(FoldersActions.setList({ folders }));
  } else {
    const error = manageFoldersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(FoldersActions.getListCompleted());
}

export function* createFolder({ payload = {} }) {
  const { type = FOLDER_TYPES.PLAN, name } = payload;

  const data = { type, name };

  const callResult = yield call(FoldersService.createFolder, data);

  if (callResult.ok) {
    const folder = FolderModel.transform(callResult.data);
    yield put(FoldersActions.addFolder({ folder }));
    if (type === FOLDER_TYPES.PLAN) {
      yield call(analyticsSendEvent, { payload: { event: MASTER_PLANS_EVENTS.PLAN_LIST.CREATE_FOLDER } });
    }
  } else {
    const error = manageFoldersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(FoldersActions.createFolderCompleted());
}

export function* renameFolder({ payload = {} }) {
  const {
    folderId,
    folderType,
    folderParent,
    name,
  } = payload;

  const data = {
    folderId,
    folderType,
    folderParent,
    name,
  };

  const callResult = yield call(FoldersService.renameFolder, data);

  if (callResult.ok) {
    yield put(FoldersActions.getList());

    yield take(FoldersActions.getListCompleted.type);
  } else {
    const error = manageFoldersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(FoldersActions.renameFolderCompleted());
}

export function* deleteFolder({ payload = {} }) {
  const { folderId } = payload;

  const data = { folderId };

  const callResult = yield call(FoldersService.deleteFolder, data);

  if (callResult.ok) {
    yield put(FoldersActions.getList());

    yield take(FoldersActions.getListCompleted.type);
  } else {
    const error = manageFoldersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(FoldersActions.deleteFolderCompleted());
}

export function* moveFolder({ payload = {} }) {
  const { folderId, parentId, type } = payload;

  const data = { folderId, parentId, type };

  const callResult = yield call(FoldersService.moveFolder, data);

  if (callResult.ok) {
    yield put(FoldersActions.getList());

    yield take(FoldersActions.getListCompleted.type);
  } else {
    const error = manageFoldersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(FoldersActions.moveFolderCompleted());
}

export function* moveEntities({ payload = {} }) {
  const { folderId, entityId, type = FOLDER_TYPES.PLAN } = payload;

  const data = { folderId, entityIds: [entityId] };

  const callResult = yield call(FoldersService.moveEntities, data);

  if (callResult.ok) {
    if (type === FOLDER_TYPES.PLAN) {
      yield put(MasterPlansActions.getById({ planId: entityId }));

      yield take(MasterPlansActions.getByIdCompleted.type);
    }
    // TODO: Manage the other types
  } else {
    const error = manageFoldersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(FoldersActions.moveEntitiesCompleted());
}

export function* loop() {
  yield all([
    takeLatest(FoldersActions.getList.type, getList),
    takeLatest(FoldersActions.createFolder.type, createFolder),
    takeLatest(FoldersActions.renameFolder.type, renameFolder),
    takeLatest(FoldersActions.deleteFolder.type, deleteFolder),
    takeLatest(FoldersActions.moveFolder.type, moveFolder),
    takeLatest(FoldersActions.moveEntities.type, moveEntities),
  ]);
}

export function* clean() {
  yield put(FoldersActions.resetToInitialState());
}
