import {
  call, put, all, takeLatest, take, select,
} from 'redux-saga/effects';
import _cloneDeep from 'lodash/cloneDeep';

import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets/slice';
import { manageError as manageDashboardPresetsError } from '@palette/state/DashboardPresets/errors';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { swapListItemsPositions } from '@palette/helpers/CommonHelper';
import { isDraft as planIsDraft, isInPast as planIsInPast } from '@palette/helpers/MasterPlanHelper';
import { isInPast as challengeIsInPast } from '@palette/helpers/ChallengeHelper';

import { DASHBOARD_PRESET_EVENTS } from '@palette/constants/analytics';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import * as DashboardPresetsService from '@palette/services/DashboardPresetsService';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';
import * as MetaUserModel from '@palette/models/MetaUser';
import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanStatsModel from '@palette/models/MasterPlanStats';
import * as ChallengeModel from '@palette/models/Challenge';
import * as ChallengeStatsModel from '@palette/models/ChallengeStats';

export function* getDashboardPresetsList() {
  const callResult = yield call(DashboardPresetsService.getDashboardPresetsList);

  if (callResult.ok) {
    const dashboardPresets = DashboardPresetModel.transformList(callResult.data);
    yield put(DashboardPresetsActions.setDashboardPresetsList({ dashboardPresets }));
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.getDashboardPresetsListCompleted());
}

export function* getById({ payload = {} }) {
  const { presetId } = payload;

  const callResult = yield call(DashboardPresetsService.getById, { presetId });

  if (callResult.ok) {
    const dashboardPreset = DashboardPresetModel.transform(callResult.data);
    yield put(DashboardPresetsActions.setById({ dashboardPreset }));
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.getByIdCompleted());
}

export function* updateDashboardPreset({ payload = {} }) {
  const {
    presetId,
    name = undefined,
    dashboardComponents = undefined,
  } = payload;

  if (name !== undefined) {
    yield put(DashboardPresetsActions.preUpdateName({ presetId, name }));
  }

  if (dashboardComponents !== undefined) {
    yield put(DashboardPresetsActions.preUpdateDashboardComponents({ presetId, dashboardComponents }));
  }

  const callResult = yield call(DashboardPresetsService.updateDashboardPreset, { presetId, name, dashboardComponents });

  if (callResult.ok) {
    yield put(DashboardPresetsActions.getById({ presetId }));

    yield take(DashboardPresetsActions.getByIdCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: DASHBOARD_PRESET_EVENTS.UPDATE_DASHBOARD_PRESET, params: { presetId } } });
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.updateDashboardPresetCompleted());
}

export function* createDashboardPreset({ payload = {} }) {
  const { onSuccessCB = null } = payload;

  const callResult = yield call(DashboardPresetsService.createDashboardPreset);

  if (callResult.ok) {
    if (onSuccessCB !== null) {
      const dashboardPreset = DashboardPresetModel.transform(callResult.data);

      onSuccessCB(dashboardPreset.id);
    }
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.createDashboardPresetCompleted());
}

export function* getPresetCandidateUsers() {
  const callResult = yield call(DashboardPresetsService.getPresetCandidateUsers);

  if (callResult.ok) {
    const candidateUsers = MetaUserModel.transformList(callResult.data);
    yield put(DashboardPresetsActions.setPresetCandidateUsers({ candidateUsers }));
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.getPresetCandidateUsersCompleted());
}

export function* getPresetDataForCandidateUser({ payload = {} }) {
  const {
    presetId,
    candidateUserId,
  } = payload;

  const callResult = yield call(DashboardPresetsService.getPresetDataForCandidateUser, { presetId, userId: candidateUserId });

  if (callResult.ok) {
    const presetData = DashboardComponentDataModel.transformList(callResult.data);
    yield put(DashboardPresetsActions.setPresetData({ presetData }));
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.getPresetDataForCandidateUserCompleted());
}

export function* moveWidget({ payload = {} }) {
  const {
    presetId,
    widgetIndex,
    toPositionIndex,
  } = payload;

  const dashboardPreset = yield select(DashboardPresetsSelectors.getDashboardPresetById, { presetId });

  const newComponents = swapListItemsPositions(dashboardPreset.dashboardComponents, widgetIndex, toPositionIndex);

  yield put(DashboardPresetsActions.updateDashboardPreset({ presetId, dashboardComponents: newComponents }));
  yield take(DashboardPresetsActions.updateDashboardPresetCompleted.type);

  yield put(DashboardPresetsActions.moveWidgetCompleted());
}

export function* removeWidget({ payload = {} }) {
  const {
    presetId,
    widgetIndex,
  } = payload;

  const dashboardPreset = yield select(DashboardPresetsSelectors.getDashboardPresetById, { presetId });

  const newComponents = _cloneDeep(dashboardPreset.dashboardComponents);
  newComponents.splice(widgetIndex, 1);

  yield put(DashboardPresetsActions.updateDashboardPreset({ presetId, dashboardComponents: newComponents }));
  yield take(DashboardPresetsActions.updateDashboardPresetCompleted.type);

  yield put(DashboardPresetsActions.removeWidgetCompleted());
}

export function* addWidget({ payload = {} }) {
  const {
    presetId,
    widgetType,
    params,
    onSuccessCB,
  } = payload;

  const dashboardPreset = yield select(DashboardPresetsSelectors.getDashboardPresetById, { presetId });

  const newComponents = _cloneDeep(dashboardPreset.dashboardComponents);
  newComponents.unshift({ type: widgetType, params });

  yield put(DashboardPresetsActions.updateDashboardPreset({ presetId, dashboardComponents: newComponents }));
  yield take(DashboardPresetsActions.updateDashboardPresetCompleted.type);

  yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PRESET_ADD_WIDGET_SUCCESS));

  onSuccessCB();

  yield put(DashboardPresetsActions.addWidgetCompleted());
}

export function* getPlanListForCandidateUser({ payload = {} }) {
  const {
    candidateUserId,
  } = payload;

  const candidateUser = yield select(DashboardPresetsSelectors.getPresetCandidateUser, { presetUserId: candidateUserId });

  const callResult = yield call(DashboardPresetsService.getPlanListForCandidateUser, { candidateUser });

  if (callResult.ok) {
    const plans = MasterPlanModel.transformList(callResult.data);
    const pastPlans = [];
    const activePlans = [];

    plans.forEach((plan) => {
      if (planIsInPast(plan)) {
        pastPlans.push(plan);
      } else if (!planIsDraft(plan)) {
        activePlans.push(plan);
      }
    });

    const stats = MasterPlanStatsModel.transform({
      past: pastPlans.length,
      active: activePlans.length,
      draft: 0,
      activeAndPast: pastPlans.length + activePlans.length,
      total: plans.length,
    });

    yield put(DashboardPresetsActions.setPlanListForCandidateUser({ activePlans, pastPlans, stats }));
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.getPlanListForCandidateUserCompleted());
}

export function* getChallengeListForCandidateUser({ payload = {} }) {
  const {
    candidateUserId,
  } = payload;

  const candidateUser = yield select(DashboardPresetsSelectors.getPresetCandidateUser, { presetUserId: candidateUserId });

  const callResult = yield call(DashboardPresetsService.getChallengeListForCandidateUser, { candidateUser });

  if (callResult.ok) {
    const challenges = ChallengeModel.transformList(callResult.data);
    const pastChallenges = [];
    const activeChallenges = [];

    challenges.forEach((challenge) => {
      if (challengeIsInPast(challenge)) {
        pastChallenges.push(challenge);
      } else {
        activeChallenges.push(challenge);
      }
    });

    const stats = ChallengeStatsModel.transform({
      past: pastChallenges.length,
      active: activeChallenges.length,
      activeAndPast: pastChallenges.length + activeChallenges.length,
      total: challenges.length,
    });

    yield put(DashboardPresetsActions.setChallengeListForCandidateUser({ activeChallenges, pastChallenges, stats }));
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.getChallengeListForCandidateUserCompleted());
}

export function* deleteById({ payload = {} }) {
  const { presetId, onSuccessCB } = payload;

  const callResult = yield call(DashboardPresetsService.deleteById, { presetId });

  if (callResult.ok) {
    onSuccessCB(presetId);
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.deleteByIdCompleted());
}

export function* applyDashboardPresetToUsers({ payload = {} }) {
  const { presetId, selectedUsers, onSuccessCB } = payload;

  const callResult = yield call(DashboardPresetsService.applyDashboardPresetToUsers, { presetId, selectedUsers });

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PRESET_APPLY_TO_USERS_SUCCESS));

    onSuccessCB(presetId);
  } else {
    const error = manageDashboardPresetsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardPresetsActions.applyDashboardPresetToUsersCompleted());
}

export function* loop() {
  yield all([
    takeLatest(DashboardPresetsActions.getDashboardPresetsList.type, getDashboardPresetsList),
    takeLatest(DashboardPresetsActions.getById.type, getById),
    takeLatest(DashboardPresetsActions.updateDashboardPreset.type, updateDashboardPreset),
    takeLatest(DashboardPresetsActions.createDashboardPreset.type, createDashboardPreset),
    takeLatest(DashboardPresetsActions.getPresetCandidateUsers.type, getPresetCandidateUsers),
    takeLatest(DashboardPresetsActions.getPresetDataForCandidateUser.type, getPresetDataForCandidateUser),
    takeLatest(DashboardPresetsActions.moveWidget.type, moveWidget),
    takeLatest(DashboardPresetsActions.removeWidget.type, removeWidget),
    takeLatest(DashboardPresetsActions.addWidget.type, addWidget),
    takeLatest(DashboardPresetsActions.getPlanListForCandidateUser.type, getPlanListForCandidateUser),
    takeLatest(DashboardPresetsActions.getChallengeListForCandidateUser.type, getChallengeListForCandidateUser),
    takeLatest(DashboardPresetsActions.deleteById.type, deleteById),
    takeLatest(DashboardPresetsActions.applyDashboardPresetToUsers.type, applyDashboardPresetToUsers),
  ]);
}

export function* clean() {
  yield put(DashboardPresetsActions.resetToInitialState());
}
