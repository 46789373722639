import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ListWidgetBase from '@palette/components/widgets/dashboard/ListWidgetBase/ListWidgetBase';
import ListChallengeItem from '@palette/components/widgets/dashboard/ListChallengeItem/ListChallengeItem';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import { LIST_TYPES } from '@palette/constants/challenges';

import routePaths from '@palette/config/routePaths';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as ListChallengesDataModel from '@palette/models/widgets/dashboard/ListChallengesData';

import styles from './ListChallengesWidget.less';

const classNames = bindClassNames.bind(styles);

const ListChallengesWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const challengesData = useMemo(() => (ListChallengesDataModel.transform(widgetData.data)), [widgetData]);

  const challengesNode = useMemo(() => {
    if (challengesData.challenges.length === 0) {
      return (
        <DefaultEmptyState className={styles.emptyState} />
      );
    }

    return challengesData.challenges.map((challenge, index) => (
      <ListChallengeItem key={`list_challenge_item_${index}`} challenge={challenge} readOnly={readOnly} enableLinks={enableLinks} />
    ));
  }, [challengesData, readOnly, enableLinks]);

  let title = t('common.listChallenges.title');

  if (challengesData.challenges.length > 0) {
    title += ` (${challengesData.challenges.length})`;
  }

  const secondTitleHandleClick = () => {
    redirectTo({
      path: routePaths.myChallenges,
      qsObject: { type: LIST_TYPES.PAST.toUpperCase() },
    });
  };

  return (
    <ListWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      title={title}
      secondTitle={t('common.listChallenges.secondTitle')}
      secondTitleClick={secondTitleHandleClick}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      <div className={styles.content}>
        {challengesNode}
      </div>
    </ListWidgetBase>
  );
};

ListChallengesWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

ListChallengesWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default ListChallengesWidget;
