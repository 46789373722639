import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'antd';
import { StarFilled } from '@ant-design/icons';

function ObjectFieldAutoComplete(props) {
  const {
    disabled,
    connectorType,
    objectType,
    topFields: tFields,
    fields,
    size,
    placeholder,
  } = props;
  const topFields = tFields[`${connectorType}_${objectType}`] || [];
  const fieldOptions = topFields
    .map((value) => ({ value, top: true }))
    .concat(
      fields
        .filter((f) => !topFields.includes(f))
        .sort()
        .map((value) => ({ value, top: false })),
    );
  return (
    <AutoComplete
      size={size}
      placeholder={placeholder}
      disabled={disabled}
      dropdownMatchSelectWidth={false}
      filterOption={(inputValue, option) => option.value.toLowerCase()
        .includes((inputValue || '').toLowerCase())}
    >
      {fieldOptions.map(({ value: f, top }) => (
        <AutoComplete.Option key={f} value={`${objectType}.${f}`}>
          <div style={{ textTransform: 'none', display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {`${objectType}.${f}`}
            </div>
            {top && (
              <div>
                <i style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.3)', marginLeft: '6px' }}>{' most used '}</i>
                &nbsp;
                <StarFilled style={{ color: '#1eb1b5', position: 'relative', top: 1 }} />
              </div>
            )}
          </div>
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
}

ObjectFieldAutoComplete.propTypes = {
  disabled: PropTypes.bool,
  connectorType: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  topFields: PropTypes.object.isRequired,
  size: PropTypes.string,
  placeholder: PropTypes.string,
};

ObjectFieldAutoComplete.defaultProps = {
  disabled: false,
  size: null,
  placeholder: null,
};

export default ObjectFieldAutoComplete;
