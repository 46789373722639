/* eslint-disable no-underscore-dangle */
import { myEval } from './my-eval';

const firstNameFields = ['firstname', 'first_name', 'fname', 'f_name', 'fullname', 'full_name', 'name'];
const lastNameFields = ['lastname', 'last_name', 'lname', 'l_name'];

const knownFields = (connector, object) => {
  if (connector.type === 'HUBSPOT') {
    if (object.type === 'User') {
      return {
        id: object.data.ownerId,
        firstName: object.data.firstName,
        lastName: object.data.lastName,
        email: object.data.email,
      };
    }
  } else if (connector.type === 'SALESFORCE') {
    if (object.type === 'User') {
      return {
        id: object.data.Id,
        firstName: object.data.FirstName,
        lastName: object.data.LastName,
        email: object.data.Email,
      };
    }
  } else if (connector.type === 'SELLSY') {
    if (object.type === 'User') {
      return {
        id: object.data.id,
        firstName: object.data.forename,
        lastName: object.data.name,
        email: object.data.email,
      };
    }
  } else if (connector.type === 'PIPEDRIVE') {
    if (object.type === 'User') {
      const [firstName, ...lastName] = (object.data.name || '').split(' ');
      return {
        id: object.data.id,
        firstName,
        lastName: lastName.join(' '),
        email: object.data.email,
      };
    }
  } else if (connector.type === 'SWILE_REDSHIFT') {
    if (object.type === 'User') {
      return {
        id: object.data.row_id,
        firstName: object.data.firstname,
        lastName: object.data.lastname,
        email: object.data.email,
      };
    }
  } else if (connector.type === 'QUICKBOOKS' || connector.type === 'QUICKBOOKS_SANDBOX') {
    if (object.type === 'Employee') {
      return {
        id: object.data.Id,
        firstName: object.data.GivenName,
        lastName: object.data.FamilyName,
        email: (object.data.PrimaryEmailAddr && object.data.PrimaryEmailAddr.Address) || undefined,
      };
    }
    if (object.type === 'Vendor') {
      return {
        id: object.data.Id,
        firstName: object.data.GivenName || (object.data.DisplayName || '').split(' ')[0],
        lastName: object.data.FamilyName || (object.data.DisplayName || '').split(' ').slice(1).join(' '),
        email: object.data.PrimaryEmailAddr?.Address,
      };
    }
  } else if (connector.type === 'OUTREACH') {
    if (object.type === 'User') {
      return {
        id: object.data.id,
        firstName: object.data.attributes.firstName,
        lastName: object.data.attributes.lastName,
        email: object.data.attributes.email,
      };
    }
  } else if (connector.type === 'CLOSE') {
    if (object.type === 'User') {
      return {
        id: object.data.id,
        firstName: object.data.first_name,
        lastName: object.data.last_name,
        email: object.data.email,
      };
    }
  } else if (connector.type === 'SAPLING') {
    if (object.type === 'User') {
      return {
        id: object.data.guid,
        firstName: object.data.first_name,
        lastName: object.data.last_name,
        email: object.data.company_email,
      };
    }
  } else if (connector.type === 'XERO') {
    if (object.type === 'User') {
      return {
        id: object.data.UserID,
        firstName: object.data.FirstName,
        lastName: object.data.LastName,
        email: object.data.EmailAddress,
      };
    }
  }
  return {};
};

// eslint-disable-next-line consistent-return
const getFromFormula = (connectorObject, object, field) => {
  if (connectorObject[`${field}Formula`]) {
    try {
      return myEval(connectorObject[`${field}Formula`], {
        [object.type]: object.data,
      });
    } catch (e) {
      // Do nothing
    }
  }
};

export default (connector, object) => {
  // 1. Try to get fields from know fields
  let {
    id,
    firstName,
    lastName,
    email,
  } = knownFields(connector, object);

  // 2. Use formula instead
  if (!connector.__typeToObject) {
    // eslint-disable-next-line no-param-reassign
    connector.__typeToObject = connector.objects.reduce((obj, o) => ({
      ...obj,
      [o.type]: o,
    }), {});
  }
  const connectorObject = connector.__typeToObject[object.type];
  if (connectorObject && !firstName) firstName = getFromFormula(connectorObject, object, 'firstName');
  if (connectorObject && !lastName) lastName = getFromFormula(connectorObject, object, 'lastName');
  if (connectorObject && !email) email = getFromFormula(connectorObject, object, 'email');

  // 3. Try some general/common/mainstream fallbacks
  if (typeof id === 'undefined') id = object.id;
  if (typeof email === 'undefined') email = object.data.email || '';
  if (!firstName) {
    firstName = '';
    const field = Object
      .keys(object.data)
      .find((key) => firstNameFields.includes(key.toLowerCase()));
    if (field) firstName = object.data[field] || '';
  }
  if (!lastName) {
    lastName = '';
    const field = Object
      .keys(object.data)
      .find((key) => lastNameFields.includes(key.toLowerCase()));
    if (field) lastName = object.data[field] || '';
  }

  return {
    id,
    firstName,
    lastName,
    email,
  };
};
