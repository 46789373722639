import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, Col, Divider, Form, Input, message, Row, Select, Steps,
  DatePicker,
} from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, FileTextOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';

import { selectors as ProfileSelectors } from '@palette/state/Profile';
import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import * as ProfileModel from '@palette/models/Profile';
import * as ConnectorModel from '@palette/models/Connector';

import PageTopTitle from '../../../components/pageTopTitle';
import FullScreenLoader from '../../../components/loader';
import { createChallenge } from '../../../services/admin/challenges';
import TrackingObjectForm from '../../../components/admin/trackingObjectForm';
import GroupByObjectForm from '../../../components/admin/groupByObjectForm';
import { sendEvent } from '../../../services/analytics';
import NoConnector from '../../../components/noConnector';

const { RangePicker } = DatePicker;
class AddChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      basicSettings: {
        globalScoreOrder: 'ASC',
        type: 'PUBLIC_LEADERBOARD',
      },
      tracking: { filterObject: JSON.stringify({ $and: [] }) },
      groupBy: {},
      loading: false,
    };
  }

  componentDidMount() {
    const { refreshConnectorsListIsNeeded, refreshConnectorsList } = this.props;
    if (refreshConnectorsListIsNeeded) {
      refreshConnectorsList();
    }
  }

  createChallenge = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      const { basicSettings: { customPeriod, ...basicSettings } } = this.state;
      createChallenge({
        ...basicSettings,
        beginAt: customPeriod[0].set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).toISOString(),
        endAt: customPeriod[1].set({
          hour: 23, minute: 59, second: 59, millisecond: 999,
        }).toISOString(),
        trackingObject: this.state.tracking,
        groupByObject: this.state.groupBy,
      })
        .then(({ data: challenge }) => {
          sendEvent('create challenge', { userId: this.props.profile.userData.id });
          this.props.history.push(`/challenges/${challenge._id}?tab=rewardRules`);
        })
        .catch((error) => {
          console.error(error);
          message.error('Error while creating challenge');
          this.setState({ loading: false });
        });
    });
  };

  onTracking = (tracking) => {
    sendEvent('create challenge step tracking', { userId: this.props.profile.userData.id });
    this.setState({
      step: 1,
      tracking,
    });
  };

  onGroupBy = (groupBy) => {
    sendEvent('create challenge step groupby', { userId: this.props.profile.userData.id });
    this.setState({
      step: 2,
      groupBy,
    });
  };

  onBasicSettings = (basicSettings) => {
    sendEvent('create challenge step basicsettings', { userId: this.props.profile.userData.id });
    this.setState({ basicSettings }, this.createChallenge);
  };

  renderStep1() {
    const { tracking } = this.state;
    return (
      <TrackingObjectForm
        initialValues={tracking}
        onFinish={this.onTracking}
        type="Challenge"
        footer={(
          <Row gutter={24}>
            <Col span={12} />
            <Col span={12}>
              <Button
                htmlType="submit"
                type="primary"
                block
              >
                Next
                <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        )}
      />
    );
  }

  renderStep2() {
    const { groupBy } = this.state;
    return (
      <GroupByObjectForm
        initialValues={groupBy}
        onFinish={this.onGroupBy}
        footer={(
          <Row gutter={24}>
            <Col span={12}>
              <Button block onClick={() => this.setState({ step: 0 })}>
                <ArrowLeftOutlined />
                Back
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType="submit"
                type="primary"
                block
              >
                Next
                <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        )}
      />
    );
  }

  renderStep3() {
    const { basicSettings } = this.state;
    return (
      <Form
        layout="vertical"
        initialValues={{
          ...basicSettings,
          currency: this.props.profile.userData.company.currency,
        }}
        onFinish={this.onBasicSettings}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{
            required: true,
            message: 'Please input challenge name!',
          }]}
        >
          <Input placeholder="Name of the challenge" />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={(
                <div>
                  <div>Global Score Formula</div>
                  <div className="grey" style={{ marginBottom: '4px', fontSize: '13px' }}>You can use AVG(scores), SUM(scores), MIN(scores)...</div>
                </div>
              )}
              name="globalScoreFormula"
              rules={[{
                required: true,
                message: 'Please input global score formula!',
              }]}
            >
              <Input placeholder="ex: AVG(scores)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={(
                <div>
                  <div>Global Score Order</div>
                  <div className="grey" style={{ marginBottom: '4px', fontSize: '13px' }}>Define the ranking order</div>
                </div>
              )}
              name="globalScoreOrder"
              rules={[{
                required: true,
                message: 'Please select global score order!',
              }]}
            >
              <Select
                dropdownMatchSelectWidth={false}
                placeholder="Select an order"
              >
                {['ASC', 'DESC'].map((value) => (
                  <Select.Option value={value} key={value}>
                    <span style={{ textTransform: 'capitalize' }}>
                      {value.toLowerCase().split('_').join(' ')}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Period"
              name="customPeriod"
              rules={[{
                required: true,
                message: 'Please input the period.',
              }]}
            >
              <RangePicker
                style={{ width: '100%' }}
                format="MMM Do YYYY"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Type"
              name="type"
              rules={[{
                required: true,
                message: 'Please select type!',
              }]}
            >
              <Select
                dropdownMatchSelectWidth={false}
                placeholder="Select a type"
              >
                {['PUBLIC_LEADERBOARD', 'ANONYMOUS_LEADERBOARD', 'INDIVIDUAL'].map((value) => (
                  <Select.Option value={value} key={value}>
                    <span style={{ textTransform: 'capitalize' }}>
                      {value.toLowerCase().split('_').join(' ')}
                    </span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {this.props.profile.userData.company.currencies.length > 1 && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Currency"
                name="currency"
              >
                <Select
                  dropdownMatchSelectWidth={false}
                  placeholder="Select a currency"
                >
                  {this.props.profile.userData.company.currencies.map((c) => (
                    <Select.Option value={c.code} key={c.code}>
                      {c.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col span={12}>
            <Button block onClick={() => this.setState({ step: 1 })}>
              <ArrowLeftOutlined />
              Back
            </Button>
          </Col>
          <Col span={12}>
            <Button
              htmlType="submit"
              type="primary"
              block
            >
              Create Challenge
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { step, loading } = this.state;
    const hasConnector = this.props.connectors.length > 0;
    return (
      <div className="div-wrapper">
        <div className="container">
          <div className="container-inner">
            <PageTopTitle
              title={(
                <>
                  <FileTextOutlined />
                  &nbsp;
                  &nbsp;
                  Create a challenge
                </>
              )}
              actions={(
                <Steps current={step} size="small">
                  <Steps.Step title="Tracking" />
                  <Steps.Step title="Users" />
                  <Steps.Step title="Basic Settings" />
                </Steps>
              )}
            />
            <Divider />
            {hasConnector ? (
              <div className="Plan_details_settings_wrapper">
                {step === 0 && this.renderStep1()}
                {step === 1 && this.renderStep2()}
                {step === 2 && this.renderStep3()}
              </div>
            ) : (
              <div>
                <NoConnector />
              </div>
            )}
            {loading && <FullScreenLoader />}
          </div>
        </div>
      </div>
    );
  }
}

AddChallenge.propTypes = {
  history: PropTypes.object.isRequired,
  profile: ProfileModel.propTypes.isRequired,
  connectors: PropTypes.arrayOf(ConnectorModel.propTypes).isRequired,
  refreshConnectorsListIsNeeded: PropTypes.bool.isRequired,
  refreshConnectorsList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
  connectors: ConnectorsSelectors.getConnectorsList(state),
  refreshConnectorsListIsNeeded: ConnectorsSelectors.refreshListIsNeeded(state),
});

const mapDispatchToProps = (dispatch) => ({
  refreshConnectorsList: () => dispatch(ConnectorsActions.refreshList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddChallenge));
