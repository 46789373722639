import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* list() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.challenges.list, undefined, getConfig()),
    [RIGHTS.ADMIN.CHALLENGES.VIEW],
  );
}

export function* listIC() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.challenges.list, undefined, getConfig()),
    [RIGHTS.IC.CHALLENGES],
  );
}

export function* getById(payload) {
  const { challengeId } = payload;

  const data = { challengeId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.challenges.getById, data, getConfig()),
    [RIGHTS.ADMIN.CHALLENGES.VIEW],
  );
}

export function* getICById(payload) {
  const { challengeId } = payload;

  const data = { challengeId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.challenges.getById, data, getConfig()),
    [RIGHTS.IC.CHALLENGES],
  );
}

export function* getChallengeListForUser(payload) {
  const { userId } = payload;

  const data = { userId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.challenges.listChallengesForUser, data, getConfig()),
    [RIGHTS.ADMIN.CHALLENGES.VIEW],
  );
}
