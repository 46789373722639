import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import FolderCloseFilled from '@palette/components/utils/Icons/FolderCloseFilled';
import FolderOpenFilled from '@palette/components/utils/Icons/FolderOpenFilled';
import CurvedLine from '@palette/components/utils/Icons/CurvedLine';

import * as FolderWithResourcesModel from '@palette/models/FolderWithResources';

import styles from './FolderTitle.less';

const classNames = bindClassNames.bind(styles);

const FolderTitle = ({
  className,
  folder,
  isOpen,
  fromRoot,
}) => {
  const isEmpty = useMemo(() => {
    if (folder.resources !== undefined) {
      return folder.children.length === 0 && folder.resources.length === 0;
    }

    return folder.children.length === 0;
  }, [folder]);

  let iconFolderNode = (
    <FolderCloseFilled
      className={classNames({
        iconFolder: true,
        empty: isEmpty,
      })}
    />
  );

  if (isOpen) {
    iconFolderNode = (
      <FolderOpenFilled
        className={classNames({
          iconFolder: true,
          empty: isEmpty,
        })}
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {
        !fromRoot && (
          <CurvedLine className={styles.curvedIcon} />
        )
      }
      {iconFolderNode}
      {folder.name}
    </div>
  );
};

FolderTitle.propTypes = {
  className: PropTypes.string,
  folder: FolderWithResourcesModel.propTypes,
  isOpen: PropTypes.bool,
  fromRoot: PropTypes.bool,
};

FolderTitle.defaultProps = {
  className: '',
  folder: null,
  isOpen: false,
  fromRoot: false,
};

export default FolderTitle;
