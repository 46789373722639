import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { DatePicker as AntDDatePicker } from 'antd';

import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';
import ChevronLeftLine from '@palette/components/utils/Icons/ChevronLeftLine';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

import { shortFormatWithShortYear } from '@palette/helpers/MomentHelper';

import styles from './DatePicker.less';

const classNames = bindClassNames.bind(styles);

const DatePicker = ({
  className,
  dropdownClassName,
  picker,
  size,
  isRange,
  ...otherProps
}) => {
  const { RangePicker } = AntDDatePicker;

  const defaultProps = {
    className: classNames({
      wrapper: true,
      big: size === 'big',
      [className]: className !== '',
    }),
    dropdownClassName: classNames({
      dropdown: true,
      [dropdownClassName]: dropdownClassName !== '',
    }),
    picker,
    suffixIcon: <ChevronDownLine width={24} height={24} />,
    prevIcon: <ChevronLeftLine width={24} height={24} />,
    nextIcon: <ChevronRightLine width={24} height={24} />,
    superPrevIcon: <ChevronLeftLine width={24} height={24} />,
    superNextIcon: <ChevronRightLine width={24} height={24} />,
    inputReadOnly: true,
    clearIcon: (<ClosePopupFilled />),
    ...otherProps,
  };

  if (isRange) {
    return (
      <RangePicker
        separator="-"
        format={shortFormatWithShortYear}
        {...defaultProps}
      />
    );
  }

  return <AntDDatePicker {...defaultProps} />;
};

DatePicker.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  picker: PropTypes.oneOf(['date', 'week', 'month', 'quarter', 'year']),
  size: PropTypes.oneOf(['default', 'big']),
  isRange: PropTypes.bool,
};

DatePicker.defaultProps = {
  className: '',
  dropdownClassName: '',
  picker: 'date',
  size: 'default',
  isRange: false,
};

export default DatePicker;
