export const LIST_TYPES = {
  PAST: 'past',
  ACTIVE: 'active',
};

export const CHALLENGE_TYPES = {
  PUBLIC_LEADERBOARD: 'PUBLIC_LEADERBOARD',
  ANONYMOUS_LEADERBOARD: 'ANONYMOUS_LEADERBOARD',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const REWARD_TYPES = {
  MONEY: 'MONEY',
  OTHER: 'OTHER',
};
