/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {
  Statistic, Spin,
  Table, Empty, Button, Dropdown, Menu,
} from 'antd';
import { LoadingOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getResourceColumns } from '@palette/helpers/ConnectorHelper';

import { selectors as ProfileSelectors } from '@palette/state/Profile';
import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import * as ProfileModel from '@palette/models/Profile';
import * as ConnectorModel from '@palette/models/Connector';

import {
  getCurrencyLabel, getRewardDisplay, getDisplayType,
  isCurrencyBeforeNumber, gcv,
} from '../../services/utils';
import { REWARDS_TYPE, CHALLENGE_TYPE } from '../../services/constants';
import '../../styles/challenges/challengeRanking.less';
import Avatar from '../avatar';

import ChallengeRankName from './challengeRankName';

class ChallengeRanking extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rankingdetailsLoading: null,
      rankingDetailsOpen: null,
      rankingDetails: {},
    };
  }

  componentDidMount() {
    const { refreshConnectorsListIsNeeded, refreshConnectorsList } = this.props;
    if (refreshConnectorsListIsNeeded) {
      refreshConnectorsList();
    }
  }

  getRankingDetails = (objectId) => {
    if (this.state.rankingDetailsOpen === objectId) {
      this.setState({
        rankingdetailsLoading: null,
        rankingDetailsOpen: null,
      });
    } else if (this.state.rankingDetails && this.state.rankingDetails[objectId]) {
      this.setState({
        rankingdetailsLoading: null,
        rankingDetailsOpen: objectId,
      });
    } else {
      this.setState({ rankingdetailsLoading: objectId }, () => {
        this.props.getChallengeRankingDetails(this.props.challenge._id, objectId)
          .then(({ data: details }) => {
            this.setState((prevState) => ({
              rankingdetailsLoading: null,
              rankingDetailsOpen: objectId,
              rankingDetails: {
                ...prevState.rankingDetails,
                [objectId]: details,
              },
            }));
          }).catch((e) => {
            console.error('Ranking details', objectId, e);
            this.setState({ rankingdetailsLoading: null });
          });
      });
    }
  };

  getTotalRewardsMoney = (rewards) => rewards
    .reduce((accu, reward) => (reward.type === REWARDS_TYPE.MONEY
      ? accu + reward.amount : accu),
    0);

  hasAtLeastOneMoneyReward = (rewards) => rewards
    .find((reward) => (reward.type === REWARDS_TYPE.MONEY));

  render() {
    const {
      loading, rankingdetailsLoading, rankingDetails, rankingDetailsOpen,
    } = this.state;
    const { removeUser, challenge } = this.props;
    const { currency } = challenge;
    const canManageUsers = hasAtLeastOneRight(this.props.profile, ['admin.challenges.gbo.manage']);

    return (
      <div className="ChallengeRanking_wrapper">
        <div className="ChallengeRanking_inner">

          <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
            {challenge.ranking.map((rank, index) => (
              <div key={rank.user ? rank.user._id : index}>
                <div
                  className={`ChallengeRanking_rank_container ${((rank.user && rankingDetailsOpen === rank.user._id)
                    || (rank.user && rankingdetailsLoading === rank.user._id))
                    ? 'ChallengeRanking_isOpen' : ''} ${(rank.isMe)
                    ? 'ChallengeRanking_Mine' : ''}`}
                >
                  <div
                    onClick={rank.user
                      ? () => this.getRankingDetails(rank.user._id)
                      : null}
                    className="ChallengeRanking_rank_wrapper"
                  >
                    <div className="ChallengeRanking_rank_inner">
                      <div className={`ChallengeRanking_color_wrapper ${challenge.type === CHALLENGE_TYPE.INDIVIDUAL ? '' : `Rank_${index}`}`}>
                        {challenge.type !== CHALLENGE_TYPE.INDIVIDUAL && (
                          <div className="ChallengeRanking_rank_number title bold">
                            {index + 1}
                          </div>
                        )}
                        <div className={`ChallengeRanking_avatar_name ${!rank.user ? 'Name_blur' : ''}`}>
                          <div>
                            <Avatar
                              size="small"
                              profile={{
                                ...(rank.user ? rank.user : {}),
                                ...rank,
                                connector: this.props.connectors.find((connector) => connector.id === challenge.groupByObject.connector._id),
                              }}
                            />
                          </div>
                          <ChallengeRankName rank={rank} challenge={challenge} />
                        </div>

                      </div>
                      <div style={{ flexGrow: '1' }} />
                      <div className="ChallengeRanking_numbers_wrapper">
                        {((!challenge.rewards || challenge.rewards.length === 0)
                          && (!challenge.rewardRules || challenge.rewardRules.length === 0))
                          ? null : ((challenge && rank.isMe) ? (
                            <div className="" style={{ minWidth: '120px', marginRight: '6px' }}>
                              <Statistic
                                title="Reward"
                                suffix={(this.hasAtLeastOneMoneyReward(challenge.rewards)
                                    && !isCurrencyBeforeNumber(currency))
                                  ? getCurrencyLabel(currency)
                                  : null}
                                prefix={(this.hasAtLeastOneMoneyReward(challenge.rewards)
                                    && isCurrencyBeforeNumber(currency))
                                  ? getCurrencyLabel(currency)
                                  : null}
                                value={this.hasAtLeastOneMoneyReward(challenge.rewards)
                                  ? this.getTotalRewardsMoney(challenge.rewards)
                                  : (challenge.rewards.find((r) => r.type === REWARDS_TYPE.OTHER)
                                    ? `${challenge.rewards.filter((r) => r.type === REWARDS_TYPE.OTHER).length} ${pluralize('reward', challenge.rewards.filter((r) => r.type === REWARDS_TYPE.OTHER).length)}`
                                    : '-')}
                              />
                              {challenge.rewards.find((r) => r.type === REWARDS_TYPE.OTHER)
                            && (
                              <div className="grey italic" style={{ fontSize: '12px' }}>
                                {this.hasAtLeastOneMoneyReward(challenge.rewards) ? '+ another reward' : 'Click to see details' }
                              </div>
                            )}
                            </div>
                          ) : (
                            <div className="" style={{ minWidth: '120px', marginRight: '6px' }}>
                              <Statistic
                                title="Reward"
                                suffix={(this.hasAtLeastOneMoneyReward(rank.rewards)
                                    && !isCurrencyBeforeNumber(currency))
                                  ? getCurrencyLabel(currency)
                                  : null}
                                prefix={(this.hasAtLeastOneMoneyReward(rank.rewards)
                                    && isCurrencyBeforeNumber(currency))
                                  ? getCurrencyLabel(currency)
                                  : null}
                                value={this.hasAtLeastOneMoneyReward(rank.rewards)
                                  ? rank.totalRewards
                                  : (rank.rewards.find((r) => r.type === REWARDS_TYPE.OTHER)
                                    ? `${rank.rewards.filter((r) => r.type === REWARDS_TYPE.OTHER).length} ${pluralize('reward', rank.rewards.filter((r) => r.type === REWARDS_TYPE.OTHER).length)}`
                                    : '-')}
                              />
                              {rank.rewards.find((r) => r.type === REWARDS_TYPE.OTHER)
                            && (
                              <div className="grey italic" style={{ fontSize: '12px' }}>
                                {this.hasAtLeastOneMoneyReward(rank.rewards) ? '+ another reward' : 'Click to see details' }
                              </div>
                            )}
                            </div>
                          )) }
                        <div className="" style={{ minWidth: '120px' }}>
                          <Statistic
                            title="Score"
                            value={(rank.score || rank.score === 0) ? rank.score : '-'}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div
                    className={`ChallengeRanking_details_container 
                  ${((rank.user && rankingDetailsOpen === rank.user._id)
                  || (rank.user && rankingdetailsLoading === rank.user._id))
                ? 'ChallengeRanking_details_container_open'
                : ''}`}
                  >
                    <div className="ChallengeRanking_details_wrapper">
                      {(rank.user
                        && rankingdetailsLoading === rank.user._id)
                        && (
                          <div className="center">
                            <LoadingOutlined style={{ fontSize: 24 }} />
                          </div>
                        )}
                      <div>

                        {rank.user && rankingDetails
                  && rankingDetails[rank.user._id]
                          && (
                            <div>
                              <div className="Ranking_details_reward_list">
                                {(rank.isMe === true) ? (
                                  (challenge.rewards || []).map((r, i) => (
                                    <Statistic
                                      key={`${r.type}_${r.amount}_${r.description}`}
                                      title={`Reward ${i + 1}`}
                                      value={getRewardDisplay(r, challenge.currency)}
                                      style={{ marginLeft: '4px', marginRight: '4px' }}
                                    />
                                  ))
                                )
                                  : (
                                    rank.rewards.map((r, i) => (
                                      <Statistic
                                        key={`${r.type}_${r.amount}_${r.description}`}
                                        title={`Reward ${i + 1}`}
                                        value={getRewardDisplay(r, challenge.currency)}
                                        style={{ marginLeft: '4px', marginRight: '4px' }}
                                      />
                                    ))
                                  )}
                              </div>
                              {(rank.user && rankingDetails[rank.user._id]
                                && rankingDetails[rank.user._id].length > 0)
                                ? (
                                  <Table
                                    rowKey="_id"
                                    size="small"
                                    dataSource={rankingDetails[rank.user._id]}
                                    pagination={false}
                                    scroll={{ y: 340 }}
                                  >
                                    <Table.Column
                                      title={`${pluralize(getDisplayType(challenge.trackingObject),
                                        rankingDetails[rank.user._id].length)} ${rankingDetails[rank.user._id].length > 0
                                        ? `(${rankingDetails[rank.user._id].length})`
                                        : ''}`}
                                      dataIndex="info"
                                      key="info"
                                      render={(i, element) => (
                                        <div>
                                          {getResourceColumns(
                                            this.props.connectors.find((connectorItem) => connectorItem.id === element.connector) || null,
                                            element.type,
                                            (column) => column.displayInPlans,
                                          ).map((column) => (
                                            <div key={column.name}>
                                              <span style={{ color: 'grey' }}>
                                                {column.name}
                                                :
                                              </span>
                                      &nbsp;
                                              <span>{gcv(element.data, column)}</span>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    />
                                    <Table.Column
                                      width={200}
                                      title="Score"
                                      dataIndex="score"
                                      key="score"
                                    />
                                  </Table>
                                ) : <Empty description="Nothing to show here yet" style={{ paddingBottom: '20px' }} />}
                              {removeUser && canManageUsers && (
                                <div className="center" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                  <Dropdown
                                    trigger="click"
                                    overlay={(
                                      <Menu>
                                        <Menu.Item
                                          key="remove"
                                          icon={<DeleteOutlined />}
                                          onClick={() => removeUser(rank.user._id)}
                                          danger
                                        >
                                          Remove this user from the challenge
                                        </Menu.Item>
                                      </Menu>
                                    )}
                                  >
                                    <Button type="link">
                                      Options
                                      {' '}
                                      <DownOutlined />
                                    </Button>
                                  </Dropdown>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Spin>

        </div>
      </div>
    );
  }
}

ChallengeRanking.propTypes = {
  challenge: PropTypes.object.isRequired,
  getChallengeRankingDetails: PropTypes.func.isRequired,
  removeUser: PropTypes.func,
  profile: ProfileModel.propTypes.isRequired,
  connectors: PropTypes.arrayOf(ConnectorModel.propTypes).isRequired,
  refreshConnectorsListIsNeeded: PropTypes.bool.isRequired,
  refreshConnectorsList: PropTypes.func.isRequired,
};

ChallengeRanking.defaultProps = {
  removeUser: null,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
  connectors: ConnectorsSelectors.getConnectorsList(state),
  refreshConnectorsListIsNeeded: ConnectorsSelectors.refreshListIsNeeded(state),
});

const mapDispatchToProps = (dispatch) => ({
  refreshConnectorsList: () => dispatch(ConnectorsActions.refreshList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeRanking);
