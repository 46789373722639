export const RIGHTS = {
  ADMIN: {
    ADJUSTMENTS: {
      MANAGE: 'admin.adjustments.manage',
    },
    CHALLENGES: {
      CREATE: 'admin.challenges.create',
      DELETE: 'admin.challenges.delete',
      DISPLAY: 'admin.challenges.display',
      UPDATE: 'admin.challenges.update',
      UPDATE_CONNECTOR: 'admin.challenges.update_connector',
      UPDATE_NOTIFICATIONS: 'admin.challenges.update_notifications',
      VIEW: 'admin.challenges.view',
      GBO: {
        MANAGE: 'admin.challenges.gbo.manage',
      },
    },
    COMMISSIONS: {
      MANAGE: 'admin.commissions.manage',
    },
    COMPANY: {
      MANAGE: 'admin.company.manage',
    },
    CONNECTORS: {
      CREATE: 'admin.connectors.create',
      DISPLAY: 'admin.connectors.display',
      MANAGE: 'admin.connectors.manage',
      VIEW: 'admin.connectors.view',
    },
    NOTEBOOKS: {
      DISPLAY: 'admin.notebooks.display',
      MANAGE: 'admin.notebooks.manage',
      VIEW: 'admin.notebooks.view',
    },
    OBJECTS: {
      CSV_UPLOAD: 'admin.objects.csv_upload',
      DISPLAY: 'admin.objects.display',
      MANAGE: 'admin.objects.manage',
      VIEW: 'admin.objects.view',
    },
    PLANS: {
      CREATE: 'admin.plans.create',
      DELETE: 'admin.plans.delete',
      DISPLAY: 'admin.plans.display',
      UPDATE: 'admin.plans.update',
      UPDATE_CONNECTOR: 'admin.plans.update_connector',
      VIEW: 'admin.plans.view',
      GBO: {
        MANAGE: 'admin.plans.gbo.manage',
      },
    },
    QUOTAS: {
      DISPLAY: 'admin.quotas.display',
      MANAGE: 'admin.quotas.manage',
      VIEW: 'admin.quotas.view',
      USERS: {
        MANAGE: 'admin.quotas.users.manage',
      },
    },
    ROLES: {
      DISPLAY: 'admin.roles.display',
      VIEW: 'admin.roles.view',
      MANAGE: 'admin.roles.manage',
    },
    STATEMENTS: {
      VALIDATIONS: {
        MANAGE: 'admin.statements.validations.manage',
      },
    },
    TEAMS: {
      VIEW_ALL: 'admin.teams.view_all',
      VIEW_SCOPED: 'admin.teams.view_scoped',
      MANAGE: 'admin.teams.manage',
      MEMBERS: {
        MANAGE: 'admin.teams.members.manage',
      },
    },
    USERS: {
      DISPLAY: 'admin.users.display',
      INVITE: 'admin.users.invite',
      TOGGLE_ACTIVATE: 'admin.users.toggle-activate',
      UPDATE: 'admin.users.update',
      VIEW: 'admin.users.view',
    },
    WILDCARD: 'admin.*',
  },
  IC: {
    CHALLENGES: 'ic.challenges',
    COMPENSATION: 'ic.compensation',
    DASHBOARD: {
      UPDATE: 'ic.dashboard.update',
    },
  },
};

export const FEATURES = {
  CHALLENGE: 'challenge',
  COMPENSATION: 'compensation',
  STATEMENT_VALIDATION: 'statementValidation',
  STATEMENT_VALIDATION_NOTIFICATIONS: 'statementValidationNotifications',
  DIGEST_NOTIFICATIONS: 'digestNotifications',
  NOTEBOOKS: 'notebooks',
};

export const FEATURES_ASSOCIATED_RIGHTS = {
  [FEATURES.CHALLENGE]: [
    RIGHTS.ADMIN.CHALLENGES.CREATE,
    RIGHTS.ADMIN.CHALLENGES.DELETE,
    RIGHTS.ADMIN.CHALLENGES.DISPLAY,
    RIGHTS.ADMIN.CHALLENGES.UPDATE,
    RIGHTS.ADMIN.CHALLENGES.UPDATE_CONNECTOR,
    RIGHTS.ADMIN.CHALLENGES.UPDATE_NOTIFICATIONS,
    RIGHTS.ADMIN.CHALLENGES.VIEW,
    RIGHTS.ADMIN.CHALLENGES.GBO.MANAGE,
    RIGHTS.IC.CHALLENGES,
  ],
  [FEATURES.COMPENSATION]: [
    RIGHTS.ADMIN.ADJUSTMENTS.MANAGE,
    RIGHTS.ADMIN.COMMISSIONS.MANAGE,
    RIGHTS.ADMIN.PLANS.CREATE,
    RIGHTS.ADMIN.PLANS.DELETE,
    RIGHTS.ADMIN.PLANS.DISPLAY,
    RIGHTS.ADMIN.PLANS.UPDATE,
    RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR,
    RIGHTS.ADMIN.PLANS.VIEW,
    RIGHTS.ADMIN.PLANS.GBO.MANAGE,
    RIGHTS.IC.COMPENSATION,
  ],
  [FEATURES.STATEMENT_VALIDATION]: [
    RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE,
  ],
};

export const ROLE_MANAGEMENT_EXCLUDED_RIGHTS = [
  RIGHTS.ADMIN.WILDCARD,
  RIGHTS.ADMIN.TEAMS.VIEW_ALL,
  RIGHTS.ADMIN.TEAMS.VIEW_SCOPED,
];

export const ROLE_MANAGEMENT_ADMIN_SECTIONS = [
  {
    i18nTitle: 'roleManagement.sections.admin.commissions',
    rights: [
      RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE,
      RIGHTS.ADMIN.COMMISSIONS.MANAGE,
      RIGHTS.ADMIN.ADJUSTMENTS.MANAGE,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.plans',
    rights: [
      RIGHTS.ADMIN.PLANS.DISPLAY,
      RIGHTS.ADMIN.PLANS.CREATE,
      RIGHTS.ADMIN.PLANS.DELETE,
      RIGHTS.ADMIN.PLANS.GBO.MANAGE,
      RIGHTS.ADMIN.PLANS.VIEW,
      RIGHTS.ADMIN.PLANS.UPDATE,
      RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.challenges',
    rights: [
      RIGHTS.ADMIN.CHALLENGES.DISPLAY,
      RIGHTS.ADMIN.CHALLENGES.CREATE,
      RIGHTS.ADMIN.CHALLENGES.DELETE,
      RIGHTS.ADMIN.CHALLENGES.GBO.MANAGE,
      RIGHTS.ADMIN.CHALLENGES.VIEW,
      RIGHTS.ADMIN.CHALLENGES.UPDATE,
      RIGHTS.ADMIN.CHALLENGES.UPDATE_CONNECTOR,
      RIGHTS.ADMIN.CHALLENGES.UPDATE_NOTIFICATIONS,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.quotas',
    rights: [
      RIGHTS.ADMIN.QUOTAS.DISPLAY,
      RIGHTS.ADMIN.QUOTAS.MANAGE,
      RIGHTS.ADMIN.QUOTAS.USERS.MANAGE,
      RIGHTS.ADMIN.QUOTAS.VIEW,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.users',
    rights: [
      RIGHTS.ADMIN.USERS.DISPLAY,
      RIGHTS.ADMIN.USERS.INVITE,
      RIGHTS.ADMIN.USERS.TOGGLE_ACTIVATE,
      RIGHTS.ADMIN.USERS.VIEW,
      RIGHTS.ADMIN.USERS.UPDATE,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.teams',
    rights: [
      RIGHTS.ADMIN.TEAMS.MANAGE,
      RIGHTS.ADMIN.TEAMS.MEMBERS.MANAGE,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.roles',
    rights: [
      RIGHTS.ADMIN.ROLES.DISPLAY,
      RIGHTS.ADMIN.ROLES.MANAGE,
      RIGHTS.ADMIN.ROLES.VIEW,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.objects',
    rights: [
      RIGHTS.ADMIN.OBJECTS.DISPLAY,
      RIGHTS.ADMIN.OBJECTS.CSV_UPLOAD,
      RIGHTS.ADMIN.OBJECTS.MANAGE,
      RIGHTS.ADMIN.OBJECTS.VIEW,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.notebooks',
    rights: [
      RIGHTS.ADMIN.NOTEBOOKS.DISPLAY,
      RIGHTS.ADMIN.NOTEBOOKS.MANAGE,
      RIGHTS.ADMIN.NOTEBOOKS.VIEW,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.connectors',
    rights: [
      RIGHTS.ADMIN.CONNECTORS.DISPLAY,
      RIGHTS.ADMIN.CONNECTORS.CREATE,
      RIGHTS.ADMIN.CONNECTORS.VIEW,
      RIGHTS.ADMIN.CONNECTORS.MANAGE,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.admin.company',
    rights: [
      RIGHTS.ADMIN.COMPANY.MANAGE,
    ],
  },
];

export const ROLE_MANAGEMENT_IC_SECTIONS = [
  {
    i18nTitle: 'roleManagement.sections.ic.dashboard',
    rights: [
      RIGHTS.IC.DASHBOARD.UPDATE,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.ic.plans',
    rights: [
      RIGHTS.IC.COMPENSATION,
    ],
  },
  {
    i18nTitle: 'roleManagement.sections.ic.challenges',
    rights: [
      RIGHTS.IC.CHALLENGES,
    ],
  },
];

export const ROLE_SCOPE_RIGHTS_SELECT_OPTIONS = [
  {
    i18nLabel: 'roleManagement.rights.scope.options.labels.none',
    value: null,
  },
  {
    i18nLabel: 'roleManagement.rights.scope.options.labels.view_scoped',
    value: RIGHTS.ADMIN.TEAMS.VIEW_SCOPED,
  },
  {
    i18nLabel: 'roleManagement.rights.scope.options.labels.view_all',
    value: RIGHTS.ADMIN.TEAMS.VIEW_ALL,
  },
];

export const EMAIL_PREFERENCES = {
  WEEKLY_DIGEST: 'weeklyDigest',
  MONTHLY_DIGEST: 'monthlyDigest',
  THREAD_NOTIFICATIONS: 'threadNotifications',
  STATEMENT_VALIDATION_NOTIFICATIONS: 'statementValidationNotifications',
};

export const STATUSES = {
  CONNECTED: 'CONNECTED',
  PENDING: 'PENDING',
  UNKNOWN: 'UNKNOWN',
};

export const WHITELISTED_COMPANIES = {
  PALETTE_DEV_COMPANY: '5faadaa4ecebaf7acea5d5af',
  PALETTE_DEMO_COMPANY: '5fc79c5dd05fb74fe8c1b4a7',
};

export const STATEMENT_FX_RATES_MODE = {
  FROM_PAYMENT_DATE: 'FROM_PAYMENT_DATE',
  FROM_COMMISSION_DATE: 'FROM_COMMISSION_DATE',
};
