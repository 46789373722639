import Moment from 'moment';
import _ from 'lodash';

function sortAsc(a, b) {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
}

function sortDesc(a, b) {
  if (a > b) return -1;
  if (a < b) return 1;
  return 0;
}

const MAP = (array, field, defaultValue = 0) => array.map((item) => (typeof item[field] !== 'undefined' ? item[field] : defaultValue));
const SUM = (scores) => (scores.length > 0 ? scores.reduce((t, s) => t + +s, 0) : null);
const COUNT = (scores) => scores.length;
const MIN = (scores) => (scores.length > 0 ? [...scores].sort(sortAsc)[0] : null);
const MAX = (scores) => (scores.length > 0 ? [...scores].sort(sortDesc)[0] : null);
const AVG = (scores) => (scores.length > 0 ? SUM(scores) / scores.length : null);
const IF = (condition, valA, valB) => (condition ? valA : valB);
const GET = (object, path, defaultValue) => {
  const value = _.get(object, path);
  return typeof value === 'undefined' ? defaultValue : value;
};
const NUMBER_WITH_COMMAS = (x, toFixed) => {
  const number = +x;
  if (Number.isNaN(number)) return '';
  const str = `${typeof toFixed !== 'undefined' ? number.toFixed(toFixed) : number}`;
  const [int, float] = str.split('.');
  return int.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (float ? `.${float}` : '');
};

const SEPARATORS = [' ', '-', '\''];

export function capitalize(s, separators = SEPARATORS) {
  const string = (s || '');
  if (separators.length === 0) {
    return `${(string[0] || '').toUpperCase()}${string.substr(1).toLowerCase()}`;
  }
  const [separator, ...seps] = separators;
  return string
    .split(separator)
    .map((str) => capitalize(str, seps))
    .join(separator);
}

/* eslint-disable no-new-func */
export function myEval(expression, s, failedValue) {
  const scope = {
    ...s,
    moment: Moment,
    MAP,
    SUM,
    AVG,
    MIN,
    MAX,
    COUNT,
    IF,
    GET,
    CAPITALIZE: capitalize,
    NUMBER_WITH_COMMAS,
  };
  if (typeof failedValue !== 'undefined') {
    try {
      return Function.apply(null, [...Object.keys(scope), 'expr', 'return eval(expr)']).apply(null, [...Object.values(scope), expression]);
    } catch (error) {
      return failedValue;
    }
  }
  return Function.apply(null, [...Object.keys(scope), 'expr', 'return eval(expr)']).apply(null, [...Object.values(scope), expression]);
}
/* eslint-enable no-new-func */
