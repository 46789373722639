import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import { formatBeginEndDate, getMoment } from '@palette/helpers/MomentHelper';
import { getRewardsTotal, isUpcoming } from '@palette/helpers/ChallengeHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';

import routePaths from '@palette/config/routePaths';

import * as ChallengeModel from '@palette/models/Challenge';

import styles from './ChallengeListItemForUser.less';

const classNames = bindClassNames.bind(styles);

const ChallengeListItemForUser = ({ className, challenge }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const nameNode = useMemo(() => (
    <Link
      className={styles.name}
      maxWidth="20"
      displayIcon
      path={routePaths.challengeDetails}
      params={{ challengeId: challenge.id }}
    >
      {challenge.name}
    </Link>
  ), [challenge]);

  const datesNode = useMemo(() => {
    const beginAtMoment = moment.utc(challenge.beginAt);
    const endAtMoment = moment.utc(challenge.endAt);

    const challengeDates = formatBeginEndDate(beginAtMoment, endAtMoment, false, true);

    return (
      <div className={styles.dates}>
        {challengeDates}
      </div>
    );
  }, [challenge]);

  const rewardsNode = useMemo(() => {
    const totalRewards = getRewardsTotal(challenge);

    const totalRewardsValue = totalRewards === 0 ? '-' : formatPrice(totalRewards, challenge.currency);

    return (
      <div className={styles.result}>
        <div className={styles.label}>
          {t('challengeListItemForUser.rewards')}
        </div>
        <div className={styles.value}>
          {totalRewardsValue}
        </div>
      </div>
    );
  }, [challenge]);

  const rankNode = useMemo(() => (
    <div className={styles.result}>
      <div className={styles.label}>
        {t('challengeListItemForUser.rank')}
      </div>
      <div className={styles.value}>
        {challenge.rankingIndex + 1}
      </div>
    </div>
  ), [challenge]);

  const resultsNode = useMemo(() => {
    if (isUpcoming(challenge)) return null;

    return (
      <div className={styles.resultsWrapper}>
        {rewardsNode}
        {rankNode}
      </div>
    );
  }, [challenge, rewardsNode, rankNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.nameDatesWrapper}>
        {nameNode}
        {datesNode}
      </div>
      {resultsNode}
    </div>
  );
};

ChallengeListItemForUser.propTypes = {
  className: PropTypes.string,
  challenge: ChallengeModel.propTypes.isRequired,
};

ChallengeListItemForUser.defaultProps = {
  className: '',
};

export default ChallengeListItemForUser;
