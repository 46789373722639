import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChallengeWidgetBase from '@palette/components/widgets/dashboard/ChallengeWidgetBase/ChallengeWidgetBase';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getRewardsTotal } from '@palette/helpers/ChallengeHelper';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as ChallengeModel from '@palette/models/Challenge';

import styles from './ChallengeSimpleDisplayWidget.less';

const classNames = bindClassNames.bind(styles);

const ChallengeSimpleDisplayWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const challenge = useMemo(() => (ChallengeModel.transform(widgetData.data)), [widgetData]);
  const rewardsTotal = useMemo(() => getRewardsTotal(challenge), [challenge]);

  return (
    <ChallengeWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      autoRenew={widgetData.dashboardComponent.params.useMostRecent}
      challenge={challenge}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      <div className={styles.content}>
        <div className={styles.valueArea}>
          <div className={styles.title}>
            {t('challengeSimpleDisplayWidget.rewards')}
          </div>
          <div className={styles.value}>
            {rewardsTotal > 0 ? formatPrice(rewardsTotal, challenge.currency) : '-'}
          </div>
        </div>
        <div className={styles.valueArea}>
          <div className={styles.title}>
            {t('challengeSimpleDisplayWidget.rank')}
          </div>
          <div className={styles.value}>
            {challenge.rankingIndex + 1}
          </div>
        </div>
      </div>
    </ChallengeWidgetBase>
  );
};

ChallengeSimpleDisplayWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

ChallengeSimpleDisplayWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default ChallengeSimpleDisplayWidget;
