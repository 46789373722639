import React, { Component } from 'react';
import { LoadingOutlined, TrophyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  Spin, Tabs,
} from 'antd';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import { CHALLENGE_TYPE, REWARDS_TYPE } from '../../../services/constants';
import { parseSearch } from '../../../services/utils';
import { getChallengeDetails, getChallengeRankingDetails } from '../../../services/ic';
import ChallengeRanking from '../../../components/challenges/challengeRanking';
import RewardRulesDisplay from '../../../components/admin/challenges/rewardRulesDisplay';

import BackLink from '../../../components/backLink';

import '../../../styles/challenges/my-challenge-details.less';

const { TabPane } = Tabs;
class MyChallengeDetails extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      loading: false,
      challenge: null,
    };
  }

  componentDidMount() {
    this.getChallengeDetails();
  }

  getChallengeDetails = () => {
    this.setState({ loading: true }, () => {
      getChallengeDetails({ challengeId: this.props.match.params.challengeId }).then(({
        data: challenge,
      }) => this.setState({
        challenge: {
          ...challenge,
          ranking: challenge.ranking.reduce((acc, element) => [...acc, {
            ...element,
            totalRewards: element.rewards
              .reduce((accu, reward) => (reward.type === REWARDS_TYPE.MONEY
                ? accu + reward.amount : accu),
              0),
          }], []),
        },
        loading: false,
      })).catch((err) => {
        console.error('loading challenge', err);
        this.setState({ loading: false });
      });
    });
  };

  onTabChange = (key) => {
    this.props.history.push(`/my-challenges/${this.props.match.params.challengeId}?tab=${key}`);
  };

  render() {
    const { loading, challenge } = this.state;
    const { tab } = parseSearch(this.props.location.search);

    return (
      <div>
        <div className="MyChallenges_details_top_design_container">

          <div className="MyChallenges_details_top_design_wrapper">
            <div className="MyChallenges_details_top_design_left" />
            <div className="MyChallenges_details_top_design_right" />
            <div className="MyChallenges_details_top_design_inner">
              <div className="center width-100 MyChallenges_details_top_design_inner_content">
                <div style={{
                  width: '880px',
                  maxWidth: '100%',
                  display: 'flex',
                }}
                >
                  <br />
                  <BackLink defaultUrl="/my-challenges" style={{ color: 'white' }} />
                </div>
                <h1 className="no-margin bold white">
                  {challenge ? challenge.name : ''}
                </h1>
                {challenge && (
                  <div>
                    <div className="">
                      {`${Moment(challenge.beginAt).format('MMM Do, YYYY')} - ${Moment(challenge.endAt).format('MMM Do, YYYY')}`}
                    </div>
                    <div className="">

                      {`${CHALLENGE_TYPE.INDIVIDUAL === challenge.type
                        ? 'Individual challenge'
                        : `${challenge.ranking.length} participants`}`}
                    </div>
                  </div>

                )}
              </div>
              <TrophyOutlined className="MyChallenges_details_top_design_icon" />
            </div>
          </div>
        </div>
        <div className="div-wrapper Challenges_wrapper">
          <div className="container">
            <div className="container-inner">

              <Spin
                tip="Loading..."
                spinning={loading}
                indicator={<LoadingOutlined style={{ fontSize: 24, marginTop: '12px' }} spin />}
              >
                <div className="Challenge_details_content_wrapper">
                  <br />
                  <br />
                  <div>
                    <Tabs
                      centered
                      activeKey={tab || 'ranking'}
                      onChange={this.onTabChange}
                    >
                      <TabPane tab={(challenge && challenge.type === CHALLENGE_TYPE.INDIVIDUAL) ? 'Performance' : 'Ranking'} key="ranking">
                        {challenge && (
                          <div>
                            <ChallengeRanking
                              challenge={challenge}
                              getChallengeRankingDetails={getChallengeRankingDetails}
                            />
                          </div>
                        )}
                      </TabPane>
                      <TabPane tab="Details" key="details">

                        {challenge && (
                          <div className="Challenge_settings_steps_wrapper">
                            <h2 className="bold">Formulas used in the Challenge</h2>
                            <div>
                              {'Global score formula: '}
                              <b>{`${challenge.globalScoreFormula}, ${challenge.globalScoreOrder}`}</b>
                            </div>
                            <div>
                              {'Score formula: '}
                              <b>{challenge.trackingObject.scoreFormula}</b>
                            </div>
                            <br />
                            <br />
                            <h2 className="bold">List of the rewards available</h2>
                            <div className="Challenge_settings_steps_inner">
                              <RewardRulesDisplay
                                rules={challenge.rewardRules}
                                challenge={challenge}
                              />
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MyChallengeDetails.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(MyChallengeDetails);
