/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Statistic, Divider, Tag } from 'antd';
import Moment from 'moment';
import { TrophyOutlined } from '@ant-design/icons';
import routePaths from '@palette/config/routePaths';
import { getCurrencyLabel, isCurrencyBeforeNumber, getChallengeTypeText } from '../../services/utils';
import { CHALLENGE_TYPE, REWARDS_TYPE } from '../../services/constants';
import '../../styles/challenges/challengeVignette.less';

const IndividualChallengeVignette = ({
  challenge, isAdmin, small, adminView,
}) => {
  const totalReward = challenge.rewards
    .reduce((acc, element) => (element.type === REWARDS_TYPE.MONEY
      ? acc + element.amount : acc), 0);
  return (
    <div className={`ChallengeVignette_wrapper ${small ? 'ChallengeVignette_wrapper_small' : ''} ${challenge.type} ${challenge.live ? '' : 'Challenge_vignette_wrapper_draft'}`}>
      <div className="ChallengeVignette_inner">
        <div style={{
          position: 'relative',
          zIndex: '1',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
        >
          <h2
            className="ChallengeVignette_title bold no-margin"
            style={{ zIndex: '2' }}
          >
            <a href={adminView
              ? null
              : (isAdmin ? `/#${routePaths.challengeDetails.replace(':challengeId', challenge._id)}` : `/#${routePaths.myChallengeDetails.replace(':challengeId', challenge._id)}`)}
            >
              {challenge.name}
            </a>
          </h2>

          <div
            className="grey"
          >
            {`${Moment(challenge.beginAt).format('MMM Do, YYYY')} - ${Moment(challenge.endAt).format('MMM Do, YYYY')}`}
          </div>
          <div
            className="grey"
          >
            {getChallengeTypeText(challenge.type)}
            {!challenge.live && <Tag color="black" style={{ marginLeft: 8 }}>DRAFT</Tag>}
          </div>
          <Divider />
          <div className="ChallengeVignette_stats center">
            <Statistic
              title="Rewards"
              value={totalReward}
              prefix={isCurrencyBeforeNumber(challenge.currency)
                ? getCurrencyLabel(challenge.currency) : null}
              suffix={isCurrencyBeforeNumber(challenge.currency)
                ? null : getCurrencyLabel(challenge.currency)}
            />
            {challenge.type !== CHALLENGE_TYPE.INDIVIDUAL && (
              <Statistic
                title="Rank"
                value={challenge.rankingIndex + 1}
              />
            )}
          </div>

          {challenge.type !== CHALLENGE_TYPE.INDIVIDUAL
     && challenge.rankingIndex < 3
     && (
       <div className={`Individual_challenge_vignette_trophy Individual_trophy_${challenge.rankingIndex}`}>
         <TrophyOutlined />
       </div>
     )}

        </div>
      </div>
    </div>
  );
};

IndividualChallengeVignette.propTypes = {
  challenge: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  small: PropTypes.bool,
  adminView: PropTypes.bool,
};
IndividualChallengeVignette.defaultProps = {
  isAdmin: false,
  adminView: false,
  small: false,
};

export default IndividualChallengeVignette;
