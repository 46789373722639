import Analytics from '@palette/state/Analytics';
import AuditTrail from '@palette/state/AuditTrail';
import Challenges from '@palette/state/Challenges';
import Commissions from '@palette/state/Commissions';
import Company from '@palette/state/Company';
import Connectors from '@palette/state/Connectors';
import Dashboard from '@palette/state/Dashboard';
import DashboardPresets from '@palette/state/DashboardPresets';
import Folders from '@palette/state/Folders';
import GlobalNotif from '@palette/state/GlobalNotif';
import MasterPlans from '@palette/state/MasterPlans';
import Navigation from '@palette/state/Navigation';
import Notebooks from '@palette/state/Notebooks';
import Profile from '@palette/state/Profile';
import Quotas from '@palette/state/Quotas';
import QuotasPerformance from '@palette/state/QuotasPerformance';
import Resources from '@palette/state/Resources';
import Statements from '@palette/state/Statements';
import StatementsHistory from '@palette/state/StatementsHistory';
import Teams from '@palette/state/Teams';
import Threads from '@palette/state/Threads';
import Users from '@palette/state/Users';
import UsersVariables from '@palette/state/UsersVariables';

export const entities = [
  Analytics,
  AuditTrail,
  Challenges,
  Commissions,
  Company,
  Connectors,
  Dashboard,
  DashboardPresets,
  Folders,
  GlobalNotif,
  MasterPlans,
  Navigation,
  Notebooks,
  Profile,
  Quotas,
  QuotasPerformance,
  Resources,
  Statements,
  StatementsHistory,
  Teams,
  Threads,
  Users,
  UsersVariables,
];
