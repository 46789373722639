/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { BranchesOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Collapse, Divider, Input, Tooltip,
} from 'antd';
import pluralize from 'pluralize';
import React from 'react';
import ReactDragListView from 'react-drag-listview';
import PropTypes from 'prop-types';
import AutoCompleteInput from '../autoCompleteInput';
import '../../styles/admin/variables.less';

const { Panel } = Collapse;

const Variables = ({
  variables,
  onDragEnd,
  options,
  triggers,
  onVariableFieldChange,
  deleteVariableStep,
  setVariableCond,
  deleteVariable,
  onVariableStepFieldChangeOption,
  activeKeysVariables,
  onChangeActiveKey,
  addVariableCondition,
  displayType,
  addonBefore,
}) => (
  <ReactDragListView
    lineClassName="Drag_line"
    onDragEnd={(fromIndex, toIndex) => {
      onDragEnd(fromIndex, toIndex);
    }}
    handleSelector="a"
    nodeSelector=".One_var"
  >
    {variables.map((variable, variableIndex) => (
      <div className="One_var" key={`RULE_${variableIndex}`}>
        {variable.type === 'SIMPLE' ? (
        // eslint-disable-next-line react/no-array-index-key
          <div
            className="flex"
            style={{
              paddingTop: '8px',
              paddingBottom: '8px',
              paddingLeft: '6px',
              paddingRight: '6px',
            }}
          >
            {variables.length > 1 && (
              <div
                className="Drag_handle_wrapper"
              >
                <a
                  style={{
                    color: '#979797',
                    paddingRight: '10px',
                  }}
                  className="drag-handle"
                >
                  <div className="No_select">
                    <img
                      draggable="false"
                      src="/img/drag_icon.svg"
                      alt="drag"
                      className="No_select"
                      style={{
                        width: '20px',
                        height: '20px',
                        outline: 'none',
                        opacity: '0.3',
                      }}
                    />
                  </div>

                </a>
              </div>
            )}
            <div
              className="flex"
              style={{
                flexDirection: 'column',
                minWidth: addonBefore ? ((addonBefore.length * 12) + 140) : 160,
                marginRight: '4px',
              }}
            >
              <label style={{ fontSize: '11px' }} className="grey">Name</label>
              <Input
                addonBefore={addonBefore}
                placeholder="Variable name"
                value={variable.name}
                onChange={(e) => onVariableFieldChange('name', variableIndex, e.target.value)}
                required
              />
            </div>
            <div className="flex width-100" style={{ flexDirection: 'column', marginRight: '4px' }}>
              <label style={{ fontSize: '11px' }} className="grey">Value</label>
              <AutoCompleteInput
                required
                value={variable.formula}
                onChange={(value) => onVariableFieldChange('formula', variableIndex, value)}
                placeholder="Variable value (formula)"
                trigger={triggers}
                options={options}
              />
            </div>
            <Tooltip title="Add IF statement">
              <Button
                ghost
                style={{ marginTop: '16px' }}
                className="Rules_delete_button"
                onClick={() => setVariableCond(variableIndex)}
              >
                <BranchesOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Delete Variable">
              <Button
                ghost
                style={{ marginTop: '16px' }}
                className="Rules_delete_button"
                onClick={() => deleteVariable(variableIndex)}
              >
                <CloseOutlined />
              </Button>
            </Tooltip>

          </div>
        ) : (
          <div>
            <Collapse
              expandIconPosition="right"
              bordered={false}
              key={variableIndex}
              style={{
                paddingBottom: '8px', paddingTop: '8px', background: 'transparent', border: 'none',
              }}
              activeKey={activeKeysVariables}
              onChange={onChangeActiveKey}
            >
              <Panel
                style={{ background: 'transparent', border: 'none' }}
                key={variableIndex}
                header={(
                  <div className="flex">
                    {variables.length > 1 && (
                      <div className="Drag_handle_wrapper">
                        <a
                          style={{
                            color: '#979797',
                            paddingRight: '10px',
                          }}
                          className="drag-handle"
                        >
                          <div className="No_select">
                            <img
                              draggable="false"
                              src="/img/drag_icon.svg"
                              alt="drag"
                              className="No_select"
                              style={{
                                width: '20px',
                                height: '20px',
                                outline: 'none',
                                opacity: '0.3',
                              }}
                            />
                          </div>

                        </a>
                      </div>
                    )}
                    <div
                      className="flex"
                      style={{
                        flexDirection: 'column',
                        minWidth: addonBefore ? ((addonBefore.length * 12) + 140) : 160,
                        marginRight: '4px',
                      }}
                    >
                      <label style={{ fontSize: '11px' }} className="grey">Name</label>
                      <Input
                        placeholder="Variable name"
                        value={variable.name}
                        onChange={(e) => onVariableFieldChange('name', variableIndex, e.target.value)}
                        required
                        onClick={(e) => { if (e) { e.stopPropagation(); } }}
                        addonBefore={addonBefore}
                      />
                    </div>
                    <Tooltip title="Delete Variable">
                      <Button
                        ghost
                        style={{ marginTop: '16px' }}
                        className="Rules_delete_button"
                        onClick={() => deleteVariable(
                          variableIndex,
                        )}
                      >
                        <CloseOutlined />
                      </Button>
                    </Tooltip>
                    <div className="Rule_variable_number_condition">
                      {`${variable.steps.length} ${pluralize('condition', variable.steps.length)}`}
                    </div>
                  </div>
                )}
              >
                <div>
                  {variable.steps.map((step, stepIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={stepIndex} style={{ marginTop: 12 }} className="flex">
                      <div className="width-100">
                        <div className="Condition ConditionTop flex">
                          <div className="AddonBefore">
                            {stepIndex === 0 ? <span className="bold">IF</span> : <span className="bold">ELSE IF</span>}
                          </div>
                          <div className="width-100">
                            <AutoCompleteInput
                              required
                              value={step.condition}
                              onChange={(value) => onVariableStepFieldChangeOption('condition', variableIndex, stepIndex, value)}
                              placeholder={`Condition (ex ${displayType}.xxx === yyy)`}
                              trigger={triggers}
                              options={options}
                              style={{ width: '100%' }}
                            />
                          </div>
                        </div>
                        <div className="Condition ConditionBottom flex">
                          <div className="AddonBefore">
                            <span className="bold">THEN</span>
                            {` ${variable.name} =`}
                          </div>
                          <div className="width-100">
                            <AutoCompleteInput
                              required
                              value={step.formula}
                              onChange={(value) => onVariableStepFieldChangeOption('formula', variableIndex, stepIndex, value)}
                              placeholder="Value (formula)"
                              trigger={triggers}
                              options={options}
                              style={{ width: '100%' }}
                              className="InputGroup_right"
                            />
                          </div>
                        </div>
                      </div>
                      <Tooltip title="Delete condition">
                        <Button
                          size="small"
                          ghost
                          className="Rules_delete_button"
                          onClick={() => deleteVariableStep(
                            variableIndex, stepIndex,
                          )}
                        >
                          <CloseOutlined />
                        </Button>
                      </Tooltip>
                    </div>
                  ))}
                  <div className="Condition flex" style={{ marginTop: 12, width: 'calc(100% - 26px)' }}>
                    <div className="AddonBefore">
                      <span className="bold">ELSE</span>
                      {` ${variable.name} =`}
                    </div>
                    <div className="width-100">
                      <AutoCompleteInput
                        required
                        value={variable.formula}
                        onChange={(value) => onVariableFieldChange('formula', variableIndex, value)}
                        placeholder="Value (formula)"
                        trigger={triggers}
                        options={options}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>

                  <Button
                    type="link"
                    style={{ marginTop: 6 }}
                    onClick={() => addVariableCondition(
                      variableIndex,
                    )}
                    className="Add_bracket_btn "
                    icon={<PlusOutlined />}
                    size="small"
                  >
                    Add a condition
                  </Button>
                </div>
              </Panel>

            </Collapse>
          </div>
        )}
        <Divider style={{ margin: '0' }} />
      </div>
    ))}
  </ReactDragListView>
);

Variables.propTypes = {
  variables: PropTypes.array.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  triggers: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  deleteVariableStep: PropTypes.func.isRequired,
  onVariableFieldChange: PropTypes.func.isRequired,
  addVariableCondition: PropTypes.func.isRequired,
  setVariableCond: PropTypes.func.isRequired,
  deleteVariable: PropTypes.func.isRequired,
  onVariableStepFieldChangeOption: PropTypes.func.isRequired,
  activeKeysVariables: PropTypes.array.isRequired,
  onChangeActiveKey: PropTypes.func.isRequired,
  displayType: PropTypes.string.isRequired,
  addonBefore: PropTypes.string,
};
Variables.defaultProps = {
  addonBefore: null,
};
export default Variables;
