import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import MyDashboardPageContent from '@palette/components/ic/myDashboard/MyDashboardPageContent/MyDashboardPageContent';
import CallMeHandLottieAnimation from '@palette/components/utils/CallMeHandLottieAnimation/CallMeHandLottieAnimation';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './MyDashboardPage.less';

const MyDashboardPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION, RIGHTS.IC.CHALLENGES])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.myDashboardTitle')} />
      <PageTitle>
        <div className={styles.titleWrapper}>
          <h1>
            {t('myDashboardPage.title', { firstName: profile.userData.firstName })}
          </h1>
          <CallMeHandLottieAnimation className={styles.lottieAnimation} />
        </div>
      </PageTitle>
      <MyDashboardPageContent />
    </div>
  );
};

export default MyDashboardPage;
