import React from 'react';
import { useTranslation } from 'react-i18next';

import CommissionListPageContent from '@palette/components/commissionList/CommissionListPageContent/CommissionListPageContent';
import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './CommissionListPage.less';

const CommissionListPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.commissionListTitle')} />
      <PageTitle
        title={t('commissionList.page.title')}
        subtitle={t('commissionList.page.subtitle')}
      />
      <CommissionListPageContent />
    </div>
  );
};

export default CommissionListPage;
