import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import UserStatementInfosAndActions from '@palette/components/statement/userStatement/UserStatementInfosAndActions/UserStatementInfosAndActions';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import UserStatementAdjustments from '@palette/components/statement/userStatement/UserStatementAdjustments/UserStatementAdjustments';
import UserStatementChallenges from '@palette/components/statement/userStatement/UserStatementChallenges/UserStatementChallenges';
import UserStatementPlans from '@palette/components/statement/userStatement/UserStatementPlans/UserStatementPlans';
import UserStatementCorrections from '@palette/components/statement/userStatement/UserStatementCorrections/UserStatementCorrections';
import UserStatementHistory from '@palette/components/statement/userStatementHistory/UserStatementHistory/UserStatementHistory';
import Alert from '@palette/components/designSystem/Alert/Alert';
import Tabs from '@palette/components/designSystem/Tabs/Tabs';

import { getMoment } from '@palette/helpers/MomentHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { STATEMENT_HISTORY_ID } from '@palette/constants/navigation';
import { USER_STATEMENT_CONTENT_TABS_QS_KEY, USER_STATEMENT_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';
import { useStatementUserHistoryInParams } from '@palette/hooks/StatementHistoryHooks';

import { actions as StatementsHistoryActions } from '@palette/state/StatementsHistory';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';
import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './UserStatementContent.less';

const classNames = bindClassNames.bind(styles);

const UserStatementContent = ({ className, statementPeriod, userStatement, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const { listByFiltersPagination } = useStatementUserHistoryInParams();

  const [getPaginationLimit] = useLimitInSearch();
  const [getPaginationPage] = usePageInSearch();

  const getHistoryChangesListWithParams = useCallback(() => {
    if (!userStatement) return;

    const listByFiltersPayload = {
      statementId: userStatement.id,
      page: getPaginationPage,
      limit: getPaginationLimit,
    };

    dispatch(StatementsHistoryActions.listByFilters(listByFiltersPayload));
  }, [userStatement, getPaginationPage, getPaginationLimit]);

  useEffect(() => {
    getHistoryChangesListWithParams();
  }, [getHistoryChangesListWithParams]);

  const joinLeaveDateWarningNode = useMemo(() => {
    if (userStatement === null) return null;

    const userJoinDate = userStatement.user.joinDate;
    const userLeaveDate = userStatement.user.leaveDate;

    if (userJoinDate === null && userLeaveDate === null) return null;

    let message = null;
    const periodBeginDate = moment.utc(statementPeriod.beginDate);
    const periodEndDate = moment.utc(statementPeriod.endDate);
    const now = moment.utc();

    if (userJoinDate !== null) {
      const userJoinDateMoment = moment.utc(userJoinDate);
      if (userJoinDateMoment.isAfter(periodBeginDate)) {
        message = t('userStatementContent.userWillBeHired', { date: userJoinDateMoment.format('LL') });
        if (userJoinDateMoment.isBefore(now)) {
          message = t('userStatementContent.userWasHired', { date: userJoinDateMoment.format('LL') });
        }
      }
    }

    if (userLeaveDate !== null) {
      const userLeaveDateMoment = moment.utc(userLeaveDate);
      if (userLeaveDateMoment.isBefore(periodEndDate)) {
        message = t('userStatementContent.userWasDeactivated', { date: userLeaveDateMoment.format('LL') });
        if (userLeaveDateMoment.isAfter(now)) {
          message = t('userStatementContent.userWillBeDeactivated', { date: userLeaveDateMoment.format('LL') });
        }
      }
    }

    if (message !== null) {
      return (
        <Alert className={styles.joinLeaveDateWarning} type={ALERT_TYPES.WARNING} message={message} />
      );
    }

    return null;
  }, [statementPeriod, userStatement]);

  const infosAndActionsNode = useMemo(() => {
    if (userStatement === null) return null;

    return (
      <UserStatementInfosAndActions
        statementPeriod={statementPeriod}
        userStatement={userStatement}
        currency={currency}
      />
    );
  }, [statementPeriod, userStatement, currency]);

  const statementTabNode = useMemo(() => (
    <>
      <UserStatementCorrections
        userStatement={userStatement}
        currency={currency}
        statementPeriod={statementPeriod}
      />
      <UserStatementAdjustments
        userStatement={userStatement}
        currency={currency}
        statementPeriod={statementPeriod}
      />
      <UserStatementChallenges userStatement={userStatement} />
      <UserStatementPlans userStatement={userStatement} />
    </>
  ), [statementPeriod, userStatement, currency]);

  const historyTabNode = useMemo(() => <UserStatementHistory />, []);

  const getTabPropsByType = useCallback((tabType) => ({
    title: t(`userStatementContent.tabs.${tabType}`),
    ...(tabType === USER_STATEMENT_CONTENT_TABS_IDS.HISTORY && { titleCount: listByFiltersPagination.total }),
    content: tabType === USER_STATEMENT_CONTENT_TABS_IDS.HISTORY ? historyTabNode : statementTabNode,
    defaultActive: tabType === USER_STATEMENT_CONTENT_TABS_IDS.STATEMENT,
    tabKey: tabType,
  }), [
    statementTabNode,
    historyTabNode,
    listByFiltersPagination,
  ]);

  const tabs = useMemo(() => Object.values(USER_STATEMENT_CONTENT_TABS_IDS).map((tabType) => getTabPropsByType(tabType)), [getTabPropsByType]);

  const tabsNode = useMemo(() => (
    <Tabs
      className={styles.tabs}
      tabs={tabs}
      qsTabKey={USER_STATEMENT_CONTENT_TABS_QS_KEY}
      alwaysDisplayTitleCount
    />
  ), [tabs]);

  const contentNode = useMemo(() => {
    if (userStatement === null) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <div id={STATEMENT_HISTORY_ID} className={styles.content}>
        {tabsNode}
      </div>
    );
  }, [userStatement, tabsNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {joinLeaveDateWarningNode}
      {infosAndActionsNode}
      {contentNode}
    </div>
  );
};

UserStatementContent.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  userStatement: UserStatementModel.propTypes,
  currency: PropTypes.string,
};

UserStatementContent.defaultProps = {
  className: '',
  userStatement: null,
  currency: null,
};

export default UserStatementContent;
