import { createContext } from 'react';
import { getI18n } from '@palette/i18n';

import { formatBeginEndDate, getMoment, shortFormatWithShortYear } from '@palette/helpers/MomentHelper';

import {
  STATEMENT_APPROVAL_STATUSES,
  STATEMENT_STRATEGY_MONTH_I18N,
  STATEMENT_STRATEGY_MONTH_SHORT_I18N,
  STATEMENT_STRATEGY_TYPES,
  STATEMENT_STRATEGY_TYPES_I18N,
} from '@palette/constants/statements';

export const getPeriodName = (strategy, period, year = null) => {
  const i18n = getI18n();

  let periodName = '';

  if (strategy.type === STATEMENT_STRATEGY_TYPES.WEEK) {
    periodName = i18n.t(STATEMENT_STRATEGY_TYPES_I18N.WEEK_LONG, { period });
  }

  if (strategy.type === STATEMENT_STRATEGY_TYPES.BI_WEEK) {
    periodName = i18n.t(STATEMENT_STRATEGY_TYPES_I18N.BI_WEEK_LONG, { period });
  }

  if (strategy.type === STATEMENT_STRATEGY_TYPES.HALF_MONTH) {
    const monthPeriod = Math.ceil(period / 2);
    const monthI18Id = `M${monthPeriod}`;
    const halfMonthPeriod = period % 2 === 0 ? 2 : 1;
    periodName = `${i18n.t(STATEMENT_STRATEGY_MONTH_I18N[monthI18Id])} ${i18n.t(STATEMENT_STRATEGY_TYPES_I18N.HALF_MONTH_LONG, { period: halfMonthPeriod })}`;
  }

  if (strategy.type === STATEMENT_STRATEGY_TYPES.MONTH) {
    const monthI18Id = `M${period}`;
    periodName = i18n.t(STATEMENT_STRATEGY_MONTH_I18N[monthI18Id]);
  }

  if (periodName && year !== null) {
    periodName += ` ${year}`;
  }

  return periodName;
};

export const getPeriodShortName = (strategy, period, year = null) => {
  const i18n = getI18n();

  let periodShortName = '';

  if (strategy.type === STATEMENT_STRATEGY_TYPES.WEEK) {
    periodShortName = i18n.t(STATEMENT_STRATEGY_TYPES_I18N.WEEK_SHORT, { period });
  }

  if (strategy.type === STATEMENT_STRATEGY_TYPES.BI_WEEK) {
    periodShortName = i18n.t(STATEMENT_STRATEGY_TYPES_I18N.BI_WEEK_SHORT, { period });
  }

  if (strategy.type === STATEMENT_STRATEGY_TYPES.HALF_MONTH) {
    const monthPeriod = Math.ceil(period / 2);
    const monthI18Id = `M${monthPeriod}`;
    const halfMonthPeriod = period % 2 === 0 ? 2 : 1;
    periodShortName = `${i18n.t(STATEMENT_STRATEGY_MONTH_SHORT_I18N[monthI18Id])} ${i18n.t(STATEMENT_STRATEGY_TYPES_I18N.HALF_MONTH_SHORT, { period: halfMonthPeriod })}`;
  }

  if (strategy.type === STATEMENT_STRATEGY_TYPES.MONTH) {
    const monthI18Id = `M${period}`;
    periodShortName = i18n.t(STATEMENT_STRATEGY_MONTH_SHORT_I18N[monthI18Id]);
  }

  if (periodShortName && year !== null) {
    periodShortName += ` ${year.toString().slice(-2)}`;
  }

  return periodShortName;
};

export const getPeriodDates = (beginDate, endDate) => `${shortFormatWithShortYear(beginDate, true)} - ${shortFormatWithShortYear(endDate, true)}`;

export const formatPeriodNameDates = (beginDate, endDate) => {
  const moment = getMoment();

  const beginMomentDate = moment.utc(beginDate);
  const endMomentDate = moment.utc(endDate);

  return formatBeginEndDate(beginMomentDate.format(), endMomentDate.format(), false, true);
};

let userStatementsPanelContext = null;
export const getUserStatementsPanelContext = () => {
  if (userStatementsPanelContext === null) {
    userStatementsPanelContext = createContext(null);
  }

  return userStatementsPanelContext;
};

export const getStrategyDetailsStr = (strategy) => {
  const { type, weekRollType, halfMonthPivot } = strategy;
  const i18n = getI18n();

  if (type === STATEMENT_STRATEGY_TYPES.MONTH) return null;

  if (type === STATEMENT_STRATEGY_TYPES.HALF_MONTH) {
    return i18n.t('statementStrategy.monthBreaks', {
      break: i18n.t(`statementStrategy.monthBreaks.${halfMonthPivot}`),
    });
  }

  return i18n.t('statementStrategy.weekBegins', {
    day: i18n.t(`statementStrategy.weekBegins.${weekRollType}`),
  });
};

export const statementPeriodIsInFuture = (statementPeriod, flexible = true) => {
  const moment = getMoment();
  let now = moment().utc();

  if (flexible) {
    // flexible means we consider statement period is not in the future
    // if its beginDate is in the next 10 days and consider it is really in the future
    // if its beginDate is after this "flexible" 10 days
    now = now.add(10, 'days');
  }

  return moment.utc(statementPeriod.beginDate).isAfter(now);
};

export const getStatementUserApprovalStatus = (userStatement) => {
  const { approvals } = userStatement;

  const isApprovedByMe = approvals.find((approval) => approval.isApprovedByMe);

  if (isApprovedByMe) {
    const { isInConflict } = isApprovedByMe;

    if (isInConflict) {
      return STATEMENT_APPROVAL_STATUSES.IN_CONFLICT;
    }
  } else {
    return STATEMENT_APPROVAL_STATUSES.TO_APPROVE;
  }

  return STATEMENT_APPROVAL_STATUSES.APPROVED;
};
