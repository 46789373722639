import { getMoment } from '@palette/helpers/MomentHelper';

import { REWARD_TYPES } from '@palette/constants/challenges';

export const isInPast = (challenge) => {
  const moment = getMoment();
  const now = moment().utc();

  if (challenge.endAt !== null) {
    const endMomentDate = moment(challenge.endAt).utc()
      .endOf('day');

    return endMomentDate.utc().isBefore(now);
  }

  return false;
};

export const isUpcoming = (challenge) => {
  const moment = getMoment();
  const now = moment().utc();

  const beginMomentDate = moment(challenge.beginAt).utc()
    .startOf('day');

  return beginMomentDate.utc().isAfter(now);
};

export const getRewardsTotal = (challenge) => {
  let total = 0;

  challenge.rewards.forEach((reward) => {
    if (reward.type === REWARD_TYPES.MONEY) {
      total += reward.amount;
    }
  });

  return total;
};
