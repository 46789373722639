/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  message, Select, Spin, Button, Tag,
  Input, Divider, Card, Modal, Checkbox,
} from 'antd';
import {
  CloseOutlined, LoadingOutlined, FileTextOutlined,
  EditOutlined, SaveOutlined, CloseCircleOutlined, SettingOutlined,
} from '@ant-design/icons';
import routePaths from '@palette/config/routePaths';
import * as PlanService from '../../../services/admin/plans';
import * as ChallengeService from '../../../services/admin/challenges';
import {
  isPlanFreezed, formatPeriods, getTagColorFromScope, toVariableName,
} from '../../../services/utils';
import { SCOPE, ENTITIES } from '../../../services/constants';
import TrackingObjectForm from '../trackingObjectForm';
import GroupByObjectForm from '../groupByObjectForm';

const Entity = {
  Plan: {
    updateTrackingObject: PlanService.updateTrackingObject,
    updateGroupByObject: PlanService.updateGroupByObject,
  },
  Challenge: {
    updateTrackingObject: ChallengeService.updateTrackingObject,
    updateGroupByObject: ChallengeService.updateGroupByObject,
  },
};

class ConnectorTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      injections: [],
      editInjectedPlanIndex: null,
      editInjectedPlanName: null,
      modaleInjectPlanOptions: null,
    };
  }

  componentDidMount() {
    if (this.props.type === ENTITIES.PLAN) {
      this.setState({ injections: this.props.entity.injections });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const now = new Date().toISOString();
    const pastPlans = props.plans.filter((p) => p.endAt < now);
    const futurePlans = props.plans.filter((p) => p.beginAt > now);
    const currentPlans = props.plans.filter((p) => p.beginAt <= now && p.endAt >= now);
    if (props.type === ENTITIES.PLAN) {
      return {
        ...state,
        pastPlans,
        currentPlans,
        futurePlans,
      };
    }
    return state;
  }

  updateTrackingObject = (values) => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      Entity[this.props.type].updateTrackingObject(
        this.props.entity._id, values,
      )
        .then(() => this.setState({ loading: false }, this.props.refresh))
        .catch((error) => {
          console.error(error);
          message.error('Error while updating Resource to track');
          this.setState({ loading: false });
        });
    });
  };

  updateGroupByObject = (values) => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      Entity[this.props.type].updateGroupByObject(this.props.entity._id, values)
        .then(() => this.setState({ loading: false }, this.props.refresh))
        .catch((error) => {
          console.error(error);
          message.error('Error while updating Resource Owner parameters');
          this.setState({ loading: false });
        });
    });
  };

  updatePlanInjections = () => this.setState({ loading: true }, () => {
    const injections = this.state.injections.map((elem) => ({
      type: 'MATCH_USER',
      ...elem,
      plan: elem.plan._id,
    }));
    PlanService.updatePlan(this.props.entity._id, { injections })
      .then(() => this.setState({
        loading: false,
        editInjectedPlanIndex: null,
        editInjectedPlanName: null,
      }, this.props.refresh))
      .catch((error) => {
        console.error(error);
        message.error('Error while updating Resource parameters');
        this.setState({ loading: false });
      });
  });

  getSelectPlanDisplay = (p) => (
    <Select.Option
      key={p._id}
      value={p._id}
      label={p.name}
      disabled={(
        this.state.injections.find((i) => i.plan._id === p._id)
        || this.props.entity._id === p._id
      )}
    >
      {p.name}
      <span className="Hide_if_selected grey">{` - ${formatPeriods(p)}`}</span>
      {p.scope !== SCOPE.INDIVIDUAL && (
        <Tag
          className="Hide_if_selected"
          style={{ marginLeft: '8px' }}
          color={getTagColorFromScope(p.scope)}
        >
          {p.scope}
        </Tag>
      )}
      {!p.live && (
        <Tag
          color="black"
          className="Hide_if_selected"
          style={{ marginLeft: '8px' }}
        >
          DRAFT
        </Tag>
      )}
    </Select.Option>
  );

  onPlanSelectChange = (value) => this.setState((prevState) => ({
    injections: [...prevState.injections, {
      name: `iPlan${prevState.injections.length + 1}`,
      plan: this.getPlanFromId(
        this.props.plans,
        value,
      ),
      type: 'MATCH_USER',
    }],
  }));

  removeInjectionPlan = (id) => {
    this.setState((prevState) => ({
      injections: prevState.injections.filter((i) => i.plan._id !== id),
    }));
  };

  getPlanFromId = (plans, id) => plans.find((p) => p._id === id);

  editInjectedPlanName = (index, name) => this.setState({
    editInjectedPlanIndex: index,
    editInjectedPlanName: name,
  });

  cancelEditInjectedPlanName = () => this.setState({
    editInjectedPlanIndex: null,
    editInjectedPlanName: null,
  });

  onChangeInjectedPlan = (e) => this.setState({ editInjectedPlanName: toVariableName(e.target.value) });

  saveEditInjectedPlanName = () => {
    this.setState((prevState) => ({
      injections: prevState.injections
        .map((element, index) => (
          index === prevState.editInjectedPlanIndex
            ? { ...element, name: prevState.editInjectedPlanName }
            : element
        )),
    }), () => {
      this.updatePlanInjections();
    });
  };

  updatePlanInjectionStrategy = (type, index) => {
    this.setState((prevState) => ({
      injections: prevState.injections
        .map((element, i) => (
          i === index
            ? { ...element, type }
            : element
        )),
    }), () => {
      this.updatePlanInjections();
    });
  };

  updatePlanInjectionOption = (field, include, index) => {
    this.setState((prevState) => ({
      injections: prevState.injections
        .map((element, i) => (
          i === index
            ? { ...element, [field]: include }
            : element
        )),
    }), () => {
      this.updatePlanInjections();
    });
  };

  openOptionsForInjectedPlan = (index) => {
    this.setState({
      modaleInjectPlanOptions: index,
    });
  };

  closeOptionModaleForInjectedPlan = () => {
    this.setState({
      modaleInjectPlanOptions: null,
    });
  };

  render() {
    const {
      entity,
      type,
      plans,
    } = this.props;
    const disabled = isPlanFreezed(entity);
    const {
      pastPlans,
      futurePlans,
      currentPlans,
      injections,
      editInjectedPlanIndex,
      editInjectedPlanName,
      modaleInjectPlanOptions,
    } = this.state;

    return (
      <div className="Plan_details_settings_wrapper">
        <Spin
          spinning={this.state.loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
        >
          <Card>
            <h2 className="bold" style={{ marginBottom: '2px' }}>Resource to Track</h2>
            <div className="grey" style={{ marginBottom: '18px' }}>
              Define the resource that you want to track in this
              {' '}
              {type}
              .
            </div>
            <TrackingObjectForm
              initialValues={entity.trackingObject}
              onFinish={this.updateTrackingObject}
              disabled={disabled}
              type={type}
              operation="update"
              entity={entity}
            />
          </Card>
          <br />
          <Card>
            <h2 className="bold" style={{ marginBottom: '2px' }}>Resource Owner</h2>
            <div className="grey" style={{ marginBottom: '18px' }}>
              Defining the resource owner helps Palette undersand who should be rewarded for a given
              {' '}
              {entity.trackingObjectType}
            </div>
            <GroupByObjectForm
              initialValues={entity.groupByObject}
              onFinish={this.updateGroupByObject}
              disabled={disabled}
              entity={entity}
              type={type}
            />
          </Card>
          <br />
          {type === ENTITIES.PLAN && plans && plans.length > 0 && (
            <Card style={{ marginTop: '14px' }} id="dependencies">
              <h2 className="bold" style={{ marginBottom: '2px' }}>Dependencies</h2>
              <div className="grey" style={{ marginBottom: '18px' }}>
                You can add other plans as dependencies to access their data in the rules of this plan.
                For instance you can use previous achievements to
                define accelerators in the current plan.
              </div>
              <div style={{ marginTop: '12px' }}>
                {injections.map(({ plan, name }, index) => (
                  <div
                    key={plan._id}
                    className="flex"
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      className="flex"
                      style={{
                        paddingTop: '6px',
                        marginBottom: '6px',
                        background: 'white',
                        borderRadius: '4px',
                      }}
                    >
                      <div className="flex" style={{ alignItems: 'center' }}>
                        { index === editInjectedPlanIndex
                          ? (
                            <div style={{ marginRight: '16px' }}>
                              <Input
                                size="small"
                                autoFocus
                                style={{ width: '120px', marginRight: '8px' }}
                                value={editInjectedPlanName}
                                onChange={this.onChangeInjectedPlan}
                              />

                              <a
                                onClick={() => this.cancelEditInjectedPlanName()}
                                style={{ color: '#1eb1b5' }}
                              >
                                <CloseCircleOutlined />
                              </a>
                              <Divider type="vertical" />
                              <a
                                onClick={() => this.saveEditInjectedPlanName()}
                                style={{ color: '#1eb1b5' }}
                              >
                                <SaveOutlined />
                                &nbsp;Save
                              </a>
                            </div>

                          )
                          : (
                            <Tag className="bold" icon={<FileTextOutlined />}>
                              {name}
                              &nbsp;
                              {!disabled && (
                                <a
                                  onClick={() => this.editInjectedPlanName(index, name)}
                                  style={{ color: '#1eb1b5' }}
                                >
                                  <EditOutlined />
                                </a>
                              )}
                            </Tag>
                          )}
                      </div>

                      <Divider type="vertical" style={{ height: '26px' }} />
                      <div
                        className="flex"
                        style={{ flexGrow: '1', alignItems: 'center' }}
                      >
                        <a
                          className="bold"
                          href={`/#${routePaths.v2.planDetails.replace(':masterPlanId', plan._id)}`}
                        >
                          {plan.name}
                        </a>
                        <span className="grey">
                          {` - ${formatPeriods(plan)}`}
                        </span>
                        {plan.scope !== SCOPE.INDIVIDUAL && (
                          <Tag
                            className="Hide_if_selected"
                            style={{ marginLeft: '8px' }}
                            color={getTagColorFromScope(plan.scope)}
                          >
                            {plan.scope}
                          </Tag>
                        )}
                        {!plan.live && (
                          <Tag
                            color="black"
                            className="Hide_if_selected"
                            style={{ marginLeft: '8px' }}
                          >
                            DRAFT
                          </Tag>
                        )}
                      </div>
                      <Divider style={{ height: '26px' }} type="vertical" />
                      <div
                        className="flex"
                        style={{
                          fontSize: '13px',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <a
                            disabled={disabled}
                            onClick={disabled ? null : () => this.openOptionsForInjectedPlan(index)}
                          >
                            <SettingOutlined />
                            {' '}
                            Options
                          </a>
                        </div>

                      </div>

                      <div>
                        <Button
                          disabled={disabled}
                          ghost
                          className="grey"
                          style={{ border: 'none', boxShadow: 'none' }}
                          onClick={() => this.removeInjectionPlan(plan._id)}
                        >
                          <CloseOutlined />
                        </Button>
                      </div>
                    </div>
                    {index < injections.length - 1 && <Divider style={{ margin: '12px 0' }} />}
                  </div>
                ))}
                <Select
                  disabled={disabled}
                  showSearch
                  listHeight={274}
                  className="width-100"
                  style={{ marginTop: '12px' }}
                  value={null}
                  placeholder="Select a plan to add as dependency"
                  dropdownMatchSelectWidth={false}
                  onChange={this.onPlanSelectChange}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option.options) {
                      return option.options.includes((c) => c.children[0]
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0);
                    }
                    return option.children[0]
                      .toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                >
                  <Select.OptGroup label="Future plans">
                    {futurePlans && futurePlans.map((p) => (this.getSelectPlanDisplay(p)))}
                  </Select.OptGroup>
                  <Select.OptGroup label="Current plans">
                    {currentPlans && currentPlans.map((p) => (this.getSelectPlanDisplay(p)))}
                  </Select.OptGroup>
                  <Select.OptGroup label="Past plans">
                    {pastPlans && pastPlans.map((p) => (this.getSelectPlanDisplay(p)))}
                  </Select.OptGroup>

                </Select>
              </div>
              <div className="center" style={{ marginTop: '18px' }}>
                <div>
                  <Button
                    className="width-100"
                    type="primary"
                    onClick={this.updatePlanInjections}
                    disabled={disabled}
                  >
                    Update dependencies
                  </Button>
                </div>

              </div>

            </Card>
          )}
        </Spin>
        {(modaleInjectPlanOptions !== null) && (
          <Modal
            visible
            onCancel={this.closeOptionModaleForInjectedPlan}
            title="Edit dependency options"
            footer={(
              <div>
                <Button type="primary" onClick={this.closeOptionModaleForInjectedPlan}>Close</Button>
              </div>
            )}
          >
            <Spin
              spinning={this.state.loading}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
            >
              <div>
                <div>
                  <div>
                    <h4 className="bold">Progress history</h4>
                    <div className="grey">
                      Choose whether to include the progress history in the dependency or not.
                      <br />
                      Note that this is not checked by default because it
                      consumes a lot of resources and has to be included only if needed.
                      <br />
                      You could need that when trying to check when a user
                      (in the dependency plan) passed a specific progress / quota achievement.
                    </div>
                  </div>
                  <br />
                  <div>
                    <Checkbox
                      checked={this.state.injections[modaleInjectPlanOptions].withDataPoints || false}
                      onChange={(value) => {
                        this.updatePlanInjectionOption(
                          'withDataPoints',
                          value.target.checked,
                          modaleInjectPlanOptions,
                        );
                      }}
                    >
                      Include progress history
                    </Checkbox>
                  </div>
                </div>
                <div>
                  <Divider />
                  <div>
                    <h4 className="bold">Payment List</h4>
                    <div className="grey">
                      Choose whether to include the full payment list in the dependency or not.
                      <br />
                      You could need that when trying to have the actual payout amounts
                      instead of the theoretical commission amounts (total commissions released/paid).
                    </div>
                  </div>
                  <br />
                  <div>
                    <Checkbox
                      checked={this.state.injections[modaleInjectPlanOptions].withPayments || false}
                      onChange={(value) => {
                        this.updatePlanInjectionOption(
                          'withPayments',
                          value.target.checked,
                          modaleInjectPlanOptions,
                        );
                      }}
                    >
                      Include payment list
                    </Checkbox>
                  </div>
                </div>

                <div>
                  <Divider />
                  <h4 className="bold">Users to inject</h4>
                  <div className="grey">
                    Choose whether to inject
                    All users information or only the one that
                    match with the given
                    {' '}
                    {entity.trackingObject.type}
                  </div>
                  <br />
                  <Select
                    value={this.state.injections[modaleInjectPlanOptions].type || 'MATCH_USER'}
                    // size="large"
                    width={300}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => this.updatePlanInjectionStrategy(
                      value,
                      modaleInjectPlanOptions,
                    )}
                  >
                    <Select.Option value="ALL_USERS">All Users</Select.Option>
                    <Select.Option value="MATCH_USER">Match User</Select.Option>
                  </Select>
                </div>
              </div>
            </Spin>

          </Modal>
        )}
      </div>
    );
  }
}

ConnectorTab.propTypes = {
  entity: PropTypes.object.isRequired,
  type: PropTypes.oneOf([ENTITIES.PLAN, ENTITIES.CHALLENGE]).isRequired,
  refresh: PropTypes.func.isRequired,
  plans: PropTypes.array,
};

ConnectorTab.defaultProps = {
  plans: [],
};

export default ConnectorTab;
