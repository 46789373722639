import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import DeleteMasterPlanModal from '@palette/components/masterPlan/DeleteMasterPlanModal/DeleteMasterPlanModal';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './DiscardPlanButton.less';

const classNames = bindClassNames.bind(styles);

const DiscardPlanButton = ({ className, plan, labelEnabled }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [deleteMasterPlanModalIsVisible, showDeleteMasterPlanModal] = useState(false);

  const discardButton = useMemo(() => (
    <Button
      className={styles.action}
      type="linkDestroy"
      icon={<TrashFilled className={styles.actionIcon} />}
      onClick={() => showDeleteMasterPlanModal(true)}
    >
      {labelEnabled && (
        <div className={styles.actionLabel}>
          {t('masterPlan.discardPlan')}
        </div>
      )}
    </Button>
  ), [labelEnabled]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DELETE])) return null;

  let buttonNode = discardButton;

  if (!labelEnabled) {
    buttonNode = (
      <Tooltip title={t('masterPlan.discardPlan')}>
        {discardButton}
      </Tooltip>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {buttonNode}
      {deleteMasterPlanModalIsVisible && (
        <DeleteMasterPlanModal
          visible={deleteMasterPlanModalIsVisible}
          onClose={() => showDeleteMasterPlanModal(false)}
          plan={plan}
        />
      )}
    </div>
  );
};

DiscardPlanButton.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  labelEnabled: PropTypes.bool,
};

DiscardPlanButton.defaultProps = {
  className: '',
  labelEnabled: false,
};

export default DiscardPlanButton;
