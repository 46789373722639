import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import UserStatementChallengesItem from '@palette/components/statement/userStatement/UserStatementChallengesItem/UserStatementChallengesItem';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './UserStatementChallenges.less';

const classNames = bindClassNames.bind(styles);

const UserStatementChallenges = ({ className, userStatement }) => {
  const { t } = useTranslation();

  const contentNode = useMemo(() => {
    if (!userStatement || !userStatement.challenges.length) return null;

    const title = (
      <div className={styles.title}>
        {t('userStatementChallenges.collapse.title', { count: userStatement.challenges.length })}
      </div>
    );

    const content = [(
      <div className={styles.challengesListHeader} key="challenges-list-header">
        <span className={styles.colRow}>{t('userStatementChallenges.collapse.nameLabel')}</span>
        <span className={styles.colRow}>{t('userStatementChallenges.collapse.commissionLabel')}</span>
      </div>
    )];

    content.push(userStatement.challenges.map((challenge) => (
      <UserStatementChallengesItem key={challenge.id} challenge={challenge} />
    )));

    const extra = (
      <div className={styles.extraDetails}>
        <div className={styles.totalWrapper}>
          <div className={styles.totalLabel}>
            {t('userStatementChallenges.collapse.total')}
          </div>
          <div className={styles.totalAmount}>
            {formatPrice(userStatement.challengesTotalAmount, userStatement.currency)}
          </div>
        </div>
      </div>
    );

    return (
      <Collapse
        className={styles.collapseContainer}
        panels={[{
          title,
          content,
          extra,
        }]}
        keyValue={userStatement.id}
        defaultActiveKey={undefined}
      />
    );
  }, [userStatement]);

  if (!userStatement) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UserStatementChallenges.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes,
};

UserStatementChallenges.defaultProps = {
  className: '',
  userStatement: null,
};

export default UserStatementChallenges;
