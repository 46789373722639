import PropTypes from 'prop-types';

import {
  manageStringAttribute,
  manageNumberAttribute,
  manageDateAttribute,
  manageArrayAttribute,
} from '@palette/helpers/ModelHelper';

import { CHALLENGE_TYPES } from '@palette/constants/challenges';

import * as RewardModel from '@palette/models/Reward';

export const modelName = 'Challenge';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(CHALLENGE_TYPES)),
  beginAt: PropTypes.string,
  endAt: PropTypes.string,
  currency: PropTypes.string,
  rankingIndex: PropTypes.number,
  rewards: PropTypes.arrayOf(RewardModel.propTypes),
});

export const manageChallengeType = (challenge) => CHALLENGE_TYPES[manageStringAttribute(challenge, 'type')] || CHALLENGE_TYPES.PUBLIC_LEADERBOARD;

export const transform = (challenge) => {
  if (!challenge) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(challenge, '_id'),
      name: manageStringAttribute(challenge, 'name'),
      type: manageChallengeType(challenge),
      beginAt: manageDateAttribute(challenge, 'beginAt'),
      endAt: manageDateAttribute(challenge, 'endAt'),
      currency: manageStringAttribute(challenge, 'currency'),
      rankingIndex: manageNumberAttribute(challenge, 'rankingIndex'),
      rewards: RewardModel.transformList(manageArrayAttribute(challenge, 'rewards')),
    }
  );
};

export const transformList = (challenges) => challenges.map((challenge) => transform(challenge));
