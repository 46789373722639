import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntDForm } from 'antd';
import _isEqual from 'lodash/isEqual';

import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Form from '@palette/components/designSystem/Form/Form';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import Select from '@palette/components/designSystem/Select/Select';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import EyesLine from '@palette/components/utils/Icons/EyesLine';
import InfoCircleLine from '@palette/components/utils/Icons/InfoCircleLine';
import ArchiveMasterPlanModal from '@palette/components/masterPlan/ArchiveMasterPlanModal/ArchiveMasterPlanModal';
import DeleteMasterPlanModal from '@palette/components/masterPlan/DeleteMasterPlanModal/DeleteMasterPlanModal';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Switch from '@palette/components/designSystem/Switch/Switch';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import Link from '@palette/components/designSystem/Link/Link';
import MasterPlansListSelect from '@palette/components/masterPlan/MasterPlansListSelect/MasterPlansListSelect';
import MasterPlanSettingsGeneralNameFormItem from '@palette/components/masterPlanSettings/MasterPlanSettingsGeneralFormItems/MasterPlanSettingsGeneralNameFormItem/MasterPlanSettingsGeneralNameFormItem';
import MasterPlanSettingsGeneralScopeFormItem from '@palette/components/masterPlanSettings/MasterPlanSettingsGeneralFormItems/MasterPlanSettingsGeneralScopeFormItem/MasterPlanSettingsGeneralScopeFormItem';
import MasterPlanSettingsGeneralFrequencyBeginEndFormItems from '@palette/components/masterPlanSettings/MasterPlanSettingsGeneralFormItems/MasterPlanSettingsGeneralFrequencyBeginEndFormItems/MasterPlanSettingsGeneralFrequencyBeginEndFormItems';
import Alert from '@palette/components/designSystem/Alert/Alert';
import TimezoneSelect from '@palette/components/utils/TimezoneSelect/TimezoneSelect';
import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useSettingsGeneralFrequencyBeginEndFormItems } from '@palette/hooks/MasterPlanSettingsHooks';

import { isInPast, isArchived, isPlanAutoFreezeEnabled, scrollToNextStep } from '@palette/helpers/MasterPlanHelper';
import { getLocalTimezone, getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight, isSuperAdmin } from '@palette/helpers/ProfileHelper';

import {
  CREATE_PLAN_FLOW_STEPS,
  FREEZE_TYPES,
  SCOPES,
} from '@palette/constants/masterPlans';
import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { ALERT_TYPES } from '@palette/constants/alert';
import { RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './MasterPlanSettingsGeneral.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsGeneral = ({
  className,
  planId,
  inPlanCreationFlow,
  inPlanCreationFlowSetSaveCB,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const profile = useProfile();

  const [onSuccessSaveGeneralSettingsCB, setOnSuccessSaveGeneralSettingsCB] = useState(null);
  const [historyPlanSelectShowed, showHistoryPlanSelect] = useState(false);

  const planFromState = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planId }));
  const parentPlanFromState = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planFromState?.parentPlan }));

  const plan = useMemo(() => {
    if (planId === null) {
      return MasterPlanModel.transform({});
    }

    return planFromState;
  }, [planId, planFromState]);

  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId: plan.quotaId }));

  useEffect(() => {
    if (plan.quotaId !== null) {
      dispatch(QuotasActions.getById({ quotaId: plan.quotaId }));
    }
  }, [plan]);

  const updatePlanIsPending = useSelector(MasterPlansSelectors.updatePlanIsPending);
  const createPlanIsPending = useSelector(MasterPlansSelectors.createPlanIsPending);
  const archivePlanIsPending = useSelector(MasterPlansSelectors.archiveByIdIsPending);

  const createOrUpdateIsPending = createPlanIsPending || updatePlanIsPending;

  const freezeTypesOptions = Object.keys(FREEZE_TYPES).map((freezeTypeKey) => ({
    label: t(`masterPlanFreezeType.${freezeTypeKey.toLowerCase()}`),
    value: FREEZE_TYPES[freezeTypeKey],
  }));

  const initialName = useMemo(() => (plan.name), [plan]);
  const initialScope = useMemo(() => (plan.scope), [plan]);
  const initialCurrency = useMemo(() => (plan.currency || profile.userData.company.currency), [plan]);
  const initialPeriodType = useMemo(() => (plan.periodType), [plan]);
  const initialFrequency = useMemo(() => (plan.frequency), [plan]);
  const initialBeginPeriod = useMemo(() => (plan.beginPeriod), [plan]);
  const initialEndPeriod = useMemo(() => (plan.endPeriod), [plan]);
  const initialLive = useMemo(() => (plan.live), [plan]);
  const initialFreezeType = useMemo(() => (plan.freezeType), [plan]);
  const initialAutoFreezeEnabled = useMemo(() => (isPlanAutoFreezeEnabled(plan)), [plan]);
  const initialAutoFreezeAfter = useMemo(() => (plan.autofreeze?.freezeAfter ?? null), [plan]);
  const initialAllowWrongPayoutsPercentageValue = useMemo(() => (plan.allowWrongPayoutsPercentageValue), [plan]);
  const initialTimezone = useMemo(() => {
    if (plan.timezone === null) {
      return getLocalTimezone();
    }

    return plan.timezone;
  }, [plan]);
  const initialParentPlan = useMemo(() => (plan.parentPlan), [plan]);
  const initialCustomBeginAt = useMemo(() => (plan.customBeginAt ? moment.utc(plan.customBeginAt) : moment.utc()), [plan]);
  const initialCustomBeginAtString = useMemo(() => (plan.customBeginAt), [plan]);
  const initialCustomEndAt = useMemo(() => {
    let customEndAt = null;

    if (plan.periodType === PERIOD_TYPES.CUSTOM) {
      customEndAt = moment.utc(plan.customBeginAt).add(plan.frequency - 1, 'days').endOf('day');
    }

    return customEndAt ? moment(customEndAt) : moment();
  }, [plan]);
  const initialFiscalYearShift = useMemo(() => (plan.fiscalYearShift), [plan]);

  const [scopeValue, setScopeValue] = useState(initialScope);
  const [autoFreezeAfterEnabled, setAutoFreezeAfterEnabled] = useState(initialAutoFreezeEnabled);

  useEffect(() => {
    setScopeValue(initialScope);
    setAutoFreezeAfterEnabled(initialAutoFreezeEnabled);
  }, [
    initialScope,
    initialAutoFreezeEnabled,
  ]);

  const [deleteMasterPlanModalIsVisible, showDeleteMasterPlanModal] = useState(false);
  const [archiveMasterPlanModalIsVisible, showArchiveMasterPlanModal] = useState(false);

  const [form] = AntDForm.useForm();

  const [
    periodTypeValue,
    frequencyValue,
    beginPeriodValue,
    endPeriodValue,
    fiscalYearShiftValue,
    customBeginAtValue,
    frequencyBeginEndFormItemsInitialValues,
    displayEndPeriod,
    handleSetEndPeriod,
    handleRemoveEndPeriod,
    handleFrequencyBeginEndFormItemsChange,
    getFinalFrequencyBeginEndFormItemsValues,
  ] = useSettingsGeneralFrequencyBeginEndFormItems(
    form,
    initialPeriodType,
    initialFrequency,
    initialBeginPeriod,
    initialEndPeriod,
    initialFiscalYearShift,
    initialCustomBeginAt,
    initialCustomBeginAtString,
    initialCustomEndAt,
  );

  const internalInitialValues = useMemo(() => ({
    name: initialName,
    scope: initialScope,
    currency: initialCurrency,
    live: initialLive,
    freezeType: initialFreezeType,
    autoFreezeEnabled: initialAutoFreezeEnabled,
    autoFreezeAfter: initialAutoFreezeAfter,
    allowWrongPayoutsPercentageValue: initialAllowWrongPayoutsPercentageValue,
    timezone: initialTimezone,
    parentPlan: initialParentPlan,
  }), [plan]);

  const initialValues = useMemo(() => ({
    ...internalInitialValues,
    ...frequencyBeginEndFormItemsInitialValues,
  }), [internalInitialValues, frequencyBeginEndFormItemsInitialValues]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const getFormUpdatedData = useCallback((values) => {
    const [finalValues, finalInitialValues] = getFinalFrequencyBeginEndFormItemsValues(values, internalInitialValues);

    let updatedData = Object.keys(finalValues).reduce((data, valKey) => {
      if (!_isEqual(finalInitialValues[valKey], finalValues[valKey])) {
        return {
          ...data,
          [valKey]: finalValues[valKey],
        };
      }

      return data;
    }, {});

    if (plan.currency === null) {
      updatedData = {
        ...updatedData,
        currency: values.currency,
      };
    }

    if (plan.timezone === null) {
      updatedData = {
        ...updatedData,
        timezone: values.timezone,
      };
    }

    if (updatedData.autoFreezeAfter && !autoFreezeAfterEnabled) {
      delete updatedData.autoFreezeAfter;
    }

    return [updatedData, finalValues, finalInitialValues];
  }, [
    getFinalFrequencyBeginEndFormItemsValues,
    internalInitialValues,
    plan,
    autoFreezeAfterEnabled,
  ]);

  const formIsPristine = useMemo(() => {
    const [updatedData] = getFormUpdatedData(formValues);

    return Object.keys(updatedData).length === 0;
  }, [formValues, getFormUpdatedData]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    if (changedValues.scope !== undefined) {
      setScopeValue(changedValues.scope);

      if (inPlanCreationFlow) {
        scrollToNextStep();
      }
    }

    if (changedValues.autoFreezeEnabled !== undefined) {
      setAutoFreezeAfterEnabled(changedValues.autoFreezeEnabled);
    }

    handleFrequencyBeginEndFormItemsChange(changedValues, allValues);

    setFormValues(allValues);
  });

  const handleFinish = useCallback((values) => {
    const [updatedData, finalValues] = getFormUpdatedData(values);

    let updatePlan = (quotaId = undefined) => {
      let updatedPlanData = updatedData;

      if (plan.injections.length > 0 && updatedData.periodType !== undefined && plan.periodType !== updatedData.periodType) {
        updatedPlanData = {
          ...updatedPlanData,
          injections: [],
        };
      }

      const hasAutoFreezeEnabled = 'autoFreezeEnabled' in updatedPlanData;
      const hasAutoFreezeAfter = 'autoFreezeAfter' in updatedPlanData;

      if (hasAutoFreezeEnabled || (hasAutoFreezeAfter && autoFreezeAfterEnabled)) {
        updatedPlanData = {
          ...updatedPlanData,
          autofreeze: {
            enabled: updatedPlanData.autoFreezeEnabled || autoFreezeAfterEnabled,
            ...(autoFreezeAfterEnabled ? { freezeAfter: updatedPlanData.autoFreezeAfter ?? undefined } : undefined),
          },
        };
      }

      dispatch(MasterPlansActions.updatePlan({
        planId: plan.id,
        ...updatedPlanData,
        quotaId,
      }));
    };

    const checkQuota = (updatePlanFunc) => {
      const quotaIsNotCompatible = quota !== null
        && (
          quota.periodType !== finalValues.periodType
          || (quota.global && finalValues.scope !== SCOPES.TEAM)
          || (finalValues.scope === SCOPES.TEAM && !quota.global)
        );
      if (
        quotaIsNotCompatible
      ) {
        if (quota.periodType === PERIOD_TYPES.CUSTOM || quota.global) {
          dispatch(QuotasActions.deleteById({ quotaId: quota.id, onSuccessCB: () => updatePlanFunc(null) }));
        } else {
          updatePlanFunc(null);
        }
      } else {
        updatePlanFunc();
      }
    };

    if (inPlanCreationFlow) {
      const onSuccessCB = (newPlanId = plan.id) => {
        onSuccessSaveGeneralSettingsCB(newPlanId);
      };
      if (plan.id === '') {
        dispatch(MasterPlansActions.createPlan({
          ...plan,
          ...updatedData,
          creationFlowStep: CREATE_PLAN_FLOW_STEPS.STEP_GENERAL.value,
          onSuccessCB,
        }));
      } else if (Object.keys(updatedData).length > 0) {
        updatePlan = (quotaId = undefined) => {
          dispatch(MasterPlansActions.updatePlan({
            planId: plan.id,
            ...updatedData,
            disableSuccessNotification: true,
            onSuccessAfterUpdatesCB: onSuccessCB,
            quotaId,
          }));
        };

        checkQuota(updatePlan);
      } else {
        onSuccessCB();
      }
    } else if (Object.keys(updatedData).length > 0) {
      checkQuota(updatePlan);
    }
  }, [
    plan,
    quota,
    getFormUpdatedData,
    inPlanCreationFlow,
    onSuccessSaveGeneralSettingsCB,
    autoFreezeAfterEnabled,
  ]);

  const handleUpdatePlan = useCallback((successSaveGeneralSettingsCB = null) => {
    if (successSaveGeneralSettingsCB !== null) {
      setOnSuccessSaveGeneralSettingsCB(() => successSaveGeneralSettingsCB);
    }

    form.submit();
  }, [form]);

  useEffect(() => {
    if (inPlanCreationFlow && inPlanCreationFlowSetSaveCB !== null) {
      inPlanCreationFlowSetSaveCB(handleUpdatePlan);
    }
  }, [inPlanCreationFlow, inPlanCreationFlowSetSaveCB, handleUpdatePlan]);

  const handleShowHistorySelectPlanLink = () => {
    showHistoryPlanSelect(true);

    form.setFieldsValue({ parentPlan: null });
  };

  const handleRemoveParentPlanLink = () => {
    showHistoryPlanSelect(false);

    form.setFieldsValue({ parentPlan: null });

    form.submit();
  };

  const liveSettingsNode = useMemo(() => {
    if (inPlanCreationFlow) return null;

    return (
      <div className={styles.liveWrapper}>
        <div className={styles.liveSwitchWrapper}>
          <FormItem
            className={styles.liveSwitch}
            name="live"
            required
          >
            <Switch disabled={createOrUpdateIsPending} />
          </FormItem>
          <div className={styles.liveLabel}>
            {t('masterPlanSettingsGeneral.form.live.label')}
          </div>
        </div>
        <div className={styles.liveDescription}>
          {t('masterPlanSettingsGeneral.form.live.description')}
        </div>
      </div>
    );
  }, [inPlanCreationFlow]);

  const freezeSettingsNode = useMemo(() => {
    if (inPlanCreationFlow) return null;

    return (
      <div className={styles.freezeSettingsWrapper}>
        <div className={styles.freezeSettingsTitle}>
          {t('masterPlanSettingsGeneral.freezeSettings.title')}
        </div>
        <div className={styles.freezeSettingsSubTitle}>
          {t('masterPlanSettingsGeneral.freezeSettings.subtitle.autofreeze')}
        </div>
        <div className={styles.autoFreezeSwitchWrapper}>
          <FormItem
            className={styles.autoFreezeSwitch}
            name="autoFreezeEnabled"
            required
          >
            <Switch disabled={createOrUpdateIsPending} />
          </FormItem>
          <div className={styles.autoFreezeLabel}>
            {t('masterPlanSettingsGeneral.freezeSettings.autoFreeze.label1')}
            <FormItem name="autoFreezeAfter" className={styles.autoFreezeFormItem}>
              <Input
                className={styles.autoFreezeFormInput}
                type="number"
                placeholder="0"
                min={0}
                disabled={createOrUpdateIsPending || !autoFreezeAfterEnabled}
              />
            </FormItem>
            {t('masterPlanSettingsGeneral.freezeSettings.autoFreeze.label2')}
          </div>
        </div>
        <div className={styles.freezeSettingsSubTitle}>
          {t('masterPlanSettingsGeneral.freezeSettings.subtitle.type')}
        </div>
        <div className={styles.freezeSettingsDescription}>
          {t('masterPlanSettingsGeneral.freezeSettings.description')}
        </div>
        <FormItem
          name="freezeType"
          label={(
            <div className={styles.freezeSettingsLabel}>
              {t('masterPlanSettingsGeneral.form.freezeType.label')}
              <Tooltip title={t('masterPlanSettingsGeneral.form.freezeType.tooltip')}>
                <InfoCircleLine className={styles.freezeSettingsTooltipIcon} />
              </Tooltip>
            </div>
          )}
          required
        >
          <Select size="big" options={freezeTypesOptions} disabled={createOrUpdateIsPending} />
        </FormItem>
      </div>
    );
  }, [
    inPlanCreationFlow,
    createOrUpdateIsPending,
    autoFreezeAfterEnabled,
  ]);

  const payoutsPercentageSettingsNode = useMemo(() => {
    if (!isSuperAdmin() || inPlanCreationFlow) return null;

    return (
      <div className={styles.payoutsPercentageSettingsWrapper}>
        <div className={styles.payoutsPercentageSettingsTitle}>
          {t('masterPlanSettingsGeneral.payoutsPercentageSettings.title')}
        </div>
        <div className={styles.allowWrongPayoutsPercentageSwitchWrapper}>
          <FormItem
            className={styles.allowWrongPayoutsPercentageSwitch}
            name="allowWrongPayoutsPercentageValue"
            required
          >
            <Switch disabled={createOrUpdateIsPending} />
          </FormItem>
          <div className={styles.allowWrongPayoutsPercentageLabel}>
            {t('masterPlanSettingsGeneral.form.allowWrongPayoutsPercentage.label')}
          </div>
        </div>
      </div>
    );
  }, [inPlanCreationFlow, createOrUpdateIsPending]);

  const historySettingsNode = useMemo(() => {
    if (inPlanCreationFlow) return null;

    let hasLink = false;
    let description = t('masterPlanSettingsGeneral.historySettings.description.empty');

    if (plan.parentPlan) {
      const parentPlan = plan.parentName || parentPlanFromState.name;

      hasLink = true;
      description = t('masterPlanSettingsGeneral.historySettings.description', { name: parentPlan });
    }

    return (
      <div className={styles.historySettingsWrapper}>
        <div className={styles.historySettingsTitle}>
          {t('masterPlanSettingsGeneral.historySettings.title')}
        </div>
        <div className={styles.historyDescriptionContainer}>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={styles.historySettingsDescription} dangerouslySetInnerHTML={{ __html: description }} />
          {hasLink && (
            <div className={styles.historySettingsActions}>
              <Link
                className={styles.viewPlan}
                path={routePaths.v2.planDetails}
                params={{
                  masterPlanId: plan.parentPlan,
                }}
                target="_blank"
              >
                <EyesLine className={styles.viewPlanIcon} width={16} height={16} />
                <span>
                  {t('masterPlanSettingsGeneral.historySettings.viewPlan')}
                </span>
              </Link>
              <Button
                className={styles.deleteLinkBtn}
                type="linkDestroy"
                icon={(<TrashFilled width={16} height={16} />)}
                onClick={handleRemoveParentPlanLink}
                disabled={createOrUpdateIsPending}
              >
                {t('masterPlanSettingsGeneral.historySettings.removeLink')}
              </Button>
            </div>
          )}
        </div>
        {!historyPlanSelectShowed && !hasLink && (
          <Button
            className={styles.historyDefineBtn}
            type="link"
            icon={<AddFilled width={18} height={18} />}
            onClick={handleShowHistorySelectPlanLink}
          >
            {t('masterPlanSettingsGeneral.historySettings.defineLink')}
          </Button>
        )}
        <FormItem
          className={classNames({
            historyFormItem: true,
            hidden: !historyPlanSelectShowed || hasLink,
          })}
          name="parentPlan"
          label={t('masterPlanSettingsGeneral.historySettings.form.parentPlan.label')}
        >
          <MasterPlansListSelect
            placeholder={t('masterPlanSettingsGeneral.historySettings.form.parentPlan.placeholder')}
            size="big"
            disabled={createOrUpdateIsPending}
            filterOnPeriodType={plan.periodType}
            exceptPlanIds={[plan.id]}
          />
        </FormItem>
      </div>
    );
  }, [
    inPlanCreationFlow,
    historyPlanSelectShowed,
    createOrUpdateIsPending,
    plan.parentPlan,
    plan.parentName,
  ]);

  const actionsNode = useMemo(() => {
    if (inPlanCreationFlow) return null;

    let archiveActionNode = null;
    let deleteActionNode = null;

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE]) && isInPast(plan) && !isArchived(plan)) {
      archiveActionNode = (
        <div className={styles.actionItem}>
          <div className={styles.title}>
            {t('masterPlanSettingsGeneral.archiveAction.title')}
          </div>
          <div className={styles.content}>
            <div className={styles.description}>
              {t('masterPlanSettingsGeneral.archiveAction.description')}
            </div>
            <Button
              className={styles.actionItemBtn}
              type="secondary"
              onClick={() => showArchiveMasterPlanModal(true)}
              disabled={createOrUpdateIsPending || archivePlanIsPending}
            >
              {t('masterPlanSettingsGeneral.archiveAction.btnLabel')}
            </Button>
          </div>
          {archiveMasterPlanModalIsVisible && (
            <ArchiveMasterPlanModal visible onClose={() => showArchiveMasterPlanModal(false)} plan={plan} />
          )}
        </div>
      );
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DELETE])) {
      deleteActionNode = (
        <div
          className={classNames({
            actionItem: true,
            isNotAlone: archiveActionNode !== null,
          })}
        >
          <div className={styles.title}>
            {t('masterPlanSettingsGeneral.deleteAction.title')}
          </div>
          <div className={styles.content}>
            <div className={styles.description}>
              {t('masterPlanSettingsGeneral.deleteAction.description')}
            </div>
            <Button
              className={styles.actionItemBtn}
              type="dangerSecondary"
              onClick={() => showDeleteMasterPlanModal(true)}
              disabled={createOrUpdateIsPending}
            >
              {t('masterPlanSettingsGeneral.deleteAction.btnLabel')}
            </Button>
          </div>
          {deleteMasterPlanModalIsVisible && (
            <DeleteMasterPlanModal visible onClose={() => showDeleteMasterPlanModal(false)} plan={plan} />
          )}
        </div>
      );
    }

    return (
      <div className={styles.actions}>
        <Button className={styles.submitBtn} onClick={handleUpdatePlan} disabled={formIsPristine || createOrUpdateIsPending}>
          {t('masterPlanSettingsGeneral.form.submitBtnLabel')}
        </Button>
        {(archiveActionNode || deleteActionNode) && (
          <div className={styles.moreActions}>
            {archiveActionNode}
            {deleteActionNode}
          </div>
        )}
      </div>
    );
  }, [
    profile,
    inPlanCreationFlow,
    formIsPristine,
    createOrUpdateIsPending,
    archivePlanIsPending,
    deleteMasterPlanModalIsVisible,
    archiveMasterPlanModalIsVisible,
  ]);

  const warningQuotaNode = useMemo(() => {
    if (quota === null) return null;

    if (
      quota.periodType !== periodTypeValue
      || (quota.global && scopeValue !== SCOPES.TEAM)
      || (scopeValue === SCOPES.TEAM && !quota.global)
    ) {
      return (
        <Alert
          className={styles.quotaWarning}
          type={ALERT_TYPES.WARNING}
          message={t('masterPlanSettingsGeneral.quotaWarning.message')}
          description={t('masterPlanSettingsGeneral.quotaWarning.description')}
        />
      );
    }

    return null;
  }, [quota, periodTypeValue, scopeValue]);

  const warningDependenciesNode = useMemo(() => {
    if (plan.injections.length === 0) return null;

    if (plan.periodType !== periodTypeValue) {
      return (
        <Alert
          className={styles.dependenciesWarning}
          type={ALERT_TYPES.WARNING}
          message={t('masterPlanSettingsGeneral.dependenciesWarning.message')}
          description={t('masterPlanSettingsGeneral.dependenciesWarning.description')}
        />
      );
    }

    return null;
  }, [plan, periodTypeValue]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <MasterPlanSettingsGeneralNameFormItem disabled={createOrUpdateIsPending} />
        <MasterPlanSettingsGeneralScopeFormItem disabled={createOrUpdateIsPending} />
        <MasterPlanSettingsGeneralFrequencyBeginEndFormItems
          periodTypeValue={periodTypeValue}
          frequencyValue={frequencyValue}
          beginPeriodValue={beginPeriodValue}
          displayEndPeriod={displayEndPeriod}
          endPeriodValue={endPeriodValue}
          fiscalYearShiftValue={fiscalYearShiftValue}
          customBeginAtValue={customBeginAtValue}
          handleSetEndPeriod={handleSetEndPeriod}
          handleRemoveEndPeriod={handleRemoveEndPeriod}
          disabled={createOrUpdateIsPending}
        />
        <div className={styles.currencyTimezoneWrapper}>
          <div className={styles.rowFieldItem}>
            <FormItem
              className={styles.rowFieldSelect}
              name="timezone"
              label={t('masterPlanSettingsGeneral.form.timezone.label')}
            >
              <TimezoneSelect size="big" disabled={createOrUpdateIsPending} />
            </FormItem>
          </div>
          <div className={styles.rowFieldItem}>
            <FormItem
              className={styles.rowFieldSelect}
              name="currency"
              label={t('masterPlanSettingsGeneral.form.currency.label')}
              required
            >
              <CompanyCurrencySelect
                size="big"
                disabled={createOrUpdateIsPending}
                enableMixedCurrencyOption
              />
            </FormItem>
            <Link
              className={styles.addCurrencyLink}
              path={routePaths.v2.settings}
            >
              <AddFilled className={styles.addCurrencyLinkIcon} width={18} height={18} />
              <span>
                {t('masterPlanSettingsGeneral.form.currency.addNew.label')}
              </span>
            </Link>
          </div>
        </div>
        {liveSettingsNode}
        {freezeSettingsNode}
        {payoutsPercentageSettingsNode}
        {historySettingsNode}
        {warningQuotaNode}
        {warningDependenciesNode}
        {actionsNode}
      </Form>
    </div>
  );
};

MasterPlanSettingsGeneral.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string,
  inPlanCreationFlow: PropTypes.bool,
  inPlanCreationFlowSetSaveCB: PropTypes.func,
};

MasterPlanSettingsGeneral.defaultProps = {
  className: '',
  planId: null,
  inPlanCreationFlow: false,
  inPlanCreationFlowSetSaveCB: null,
};

export default MasterPlanSettingsGeneral;
