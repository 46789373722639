import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import Loader from '@palette/components/utils/Loader/Loader';
import ChallengeListForUser from '@palette/components/userDetails/ChallengeListForUser/ChallengeListForUser';

import { CHALLENGES_PAGE_CONTENT_QS_KEY, CHALLENGES_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as ChallengesActions, selectors as ChallengesSelectors } from '@palette/state/Challenges';

import styles from './UserDetailsChallengesTab.less';

const classNames = bindClassNames.bind(styles);

const UserDetailsChallengesTab = ({ className, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getChallengeListForUserIsPending = useSelector(ChallengesSelectors.getChallengeListForUserIsPending);
  const challengeListForUser = useSelector(ChallengesSelectors.getChallengeListForUser);

  useEffect(() => {
    if (!getChallengeListForUserIsPending) {
      dispatch(ChallengesActions.getChallengeListForUser({ user }));
    }
  }, [user]);

  const tabs = useMemo(() => (
    Object.values(CHALLENGES_PAGE_CONTENT_TABS_IDS).map(
      (type) => ({
        title: t(`challenges.tabs.${type}`),
        titleCount: challengeListForUser.stats[type] || 0,
        content: (
          <ChallengeListForUser challengeList={challengeListForUser[type]} listType={type} user={user} />
        ),
        defaultActive: type === CHALLENGES_PAGE_CONTENT_TABS_IDS.ACTIVE,
        tabKey: type,
      }),
    )
  ), [challengeListForUser, user]);

  const tabsNode = useMemo(() => {
    if (getChallengeListForUserIsPending) {
      return (
        <Loader />
      );
    }

    return (
      <Tabs
        className={styles.tabs}
        tabs={tabs}
        qsTabKey={CHALLENGES_PAGE_CONTENT_QS_KEY}
      />
    );
  }, [getChallengeListForUserIsPending, tabs]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {tabsNode}
    </div>
  );
};

UserDetailsChallengesTab.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserDetailsChallengesTab.defaultProps = {
  className: '',
};

export default UserDetailsChallengesTab;
