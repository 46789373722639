import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import MyUserStatementChallengesItem from '@palette/components/ic/myStatement/MyUserStatementChallengesItem/MyUserStatementChallengesItem';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './MyUserStatementChallenges.less';

const classNames = bindClassNames.bind(styles);

const MyUserStatementChallenges = ({ className, userStatement }) => {
  const { t } = useTranslation();

  const titleNode = useMemo(() => (
    <div className={styles.title}>
      {t('userStatementChallenges.collapse.title', { count: userStatement.challenges.length })}
    </div>
  ), [userStatement]);

  const extraNode = useMemo(() => (
    <div className={styles.extraDetails}>
      <div className={styles.totalLabel}>
        {t('userStatementChallenges.collapse.total')}
      </div>
      <div className={styles.totalAmount}>
        {formatPrice(userStatement.challengesTotalAmount, userStatement.currency)}
      </div>
    </div>
  ), [userStatement]);

  const contentNode = useMemo(() => {
    const challengeItemsNodes = userStatement.challenges.map((challenge) => (
      <MyUserStatementChallengesItem
        key={challenge.id}
        className={styles.challengeItem}
        challenge={challenge}
      />
    ));

    return (
      <div>
        <div className={styles.challengesListHeader}>
          <span className={styles.colRow}>{t('userStatementChallenges.collapse.nameLabel')}</span>
          <span className={styles.colRow}>{t('userStatementChallenges.collapse.commissionLabel')}</span>
        </div>
        {challengeItemsNodes}
      </div>
    );
  }, [userStatement]);

  if (userStatement.challenges.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Collapse
        className={styles.collapseContainer}
        panels={[{
          title: titleNode,
          extra: extraNode,
          content: contentNode,
        }]}
        keyValue={userStatement.id}
        defaultActiveKey={userStatement.id}
      />
    </div>
  );
};

MyUserStatementChallenges.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes.isRequired,
};

MyUserStatementChallenges.defaultProps = {
  className: '',
};

export default MyUserStatementChallenges;
